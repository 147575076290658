import React from 'react';
import LineGraph, { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { fetchConnector } from '../../pnlAnalytics/dataFetcher';
import { FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { companyFixedNamesMap } from '../Filters';
import { optNumberFormat } from '../../containers/pivotHelper';

const dollarVal = (val: number) => optNumberFormat(val, 0, true, false, false);

let graphConfig: LineGraphConfig = {
    title: '5 Year Vessel Running Daily Cost (Per Quarter no DryDock)',
    dataKey: 'journal_quarter',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => d,
    xTooltipFormatter: (d) => d,
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => dollarVal(v),
    },
    yTooltipFormatter: (v) => dollarVal(v),
    chartsSettings: [],
};

type Props = FetchAccAnalyticsPropsFromRedux & {};

const VesselHistCostGraph = (props: Props) => {
    const histVeselCostData = props.pnlAnalytics.accounting_danaos_vessels;

    if (histVeselCostData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={histVeselCostData}
                minHeight={'500px'}
            />
        );
    }

    let respData = histVeselCostData.data! as AccountingDanaosVessels;

    graphConfig.chartsSettings = respData.cost_sorted_vessel_names.map((companyCode) => {
        let companyMapping = companyFixedNamesMap.get(companyCode);
        return {
            chartType: ChartType.AreaAsLine,
            key: `${companyCode}|per_day_amount`,
            color: companyMapping!.backgroundColor,
            title: companyMapping!.title,
        };
    });

    return <LineGraph {...graphConfig} values={respData.hist_vessel_cost} />;
};

export default fetchConnector(VesselHistCostGraph);
