import React from 'react';
import { AllTradesPropsType, ExchangeTradesPropsType } from '../Types';
import { PriceAField, ProductAField } from './ProductA';
import { ProductBField } from './ProductB';

interface ProductFieldsProps extends ExchangeTradesPropsType {}

export function ProductFields(props: ProductFieldsProps) {
    let allTradesProps: AllTradesPropsType = {
        state: props.state,
        setState: newFields => {
            props.setState({ ...props.state, ...newFields });
        },
        options: props.options,
        disabled: props.disabled
    };
    return (
        <div>
            <ProductAField {...props} />
            <ProductBField {...props} />
            <PriceAField {...allTradesProps} />
        </div>
    );
}
