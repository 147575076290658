import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { companyFixedNamesMap } from '../Filters';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels, MoveData } from '../../pnlAnalytics/fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { CostCellComp } from './CostCell';

const useStyles = makeStyles(() => ({
    groupCostColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '80px !important',
        // fontSize: '15px !important',
    },
    balanceSheetColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '300px !important',
        // fontSize: '15px !important',
    },
    cardColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '300px !important',
        // fontSize: '15px !important',
    },
    totalColumn: {
        borderLeft: '#bfbfbf solid 1px',
    },
}));

type Props = FetchAccAnalyticsPropsFromRedux &
    RouteComponentProps & {
        // minHeight: string;
        // allColumnNamesKey: string;
        uniqueTableName: UniqueTableName;
        title: string;
        tableDataKey: string;
        reportValFormatter: (val: number) => string;
        moveValFormatter: (val: number) => string;
        zeroThreshold: number;
    };

const GRAND_TOTAL_ROW = 'Running Inc. Drydock';

export const staticColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'group_cost',
        title: 'Group Cost',
        bodyTemplate2: (val, _) => {
            if (val === 'Running Cost') return 'Running';
            if (val === 'Drydock Cost') return 'DryDock';
            if (val === GRAND_TOTAL_ROW) return 'Total';
            return val;
        },
    },
    {
        name: 'bsg_code',
        title: 'Balance Sheet Group',
        bodyTemplate2: (val, row) => {
            if (val === GRAND_TOTAL_ROW) return val;
            if (val === '') return '';
            return `[${val}] ${row['name']}`;
        },
        hoverElement: (row, _) => {
            let ledger_cards = row['missing_ledger_cards'];
            if (!ledger_cards) return '';
            return (
                <div>
                    <p>The bellow ledger cards where not shown as they resulted in 0 values</p>
                    <p>{ledger_cards}</p>
                </div>
            );
        },
    },
    {
        name: 'ledger_card_code',
        title: 'Ledger Card',
        bodyTemplate2: (val, row) => {
            if (!val) return val;
            return `[${val}] ${row['name']}`;
        },
    },
];

const VesselRunningCostComp = (props: Props) => {
    const classes = useStyles();
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'790px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    staticColumns[0].headerCellClassName = classes.groupCostColumn;
    staticColumns[1].headerCellClassName = classes.balanceSheetColumn;
    staticColumns[2].headerCellClassName = classes.cardColumn;

    let data = respData[props.tableDataKey as keyof AccountingDanaosVessels] as MoveData;

    let columns: Array<string> = respData.cost_sorted_vessel_names.concat(['Total']);
    let compColumnsConfig: Array<SimpleTableColumnConfig> = columns.map((columnName) => {
        return {
            name: `${columnName}|amount_report`,
            title: (companyFixedNamesMap.get(columnName)?.title as string) || columnName,
            customDisplay: (row: SimpleTableRow, rawVal: any) => {
                return CostCellComp(
                    filters,
                    columnName,
                    row,
                    rawVal,
                    data,
                    '12px',
                    props.reportValFormatter,
                    props.moveValFormatter,
                    props.zeroThreshold
                );
            },
        };
    });

    compColumnsConfig[compColumnsConfig.length - 1].headerCellClassName = classes.totalColumn;
    compColumnsConfig[compColumnsConfig.length - 1].bodyCellsClassName = classes.totalColumn;

    const config: ExpandableTableConfig = {
        uniqueTableName: props.uniqueTableName,
        title: props.title,
        size: TableSize.Medium,
        columns: staticColumns.concat(compColumnsConfig),
        grandTotal: true,
        grandTotalRowName: GRAND_TOTAL_ROW,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 2,
        defaultExpandable: 1,
        stickyHeader: true,
    };

    return (
        <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
            <ExpandableTable data={data.data} config={config} />
        </Grid>
    );
};
export default accFetchConnector(VesselRunningCostComp);
