import React from 'react';
import { CorpBookColor, ForwardBookColor, ProductBookColor, ShippingBookColor, TotalBookColor } from '../../common/stylesConsts';
import moment from 'moment';
import { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import CommonPNLSummaryGraph from './CommonPNLSummaryGraphs';
import { kFormatter } from '../../containers/pivotHelper';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { STANDARD_GRAPH_ELEMENT_HEIGHT } from '../../common/common';
import { PNLMetaDataResp } from '../fetchTypes';

let graphConfig: LineGraphConfig = {
    title: 'SwissChemGas VaR',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => moment(d).format('DD MMM'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: [0, 100_000_000], // Default stupid number for limit
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    referenceLines: [{ y: 90_000_000, tooltipLabel: 'VaR Limit', stroke: 'red', strokeWidth: 3, strokeDasharray: '3 3' }], // Default stupid number for limit
    chartsSettings: [
        { chartType: ChartType.AreaAsLine, key: 'scg_var_95', color: TotalBookColor, title: 'Total' },
        {
            chartType: ChartType.AreaAsLine,
            key: 'scg_var_95_m_y1',
            color: TotalBookColor,
            title: 'Total Y-1',
            strokeDasharray: '6 6',
            strokeOpacity: 0.5,
            fillOpacity: 0,
        },
        { chartType: ChartType.AreaAsLine, key: 'product_var_95', color: ProductBookColor, title: 'Product' },
        { chartType: ChartType.AreaAsLine, key: 'shipping_var_95', color: ShippingBookColor, title: 'Shipping' },
        { chartType: ChartType.AreaAsLine, key: 'forward_var_95', color: ForwardBookColor, title: 'Forward', hiddenOnLoad: true },
        { chartType: ChartType.AreaAsLine, key: 'corp_var_95', color: CorpBookColor, title: 'Corp' },
        {
            chartType: ChartType.AreaAsLine,
            key: 'corp_var_95_m_y1',
            color: CorpBookColor,
            title: 'Corp Y-1',
            strokeDasharray: '6 6',
            strokeOpacity: 0.3,
            fillOpacity: 0,
            hiddenOnLoad: true,
        },
    ],
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const VARGraph = (props: Props) => {
    const metaData = props.pnlAnalytics.meta;

    if (metaData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics}
                data={metaData}
                minHeight={STANDARD_GRAPH_ELEMENT_HEIGHT}
            />
        );
    }

    let respData = metaData.data! as PNLMetaDataResp;

    let varLimit = 2_500_000;
    if (respData.selected.pipe_run_date <= '2023-06-30') {
        varLimit = 1_200_000;
    }
    graphConfig['yAxis']['yLimit'][1] = varLimit + 100_000;
    graphConfig['referenceLines']![0]['y'] = varLimit;

    return <CommonPNLSummaryGraph historicalPNLPerBookMetricsDataKey={'pnl_summary_graphs'} config={graphConfig} />;
};

export default fetchConnector(VARGraph);
