import axios, { AxiosError, Canceler, Method } from 'axios';
import { config } from '../config';
import { AuthUserState } from '../reducers/authUser';
import { getAuthUser } from './auth';
import { MainSnackbar } from './SwissSnackBar';

axios.interceptors.request.use((request) => {
    return request;
});

export type CancelRequest = (message?: string) => void;

export interface ErrorContentType {
    parsedMsg: string;
    originalMsg: string;
    jsonError: { [_: string]: any };
    status?: number;
}

const getAxiosErrorMessage = (error: AxiosError): ErrorContentType => {
    let parsedMsg, originalMsg, status;
    let jsonError = {};
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        status = error.response.status;
        jsonError = error.response.data;
        originalMsg = JSON.stringify(error.response.data);
        parsedMsg = `We got an error response back of ${error.response.status} with data ${originalMsg}`;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        originalMsg = `${error}`;
        parsedMsg = `We didn't get a response and have the generic error of ${error}`;
    } else {
        // Something happened in setting up the request that triggered an Error
        originalMsg = `${error}`;
        parsedMsg = `Unknown Error occurred: ${error}`;
    }
    return {
        parsedMsg,
        originalMsg,
        jsonError,
        status,
    };
};

export interface BaseFetchConfig {
    url: string;
    params?: any;
    headers?: any;
    data?: any;
    onStart?: (cancelRequest: CancelRequest) => void;
    onFinal?: () => void;
}

export interface FetchConfig<T> extends BaseFetchConfig {
    onSuccess: (jsonRes: T) => void;
    onFail: (errMsg: ErrorContentType) => void;
}

export interface MethodFetchConfig<T> extends FetchConfig<T> {
    method: Method;
    headers?: any;
}

export function fetcher<T>(methodFetchConfig: MethodFetchConfig<T>): void {
    let cancel: Canceler;
    const cancelToken = new axios.CancelToken((c) => {
        cancel = c;
    });
    if (methodFetchConfig.onStart)
        methodFetchConfig.onStart((message) => {
            cancel(message);
        });
    axios({
        method: methodFetchConfig.method,
        baseURL: config.BACKEND_URL,
        url: methodFetchConfig.url,
        params: methodFetchConfig.params,
        headers: methodFetchConfig.headers,
        data: methodFetchConfig.data,
        cancelToken: cancelToken,
    })
        .then((response) => {
            if (response.status < 200 && response.status >= 300) {
                let errMsg = `We were expecting return code 2xx but got ${response.status}`;
                methodFetchConfig.onFail({
                    parsedMsg: errMsg,
                    originalMsg: errMsg,
                    jsonError: {},
                    status: response.status,
                });
                return;
            }
            if (response.data.constructor !== {}.constructor) {
                let errMsg = `The data we got are not JSON '${response.data}'`;
                methodFetchConfig.onFail({
                    parsedMsg: errMsg,
                    originalMsg: errMsg,
                    jsonError: {},
                    status: response.status,
                });
                return;
            }
            methodFetchConfig.onSuccess(response.data);
        })
        .catch(function (error) {
            if (axios.isCancel(error)) {
                console.log(`Request ${methodFetchConfig.url} was cancelled`);
                return;
            }
            methodFetchConfig.onFail(getAxiosErrorMessage(error));
        })
        .then(() => {
            if (methodFetchConfig.onFinal) methodFetchConfig.onFinal();
        });
}

export function getFetcher<T>(fetchConfig: FetchConfig<T>): void {
    fetcher<T>({
        ...fetchConfig,
        method: 'GET',
    });
}

export enum NewFetchStatus {
    INIT = 'INIT',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export interface NewFetchData<ResponseData> {
    status: NewFetchStatus;
    data?: ResponseData;
    err?: ErrorContentType;
    cancelRequest?: CancelRequest;
}

export function initNewFetchData<ResponseData>(): NewFetchData<ResponseData> {
    return { status: NewFetchStatus.INIT };
}

export interface SimpleSuccess<ResponseData> {
    onSimpleSuccess: (respData: ResponseData) => void;
}

export interface NewSimpleFetchConfig extends BaseFetchConfig {
    method: Method;
    onFail?: (errMsg: ErrorContentType) => void;
    appStore: {
        enableBackdropSpinner: () => void;
        disableBackdropSpinner: () => void;
    };
    mainSnackbar: MainSnackbar;
    authUserState: AuthUserState;
    withBackdropSpinner?: boolean;
    withErrorSnackbar?: boolean;
}

export interface NewSimpleSuccessFetchConfig<ResponseData> extends NewSimpleFetchConfig, SimpleSuccess<ResponseData> {}

export function newSimpleFetcher<ResponseData>(newFetchConfig: NewSimpleSuccessFetchConfig<ResponseData>): void {
    // let dataKey = newFetchConfig.appStoreDataKey;
    // let updateAppStoreDataKey = newFetchConfig.appStoreUpdateDataKey ? newFetchConfig.appStoreUpdateDataKey : `${dataKey}Update`;
    // let updateNewFetchData = (newFetchConfig.appStore as any)[updateAppStoreDataKey] as unknown as (
    //     newFetchData: NewFetchData<StoreData>
    // ) => void;

    if (!newFetchConfig.headers) {
        newFetchConfig.headers = {};
    }
    if (!('Swiss-Tech-Auth-Token' in newFetchConfig.headers)) {
        newFetchConfig.headers['Swiss-Tech-Auth-Token'] = getAuthUser(newFetchConfig.authUserState).swiss_token;
    }

    return fetcher<ResponseData>({
        ...newFetchConfig,
        onStart: (cancelRequest) => {
            if (newFetchConfig.withBackdropSpinner) newFetchConfig.appStore.enableBackdropSpinner();
            newFetchConfig.onStart?.(cancelRequest);
        },
        onSuccess: (respData) => {
            newFetchConfig.onSimpleSuccess(respData);
        },
        onFail: (err) => {
            if (newFetchConfig.withErrorSnackbar) newFetchConfig.mainSnackbar.showErrorSnackbar(err.parsedMsg);
            newFetchConfig.onFail?.(err);
        },
        onFinal: () => {
            if (newFetchConfig.withBackdropSpinner) newFetchConfig.appStore.disableBackdropSpinner();
            newFetchConfig.onFinal?.();
        },
    });
}

export interface StoreSuccess<ResponseData, StoreData> {
    onStoreSuccess: (respData: ResponseData) => StoreData;
}

export interface NewFetchConfig<ResponseData, StoreData> extends NewSimpleFetchConfig, StoreSuccess<ResponseData, StoreData> {
    appStoreDataKey: string;
    appStoreUpdateDataKey?: string; // If empty, then just assume update<appStoreDataKey>
}

export function newFetcher<ResponseData, StoreData = ResponseData>(newFetchConfig: NewFetchConfig<ResponseData, StoreData>): void {
    let dataKey = newFetchConfig.appStoreDataKey;
    let updateAppStoreDataKey = newFetchConfig.appStoreUpdateDataKey ? newFetchConfig.appStoreUpdateDataKey : `${dataKey}Update`;
    let updateNewFetchData = (newFetchConfig.appStore as any)[updateAppStoreDataKey] as unknown as (
        newFetchData: NewFetchData<StoreData>
    ) => void;

    if (!newFetchConfig.headers) {
        newFetchConfig.headers = {};
    }
    if ('Swiss-Tech-Auth-Token' in newFetchConfig.headers) {
        newFetchConfig.headers['Swiss-Tech-Auth-Token'] = getAuthUser(newFetchConfig.authUserState).swiss_token;
    }

    return newSimpleFetcher<ResponseData>({
        ...newFetchConfig,
        onStart: (cancelRequest) => {
            if (newFetchConfig.withBackdropSpinner) newFetchConfig.appStore.enableBackdropSpinner();
            updateNewFetchData({ status: NewFetchStatus.LOADING, cancelRequest: cancelRequest });
            newFetchConfig.onStart?.(cancelRequest);
        },
        onSimpleSuccess: (respData) => {
            let storeData = newFetchConfig.onStoreSuccess(respData);
            updateNewFetchData({ status: NewFetchStatus.SUCCESS, data: storeData });
        },
        onFail: (err) => {
            updateNewFetchData({ status: NewFetchStatus.FAILED, err: err });
            if (newFetchConfig.withErrorSnackbar) newFetchConfig.mainSnackbar.showErrorSnackbar(err.parsedMsg);
            newFetchConfig.onFail?.(err);
        },
        onFinal: () => {
            if (newFetchConfig.withBackdropSpinner) newFetchConfig.appStore.disableBackdropSpinner();
            newFetchConfig.onFinal?.();
        },
    });
}

export function postFetcher<T>(fetchConfig: FetchConfig<T>): void {
    fetcher({
        ...fetchConfig,
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...fetchConfig.headers,
        },
    });
}

export function putFetcher<T>(fetchConfig: FetchConfig<T>): void {
    fetcher({
        ...fetchConfig,
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...fetchConfig.headers,
        },
    });
}
