import React from 'react';
import {AllTradesPropsType} from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import {executingForSummary, handleAllTradesSimpleFieldChange} from '../common';

interface ExecutingForFieldProps extends AllTradesPropsType {}

export function ExecutingForField(props: ExecutingForFieldProps) {
    const { state, setState, options } = props;

    return (
        <AutoCompleteField
            options={options.tradersNickNames}
            defaultValue={options.defaultTraderNickName}
            inputValue={state.executingFor.value}
            handleChange={(newValue: string) => {
                handleAllTradesSimpleFieldChange(state, setState, 'executingFor', {
                    value: newValue,
                    error: '',
                    summary: executingForSummary(options.authUserNickName, newValue),
                    required: state.executingFor.required
                });
            }}
            required={state.executingFor.required}
            label={'Executing For'}
            width={140}
            marginLeft={'16px'}
            disabled={props.disabled}
        />
    );
}
