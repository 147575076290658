import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getDate } from '../../common/common';
import { DateType } from '@date-io/type';
import moment from 'moment';
import React from 'react';

export default function TradeDate(props: {
    disabled?: boolean;
    value: string;
    changeValue: (newValue: string) => void;
}) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                disabled={props.disabled}
                InputProps={{}}
                style={{ margin: 0, minHeight: '5rem' }}
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label={'Trade Date *'}
                value={props.value}
                maxDate={getDate(0)}
                disableFuture={true}
                autoOk={true}
                allowKeyboardControl={false}
                onAccept={(date: DateType | null) => {
                    if (date === null) return;
                    let momentDate = moment(date).startOf('day');
                    let dateStr = momentDate.format();
                    props.changeValue(dateStr);
                }}
                onChange={(_: Date | null, __?: string | null) => {}}
            />
        </MuiPickersUtilsProvider>
    );
}
