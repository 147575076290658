import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import { Grid } from '@material-ui/core';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import BankBalances from './BankBalances';
import PaymentsAndIC from './PaymentsAndIC';
import CashRecGroups from './CashRecGroups';
import CashBalanceTimelineGraph from './CashBalanceTimelineGraph';
import SCGOurCashMoves from "./SCGOurCashMoves";

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const CashComp = (props: Props) => {
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingDanaosVessels,
            params: {
                start_date: filters.quickDateSelection!.start_date,
                end_date: filters.quickDateSelection!.end_date,
                cmp_start_date: filters.quickDateSelection!.cmp_start_date,
                cmp_end_date: filters.quickDateSelection!.cmp_end_date,
                company: filters.quickDateSelection!.company,
            },
        });
    }, [props, filters]);

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <BankBalances {...props} />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <PaymentsAndIC {...props} />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <CashRecGroups {...props} />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <SCGOurCashMoves {...props} />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <CashBalanceTimelineGraph {...props} />
            </Grid>
        </React.Fragment>
    );
};

export default accFetchConnector(CashComp);
