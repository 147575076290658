// ==================== ACTIONS ====================
import { Moment } from 'moment-timezone';
import { QuickDate } from '../backAccounting/dataFetcher';

const SET_BA_FILTERS = 'SET_BA_FILTERS';

export interface BackAccountingFiltersState {
    startDate: null | Moment;
    endDate: null | Moment;
    lastEntryDatetime: null | string;
    lastSyncDatetime: null | string;
    validTradeDates: null | Array<string>;
    tradeDate: null | Moment;
    lastAccountingLockedDate: null | string;
    swissYear: null | number;
    includeAdjProj: boolean;
    reversePrevYear: boolean;
    quickDateOptions: null | Array<QuickDate>;
    quickDateSelection: null | string;
    validCashPlanningDates: null | Array<string>;
}

interface SetBAFiltersActionType {
    type: typeof SET_BA_FILTERS;
    payload: BackAccountingFiltersState;
}

export const setBAFiltersAction = (payload: BackAccountingFiltersState): SetBAFiltersActionType => ({
    type: SET_BA_FILTERS,
    payload: payload,
});

export type SetFiltersActionTypes = SetBAFiltersActionType;

// ==================== STORE ====================

const initialState: BackAccountingFiltersState = {
    startDate: null,
    endDate: null,
    lastEntryDatetime: null,
    lastSyncDatetime: null,
    validTradeDates: null,
    tradeDate: null,
    lastAccountingLockedDate: null,
    swissYear: null,
    includeAdjProj: true,
    reversePrevYear: true,
    quickDateOptions: null,
    quickDateSelection: null,
    validCashPlanningDates: null,
};

export const backAccountingReducer = (state = initialState, action: SetFiltersActionTypes): BackAccountingFiltersState => {
    switch (action.type) {
        case SET_BA_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
