import React from 'react';
import { ExchangeTradesPropsType, FieldType } from '../Types';
import { getNewConvertedLegVolumeState, isProductSpread } from '../common';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface DiffFieldProps extends ExchangeTradesPropsType {}

export function DiffField(props: DiffFieldProps) {
    const { state, setState } = props;

    const handleDiffChange = (newDiff: boolean) => {
        let newDiffField: FieldType<boolean> = {
            value: newDiff,
            error: '',
            required: state.diff.required,
            summary: null
        };
        let convertedLegVolume = getNewConvertedLegVolumeState(
            { ...props.state.convertedLegVolume },
            state.productA,
            state.productB,
            newDiffField
        );
        setState({
            ...state,
            diff: newDiffField,
            convertedLegVolume: convertedLegVolume
        });
    };

    let disabled = !isProductSpread(state.productA.value, state.productB.value);

    let diffTooltip: string = disabled
        ? 'Only enabled for product spreads'
        : 'Check this if the trade will come in as ICE/CME diff';

    return (
        <Tooltip title={diffTooltip} placement={'top'}>
            <FormControlLabel
                disabled={disabled || props.disabled}
                style={{ marginBottom: '28px', marginLeft: '16px' }}
                control={
                    <Checkbox
                        checked={state.diff.value}
                        onChange={event => {
                            handleDiffChange(event.target.checked);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') handleDiffChange(!state.diff.value);
                        }}
                        color="primary"
                    />
                }
                label="Diff"
            />
        </Tooltip>
    );
}
