import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import { SimpleTableConfig, TableSize } from '../../tables/types';
import SimpleTable from '../../tables/SimpleTable';
import { redGreenHundred } from '../../containers/pivotHelper';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatterAggWithSign } from '../common/common';
import { exposuresNumFormatter } from '../../common/exposures';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const columns = [
    { name: 'description', title: 'Description' },
    { name: 'pnl', title: 'P&L', bodyTemplate: exposuresNumFormatter, textColor: redGreenHundred },
];

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.AdjPNL,
    title: 'Adjusted P&L',
    size: TableSize.Large,
    columns: columns,
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

const AdjPNLTable = (props: Props) => {
    const allTradesRunDateData = props.pnlAnalytics.all_trades_run_date;

    if (allTradesRunDateData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AllTradesRunDate}
                data={allTradesRunDateData}
                minHeight={'260px'}
            />
        );
    }

    let respData = allTradesRunDateData.data! as AllTradesRunDateDataResp;
    return <SimpleTable data={respData.adj_pnl_table} config={config} />;
};

export default fetchConnector(AdjPNLTable);
