import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CenteredCircularProgress, MIN_PNL_DISPLAY_WIDTH } from '../common/common';
import { prepareData } from '../pnlAnalytics/dataFetcher';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../actions/pnlAnalytics';
import { ErrorPage, NotAuthorizedPage, UserErrorPage } from '../common/error';
import { useDispatch } from 'react-redux';
import { swissUseSelector } from '../reducers';
import { setBAFiltersAction } from '../reducers/backAccounting';
import { BackAccountingFiltersState } from '../reducers/backAccounting';
import { AuthUserState } from '../reducers/authUser';
import { bAccFetchConnector, BAccMetaData, FetchBackAccAnalyticsPropsFromRedux } from './dataFetcher';
import { MainBATabs } from './common';
import SCGPhysical from './scgPhysical/SCGPhysical';
import moment from 'moment-timezone';
import { canAccessBackAccounting } from '../accounting/common';
import { quickDateToStr } from './common/FilterDates';
import CashPlanning from './cashPlanning';
import { getRealUser } from '../common/auth';
import { useZusStore } from '../reducers/appStore';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: ${MIN_PNL_DISPLAY_WIDTH};
`;

const StyledBox = styled(Box)`
    background-color: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const defaultUserTab = new Map<string, MainBATabs>();
defaultUserTab.set('skatsikis@swissmarine.gr', MainBATabs.CashPlanning);
// defaultUserTab.set('filip.theodorakis@swisschemgas.com', MainBATabs.CashPlanning);
defaultUserTab.set('gkoropoulis@swissmarine.gr', MainBATabs.CashPlanning);

const BackAccountingComp = (props: Props) => {
    const authUser = swissUseSelector<AuthUserState>((state) => state.authUser);
    const bAFilterState = swissUseSelector<BackAccountingFiltersState>((state) => state.backAccounting);

    let defaultTab: MainBATabs = defaultUserTab.has(getRealUser(props.authUser).email)
        ? defaultUserTab.get(getRealUser(props.authUser).email)!
        : MainBATabs.SCGPhysical;
    const [mainTab, setMainTabs] = React.useState<MainBATabs>(defaultTab);
    const handleSetMainTab = (event: React.ChangeEvent<{}>, newValue: MainBATabs) => {
        setMainTabs(newValue);
    };
    const { setCashPlanningDateFilter } = useZusStore((state) => state);

    // Filters
    const dispatch = useDispatch();

    useEffect(() => {
        if (!canAccessBackAccounting(props.authUser)) return;
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.BAccountingMeta,
            onSuccess: (data: any) => {
                // Unfortunately we need to do this check here as we don't want the filters to be reset whenever
                // this code executes
                if (bAFilterState.lastSyncDatetime !== null) return;
                let bAccMetaData = data as BAccMetaData;
                dispatch(
                    setBAFiltersAction({
                        startDate: moment(bAccMetaData.start_date).startOf('day'),
                        endDate: moment(bAccMetaData.end_date).startOf('day'),
                        lastSyncDatetime: bAccMetaData.last_sync_datetime,
                        lastEntryDatetime: bAccMetaData.last_entry_datetime,
                        validTradeDates: bAccMetaData.valid_trade_dates,
                        tradeDate: moment(bAccMetaData.trade_date).startOf('day'),
                        lastAccountingLockedDate: bAccMetaData.last_accounting_locked_date,
                        swissYear: bAccMetaData.swiss_year,
                        includeAdjProj: bAccMetaData.include_adj_proj,
                        reversePrevYear: bAccMetaData.reverse_prev_year,
                        quickDateOptions: bAccMetaData.quick_dates,
                        validCashPlanningDates: bAccMetaData.valid_cash_planning_dates,
                        quickDateSelection: quickDateToStr(bAccMetaData.quick_dates[bAccMetaData.default_quick_date_idx]),
                    })
                );
                setCashPlanningDateFilter(bAccMetaData.valid_cash_planning_dates[0]);
            },
        });
    }, [props, dispatch, authUser, bAFilterState, setCashPlanningDateFilter]);
    const backMetaData = props.pnlAnalytics.back_accounting_meta;

    if (!canAccessBackAccounting(props.authUser)) {
        return <NotAuthorizedPage msg={'Not authorized to access Accounting'} />;
    }

    if (backMetaData.status === PNLFetchStatus.INIT) {
        return <CenteredCircularProgress msg={'Initializing ...'} />;
    }
    if (backMetaData.status === PNLFetchStatus.LOADING) {
        return <CenteredCircularProgress msg={'Fetching Back Office Accounting metadata ...'} />;
    }
    if (backMetaData.status === PNLFetchStatus.FAILED) {
        let returnedErrorMsg: undefined | any = backMetaData.err!.jsonError['err_msg'];
        let errMsg: string = returnedErrorMsg ? (returnedErrorMsg as string) : backMetaData.err!.parsedMsg;
        return backMetaData.err?.status === 400 ? <UserErrorPage msg={errMsg} /> : <ErrorPage msg={errMsg} />;
    }

    if (!backMetaData.data) {
        return (
            <ErrorPage msg={'While we should have had Back Office Accounting meta data for some reason we do not. This is our own bug'} />
        );
    }

    return (
        <StyledContainer>
            <div className={'MuiToolbar-dense'} />
            <StyledBox className={'MuiToolbar-gutters'} my={2}>
                <Grid item xs={12}>
                    <Tabs
                        style={{ backgroundColor: 'clear' }}
                        value={mainTab}
                        onChange={handleSetMainTab}
                        indicatorColor="secondary"
                        centered
                        textColor="secondary">
                        <Tab value={MainBATabs.SCGPhysical} label={<MainTabLabel selection={MainBATabs.SCGPhysical} />} />
                        <Tab value={MainBATabs.CashPlanning} label={<MainTabLabel selection={MainBATabs.CashPlanning} />} />
                    </Tabs>
                </Grid>
                {mainTab === MainBATabs.SCGPhysical && <SCGPhysical {...props} />}
                {mainTab === MainBATabs.CashPlanning && <CashPlanning {...props} />}
            </StyledBox>
        </StyledContainer>
    );
};

const MainTabLabel = (props: { selection: MainBATabs }) => {
    return (
        <span style={{ alignItems: 'center', display: 'flex', marginLeft: '20px', marginRight: '50px' }}>
            {props.selection === MainBATabs.SCGPhysical && <span />}
            <span style={{ paddingLeft: '10px' }}>{props.selection}</span>
        </span>
    );
};

export default bAccFetchConnector(BackAccountingComp);
