import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Security, useOktaAuth } from '@okta/okta-react';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { okta } from './config';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import SiteAlertsBars from './SiteAlertsBars';
import { postFetcher } from './common/fetcher';
import { AuthStatus, AuthUserState } from './reducers/authUser';
import { getAuthDescription, isAuthScreenShotBot } from './common/auth';
import { RootState } from './reducers';
import { authUserAction, signOutAction } from './actions/authUser';
import { siteErrorOpenAction, siteSuccessOpenAction } from './actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import MainTopAppBar from './containers/MainTopAppBar';
import EdfTrades from './EdfTrades';
import BlotterHome from './blotter/BlotterHome';
import Tasks from './Tasks';
import Accounting from './accounting/Accounting';
import BackAccounting from './backAccounting/BackAccounting';
import PNLAnalytics from './pnlAnalytics/PNLAnalytics';
import { NotFoundPage } from './common/error';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useZusStore } from './reducers/appStore';
import OktaSignInWidget from './OktaSignInWidget';
import { CenteredCircularProgress } from './common/common';
import moment from 'moment';
import { ConfirmProvider } from 'material-ui-confirm';

/*
==========OKTA================
https://developer.okta.com/docs/guides/sign-in-to-spa-embedded-widget/react/main/#default-page-route
==============================
 */

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '20',
            },
        },
    },
});

const oktaAuth = new OktaAuth(okta.oidc);

const App = () => {
    const history = useHistory();

    // const startOktaBackground = async () => await oktaAuth.start();

    // useEffect(() => {
    //     if (!oktaStarted) {
    //         console.log('Starting to listen to OKTA expired');
    //         oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
    //             console.log('REFRESHED!!!!!');
    //         });
    //         // startOktaBackground().then((myVar) => {
    //         //     console.log('REFRESHED!!!!!!!!!!!!!!!???????');
    //         //     console.log(myVar);
    //         // });
    //         setOktaStarted(true);
    //     }
    // }, [oktaStarted]);

    const customAuthHandler = () => {
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth: any, _originalUri: any) => {
        // history.replace(toRelativeUrl(originalUri || '', window.location.origin));
    };

    return (
        <MuiThemeProvider theme={theme}>
            <ConfirmProvider>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
                        <Router>
                            <OktaAuthWrapper />
                        </Router>
                    </Security>
                    <SiteAlertsBars />
                </SnackbarProvider>
            </ConfirmProvider>
        </MuiThemeProvider>
    );
};

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    authUserAction: authUserAction,
    siteErrorOpenAction: siteErrorOpenAction,
    siteSuccessOpenAction: siteSuccessOpenAction,
    signOutAction: signOutAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const OktaAuthComp = (props: Props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const [oktaStarted, setOktaStarted] = useState<boolean>(false);
    const [oktaTokenExpired, setOktaTokenExpired] = useState<boolean>(false);
    const [refreshRequestPending, setRefreshRequestPending] = useState<boolean>(false);
    let isSignedIn = props.authUser.auth_status === AuthStatus.VERIFIED;
    let tokenExpired = false;
    if (isSignedIn) {
        tokenExpired = props.authUser.auth_user!.exp > Date.now();
    }
    let shouldVerifyToken = !isSignedIn || tokenExpired;
    let isScreenShotBot = isAuthScreenShotBot(props.authUser);
    let location = useLocation();

    const onSuccess = (tokens: any) => {
        oktaAuth.handleLoginRedirect(tokens);
    };

    const onError = (err: any) => {
        console.log('Sign in error:', err);
    };

    const signOutOkta = async () => oktaAuth.signOut();
    const signOut = () => {
        signOutOkta().then(() => {
            props.signOutAction();
        });
    };

    useEffect(() => {
        const postVerifyAuth = (authState: AuthState) => {
            console.log('About to Verify Auth with backend');
            oktaAuth
                .getUser()
                .then((info) => {
                    // Add expiresAt to the user info as that is the only thing that is missing for a complete user info
                    info['expiresAt'] = authState.accessToken!.expiresAt;
                    postFetcher<AuthUserState>({
                        url: 'verify_auth',
                        headers: { 'Swiss-Tech-Okta-Auth-Token': authState.accessToken!.accessToken },
                        data: info,
                        onSuccess: (jsonData) => {
                            console.log(`Successful Auth Verification for ${getAuthDescription(jsonData)}`);
                            console.log(`Token will expire at ${moment.utc(jsonData.auth_user!.exp * 1000).local()}`);
                            props.authUserAction(jsonData);
                        },
                        onFail: (err) => {
                            console.log(`Failed Auth Verification with error ${err}`);
                            props.siteErrorOpenAction({ message: err.parsedMsg });
                        },
                        onFinal: () => {
                            setRequestPending(false);
                            setOktaTokenExpired(false);
                            setRefreshRequestPending(false);
                        },
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        };

        if (!oktaStarted) {
            console.log('Starting to listen to OKTA expired');
            oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
                console.log('Okta Token Expired. Refreshing local Token with POST Verify');
                setOktaTokenExpired(true);
            });
            setOktaStarted(true);
        }
        if (oktaTokenExpired && authState && authState.isAuthenticated && !refreshRequestPending) {
            // Only used when token is expired
            console.log(`About to refresh local token due to OKta token expiring`);
            setRefreshRequestPending(true);
            postVerifyAuth(authState);
        } else if (authState && authState.isAuthenticated && shouldVerifyToken && !requestPending) {
            setRequestPending(true);
            console.log(`About to get OKTA user info that should only happen once ever 60 minutes ${Date().toLocaleString()}`);
            postVerifyAuth(authState);
        } else if (!isSignedIn && location.pathname.startsWith('/screenshot/20') && !requestPending) {
            // Add 20 for the start of the year
            setRequestPending(true);
            postFetcher<AuthUserState>({
                url: 'verify_auth',
                onSuccess: (jsonData) => {
                    console.log(`Successful Auth Verification for ${getAuthDescription(jsonData)}`);
                    props.authUserAction(jsonData);
                },
                onFail: (err) => {
                    console.log(`Failed Auth Verification with error ${err}`);
                    props.siteErrorOpenAction({ message: err.parsedMsg });
                },
                onFinal: () => {
                    setRequestPending(false);
                },
            });
        }
    }, [
        refreshRequestPending,
        oktaTokenExpired,
        oktaStarted,
        authState,
        oktaAuth,
        props,
        requestPending,
        isSignedIn,
        shouldVerifyToken,
        location.pathname,
    ]); // Update if authState changes

    if (requestPending) {
        return <CenteredCircularProgress />;
    }

    if (!isSignedIn && !requestPending) {
        return <OktaSignInWidget onSuccess={onSuccess} onError={onError} />;
    }

    return (
        <>
            {isScreenShotBot && <Route path="/screenshot/:pipe_run_date" component={PNLAnalytics} />}
            {isSignedIn && !isScreenShotBot && <SecuredRoutes signOut={signOut} />}
        </>
    );
};

const OktaAuthWrapper = connector(OktaAuthComp);

type SecuredRoutesProps = {
    signOut: () => void;
};

const SecuredRoutes = (props: SecuredRoutesProps) => {
    return (
        <>
            <MainTopAppBar signOut={props.signOut} />
            <SiteBackdropSpinner />
            <Switch>
                <Route exact path="/edf">
                    <EdfTrades />
                </Route>
                <Route path="/blotter">
                    <BlotterHome />
                </Route>
                <Route path="/tasks/*">
                    <Tasks />
                </Route>
                <Route path="/accounting" component={Accounting} />
                <Route path="/back-accounting" component={BackAccounting} />
                <Route path="/date/:pipe_run_date" component={PNLAnalytics} />
                <Route path="/" component={PNLAnalytics} />
                <Route component={NotFoundPage} />
            </Switch>
        </>
    );
};

const BackdropSpinner = ({ loading }: { loading: boolean }) => {
    return (
        <Backdrop style={{ color: '#fff', zIndex: 2000 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

const SiteBackdropSpinner = () => {
    const backdropSpinner = useZusStore((state) => state.backdropSpinner);
    return <BackdropSpinner loading={backdropSpinner} />;
};

export default App;
