import { Grid, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { GetApp, Launch, MoreHoriz, RotateLeft, ZoomIn, ZoomOut } from '@material-ui/icons';
import { jsonToCVSConverter } from '../common/downloadData';
import { postFetcher } from '../common/fetcher';
import { getAuthUser } from '../common/auth';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerStateType } from '../reducers';
import { AuthUserState } from '../reducers/authUser';
import { PNLFetchStatus } from '../actions/pnlAnalytics';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { siteErrorOpenAction } from '../actions/siteAlerts';
import moment from 'moment';

type ExpandableTableTitleProps = TitleBaseProps & {
    pivotDepth: number;
    changeExpansion: (newExpansionDepth?: number) => void;
};

const ENABLE_DOWNLOAD = false;

export const ExpandableTableTitle = (props: ExpandableTableTitleProps) => {
    return (
        <TitleBase allData={props.allData} data={props.data} title={props.title} columnNames={props.columnNames}>
            {(handleClose: () => void) =>
                // Unfortunately MenuComponent does not accept ReactFragment as children so we need to create a fake array
                // https://github.com/mui-org/material-ui/issues/16181
                [0, 1, 2].map((el) => {
                    if (el === 0) {
                        return (
                            <MenuItem
                                key={el}
                                onClick={() => {
                                    props.changeExpansion(props.pivotDepth);
                                    handleClose();
                                }}>
                                <ZoomIn />
                                <span style={{ marginLeft: '5px' }}>Expand All</span>
                            </MenuItem>
                        );
                    } else if (el === 1) {
                        return (
                            <MenuItem
                                key={el}
                                onClick={() => {
                                    props.changeExpansion(0);
                                    handleClose();
                                }}>
                                <ZoomOut />
                                <span style={{ marginLeft: '5px' }}>Collapse All</span>
                            </MenuItem>
                        );
                    } else {
                        return (
                            <MenuItem
                                key={el}
                                onClick={() => {
                                    props.changeExpansion();
                                    handleClose();
                                }}>
                                <RotateLeft />
                                <span style={{ marginLeft: '5px' }}>Reset Expanded</span>
                            </MenuItem>
                        );
                    }
                })
            }
        </TitleBase>
    );
};

type SimpleTitleProps = TitleBaseProps & {};

export const SimpleTitle = (props: SimpleTitleProps) => {
    return <TitleBase data={props.data} allData={props.allData} title={props.title} columnNames={props.columnNames} />;
};

type TitleBaseProps = {
    allData?: Array<object>;
    data: Array<object>;
    columnNames?: Array<string>;
    title: string;
    children?: any;
};

const TitleBase = (props: TitleBaseProps) => {
    const authUser = useSelector<RootReducerStateType, AuthUserState>((state) => state.authUser, shallowEqual);
    const [openSheet, setOpenSheet] = React.useState<PNLFetchStatus>(PNLFetchStatus.INIT);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const dispatch = useDispatch();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadData = () => {
        jsonToCVSConverter(props.data, props.title, true, props.columnNames);
        setAnchorEl(null);
    };

    const downloadAllData = () => {
        // Do not supply the column names and let it figure it out from the data itself
        jsonToCVSConverter(props.allData, props.title, true, undefined, props.columnNames);
        setAnchorEl(null);
    };

    const openInGoogleSheets = () => {
        postFetcher<any>({
            url: '/create_sheet',
            data: {
                title: props.title,
                date_time: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
                data: props.allData,
                order: props.columnNames,
            },
            headers: {
                'Swiss-Tech-Auth-Token': getAuthUser(authUser).swiss_token,
            },
            onStart: () => {
                setOpenSheet(PNLFetchStatus.LOADING);
            },
            onSuccess: (res) => {
                setOpenSheet(PNLFetchStatus.COMPLETED);
                window.open(`https://docs.google.com/spreadsheets/d/${res['spreadsheet_id']}`);
            },
            onFail: (err) => {
                setOpenSheet(PNLFetchStatus.FAILED);
                console.log(`Failed Auth Verification with error ${err}`);
                dispatch(siteErrorOpenAction({ message: err.parsedMsg }));
            },
            onFinal: () => {
                // Leave it empty as when we change route the component will no longer be mounted
            },
        });
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={3} style={{ backgroundColor: 'clear' }} />
                <Grid item xs={6} style={{ backgroundColor: 'clear', padding: 0 }}>
                    <h3
                        style={{
                            textAlign: 'center',
                            backgroundColor: 'clear',
                            marginTop: '10px',
                            padding: 0,
                            verticalAlign: 'middle',
                        }}>
                        {props.title}
                    </h3>
                </Grid>
                <Grid item xs={3} style={{ backgroundColor: 'clear', textAlign: 'right' }}>
                    <IconButton size={'small'} onClick={handleClick}>
                        <MoreHoriz />
                    </IconButton>
                </Grid>
            </Grid>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                style={{ zIndex: 2000 }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                {ENABLE_DOWNLOAD && (
                    <MenuItem onClick={downloadData}>
                        <GetApp />
                        <span style={{ marginLeft: '5px' }}>Download Data</span>
                    </MenuItem>
                )}
                {ENABLE_DOWNLOAD && props.allData && (
                    <MenuItem onClick={downloadAllData}>
                        <GetApp />
                        <span style={{ marginLeft: '5px' }}>Download All Data</span>
                    </MenuItem>
                )}
                <MenuItem onClick={openInGoogleSheets} disabled={openSheet === PNLFetchStatus.LOADING}>
                    {openSheet !== PNLFetchStatus.LOADING && <Launch />}
                    {openSheet === PNLFetchStatus.LOADING && (
                        <CircularProgress
                            size={24}
                            style={{
                                color: green[500],
                            }}
                        />
                    )}
                    <span style={{ marginLeft: '5px' }}>Open in Google Sheets</span>
                </MenuItem>
                {props.children !== undefined && props.children!(handleClose)}
            </Menu>
        </React.Fragment>
    );
};
