// Default fetch data parameters
import moment, { Moment } from 'moment';
import { ErrorContentType, getFetcher } from './fetcher';

export const INIT_PAGE_SIZE = 20;
export const INIT_PAGE_NUM = 0;

export interface TradeDataType {
    trade_date: string;
    statement_date: string;
    clearer_name: string;
    account_short_name: string;
    trade_type: string;
    trade_id: string;
    order_num: string;
    product_name: string;
    contract_code: string;
    lots: number;
    quoted_volume_unit: string;
    edf_lots: number;
    trade_price: number;
    quoted_trade_price_unit: string;
    edf_trade_price: number;
    is_mtb: boolean;
    term_month_date: string;
    balmo_date: string | null;
    prompt_date: string;
    is_expiring: boolean;
    is_internal: boolean;
    last_trading_date: string;
    exchange: string;
    fees: number;
    commission: number;
    state: string;
}

export interface TradesDataType {
    trades: Array<TradeDataType>;
}

export enum VerifiedState {
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified',
    ALL = 'all',
}

export type EDFFilterTypes = {
    verifiedState: VerifiedState;
    selectedDate: Moment | null;
    tradeIdsSubmit: string | null;
};

export interface EdfTradesState {
    filters: EDFFilterTypes;

    canLoadMoreTrades: boolean;
    setCanLoadMoreTrades: (newVal: boolean) => void;

    data: TradesDataType;
    setData: (newVal: TradesDataType) => void;

    rowUpdating: string;
    setRowUpdating: (newVal: string) => void;

    tradeIdCopied: string;
    setTradeIdCopied: (newVal: string) => void;
}

type FetchTradesParamsTypes = EDFFilterTypes & {
    pageSize: number;
    pageNum: number;
    onSuccess: (jsonRes: TradesDataType) => void;
    onFail: (err: ErrorContentType) => void;
};

export function fetchTradesAsync(params: FetchTradesParamsTypes) {
    let formattedDate = null;
    if (params.selectedDate != null) {
        formattedDate = moment(params.selectedDate).format('YYYY-MM-DD');
    }
    let tradeIdsSubmit = null;
    if (params.tradeIdsSubmit != null && params.tradeIdsSubmit !== '') {
        tradeIdsSubmit = params.tradeIdsSubmit;
    }
    getFetcher<TradesDataType>({
        url: 'core-trades',
        params: {
            page_size: params.pageSize,
            page_num: params.pageNum,
            state: params.verifiedState,
            run_date: formattedDate,
            trade_ids: tradeIdsSubmit,
        },
        onSuccess: (jsonData) => {
            params.onSuccess(jsonData);
        },
        onFail: (err) => {
            params.onFail(err);
        },
    });
}

export function isNewTradeBlock(trades: Array<TradeDataType>, prevIdx: number, currIdx: number): boolean {
    if (prevIdx < 0) {
        // First line doesn't count as new trade block
        return false;
    }
    return trades[prevIdx].order_num !== trades[currIdx].order_num;
}
