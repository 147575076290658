import { AuthStatus, AuthUser, AuthUserState, BotUser, RealUser, UserType } from '../reducers/authUser';

export const isAuthenticated = (authUserState: AuthUserState): boolean => {
    return authUserState.auth_status === AuthStatus.VERIFIED;
};

export const getAuthUser = (authUserState: AuthUserState): AuthUser => {
    if (!isAuthenticated(authUserState)) throw Error('You asked to get auth user but you are not verified');
    return authUserState.auth_user!;
};

export const isRealUser = (authUserState: AuthUserState): boolean => {
    let authUser = getAuthUser(authUserState);
    return authUser.auth_user_data.user_type === UserType.REAL_USER;
};

export const isBotUser = (authUserState: AuthUserState): boolean => {
    let authUser = getAuthUser(authUserState);
    return authUser.auth_user_data.user_type === UserType.BOT;
};

export const getRealUser = (authUserState: AuthUserState): RealUser => {
    let authUser = getAuthUser(authUserState);
    if (!isRealUser(authUserState)) throw Error('You asked to get Real user but you are not one');
    return authUser.auth_user_data.user as RealUser;
};

export const getBotUser = (authUserState: AuthUserState): BotUser => {
    let authUser = getAuthUser(authUserState);
    if (!isBotUser(authUserState)) throw Error('You asked to get Bot user but you are not one');
    return authUser.auth_user_data.user as BotUser;
};

export const isAuthScreenShotBot = (authUserState: AuthUserState): boolean => {
    return (
        isAuthenticated(authUserState) && isBotUser(authUserState) && getBotUser(authUserState).usage === 'screenshot'
    );
};

export const getAuthDescription = (authUserState: AuthUserState): string => {
    if (!isAuthenticated(authUserState)) {
        return `Not Authenticated with state ${authUserState.auth_status}`;
    }
    let authUser = getAuthUser(authUserState);
    let desc = `${authUser.auth_user_data.user_type}`;
    if (isRealUser(authUserState)) {
        desc += ` ${getRealUser(authUserState).email}`;
    } else if (isBotUser(authUserState)) {
        let botUser = getBotUser(authUserState);
        desc += ` ${botUser.service_type} with usage ${botUser.usage}`;
    }
    return desc;
};
