import { PNLAnalyticsEndpoint } from '../actions/pnlAnalytics';

export enum PNLTabSelector {
    Management = 'Management',
    PhysicalTrading = 'Physical',
    PaperTrading = 'Paper',
    BackOffice = 'Back Office',
}

const EndpointMetaMap = new Map<PNLAnalyticsEndpoint, string>();
EndpointMetaMap.set(PNLAnalyticsEndpoint.Meta, 'Meta');
EndpointMetaMap.set(PNLAnalyticsEndpoint.AllTradesRunDate, 'All Trades for Run Date');
EndpointMetaMap.set(PNLAnalyticsEndpoint.ClearerRunDate, 'Clearer Run Date');
EndpointMetaMap.set(PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics, 'Historical PNL Per Book Metrics');
EndpointMetaMap.set(PNLAnalyticsEndpoint.Exposures, 'Exposures');
EndpointMetaMap.set(PNLAnalyticsEndpoint.BackOfficeMisc, 'Back Office Misc');
EndpointMetaMap.set(PNLAnalyticsEndpoint.AccountingMeta, 'Accounting Meta');
EndpointMetaMap.set(PNLAnalyticsEndpoint.AccountingDanaosVessels, 'Accounting Danaos Vessels');
EndpointMetaMap.set(PNLAnalyticsEndpoint.BAccountingMeta, 'Back Office Accounting Meta');
EndpointMetaMap.set(PNLAnalyticsEndpoint.BAccountingSCGPhysical, 'Back Office SCG Physical');

export const getEndpointTitle = (endpoint: PNLAnalyticsEndpoint): string => EndpointMetaMap.get(endpoint)!;
