import { FooterAggActionTypes, SET_ACTIVE_TABLE } from '../actions/footerCellAgg';
import { UniqueTableName } from '../common/uniqueTableName';

interface FooterCellAggState {
    uniqueTableName: null | UniqueTableName;
}

const initialState: FooterCellAggState = {
    uniqueTableName: null
};
const footerCellAgg = (state = initialState, action: FooterAggActionTypes): FooterCellAggState => {
    switch (action.type) {
        case SET_ACTIVE_TABLE:
            return {
                ...state,
                uniqueTableName: action.payload.uniqueTableName
            };
        default:
            return state;
    }
};

export default footerCellAgg;
