import React, { ReactNode } from 'react';
import { AllTradesPropsType, FieldType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import { processMonthChange } from '../dates';
import {
    DEFAULT_OPT_FIELD_STR,
    getBalmoDateSummary,
    getMonthYearBFields,
    handleAllTradesSimpleFieldChange,
} from '../common';
import moment from 'moment';

const handlePromptAChange = (
    props: AllTradesPropsType,
    tradeDate: FieldType<string>,
    monthAValue: string,
    threeLetterMonthA: string, // The 3 digit month. e.g. for Q1 it's Jan
    yearAValue: string
) => {
    let { state, setState } = props;
    let monthBField = { ...state.monthB };
    let monthAError: string = '';
    if (!Boolean(monthAValue)) {
        monthAError = 'Month A is required';
    }
    let yearAError: string = '';
    if (!Boolean(yearAValue)) {
        yearAError = 'Year A is required';
    }

    let promptASummary = <span>{monthAValue}</span>;
    let monthA: FieldType<string> = {
        value: monthAValue,
        error: monthAError,
        summary: promptASummary,
        required: state.monthA.required,
    };
    let yearA: FieldType<string> = {
        value: yearAValue,
        error: yearAError,
        summary: null,
        required: state.yearA.required,
    };
    if (monthA.value === '') {
        setState({
            ...state,
            monthA: monthA,
            yearA: yearA,
            balmoDate: DEFAULT_OPT_FIELD_STR,
        });
        return;
    }
    // TODO: When A changes we might make B invalid
    let balmoDate = '';
    let balmoDateSummary: ReactNode = null;
    let yearANumber: number = Number.parseInt(yearA.value);
    let promptDate = moment().date(1).month(threeLetterMonthA).year(yearANumber);
    let tradeDateTime = moment(tradeDate.value);
    if (promptDate.format('YYYY-MM') === tradeDateTime.format('YYYY-MM')) {
        balmoDate = tradeDateTime.format();
        if (tradeDateTime.hour() >= 9) {
            balmoDate = tradeDateTime.add(1, 'days').format();
        }
        balmoDateSummary = getBalmoDateSummary(balmoDate);
    }
    let yearBField = { ...state.yearB };
    if (monthBField.value) {
        let [threeLetterNewMonthB, yearBValue] = processMonthChange(
            props.state.tradeDate.value,
            monthBField.value,
            yearBField.value
        );
        [monthBField, yearBField] = getMonthYearBFields(
            props,
            monthA,
            yearA,
            monthBField.value,
            threeLetterNewMonthB,
            yearBValue
        );
    }
    setState({
        ...state,
        monthA: monthA,
        yearA: yearA,
        monthB: monthBField,
        yearB: yearBField,
        balmoDate: { value: balmoDate, error: '', summary: balmoDateSummary, required: balmoDate !== '' },
    });
};

interface MonthAFieldProps extends AllTradesPropsType {}

export function MonthAField(props: MonthAFieldProps) {
    const { state, setState, options } = props;
    return (
        <AutoCompleteField
            options={options.tenor_months}
            inputValue={state.monthA.value}
            handleChange={(newMonthA: string) => {
                let [actualMonthA, yearA] = processMonthChange(state.tradeDate.value, newMonthA, state.yearA.value);
                handlePromptAChange(props, state.tradeDate, newMonthA, actualMonthA, yearA);
            }}
            handlePartialChange={(partialValue) => {
                handleAllTradesSimpleFieldChange(state, setState, 'monthA', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.monthA.required,
                });
            }}
            required={state.monthA.required}
            errorText={state.monthA.error}
            label={'Month A'}
            width={100}
            marginLeft={''}
            disabled={props.disabled}
        />
    );
}

interface YearAFieldProps extends AllTradesPropsType {}

export function YearAField(props: YearAFieldProps) {
    const { state, setState, options } = props;
    return (
        <AutoCompleteField
            options={options.tenor_years}
            inputValue={state.yearA.value}
            handleChange={(newYearA: string) => {
                // We need to parse the month again to find the 3 digit month to check if with the year change it's Balmo
                let [threeLetterMonthA] = processMonthChange(state.tradeDate.value, state.monthA.value, newYearA);
                handlePromptAChange(props, state.tradeDate, state.monthA.value, threeLetterMonthA, newYearA);
            }}
            handlePartialChange={(partialValue) => {
                handleAllTradesSimpleFieldChange(state, setState, 'yearA', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.yearA.required,
                });
            }}
            required={state.yearA.required}
            errorText={state.yearA.error}
            label={'Year A'}
            width={120}
            marginLeft={'16px'}
            disabled={props.disabled}
        />
    );
}
