import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { HistoricalPNLPerBookMetricsDataResp } from '../fetchTypes';
import LineGraph, { LineGraphConfig } from '../../containers/LineGraph';
import { STANDARD_GRAPH_ELEMENT_HEIGHT } from '../../common/common';

type Props = FetchPNLAnalyticsPropsFromRedux & {
    historicalPNLPerBookMetricsDataKey: string;
    config: LineGraphConfig;
};

const CommonPNLSummaryGraph = (props: Props) => {
    const histPNLPerBookMetricsData = props.pnlAnalytics.historical_pnl_per_book_metrics;

    if (histPNLPerBookMetricsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics}
                data={histPNLPerBookMetricsData}
                minHeight={STANDARD_GRAPH_ELEMENT_HEIGHT}
            />
        );
    }

    let respData = histPNLPerBookMetricsData.data! as HistoricalPNLPerBookMetricsDataResp;

    return (
        <LineGraph
            {...props.config}
            values={respData[props.historicalPNLPerBookMetricsDataKey as keyof HistoricalPNLPerBookMetricsDataResp]}
        />
    );
};

export default fetchConnector(CommonPNLSummaryGraph);
