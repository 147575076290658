import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import VesselsPNL from './ShippingPNL';
import SummaryPNL from './SummaryPNL';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const OverviewComp = (props: Props) => {
    return (
        <React.Fragment>
            <SummaryPNL {...props} />
            <VesselsPNL {...props} />
        </React.Fragment>
    );
};

export default accFetchConnector(OverviewComp);
