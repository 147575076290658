// It doesn't seem to be possible to change NODE_ENV. So we just have to ignore it in case of staging
// https://stackoverflow.com/a/57286662/23453843
if (process.env.NODE_ENV !== process.env.REACT_APP_SWISS_TECH_ENV && process.env.REACT_APP_SWISS_TECH_ENV !== 'staging') {
    throw Error(`NODE_ENV '${process.env.NODE_ENV}' and SWISS_TECH_ENV '${process.env.REACT_APP_SWISS_TECH_ENV}' don't match`);
}

export const config = {
    SWISS_TECH_ENV: process.env.REACT_APP_SWISS_TECH_ENV,
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    GOOGLE_HOSTED_DOMAIN: process.env.REACT_APP_GOOGLE_HOSTED_DOMAIN,
};

export const okta = {
    oidc: {
        // We are not using https://swissmarine.okta.com/oauth2/default
        // because the /oauth2/default requires the OKTA -> Security -> API -> Authorization Servers
        // which required the OKTA API Access Management subscription we don't use that and just login to the pure url
        // https://developer.okta.com/docs/guides/sign-in-to-spa-embedded-widget/react/main/
        issuer: 'https://swissmarine.okta.com',
        clientId: '0oa5uihxxaQfUuZgD697',
        scopes: ['openid', 'profile', 'email'],
        redirectUri: `${window.location.origin}/`,
        // tokenManager: { expireEarlySeconds: 3540 },  // Used in development to test firing of expire token
    },
    widget: {
        issuer: 'https://swissmarine.okta.com',
        clientId: '0oa5uihxxaQfUuZgD697',
        redirectUri: `${window.location.origin}/`,
        scopes: ['openid', 'profile', 'email'],
        logo: '/swissMarineFullLogo.png',
    },
};
