import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import { TableSize, SimpleTableColumnConfig } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatter, optNumberFormat, redGreenHundred, redOnlyHundred } from '../../containers/pivotHelper';
import { TrendDirection2Size } from '../../common/TrendDirection2';
import { kFormatterAggWithSign } from '../common/common';
import { rawNumFormat } from '../common/CommonPNLSummaryTable';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';

const CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT = '270px';

const hoverNumberFormat = (value: any) => optNumberFormat(value as number, 0, false, false, false);

const useStyles = makeStyles(() => ({
    headerTotalCell: {
        borderLeft: 'black solid 1px',
    },
    bodyTotalCell: {
        borderLeft: 'black solid 1px',
    },
}));

const hoverText = (ltd_val: number, ytd_val: number) => {
    return (
        <div>
            <p>
                LTD: <strong>{rawNumFormat(ltd_val)}</strong>
            </p>
            <p>
                YTD: <strong>{rawNumFormat(ytd_val)}</strong>
            </p>
        </div>
    );
};

const allColumns: Array<SimpleTableColumnConfig> = [
    { name: 'book', title: 'Book' },
    { name: 'strategy', title: 'Strategy' },
    { name: 'deal', title: 'Deal' },
    {
        name: 'expired_combined_net',
        title: 'Expired Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['expired_combined_net']);
            let ltd_val = row['expired_trade_net'] as number;
            let ytd_val = row['expired_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redOnlyHundred,
        headerBackgroundColor: '#d9e1fd',
    },
    {
        name: 'expired_delta_combined_net',
        title: 'Expired DtD Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['expired_delta_combined_net']);
            let ltd_val = row['expired_delta_trade_net'] as number;
            let ytd_val = row['expired_delta_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#d9e1fd',
    },
    {
        name: 'prompt_combined_net',
        title: 'Prompt Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['prompt_combined_net']);
            let ltd_val = row['prompt_trade_net'] as number;
            let ytd_val = row['prompt_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redOnlyHundred,
        headerBackgroundColor: '#e3ffef',
    },
    {
        name: 'prompt_delta_combined_net',
        title: 'Prompt DtD Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['prompt_delta_combined_net']);
            let ltd_val = row['prompt_delta_trade_net'] as number;
            let ytd_val = row['prompt_delta_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#e3ffef',
    },
    {
        name: 'deferred_combined_net',
        title: 'Deferred Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['deferred_combined_net']);
            let ltd_val = row['deferred_trade_net'] as number;
            let ytd_val = row['deferred_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redOnlyHundred,
        headerBackgroundColor: '#fff9ce',
    },
    {
        name: 'deferred_delta_combined_net',
        title: 'Deferred DtD Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['deferred_delta_combined_net']);
            let ltd_val = row['deferred_delta_trade_net'] as number;
            let ytd_val = row['deferred_delta_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#fff9ce',
    },
    {
        name: 'total_combined_net',
        title: 'Total Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['total_combined_net']);
            let ltd_val = row['total_trade_net'] as number;
            let ytd_val = row['total_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redOnlyHundred,
    },
    {
        name: 'total_delta_combined_net',
        title: 'Total DtD Net',
        bodyTemplate2: (val, row) => {
            return kFormatter(val);
        },
        hoverElement: (row, val) => {
            if (row['_depth'] !== 2) return hoverNumberFormat(row['total_delta_combined_net']);
            let ltd_val = row['total_delta_trade_net'] as number;
            let ytd_val = row['total_delta_net_pnl'] as number;
            return hoverText(ltd_val, ytd_val);
        },
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
    },
];

const config: ExpandableTableConfig = {
    uniqueTableName: UniqueTableName.ExpiredLiveTable,
    title: 'Expired/Prompt/Deferred PNL',
    size: TableSize.MediumSmall,
    columns: allColumns,
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 2,
    defaultExpandable: 0,
    stickyHeader: false,
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const ExpiredLiveTable = (_: Props) => {
    return <ExpiredLiveTableHelperWrapper />;
};

const ExpiredLiveTableHelper = (props: Props) => {
    const classes = useStyles();
    const allTradesRunDateData = props.pnlAnalytics.all_trades_run_date;

    if (allTradesRunDateData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.ClearerRunDate}
                data={allTradesRunDateData}
                minHeight={CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT}
            />
        );
    }

    let respData = allTradesRunDateData.data! as AllTradesRunDateDataResp;
    if (respData.expired_prompt_ltd_table.length === 0) {
        return (
            <Paper style={{ padding: '10px', textAlign: 'center' }}>
                <h3>No Expired/Live for this date</h3>
            </Paper>
        );
    }

    config.columns[config.columns.length - 2].headerCellClassName = classes.headerTotalCell;
    config.columns[config.columns.length - 2].bodyCellsClassName = classes.bodyTotalCell;

    return <ExpandableTable data={respData.expired_prompt_ltd_table} config={config} />;
};

const ExpiredLiveTableHelperWrapper = fetchConnector(ExpiredLiveTableHelper);

export default fetchConnector(ExpiredLiveTable);
