import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DtDExposures from './DtDExposures';
import DtDPrices from './DtDPrices';
import DtDPnL from './DtDPnL';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from '../dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import { PNLMetaDataResp } from '../fetchTypes';
import { MIN_4_12_EL_WIDTH } from '../../common/common';
import TodaysMtMPrices from './TodaysMtMPrices';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const BackOfficeIndex = (props: Props) => {
    let metaData = props.pnlAnalytics.meta.data! as PNLMetaDataResp;

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.BackOfficeMisc,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    return (
        <Grid container spacing={3} justify="center">
            <Grid item xs={4} style={{ backgroundColor: 'clear', minWidth: MIN_4_12_EL_WIDTH }}>
                <DtDPrices />
            </Grid>
            <Grid item xs={4} style={{ backgroundColor: 'clear', minWidth: MIN_4_12_EL_WIDTH }}>
                <DtDExposures />
            </Grid>
            <Grid item xs={4} style={{ backgroundColor: 'clear', minWidth: MIN_4_12_EL_WIDTH }}>
                <DtDPnL />
            </Grid>
            <Grid item xs={8} style={{ backgroundColor: 'clear', minWidth: MIN_4_12_EL_WIDTH }}>
                <TodaysMtMPrices />
            </Grid>
        </Grid>
    );
};

export default fetchConnector(BackOfficeIndex);
