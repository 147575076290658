import React from 'react';
import { AllTradesPropsType, FieldType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import { getMonthYearBFields, handleAllTradesSimpleFieldChange, isComboTimeSpread } from '../common';
import moment from 'moment';
import { processMonthChange } from '../dates';

export const validatePromptB = (
    props: AllTradesPropsType,
    monthAField: FieldType<string>,
    yearAField: FieldType<string>,
    monthBValue: string,
    threeLetterNewMonthB: string,
    yearBValue: string
): string => {
    const { options } = props;
    if (
        (monthBValue === '' && yearBValue === '') ||
        (monthBValue !== '' && !options.tenor_months.includes(monthBValue)) || // Ignore partial inputs
        (yearBValue !== '' && !options.tenor_years.includes(yearBValue))
    )
        return '';

    if (!Boolean(yearBValue) || !Boolean(monthBValue)) {
        return 'Prompt B partial';
    }
    if (isComboTimeSpread(monthAField.value, monthBValue)) {
        return 'Combo spreads not allowed';
    }

    let [threeLetterNewMonthA, newYearA] = processMonthChange(
        props.state.tradeDate.value,
        monthAField.value,
        yearAField.value
    );
    let promptADate = moment().date(1).month(threeLetterNewMonthA).year(Number.parseInt(newYearA));
    let promptBDate = moment().date(1).month(threeLetterNewMonthB).year(Number.parseInt(yearBValue));
    return !promptBDate.isAfter(promptADate, 'month') ? 'A must be > B' : '';
};

const handleMonthBChange = (props: MonthBFieldProps, newMonthB: string) => {
    let [threeLetterNewMonthB, yearB] = processMonthChange(
        props.state.tradeDate.value,
        newMonthB,
        props.state.yearB.value
    );
    let [monthBField, yearBField] = getMonthYearBFields(
        props,
        props.state.monthA,
        props.state.yearA,
        newMonthB,
        threeLetterNewMonthB,
        yearB
    );
    props.setState({
        ...props.state,
        monthB: monthBField,
        yearB: yearBField,
    });
};

interface MonthBFieldProps extends AllTradesPropsType {}

export function MonthBField(props: MonthBFieldProps) {
    const { state, setState, options } = props;

    return (
        <AutoCompleteField
            options={options.tenor_months}
            inputValue={state.monthB.value}
            handleChange={(newValue: string) => {
                handleMonthBChange(props, newValue);
            }}
            handlePartialChange={(partialValue) => {
                handleAllTradesSimpleFieldChange(state, setState, 'monthB', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.monthB.required,
                });
            }}
            required={state.monthB.required}
            errorText={state.monthB.error}
            label={'Month B'}
            width={100}
            marginLeft={'16px'}
            disabled={props.disabled}
        />
    );
}

interface YearBFieldProps extends AllTradesPropsType {}

export function YearBField(props: YearBFieldProps) {
    const { state, setState, options } = props;

    return (
        <AutoCompleteField
            options={options.tenor_years}
            inputValue={state.yearB.value}
            handleChange={(newYearB: string) => {
                let [threeLetterNewMonthB] = processMonthChange(
                    props.state.tradeDate.value,
                    state.monthB.value,
                    newYearB
                );
                let [monthBField, yearBField] = getMonthYearBFields(
                    props,
                    state.monthA,
                    state.yearA,
                    state.monthB.value,
                    threeLetterNewMonthB,
                    newYearB
                );
                setState({
                    ...props.state,
                    monthB: monthBField,
                    yearB: yearBField,
                });
            }}
            handlePartialChange={(partialValue) => {
                handleAllTradesSimpleFieldChange(state, setState, 'yearB', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.yearB.required,
                });
            }}
            required={state.yearB.required}
            errorText={state.yearB.error}
            label={'Year B'}
            width={120}
            marginLeft={'16px'}
            disabled={props.disabled}
        />
    );
}
