import React from 'react';
import { useLocation } from 'react-router-dom';

type ErrorPageProps = {
    msg: string;
};

export const NotAuthorizedPage = (err: ErrorPageProps) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <img src={require('../static/not-authorized.png')} alt={'Not Authorized'} />
            <h2>{err.msg.toString()}</h2>
        </div>
    );
};

export const UserErrorPage = (err: ErrorPageProps) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img src={require('../static/confused-dog.jpeg').default} alt={'Confused???'} />
            <h1>You have messed up (probably)</h1>
            <h2>{err.msg.toString()}</h2>
        </div>
    );
};
export const ErrorPage = (err: ErrorPageProps) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img src={require('../static/sad-puppy.png').default} alt={'Sad Puppy'} />
            <h1>Sorry we have messed up. We are on it!</h1>
            <h2>(Error: {err.msg.toString()})</h2>
        </div>
    );
};

export const NotFoundPage = (err?: ErrorPageProps) => {
    let msg = "We can't find the dog you are looking for";
    if (err) {
        msg = err.msg;
    }
    let history = useLocation();
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img src={require('../static/dog-not-found.png').default} alt={'Dog Not Found'} />
            <h1>{msg}</h1>
            <h2>(Page {history.pathname} not found)</h2>
        </div>
    );
};

type UnderConstructionPageProps = {
    msg: string;
};

export const UnderConstructionPage = (props: UnderConstructionPageProps) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img src={require('../static/under-construction.jpeg').default} alt={'Under Construction'} />
            <h2>{props.msg.toString()}</h2>
        </div>
    );
};
