import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useZusStore } from '../../reducers/appStore';
import { clearAccDataAction } from '../../actions/pnlAnalytics';
import { useDispatch } from 'react-redux';
import { BackAccountingFiltersState } from '../../reducers/backAccounting';
import { ADate } from '../common/FilterDates';
import { swissUseSelector } from '../../reducers';
import moment from 'moment-timezone';

const Filters = () => {
    const {
        cashPlanningFilters: { showFractions, withBuffer, withBlocked, runDate },
        toggleCashPlanningFractionsFilter,
        toggleCashPlanningBufferFilter,
        toggleCashPlanningBlockedFilter,
        setCashPlanningDateFilter,
    } = useZusStore((state) => state);
    const bAFilterState = swissUseSelector<BackAccountingFiltersState>((state) => state.backAccounting);
    const dispatch = useDispatch();
    return (
        <div style={{ marginTop: '25px' }}>
            <span style={{ marginRight: '15px' }}>
                <ADate
                    value={moment(runDate)}
                    validDates={bAFilterState.validCashPlanningDates!}
                    changeValue={(startDate) => {
                        setCashPlanningDateFilter(startDate.format('YYYY-MM-DD'));
                        dispatch(clearAccDataAction());
                    }}
                    label={'Date'}
                />
            </span>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={withBuffer}
                        onChange={() => {
                            toggleCashPlanningBufferFilter();
                            dispatch(clearAccDataAction());
                        }}
                        name="withBuffer"
                        color="primary"
                    />
                }
                label="With Buffer"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={withBlocked}
                        onChange={() => {
                            toggleCashPlanningBlockedFilter();
                            dispatch(clearAccDataAction());
                        }}
                        name="withBlocked"
                        color="primary"
                    />
                }
                label="With Blocked"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showFractions}
                        onChange={() => {
                            toggleCashPlanningFractionsFilter();
                        }}
                        name="showFractions"
                        color="primary"
                    />
                }
                label="Show Fractions"
            />
        </div>
    );
};

export default Filters;
