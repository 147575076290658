import React, {ReactChild} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { siteErrorCloseAction, siteErrorOpenAction } from '../actions/siteAlerts';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Box } from '@material-ui/core';

const mapState = (_: RootState) => ({});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteErrorCloseAction: siteErrorCloseAction
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SecondaryDrawerBarProps = {
    title: string;
};

type Props = PropsFromRedux &
    SecondaryDrawerBarProps & {
        backButtonUrl: string | null;
        children?: ReactChild;
    };

const SecondaryAppBar = (props: Props) => {
    return (
        <div>
            <Box borderBottom={1} style={{ borderColor: '#D3D3D3' }}>
                <Toolbar variant={'dense'}>
                    {props.backButtonUrl !== null && (
                        <IconButton edge="start" color={'primary'}>
                            <ArrowBack />
                        </IconButton>
                    )}
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                    {props.children}
                </Toolbar>
            </Box>
        </div>
    );
};

export const SecondaryDrawerBar = (props: SecondaryDrawerBarProps) => {
    return (
        <div>
            <Box borderBottom={1} style={{ borderColor: '#D3D3D3' }}>
                <Toolbar variant={'dense'}>
                    <Typography variant="h6">{props.title}</Typography>
                </Toolbar>
            </Box>
        </div>
    );
};

export default connector(SecondaryAppBar);
