import React from 'react';
import { AllTradesPropsType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import { handleAllTradesSimpleFieldChange, handleNonExchangeProductChange } from '../common';

interface InternalProductAFieldProps extends AllTradesPropsType {}

export function InternalProductAField(props: InternalProductAFieldProps) {
    const { state, setState, options } = props;

    return (
        <AutoCompleteField
            options={options.products}
            inputValue={state.productA.value}
            handleChange={(newVal: string) => {
                handleNonExchangeProductChange(
                    props,
                    {
                        value: newVal,
                        error: '',
                        required: state.productA.required,
                        summary: <span>{newVal}</span>
                    },
                    state.productB
                );
            }}
            handlePartialChange={partialValue => {
                handleAllTradesSimpleFieldChange(state, setState, 'productA', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.productA.required
                });
            }}
            required={state.productA.required}
            label={'Product A'}
            width={130}
            marginLeft={''}
            disabled={props.disabled}
        />
    );
}
