export enum PNLRunTypes3 {
    INTRADAY = 'intraday',
    PRELIM = 'prelim',
    VERIFIED = 'verified',
    COB = 'cob',
    COB_LIKELY = 'cob_likely',
    SCENARIO = 'scenario',
}

type PNLMeta3 = {
    run_type: PNLRunTypes3;
    pipe_run_date: string;
    run_id?: string;
    author_email?: string;
    author_nick_name?: string;
    created_at?: string;
    preliminary_pnl_run?: number;
};

export type PNLMetaDataResp = {
    found_selected_run: boolean;
    has_detail_data: boolean;
    pnl_list: Array<PNLMeta3>;
    selected: PNLMeta3;
    look_aheads: Array<string>;
    is_day_with_no_clearer_statements: boolean;
};

export type OverviewTableDataResp = {
    book: string;
    gross_pnl: number;
    total_cost: number;
    net_pnl: number;
    delta_net_pnl: number;
    var_95: number;
    delta_var_95: number;
};

type PerStrategyPaperSummaryDataResp = {
    deal: string;
    strategy: string;
    // Paper
    paper_gross_pnl: string;
    paper_total_cost: string;
    paper_net_pnl: string;
    paper_delta_net_pnl: string;
    // Total
    gross_pnl: string;
    total_cost: string;
    net_pnl: string;
    delta_net_pnl: string;
};

type PerStrategyPhysSummaryDataResp = PerStrategyPaperSummaryDataResp & {
    physical_gross_pnl: string;
    physical_total_cost: string;
    physical_net_pnl: string;
    physical_delta_net_pnl: string;
};

export type AllTradesRunDateDataResp = {
    overview_table: Array<OverviewTableDataResp>;
    per_strategy_phys_pnl_summary: Array<PerStrategyPhysSummaryDataResp>;
    per_strategy_corp_pnl_summary: Array<PerStrategyPaperSummaryDataResp>;
    adj_pnl_table: Array<any>;
    expired_prompt_ltd_table: Array<any>;
    cash_eq_pnl_table: Array<any>;
};

type ClearerBalanceTableDataResp = {
    account_type: string;
    today: number;
    dtd: number;
};

type PnlBookMetricsResp = {
    book: string;
    pct_win: string;
    best_pnl: string;
    best_day: string;
    worst_pnl: string;
    worst_day: string;
    median_win: string;
    median_loss: string;
    trading_days: string;
};

export type ClearerRunDateDataResp = {
    clearer_name: string;
    clearer_balance_table_run_date: string;
    clearer_balance_table: Array<ClearerBalanceTableDataResp>;
    clearer_hist_graph: Array<any>;
};

// TODO: Define the types below instead of any, but they are not really used either so whatever
export type HistoricalPNLPerBookMetricsDataResp = {
    pnl_book_metrics: Array<PnlBookMetricsResp>;
    pnl_summary_graphs: Array<any>;
    ltd_summary_graph: Array<any>;
    cash_summary_graph: Array<any>;
    pnl_sharpe_graph: Array<any>;
};

export type ExposureRows = {
    look_ahead: string;
    term_month_date: string;
};

export type ExposuresDataResp = {
    exposures_look_ahead_product_table_graph: Array<ExposureRows>;
    exposures_look_ahead_corp_table_graph: Array<ExposureRows>;
    exposures_look_ahead_phys_table_graph: Array<ExposureRows>;
    exposures_look_ahead_shipping_table_graph: Array<ExposureRows>;
    exposures_look_ahead_forward_contract_table_graph: Array<ExposureRows>;
    exposures_look_ahead_scg_table_graph: Array<ExposureRows>;
    spreads_by_structure: Array<ExposureRows>;
    detail_exp_phys_book_per_trade_kind: Array<any>;
    detail_exp_per_book_final_df: Array<any>;
    detail_exp_per_book_deal_final_df: Array<any>;
    transposed_products_term_months: Array<string>;
    corp_paper_by_month: Array<any>;
    physical_paper_by_month: Array<any>;
};

export type BackOfficeMiscDataResp = {
    dtd_changes: Array<any>;
    todays_mtm_prices: Array<any>;
};

export type MoveData = {
    data: Array<any>;
    compare_max_diff: number;
    compare_min_diff: number;
    budget_max_diff: number;
    budget_min_diff: number;
};

export type AccountingDanaosVessels = {
    days_in_report: number;
    days_in_compare: number;
    summary_table: Array<any>;
    pnl_sorted_admin_names: Array<string>;
    pnl_sorted_vessel_names: Array<string>;
    // Mgmt Summary Table
    mgmt_summary_table: Array<any>;
    company_group_mappings: Array<any>;
    // Cash
    cash_bank_balances: Array<any>;
    cash_payments_ic: Array<any>;
    cash_rec_groups: Array<any>;
    scg_our_cash: Array<any>;
    // Running Costs
    cost_sorted_admin_names: Array<string>;
    cost_sorted_vessel_names: Array<string>;
    admin_cost: MoveData;
    vessel_cost_yearly: MoveData;
    vessel_cost_daily: MoveData;
    hist_vessel_cost: Array<any>;
    hist_cash_balance: Array<any>;
    // Bank Accounts
    bank_institutions: Array<any>;
    bank_accounts: Array<any>;
    bank_balances_run_date_str: string;
    bank_balances: Array<any>;
    bank_transactions: Array<any>;
};

export type BACashPlanning = {
    latest_date: string;
    prev_date: string;
    cash_planning_wow: Array<any>;
    scg_cash_move_summary_df: Array<any>;
    scg_cash_move_start_date: string;
    scg_cash_move_end_date: string;
    scg_cash_move_invalid_lots: Array<string>;
};
