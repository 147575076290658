import React from 'react';
import { ExchangeTradesPropsType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import { handleExchangeSimpleFieldChange, handleExchangeBrokerFieldChange, isCrude, commissionError } from '../common';
import { DiffField } from './DiffField';
import { ConvertedLegVolume } from './ConvertedLegVolume';
import { CommissionField } from '../base/CommissionField';
import { ClearerTradeIDsField } from './ClearerTradeIDs';
import { MiniField } from './MiniField';

interface ExchangeBrokerFieldProps extends ExchangeTradesPropsType {
    marginLeft: string;
}

export function ExchangeBrokerField(props: ExchangeBrokerFieldProps) {
    const { state, setState, options } = props;

    let brokerTooltip: string = isCrude(options.crude_products, state.productA.value)
        ? `Crude ${state.productA.value} isn't traded via Broker`
        : '';
    let brokerDisabled = Boolean(brokerTooltip) || props.disabled;
    let brokerRequired = !isCrude(options.crude_products, state.productA.value) || state.broker.required;

    let exchangeOptions = isCrude(props.options.crude_products, props.state.productA.value) ? ['CME'] : options.exchanges;

    return (
        <span>
            <AutoCompleteField
                options={exchangeOptions}
                inputValue={state.exchange.value}
                handleChange={(newValue: string) => {
                    let exchangeSummary = <span>{newValue}</span>;
                    handleExchangeSimpleFieldChange(state, setState, 'exchange', {
                        value: newValue,
                        error: '',
                        summary: exchangeSummary,
                        required: state.exchange.required,
                    });
                }}
                required={state.exchange.required}
                label={'Exchanges'}
                width={120}
                marginLeft={props.marginLeft}
                disabled={props.disabled}
            />
            <DiffField {...props} />
            <MiniField {...props} />
            <ConvertedLegVolume {...props} />
            <AutoCompleteField
                options={options.brokers}
                inputValue={state.broker.value}
                handleChange={(newValue: string) => {
                    let brokerSummary = <span>with {newValue}</span>;
                    handleExchangeBrokerFieldChange(state, setState, {
                        value: newValue,
                        error: '',
                        summary: brokerSummary,
                        required: state.broker.required,
                    });
                }}
                required={brokerRequired && !brokerDisabled}
                label={'Brokers'}
                disabled={brokerDisabled}
                tooltipText={brokerTooltip}
                width={180}
                marginLeft={'16px'}
            />
            <CommissionField
                value={state.commissionOverride.value}
                handleChange={(newValue) => {
                    let errorText = '';
                    if (newValue !== '') {
                        errorText = commissionError(newValue);
                    }
                    setState({
                        ...state,
                        commissionOverride: {
                            ...state.commissionOverride,
                            value: newValue,
                            error: errorText,
                        },
                    });
                }}
                errorText={state.commissionOverride.error}
                label={'Comm. Override'}
                autofocus={false}
                marginLeft={'16px'}
            />
            <ClearerTradeIDsField {...props} />
        </span>
    );
}
