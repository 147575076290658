import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { companyGroupNamesMap } from '../Filters';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import { makeStyles } from '@material-ui/core/styles';
import SimpleTable from '../../tables/SimpleTable';
import { kFormatter, kFormatterWithParenthesis } from '../../containers/pivotHelper';
import { CellComp } from '../common/CellContainer';

const useStyles = makeStyles(() => ({
    pnlGroupColumn: {
        width: '250px',
    },
    firstLevelTotalRow: {
        borderTop: '5px solid black',
        borderBottom: '0px',
        fontSize: '17px',
        fontWeight: 'bold',
    },
    secondLevelTotalRow: {
        borderTop: '3px double black',
        borderBottom: '0px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    firstRowAfterBreakRow: {
        padding: '40px 6px 6px 6px !important',
    },
    standardRow: {
        padding: '6px 2px 6px 2px !important',
    },
}));

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const SummaryPNLComp = (props: Props) => {
    const classes = useStyles();
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingDanaosVessels,
            params: {
                start_date: filters.quickDateSelection!.start_date,
                end_date: filters.quickDateSelection!.end_date,
                cmp_start_date: filters.quickDateSelection!.cmp_start_date,
                cmp_end_date: filters.quickDateSelection!.cmp_end_date,
                company: filters.quickDateSelection!.company,
            },
        });
    }, [props, filters]);

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'349px'}
            />
        );
    }

    // Construct the vessel config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let titleColumn: SimpleTableColumnConfig = {
        name: 'pnl_group',
        title: 'Summary',
        hoverElement: (_, rawVal) => {
            let groupMapping = respData.mgmt_summary_table.find((groupMapping) => groupMapping['pnl_group'] === rawVal);
            let prefix = ['Gross Operating Income', 'Net Income For The Period'].includes(rawVal)
                ? 'Sum of Rows'
                : 'Includes Balance Sheet Groups';
            return (
                <p>
                    <b>{prefix}:</b> {groupMapping['bsg_code']}
                </p>
            );
        },
        headerCellClassName: classes.pnlGroupColumn,
        bodyCellsClassName: classes.pnlGroupColumn,
    };

    let columnNames: Array<SimpleTableColumnConfig> = respData.company_group_mappings.map((groupMapping) => {
        let columnName = groupMapping['summary_group'];
        let companyMeta = companyGroupNamesMap.get(columnName);
        let title: string = companyMeta ? companyMeta.title : columnName;
        return {
            name: `${columnName}|amount_report`,
            title: title,
            headerHoverElement: (_, __) => {
                return (
                    <p>
                        <b>Includes Companies:</b> {groupMapping['company_fixed_name']}
                    </p>
                );
            },
            customDisplay: (row: SimpleTableRow, rawVal: any) => {
                return CellComp(filters, columnName, row, rawVal, '12px', kFormatterWithParenthesis, kFormatter);
            },
        };
    });

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.AccMgmtSummary,
        title: 'Summary P&L',
        size: TableSize.Medium,
        columns: [titleColumn].concat(columnNames),
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
        stickyHeader: true,
        customRowClassName: (row) => {
            if (row['pnl_group'] === 'Gross Operating Income') {
                return classes.secondLevelTotalRow;
            } else if (row['pnl_group'] === 'Financing Cost') {
                return classes.firstRowAfterBreakRow;
            } else if (row['pnl_group'] === 'Net Income For The Period') {
                return classes.firstLevelTotalRow;
            }
            return '';
        },
    };

    return (
        <Grid item xs={12} style={{ backgroundColor: 'clear', minWidth: MIN_12_12_EL_WIDTH }}>
            <SimpleTable data={respData.mgmt_summary_table} config={config} filters={filters} />
        </Grid>
    );
};
export default accFetchConnector(SummaryPNLComp);
