import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { P_ARA, P_CP, P_FEI, P_LST } from '../../common/products';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { exposuresNumFormatter, exposuresNumFormatterNoZeros } from '../../common/exposures';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { ExposuresDataResp } from '../fetchTypes';
import SimpleTable from '../../tables/SimpleTable';
import { redOnlyHundred } from '../../containers/pivotHelper';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const pivotColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'spread',
        title: 'Spread',
    },
];

const productColumns = [P_FEI, P_ARA, P_CP, P_LST];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productName) => {
    return {
        name: productName,
        title: productName,
        bodyTemplate: exposuresNumFormatterNoZeros,
        textColor: redOnlyHundred,
    };
});

const allColumns = pivotColumns.concat(productsColumnConfig);

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.ExpDtd,
    title: 'Corp Spreads By Structure',
    size: TableSize.Small,
    columns: allColumns,
    grandTotal: false,
    aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
        return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
    },
    pivotDepth: 0,
    stickyHeader: true,
};

const SpreadsByStructure = (props: Props) => {
    const exposureData = props.pnlAnalytics.exposures;

    if (exposureData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.Exposures}
                data={exposureData}
                minHeight={'462px'}
            />
        );
    }

    let respData = exposureData.data! as ExposuresDataResp;

    return <SimpleTable data={respData.spreads_by_structure} config={config} />;
};

export default fetchConnector(SpreadsByStructure);
