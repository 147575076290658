import React, { useState } from 'react';
import {
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Label,
    Area,
    ResponsiveContainer,
    ReferenceLine,
    ReferenceLineProps,
    ComposedChart,
    Bar,
    // Since upgrading to Recharts 2.0.9 it's not able to import the AxisDomain
    // TODO: Fix in future versions hopefully?
    // @ts-ignore
    AxisDomainItem,
} from 'recharts';
import { SwissColors } from '../common/stylesConsts';
import { Paper } from '@material-ui/core';
import { SimpleTitle } from '../tables/TableTitle';

const FILL_OPACITY = 0.03;

export enum ChartType {
    AreaAsLine = 'AreaAsLine',
    Bar = 'Bar',
}

export type ChartCommon = {
    chartType: ChartType;
    key: string;
    color: string;
    title: string;
    secondary?: boolean;
    hiddenOnLoad?: boolean;
};

export type ChartAreaAsLine = ChartCommon & {
    fillOpacity?: number;
    strokeDasharray?: string;
    strokeOpacity?: number | string;
};

export type ChartBar = ChartCommon & {
    stackId?: string;
};

export type AllChartTypes = ChartAreaAsLine | ChartBar;

export type YAxisConfig = {
    yLimit: Readonly<[AxisDomainItem, AxisDomainItem]>;
    yAxisFormatter?: (value: any) => any;
};

export interface SwissReferenceLineProps extends ReferenceLineProps {
    tooltipLabel?: string;
}

export type LineGraphConfig = {
    title: string;
    dataKey: string;
    oxLabel: string;
    oyLabel: string;
    chartsSettings: Array<AllChartTypes>;
    referenceLines?: Array<SwissReferenceLineProps>;
    yAxis: YAxisConfig;
    ySecondaryAxis?: YAxisConfig;
    xAxisFormatter?: (value: any) => any;
    xTooltipFormatter?: (value: any) => any;
    yTooltipFormatter?: (value: any) => any;
};

export type LineGraphProps = LineGraphConfig & {
    values: Array<object>;
};

type InternalLineState = {
    hover: string | null;
    hidden: { [_: string]: boolean };
};

const shouldGreyOut = (hover: string | null, keyLabel: string): boolean => hover !== null && hover !== keyLabel;

const LineGraph = (props: LineGraphProps) => {
    let initBarProps: InternalLineState = {
        hover: null,
        hidden: {},
    };
    props.chartsSettings.forEach((chartsSettings) => {
        initBarProps.hidden[chartsSettings.key] = !!chartsSettings.hiddenOnLoad;
    });
    const [barProps, setBarProps] = useState<InternalLineState>(initBarProps);

    const handleLegendMouseEnter = (e: any) => {
        if (!barProps.hidden[e.dataKey]) {
            setBarProps({ ...barProps, hover: e.dataKey });
        }
    };

    const handleLegendMouseLeave = () => {
        setBarProps({ ...barProps, hover: null });
    };

    const selectBar = (e: any) => {
        setBarProps({
            ...barProps,
            hover: null,
            hidden: {
                ...barProps.hidden,
                [e.dataKey]: !barProps.hidden[e.dataKey],
            },
        });
    };
    return (
        <Paper style={{ padding: '10px 15px 5px 10px' }}>
            <SimpleTitle data={props.values} allData={props.values} title={props.title} />
            <ResponsiveContainer minHeight={'400px'} height="100%" width="100%">
                <ComposedChart style={{ backgroundColor: 'white' }} data={props.values} stackOffset={'sign'} margin={{ top: 10 }}>
                    {props.referenceLines?.map((lineProps, i) => (
                        // Reference lines and bars, line charts all share the same React Keys so we need to offset them
                        <ReferenceLine key={`${i}-refline`} {...lineProps} />
                    ))}
                    <XAxis minTickGap={20} dataKey={props.dataKey} tickFormatter={props.xAxisFormatter}>
                        <Label value={props.oxLabel} position="insideBottomRight" dy={10} dx={20} />
                    </XAxis>
                    <YAxis
                        type="number"
                        yAxisId={!!props.ySecondaryAxis ? 'left' : undefined}
                        orientation="left"
                        domain={props.yAxis.yLimit}
                        tickFormatter={props.yAxis.yAxisFormatter}
                        interval={0}>
                        <Label value={props.oyLabel} position="left" angle={-90} dy={-20} dx={-10} />
                    </YAxis>
                    {!!props.ySecondaryAxis ? (
                        <YAxis
                            type="number"
                            yAxisId="right"
                            orientation="right"
                            domain={props.ySecondaryAxis.yLimit}
                            tickFormatter={props.ySecondaryAxis.yAxisFormatter}
                            interval={0}
                        />
                    ) : null}
                    <Tooltip
                        content={
                            <CustomToolTip
                                yTooltipFormatter={props.yTooltipFormatter}
                                xTooltipFormatter={props.xTooltipFormatter}
                                referenceLines={props.referenceLines}
                            />
                        }
                    />
                    <Legend
                        iconType={'plainline'}
                        onClick={selectBar}
                        onMouseEnter={handleLegendMouseEnter}
                        onMouseLeave={handleLegendMouseLeave}
                    />
                    {props.chartsSettings.map((chartSetting, index) => {
                        if (chartSetting.chartType === ChartType.Bar) {
                            let barSetting = chartSetting as ChartBar;
                            return (
                                <Bar
                                    yAxisId={!!props.ySecondaryAxis ? (chartSetting.secondary ? 'right' : 'left') : undefined}
                                    name={chartSetting.title}
                                    key={index}
                                    stackId={barSetting.stackId}
                                    dataKey={chartSetting.key}
                                    barSize={40}
                                    hide={barProps.hidden[chartSetting.key]}
                                    fill={shouldGreyOut(barProps.hover, chartSetting.key) ? SwissColors.GreyOut : chartSetting.color}
                                />
                            );
                        } else if (chartSetting.chartType === ChartType.AreaAsLine) {
                            let areaAsLineSetting = chartSetting as ChartAreaAsLine;
                            let fillOpacity = areaAsLineSetting.fillOpacity ? areaAsLineSetting.fillOpacity : FILL_OPACITY;
                            return (
                                <Area
                                    name={chartSetting.title}
                                    type={'monotone'}
                                    connectNulls={true}
                                    key={index}
                                    dataKey={chartSetting.key}
                                    stroke={shouldGreyOut(barProps.hover, chartSetting.key) ? SwissColors.GreyOut : chartSetting.color}
                                    strokeWidth={shouldGreyOut(barProps.hover, chartSetting.key) ? 1 : 3}
                                    fill={shouldGreyOut(barProps.hover, chartSetting.key) ? SwissColors.GreyOut : chartSetting.color}
                                    hide={barProps.hidden[chartSetting.key]}
                                    fillOpacity={Number(barProps.hover === chartSetting.key || !barProps.hover ? fillOpacity : 0)}
                                    strokeDasharray={areaAsLineSetting.strokeDasharray}
                                    strokeOpacity={areaAsLineSetting.strokeOpacity}
                                />
                            );
                        } else {
                            throw Error(`We are not yet able to display charts of type ${chartSetting.chartType}`);
                        }
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        </Paper>
    );
};

const CustomToolTip = (props: any) => {
    const { active, payload, label } = props;
    const referenceLines: undefined | Array<SwissReferenceLineProps> = props.referenceLines;
    if (!active || !payload) {
        return null;
    }

    let xToolTipLabel = props.xTooltipFormatter ? props.xTooltipFormatter(label) : label;

    return (
        <Paper elevation={5} style={{ padding: '10px 20px', opacity: 0.87 }}>
            <p>{xToolTipLabel}</p>
            {payload.map((item: any, i: string) => (
                <p key={i}>
                    <span
                        style={{
                            height: '5px',
                            width: '5px',
                            backgroundColor: item.color,
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginBottom: '4px',
                            marginRight: '8px',
                        }}
                    />
                    {item.name}: <strong>{props.yTooltipFormatter(item.value)}</strong>
                </p>
            ))}
            {referenceLines &&
                referenceLines.map((refLine, i) => {
                    if (!refLine.tooltipLabel) return null;
                    return (
                        <p key={`ref-${i}`}>
                            <span
                                style={{
                                    height: '5px',
                                    width: '5px',
                                    backgroundColor: refLine.stroke,
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    marginBottom: '4px',
                                    marginRight: '8px',
                                }}
                            />
                            {refLine.tooltipLabel}: <strong>{props.yTooltipFormatter(refLine.y)}</strong>
                        </p>
                    );
                })}
        </Paper>
    );
};

export default LineGraph;
