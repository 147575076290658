import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux, SCGPhysicalResp } from '../dataFetcher';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import SimpleTable from '../../tables/SimpleTable';
import { makeStyles } from '@material-ui/core/styles';
import { accountingNumberFormat } from '../../containers/pivotHelper';

const useStyles = makeStyles(() => ({
    compColumnHeaderClassName: {
        width: '750px !important',
    },
    standardRow: {},
    firstLevelTotalRow: {
        borderTop: '5px solid black',
        borderBottom: '0px',
        fontSize: '17px',
        fontWeight: 'bold',
        padding: '10px 2px 10px 2px !important',
    },
    secondLevelTotalRow: {
        borderTop: '3px double black',
        borderBottom: '0px',
        fontSize: '15px',
        fontWeight: 'bold',
        padding: '6px 2px 6px 2px !important',
    },
    thirdLevelTotalRow: {
        borderTop: '2px solid black',
        borderBottom: '0px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '6px 2px 6px 2px !important',
    },
}));

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps;

export const staticColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'name',
        title: 'Name',
        hoverElement: (row) => {
            if (row['row_type'] === 'sum') {
                return <p>Sum of rows {row['value_items']}</p>;
            }
            if (row['row_type'] === 'total_trade') {
                return <p>LOT Totals + Sum of rows {row['value_items']}</p>;
            }
            if (row['row_type'] === 'edf') {
                return <p>Is the Sum of all the ED&F accounts OTE for the requested trade date (0 for the last day of each calendar year)</p>;
            }
            return (
                <p>
                    Ledger Cards: <strong>{row['value_items']}</strong>
                </p>
            );
        },
    },
    { name: 'amount', title: 'Amount', bodyTemplate: accountingNumberFormat },
];

const PhysRecSummaryComp = (props: Props) => {
    const classes = useStyles();
    const scgPhysical = props.pnlAnalytics.back_accounting_scg_physical;

    if (scgPhysical.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.BAccountingSCGPhysical}
                data={scgPhysical}
                minHeight={'500px'}
            />
        );
    }

    // Construct the config columns
    let respData = scgPhysical.data! as SCGPhysicalResp;

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.SCGPhysRecSummary,
        title: 'SCG Physical Trades Reconciliation Summary',
        size: TableSize.Medium,
        columns: staticColumns,
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        customRowClassName: (row) => {
            switch (row['name']) {
                case 'TOTALS A':
                    return classes.thirdLevelTotalRow;
                case 'TOTALS B':
                    return classes.thirdLevelTotalRow;
                case 'TOTALS C':
                    return classes.thirdLevelTotalRow;
                case 'TOTAL TRADE RESULTS (LOT Totals+A+B+C+Interest Financial Expenses)':
                    return classes.secondLevelTotalRow;
                case 'TOTALS D':
                    return classes.thirdLevelTotalRow;
                case 'PROFIT / LOSS':
                    return classes.firstLevelTotalRow;
                default:
                    return classes.standardRow;
            }
        },
        pivotDepth: 0,
        stickyHeader: true,
    };

    return <SimpleTable data={respData.scg_rec_summary} config={config} />;
};
export default bAccFetchConnector(PhysRecSummaryComp);
