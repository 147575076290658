import React from 'react';
import { siteErrorOpenAction, siteSuccessOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { AllTradesPropsType, RegularTradesType, ExchangeTradesPropsType, TradeOptions, SingleVolumeTradesPropsType } from './Types';
import moment from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { VolumeMetricField } from './regular/VolumeMetricField';
import { VolumeWrapperField } from './regular/VolumeWrapperField';
import { TradeDateWrapperField } from './regular/TradeDateWrapper';
import { DEFAULT_FIELD_BOOL, DEFAULT_OPT_FIELD_STR, DEFAULT_PRICE_A_FIELD, DEFAULT_REQ_FIELD_STR, executingForSummary } from './common';
import { BookStrategyField, getDealOptions } from './regular/BookStrategy';
import { ExchangeBrokerField } from './regular/ExchangeBroker';
import { SubmitButtons } from './regular/SubmitButtons';
import { postFetcher } from '../common/fetcher';
import { PromptFields } from './regular/PromptFields';
import { ProductFields } from './regular/ProductFields';
import { MiscFields } from './regular/MiscFields';
import { getAuthUser } from '../common/auth';
import AutoCompleteField from '../newTrade/base/AutoCompleteField';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteSuccessOpenAction: siteSuccessOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    options: TradeOptions;
    closeDialog: () => void;
};

type RegularTradeData = {
    fields: RegularTradesType;
    isSubmittingAddClose: boolean;
    isSubmittingAddNext: boolean;
};

class RegularTrade extends React.Component<Props, RegularTradeData> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fields: {
                tradeDate: { value: moment().format(), error: '', summary: null, required: true },
                volume: DEFAULT_REQ_FIELD_STR,
                monthA: DEFAULT_REQ_FIELD_STR,
                yearA: DEFAULT_REQ_FIELD_STR,
                balmoDate: DEFAULT_OPT_FIELD_STR,
                monthB: DEFAULT_OPT_FIELD_STR,
                yearB: DEFAULT_OPT_FIELD_STR,
                productA: DEFAULT_REQ_FIELD_STR,
                productB: DEFAULT_OPT_FIELD_STR,
                priceA: DEFAULT_PRICE_A_FIELD,
                priceB: DEFAULT_OPT_FIELD_STR,
                diff: DEFAULT_FIELD_BOOL,
                mini: DEFAULT_FIELD_BOOL,
                convertedLegVolume: DEFAULT_OPT_FIELD_STR,
                book: DEFAULT_REQ_FIELD_STR,
                strategy: DEFAULT_REQ_FIELD_STR,
                deal: DEFAULT_REQ_FIELD_STR,
                exchange: DEFAULT_REQ_FIELD_STR,
                broker: DEFAULT_REQ_FIELD_STR,
                commissionOverride: DEFAULT_OPT_FIELD_STR,
                executingFor: {
                    value: props.options.defaultTraderNickName,
                    error: '',
                    required: true,
                    summary: executingForSummary(props.options.authUserNickName, props.options.defaultTraderNickName),
                },
                clearerTradeIDs: DEFAULT_OPT_FIELD_STR,
                notes: DEFAULT_OPT_FIELD_STR,
            },
            isSubmittingAddClose: false,
            isSubmittingAddNext: false,
        };
    }

    submitTrade = (isSubmittingAddClose: boolean): void => {
        let fields = this.state.fields;
        let isSubmittingAddNext = !isSubmittingAddClose; // We can only submit one of the two
        let executingForEmail: string = '';
        this.props.options.users.forEach(function (user, _) {
            if (user.nick_name === fields.executingFor.value) {
                executingForEmail = user.email;
            }
        });
        if (executingForEmail === '') {
            throw Error(`Impossible to not have found email for trader ${fields.executingFor.value}`);
        }
        postFetcher<any>({
            url: `/tasks/all/sheets/blotter/enter_regular_trade`,
            data: {
                trade_date: moment(fields.tradeDate.value).format('YYYY-MM-DD'),
                volume: fields.volume.value,
                month_a: fields.monthA.value,
                year_a: fields.yearA.value,
                balmo_date: fields.balmoDate.value !== '' ? moment(fields.balmoDate.value).format('YYYY-MM-DD') : null,
                month_b: fields.monthB.value !== '' ? fields.monthB.value : null,
                year_b: fields.yearB.value !== '' ? fields.yearB.value : null,
                product_a: fields.productA.value,
                price_a: fields.priceA.value !== '' ? fields.priceA.value : null,
                product_b: fields.productB.value !== '' ? fields.productB.value : null,
                diff: fields.diff.value,
                mini: fields.mini.value,
                converted_leg_volume: fields.convertedLegVolume.value !== '' ? fields.convertedLegVolume.value : null,
                book: fields.book.value,
                strategy: fields.strategy.value,
                deal: fields.deal.value,
                exchange: fields.exchange.value,
                broker: fields.broker.value !== '' ? fields.broker.value : null,
                commission_override: fields.commissionOverride.value !== '' ? fields.commissionOverride.value : null,
                executing_for: executingForEmail,
                notes: fields.notes.value !== '' ? fields.notes.value : null,
                clearer_trade_ids: fields.clearerTradeIDs.value !== '' ? fields.clearerTradeIDs.value : null,
            },
            headers: {
                'Swiss-Tech-Auth-Token': getAuthUser(this.props.authUser).swiss_token,
            },
            onStart: () => {
                this.setState({
                    ...this.state,
                    isSubmittingAddClose: isSubmittingAddClose,
                    isSubmittingAddNext: isSubmittingAddNext,
                });
            },
            onSuccess: () => {
                this.props.siteSuccessOpenAction({ message: 'Trade was entered in Blotter' });
                if (isSubmittingAddClose) {
                    this.props.closeDialog();
                } else {
                    this.setState({
                        ...this.state,
                        isSubmittingAddNext: false,
                        fields: {
                            ...this.state.fields,
                            volume: DEFAULT_REQ_FIELD_STR,
                            exchange: DEFAULT_REQ_FIELD_STR,
                            broker: {
                                value: '',
                                error: '',
                                // workaround as the broker needs to be reset but we don't want to change its required property
                                required: this.state.fields.broker.required,
                                summary: null,
                            },
                            notes: DEFAULT_OPT_FIELD_STR,
                        },
                    });
                }
            },
            onFail: (err) => {
                this.setState({
                    ...this.state,
                    isSubmittingAddClose: false,
                    isSubmittingAddNext: false,
                });
                this.props.siteErrorOpenAction({ message: err.parsedMsg });
            },
        });
    };

    render() {
        let dataInputError: string = '';
        for (let [key, field] of Object.entries(this.state.fields)) {
            let capitalizedKey: string = key.charAt(0).toUpperCase() + key.slice(1);
            if (typeof field.value === 'string' && field.required && field.value === '') {
                dataInputError = `${capitalizedKey}: is Required`;
                break;
            }
            if (field.error !== '') {
                dataInputError = `${capitalizedKey}: ${field.error}`;
                break;
            }
        }

        let isSubmitting = this.state.isSubmittingAddClose || this.state.isSubmittingAddNext;
        let disableSubmitButton = Boolean(dataInputError) || isSubmitting;

        let allTradesProps: AllTradesPropsType = {
            state: this.state.fields,
            setState: (newFields) => {
                this.setState({ ...this.state, fields: { ...this.state.fields, ...newFields } });
            },
            options: this.props.options,
            disabled: isSubmitting,
        };

        let exchangeTradesProps: ExchangeTradesPropsType = {
            state: this.state.fields,
            setState: (newFields) => {
                this.setState({ ...this.state, fields: { ...this.state.fields, ...newFields } });
            },
            options: this.props.options,
            disabled: isSubmitting,
        };

        let singleVolumeTradesProps: SingleVolumeTradesPropsType = {
            state: this.state.fields,
            setState: (newFields) => {
                this.setState({ ...this.state, fields: { ...this.state.fields, ...newFields } });
            },
            options: this.props.options,
            disabled: isSubmitting,
        };

        let fields = this.state.fields;

        const dealOptions = getDealOptions(
            fields.strategy.value || '',
            this.props.options!.books.find((book) => book.name === fields.book.value )?.strategies
        );

        return (
            <div>
                <DialogContent>
                    <TradeDateWrapperField {...allTradesProps} />
                    <VolumeWrapperField {...singleVolumeTradesProps} />
                    <VolumeMetricField {...allTradesProps} />
                    <PromptFields {...allTradesProps} />
                    <ProductFields {...exchangeTradesProps} />
                    <BookStrategyField
                        marginLeft={'0px'}
                        options={this.props.options}
                        disabled={isSubmitting}
                        book={fields.book}
                        bookLabel={'Book'}
                        strategy={fields.strategy}
                        strategyLabel={'Strategy'}
                        setBookStrategy={(newBook, newStrategy) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, book: newBook, strategy: newStrategy },
                            });
                        }}
                    />
                    <AutoCompleteField
                        options={dealOptions}
                        inputValue={fields.deal.value || ''}
                        handleChange={(newValue) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, deal: {...fields.deal, value: newValue}},
                            });
                        }}
                        required={true}
                        label={'Deal'}
                        width={200}
                        marginLeft={'18px'}
                        minHeight={'0'}
                        styles={{verticalAlign: 'top'}}
                    />
                    <ExchangeBrokerField {...exchangeTradesProps} marginLeft={'16px'} />
                    <MiscFields {...allTradesProps} />
                    {dataInputError === '' && (
                        <Typography variant="h6" style={{ marginTop: '24px', minHeight: '24px', textAlign: 'center' }}>
                            {fields.tradeDate.summary} {fields.executingFor.summary} {fields.volume.summary} {fields.balmoDate.summary}{' '}
                            {fields.monthA.summary}
                            {fields.monthB.summary} {fields.productA.summary}
                            {fields.productB.summary} {fields.priceA.summary} {fields.broker.summary} {fields.exchange.summary}{' '}
                            {fields.book.summary}
                            {fields.strategy.summary}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <SubmitButtons
                        dataInputError={dataInputError}
                        disableSubmitButton={disableSubmitButton}
                        isSubmittingAddClose={this.state.isSubmittingAddClose}
                        isSubmittingAddNext={this.state.isSubmittingAddNext}
                        submitTrade={this.submitTrade}
                    />
                </DialogActions>
            </div>
        );
    }
}

export default connector(RegularTrade);
