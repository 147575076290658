import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { siteErrorCloseAction, siteErrorOpenAction } from '../actions/siteAlerts';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Infinity from '../common/Infinity';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import { EdfTradesState, fetchTradesAsync, INIT_PAGE_SIZE, isNewTradeBlock, TradeDataType } from '../common/edfCommon';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import moment from 'moment-timezone';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
    })
);

const mapState = (_: RootState) => ({});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteErrorCloseAction: siteErrorCloseAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    EdfTradesState & {
        modificationsDisabled: () => boolean;
    };

const EdfTable = (props: Props) => {
    const { filters, canLoadMoreTrades, setCanLoadMoreTrades, data, setData, modificationsDisabled } = props;
    const classes = useStyles({});
    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Run Date</TableCell>
                    <TableCell>Trade Date</TableCell>
                    <TableCell>Clearer</TableCell>
                    <TableCell>Account</TableCell>
                    <TableCell>Trade Type</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Quoted Volume</TableCell>
                    <TableCell>Quoted Trade Price</TableCell>
                    <TableCell>Term</TableCell>
                    <TableCell>Fees</TableCell>
                    <TableCell>Commission</TableCell>
                    <TableCell>Exchange</TableCell>
                    {/*To avoid button jumping back and forth between verify and un-verify*/}
                    <TableCell style={{ width: 240 }}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <Infinity
                element={TableBody}
                pageStart={0}
                loadMore={(newPageNum: number) => {
                    fetchTradesAsync({
                        pageSize: INIT_PAGE_SIZE,
                        pageNum: newPageNum,
                        ...filters,
                        onSuccess: (jsonData) => {
                            if (jsonData.trades.length < INIT_PAGE_SIZE) {
                                setCanLoadMoreTrades(false);
                            }
                            setData({ trades: [...data.trades, ...jsonData.trades] });
                        },
                        onFail: (err) => {
                            setCanLoadMoreTrades(false);
                            props.siteErrorOpenAction({
                                message: `Failed to Fetch more trades: ${err.parsedMsg}`,
                            });
                        },
                    });
                }}
                hasMore={canLoadMoreTrades}
                loader={loader}>
                {data.trades.map((row, i) => (
                    <TableRow
                        key={i}
                        style={{
                            borderTop: isNewTradeBlock(data.trades, i - 1, i) ? '2px solid #808080' : 'inherit',
                        }}>
                        <TableCell component="th" scope="row">
                            <div style={{ display: 'flex' }}>
                                <Moment format="DD MMM YY">{row.statement_date}</Moment>
                                <InternalTransferIcon row={row} />
                            </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <div style={{ display: 'flex' }}>
                                <Moment format="DD MMM YY">{row.trade_date}</Moment>
                            </div>
                        </TableCell>
                        <TableCell>{row.clearer_name}</TableCell>
                        <TableCell>{row.account_short_name}</TableCell>
                        <TableCell>
                            <TradeType row={row} />
                        </TableCell>
                        <TableCell>
                            <Tooltip title={row.contract_code} placement="top" interactive>
                                <span>{row.product_name}</span>
                            </Tooltip>
                        </TableCell>
                        <QuotedVolumeCell row={row} />
                        <QuotedTradePrice row={row} />
                        <TermMonthDate row={row} />
                        <Fees row={row} />
                        <Commission row={row} />
                        <TableCell>
                            <ExchangeTooltip title={row.trade_id} placement="top" interactive>
                                <span>{row.exchange}</span>
                            </ExchangeTooltip>
                        </TableCell>
                        <TableCell>
                            {row.state === 'PENDING'
                                ? VerifyButton(
                                      row,
                                      'primary',
                                      'VERIFIED',
                                      'verify',
                                      modificationsDisabled,
                                      props.siteErrorOpenAction,
                                      props
                                  )
                                : VerifyButton(
                                      row,
                                      'default',
                                      'PENDING',
                                      'unverify',
                                      modificationsDisabled,
                                      props.siteErrorOpenAction,
                                      props
                                  )}
                        </TableCell>
                    </TableRow>
                ))}
            </Infinity>
        </Table>
    );
};

const loader = (
    <TableRow className="loader" key={'unique'}>
        <TableCell colSpan={9} align="center">
            <CircularProgress />
            Loading more rows ...
        </TableCell>
    </TableRow>
);

function QuotedVolumeCell({ row }: { row: TradeDataType }) {
    return (
        <TableCell style={{ color: row.lots < 0 ? 'red' : 'black' }}>
            <Tooltip title={row.quoted_volume_unit} placement="top" interactive>
                <span>{Number(row.lots.toFixed(3))}</span>
            </Tooltip>
        </TableCell>
    );
}

function QuotedTradePrice({ row }: { row: TradeDataType }) {
    return (
        <TableCell>
            <Tooltip title={row.quoted_trade_price_unit} placement="top" interactive>
                <span>{Number(row.trade_price.toFixed(3))}</span>
            </Tooltip>
        </TableCell>
    );
}

function TermMonthDate(prop: { row: TradeDataType }) {
    let color = 'black';
    let balmoText = '';
    if (prop.row.balmo_date) {
        balmoText = `Balmo date: ${moment(prop.row.balmo_date!).format('Do MMM')}`;
        color = '#1976d2';
    }
    return (
        <TableCell>
            <div style={{ display: 'flex' }}>
                <Tooltip title={balmoText} placement="top" interactive>
                    <Moment format="MMM YYYY" style={{ color: color }}>
                        {prop.row.prompt_date}
                    </Moment>
                </Tooltip>
                <ExpiringIcon row={prop.row} />
            </div>
        </TableCell>
    );
    // if (prop.row.balmo_date === null) {
    //     return (
    //         <TableCell>
    //             <div style={{ display: 'flex' }}>
    //                 <Moment format="MMM YYYY">{prop.row.term_month_date}</Moment>
    //                 <ExpiringIcon row={prop.row} />
    //             </div>
    //         </TableCell>
    //     );
    // }
    // const promptDateOpts: Intl.DateTimeFormatOptions = {
    //     weekday: 'short',
    //     year: 'numeric',
    //     month: 'short',
    //     day: 'numeric',
    // };
    // return (
    //     <TableCell>
    //         <div style={{ display: 'flex' }}>
    //             <Tooltip
    //                 title={`Balmo date: ${new Date(prop.row.prompt_date).toLocaleDateString('en-US', promptDateOpts)}`}
    //                 placement="top"
    //                 interactive>
    //                 <Moment format="MMM YYYY" style={{ color: '#1976d2' }}>
    //                     {prop.row.prompt_date}
    //                 </Moment>
    //             </Tooltip>
    //             <ExpiringIcon row={prop.row} />
    //         </div>
    //     </TableCell>
    // );
}

function Fees(prop: { row: TradeDataType }) {
    return (
        <TableCell>
            <span>{Number(prop.row.fees.toFixed(3))}</span>
        </TableCell>
    );
    // let exchangeFee = Math.abs(prop.row.lots * 1000);
    // if (prop.row.product_name === 'BLPG') {
    //     exchangeFee = exchangeFee * 0.0215; // Both CME and ICE
    // } else if (prop.row.product_name === 'WTI') {
    //     exchangeFee = exchangeFee * 0.00332; // CME only. We never do ICE
    // } else if (prop.row.product_name === 'BRENT') {
    //     exchangeFee = exchangeFee * 0.00259; // CME only. We never do ICE
    // } else if (prop.row.is_mtb) {
    //     exchangeFee = prop.row.exchange === 'NYM' ? exchangeFee * 0.039552443 : exchangeFee * 0.0626705;
    // } else {
    //     exchangeFee = prop.row.exchange === 'NYM' ? exchangeFee * 0.02702 : exchangeFee * 0.046;
    // }
    // return (
    //     <TableCell>
    //         <span>{Number(exchangeFee.toFixed(3))}</span>
    //     </TableCell>
    // );
}

function Commission(prop: { row: TradeDataType }) {
    return (
        <TableCell>
            <span>{Number(prop.row.commission.toFixed(3))}</span>
        </TableCell>
    );
}

function ExpiringIcon(prop: { row: TradeDataType }) {
    if (!prop.row.is_expiring) {
        return null;
    }
    return (
        <div style={{ marginLeft: 10 }}>
            <Tooltip title={`This trade was automatically closed by our clearer`} placement="top" interactive>
                <TimerIcon fontSize="small" htmlColor={'#ff5722'} />
            </Tooltip>
        </div>
    );
}

function InternalTransferIcon(prop: { row: TradeDataType }) {
    if (!prop.row.is_internal) {
        return null;
    }
    return (
        <div style={{ marginLeft: 10 }}>
            <Tooltip title={`This is an internal trade to a TPA on ${prop.row.statement_date} statement`} placement="top" interactive>
                <SwapHoriz fontSize="small" htmlColor={'#ef6c00'} />
            </Tooltip>
        </div>
    );
}

function TradeType({ row }: { row: TradeDataType }) {
    let isTPATransfer = row.trade_type.includes('TPA Transfer');
    let isExpiring = ['P&S', 'Delivery', 'Trade Settlement'].includes(row.trade_type);
    if (isTPATransfer) {
        let msg = row.account_short_name === 'SCG' ? 'closing on SCG account' : `opening on TPA (${row.account_short_name}) account`;
        return (
            <div style={{ marginLeft: 10 }}>
                <Tooltip title={`This is an internal TPA Transfer ${msg}`} placement="top" interactive>
                    <SwapHoriz fontSize="small" htmlColor={'#ef6c00'} />
                </Tooltip>
            </div>
        );
    }
    if (isExpiring) {
        let expiringColor = '#FF2222FF'; // Default to P&S color
        if (row.trade_type === 'Delivery') {
            expiringColor = '#FFD322FF';
        } else if (row.trade_type === 'Trade Settlement') {
            expiringColor = '#FF8522FF';
        }
        return (
            <div style={{ marginLeft: 10 }}>
                <Tooltip
                    title={`This is an ${row.trade_type}, expired on ${moment(row.last_trading_date).format('Do MMM')}`}
                    placement="top"
                    interactive>
                    <TimerIcon fontSize="small" htmlColor={expiringColor} />
                </Tooltip>
            </div>
        );
    }
    return <span>{row.trade_type}</span>;
}

// ED&F trade ids have two whitespaces and Browsers reduce them to one, so this forces them to stay two whitespaces
const ExchangeTooltip = withStyles(() => ({
    tooltip: {
        whiteSpace: 'pre',
    },
}))(Tooltip);

function VerifyButton(
    row: TradeDataType,
    color: any,
    newState: string,
    text: string,
    modificationsDisabled: () => boolean,
    siteErrorOpenActionFunc: typeof siteErrorOpenAction,
    edfTradeState: EdfTradesState
) {
    const { setTradeIdCopied } = edfTradeState;
    return (
        <div style={{ position: 'relative' }}>
            <FileCopyIcon
                style={{ width: 14, height: 14, marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                    navigator.clipboard.writeText(row.trade_id).then();
                    setTradeIdCopied(row.trade_id);
                }}
            />
            {row.trade_id}
        </div>
    );
}

// function updateTrade(
//     row: TradeDataType,
//     newState: string,
//     siteErrorOpenActionFunc: typeof siteErrorOpenAction,
//     edfTradeState: EdfTradesState
// ) {
//     const { setRowUpdating, data, setData } = edfTradeState;
//     putFetcher<any>({
//         url: 'core-trades',
//         data: {
//             core_trades: [
//                 {
//                     clearer_trade_uuid: row.clearer_trade_uuid,
//                     state: newState,
//                 },
//             ],
//         },
//         onStart: () => {
//             setRowUpdating(row.trade_id);
//         },
//         onSuccess: () => {
//             setData({
//                 ...data,
//                 trades: data.trades.map((loop_trade) => {
//                     if (loop_trade.trade_id === row.trade_id) {
//                         return {
//                             ...loop_trade,
//                             state: newState,
//                         };
//                     }
//                     return loop_trade;
//                 }),
//             });
//         },
//         onFail: (err) => {
//             siteErrorOpenActionFunc({ message: err.parsedMsg });
//         },
//         onFinal: () => {
//             setRowUpdating('');
//         },
//     });
// }

export default connector(EdfTable);
