import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import { kFormatter, redOnlyHundred } from '../../containers/pivotHelper';
import { PaperTradeKindsFadedColor, PhysTradeKindsFadedColor } from '../../common/stylesConsts';
import { commonPNLSummaryTableColumns, rawNumFormat } from '../common/CommonPNLSummaryTable';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { kFormatterAggWithSign } from '../../pnlAnalytics/common/common';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    headerVarCell: {
        borderLeft: 'black solid 1px',
    },
    bodyVarCell: {
        borderLeft: 'black solid 1px',
    },
}));

const pivotColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'strategy',
        title: 'Strategy',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['strategy']}</div>;
        },
    },
    {
        name: 'deal',
        title: 'Deal',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['deal']}</div>;
        },
    },
    {
        name: 'physical_gross_pnl',
        title: 'Phys Gross',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        hoverElement: (row, val) => {
            let raw_val = row['physical_gross_pnl'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        headerBackgroundColor: PhysTradeKindsFadedColor,
    },
    {
        name: 'paper_gross_pnl',
        title: 'Paper Gross',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        hoverElement: (row, val) => {
            let raw_val = row['paper_gross_pnl'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        headerBackgroundColor: PaperTradeKindsFadedColor,
    },
];
const allColumns = pivotColumns.concat(commonPNLSummaryTableColumns);

const config: ExpandableTableConfig = {
    uniqueTableName: UniqueTableName.MPerStrategyPhysSummary,
    title: 'Physical Summary',
    size: TableSize.Small,
    columns: allColumns,
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 1,
    defaultExpandable: 0,
    stickyHeader: true,
};

const MPerStrategyPhysSummaryTable = (props: FetchPNLAnalyticsPropsFromRedux) => {
    const classes = useStyles();
    const allTradesResp = props.pnlAnalytics.all_trades_run_date;

    if (allTradesResp.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.AllTradesRunDate} data={allTradesResp} minHeight={'604px'} />
        );
    }

    config.columns[config.columns.length - 2].headerCellClassName = classes.headerVarCell;
    config.columns[config.columns.length - 2].bodyCellsClassName = classes.bodyVarCell;

    const respData = allTradesResp.data! as AllTradesRunDateDataResp;
    return <ExpandableTable data={respData['per_strategy_phys_pnl_summary']} config={config} />;
};

export default fetchConnector(MPerStrategyPhysSummaryTable);
