import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import { siteErrorOpenAction, siteSuccessOpenAction } from './actions/siteAlerts';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { getDate } from './common/common';
import { DateType } from '@date-io/type';
import { SelectedTaskDialogType, TaskCreateResType, UrlParamType } from './common/taskCommon';
import { useHistory } from 'react-router-dom';
import { fetcher } from './common/fetcher';
import ButtonSpinner from './containers/ButtonSpinner';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ReactJson from 'react-json-view';
import { Method } from 'axios';
import { getAuthUser } from './common/auth';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteSuccessOpenAction: siteSuccessOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    selectedTaskDialog: SelectedTaskDialogType;
    setSelectedTaskDialog: (newVal: SelectedTaskDialogType | null) => void;
};

const TaskRun = (props: Props) => {
    const { selectedTaskDialog, setSelectedTaskDialog } = props;
    let history = useHistory();
    let [creatingTask, setCreatingTask] = useState(false);
    const [method, setMethod] = React.useState<Method>(selectedTaskDialog.methods[0]);
    const [requestJsonText, setRequestJsonText] = React.useState('');
    const [requestJson, setRequestJson] = React.useState<any>({});
    const [validJson, setValidJson] = React.useState<any>(false);

    const handleMethodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMethod(event.target.value as Method);
    };

    const handleRequestJsonTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequestJsonText(event.target.value);
        let newRequestJson: any;
        try {
            newRequestJson = JSON.parse(event.target.value);
            event.target.value !== '{}' ? setValidJson(true) : setValidJson(false);
        } catch (e) {
            newRequestJson = { error: 'failed to parse JSON above' };
            setValidJson(false);
        }
        setRequestJson(newRequestJson);
    };

    const updateUrlParamVal = (currSelectedTaskDialog: SelectedTaskDialogType, urlParamName: string, newUrlParamVal: any) => {
        // I have not figured out a cleaner way to update the dictionary than create a new dictionary
        let newUrlParamValues: { [_: string]: any } = {};
        currSelectedTaskDialog.task.url_params.forEach((urlParam: UrlParamType) => {
            let currVal = currSelectedTaskDialog.urlParamValues[urlParam.name];
            if (urlParam.name === urlParamName) {
                // Update to the newly selected value
                newUrlParamValues[urlParam.name] = newUrlParamVal;
                return;
            }
            if (currVal === undefined) return; // This value is not set
            newUrlParamValues[urlParam.name] = currVal; // Just keep the value that it had before
        });
        setSelectedTaskDialog({
            ...currSelectedTaskDialog,
            urlParamValues: { ...newUrlParamValues },
        });
    };

    let urlParamsToSubmit: { [_: string]: any } = {};
    let missingUrlParams: Array<any> = [];
    selectedTaskDialog.task.url_params.forEach((urlParam: UrlParamType) => {
        let urlParamVal = selectedTaskDialog.urlParamValues[urlParam.name];
        if (urlParamVal === undefined) {
            missingUrlParams.push(urlParam.name);
            return;
        }
        if (urlParam.type_val === 'date') {
            // Need to convert from Moment to string
            urlParamVal = moment(urlParamVal).format('YYYY-MM-DD');
        }
        urlParamsToSubmit[urlParam.name] = urlParamVal;
    });
    let submitTooltipText = missingUrlParams.length !== 0 ? `Fields [${missingUrlParams}] need to be filled out` : '';
    if (submitTooltipText === '' && selectedTaskDialog.requestJson && !validJson) {
        submitTooltipText = 'A valid and non empty JSON is needed to submit';
    }

    const submitTask = () => {
        let jsonData = validJson ? requestJson : undefined; // If no JSON is expected don't pass it
        fetcher<TaskCreateResType>({
            method: method,
            url: `/tasks/all${selectedTaskDialog?.task.route}_queue`,
            params: selectedTaskDialog?.urlParamValues,
            data: jsonData,
            headers: {
                'Swiss-Tech-Auth-Token': getAuthUser(props.authUser).swiss_token,
            },
            onStart: () => {
                setCreatingTask(true);
            },
            onSuccess: (res) => {
                props.siteSuccessOpenAction({ message: `Task ${res.task_id} created` });
                history.push('/tasks/history');
            },
            onFail: (err) => {
                props.siteErrorOpenAction({ message: err.parsedMsg });
                setCreatingTask(false); // We disable the spinner here and not on final. See final why
            },
            onFinal: () => {
                // Leave it empty as when we change route the component will no longer be mounted
            },
        });
    };

    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={true}
            onClose={() => {
                if (creatingTask) return;
                setSelectedTaskDialog(null);
            }}>
            <DialogTitle>{`Run ${selectedTaskDialog.task.route}`}</DialogTitle>
            <DialogContent>
                <Typography variant="h6">Method</Typography>
                <FormControl style={{ width: 80, marginBottom: '16px' }}>
                    <InputLabel id="demo-simple-select-label">Method</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={method} onChange={handleMethodChange}>
                        {selectedTaskDialog.methods.map((method) => (
                            <MenuItem key={method} value={method}>
                                {method}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="h6">URL Params</Typography>
                {selectedTaskDialog.task.url_params.map((urlParam: UrlParamType, i: number) => (
                    <div key={i}>
                        {urlParam.type_val === 'bool' && (
                            <TaskParamSwitch
                                disabled={creatingTask}
                                urlParam={urlParam}
                                selectedTaskDialog={selectedTaskDialog}
                                updateUrlParamVal={updateUrlParamVal}
                            />
                        )}
                        {urlParam.type_val === 'date' && (
                            <TaskParamDate
                                disabled={creatingTask}
                                urlParam={urlParam}
                                selectedTaskDialog={selectedTaskDialog}
                                updateUrlParamVal={updateUrlParamVal}
                            />
                        )}
                    </div>
                ))}
                {selectedTaskDialog.requestJson && (
                    <div>
                        <Typography variant="h6">JSON Request</Typography>
                        <TextField
                            style={{ marginBottom: '16px' }}
                            label="JSON"
                            multiline
                            fullWidth={true}
                            rowsMax={4}
                            value={requestJsonText}
                            onChange={handleRequestJsonTextChange}
                        />
                        <ReactJson src={requestJson} />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Tooltip title={submitTooltipText} placement={'top'}>
                    <span>
                        <ButtonSpinner disabled={submitTooltipText !== ''} loading={creatingTask} onClick={submitTask}>
                            Run Task
                        </ButtonSpinner>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

function TaskParamSwitch(props: {
    disabled: boolean;
    urlParam: UrlParamType;
    selectedTaskDialog: SelectedTaskDialogType;
    updateUrlParamVal: (currSelectedTaskDialog: SelectedTaskDialogType, urlParamName: string, newUrlParamVal: any) => void;
}) {
    const { urlParam, selectedTaskDialog, updateUrlParamVal } = props;

    const urlParamName = urlParam.name;
    const urlParamVal = selectedTaskDialog.urlParamValues[urlParam.name];
    return (
        <FormControlLabel
            disabled={props.disabled}
            control={
                <Switch
                    checked={urlParamVal}
                    onChange={() => {
                        updateUrlParamVal(selectedTaskDialog, urlParamName, !urlParamVal);
                    }}
                    color="primary"
                />
            }
            label={urlParamName}
        />
    );
}

function TaskParamDate(props: {
    disabled: boolean;
    urlParam: UrlParamType;
    selectedTaskDialog: SelectedTaskDialogType;
    updateUrlParamVal: (currSelectedTaskDialog: SelectedTaskDialogType, urlParamName: string, newUrlParamVal: any) => void;
}) {
    const { urlParam, selectedTaskDialog, updateUrlParamVal } = props;

    const urlParamName = urlParam.name;
    // KeyboardDatePicker needs null not undefined
    let urlParamVal = null;
    if (selectedTaskDialog.urlParamValues[urlParamName] !== undefined) {
        urlParamVal = `${selectedTaskDialog.urlParamValues[urlParam.name]}T00:00:00`;
    }
    // const urlParamVal = `${selectedTaskDialog.urlParamValues[urlParam.name]}T00:00:00` || null;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disabled={props.disabled}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            disabled={urlParamVal === null || props.disabled}
                            onClick={() => {
                                updateUrlParamVal(selectedTaskDialog, urlParamName, undefined);
                            }}>
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                InputAdornmentProps={{
                    position: 'start',
                }}
                style={{ marginTop: 0 }}
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label={urlParamName}
                value={urlParamVal}
                maxDate={getDate(-1)}
                disableFuture={true}
                autoOk={true}
                allowKeyboardControl={false}
                onAccept={(date: DateType | null) => {
                    if (date === null) return;
                    let momentDate = moment(date).startOf('day');
                    let dateStr = momentDate.format('YYYY-MM-DD');
                    updateUrlParamVal(selectedTaskDialog, urlParamName, dateStr);
                }}
                onChange={(_: Date | null, __?: string | null) => {}}
            />
        </MuiPickersUtilsProvider>
    );
}

export default connector(TaskRun);
