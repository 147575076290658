import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { BACashPlanning } from '../../pnlAnalytics/fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { kFormatter, redOnly } from '../../containers/pivotHelper';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux } from '../dataFetcher';
import moment from 'moment-timezone';
import { FetchShowSCGCashMovesRespType, FetchShowSCGCashMovesStoreType, useZusStore } from '../../reducers/appStore';
import { newFetcher } from '../../common/fetcher';
import { swissUseSelector } from '../../reducers';
import { AuthUserState } from '../../reducers/authUser';
import { useMainSnackbar } from '../../common/SwissSnackBar';

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const SCGCashMovesTable = (props: Props) => {
    const appStore = useZusStore((state) => state);
    const authUser = swissUseSelector<AuthUserState>((state) => state.authUser);
    const mainSnackbar = useMainSnackbar();

    const cashPlanningData = props.pnlAnalytics.back_accounting_cash_planning;

    if (cashPlanningData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.BACashPlanning} data={cashPlanningData} minHeight={'680px'} />
        );
    }

    // Construct the config columns
    let respData = cashPlanningData.data! as BACashPlanning;

    let data = respData.scg_cash_move_summary_df;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'group',
            title: 'Group',
        },
        {
            name: 'subgroup',
            title: 'Sub Group',
            customDisplay: (row, rawVal) => {
                if (row['_depth'] !== 1) return <span />;
                return (
                    <div
                        style={{ cursor: 'pointer', color: '#0645AD', textDecoration: 'underline' }}
                        onClick={() => {
                            newFetcher<FetchShowSCGCashMovesRespType, FetchShowSCGCashMovesStoreType>({
                                method: 'GET',
                                authUserState: authUser,
                                appStore: appStore,
                                mainSnackbar: mainSnackbar,
                                withBackdropSpinner: true,
                                withErrorSnackbar: true,
                                appStoreDataKey: 'fetchShowSCGCashMoves',
                                url: '/acc_analytics/back_acc/detail_cash_move',
                                params: {
                                    group: row['group'] as string,
                                    subgroup: row['subgroup'] as string,
                                    lot_num: row['lot_num'] as string,
                                },
                                onStoreSuccess: (resp) => {
                                    return resp;
                                },
                            });
                        }}>
                        {rawVal}
                    </div>
                );
            },
        },
        {
            name: 'book_value',
            title: 'Amount',
            textColor: redOnly,
            bodyTemplate: kFormatter,
        },
    ];

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.SCGCashMovesTable,
        title: `SCG Cash Moves:  ${moment(respData.scg_cash_move_start_date).format('Do MMM YY')} -> ${moment(
            respData.scg_cash_move_end_date
        ).format('Do MMM YY')}`,
        size: TableSize.Medium,
        columns: columnsConfig,
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        defaultExpandable: 0,
        stickyHeader: true,
    };

    return (
        <>
            {respData.scg_cash_move_invalid_lots.length !== 0 && (
                <div style={{ backgroundColor: 'red', marginTop: '5px', marginBottom: '10px' }}>
                    We have invalid cash LOTs in Danaos: {respData.scg_cash_move_invalid_lots.toString()}
                </div>
            )}
            <ExpandableTable data={data} config={config} />
        </>
    );
};
export default bAccFetchConnector(SCGCashMovesTable);
