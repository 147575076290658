import React, { ReactChild, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import { siteErrorOpenAction, siteSuccessOpenAction } from './actions/siteAlerts';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import { CenteredCircularProgress, useDataPollingFetching } from './common/common';
import { TaskCreateResType, TaskHistoryType, TasksHistoryType } from './common/taskCommon';
import { ErrorPage } from './common/error';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { fetcher } from './common/fetcher';
import ButtonSpinner from './containers/ButtonSpinner';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import { TaskCreatedDateCell, TaskStateCell } from './containers/TaskRuns';
import ReactJson from 'react-json-view';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { getAuthUser } from './common/auth';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteSuccessOpenAction: siteSuccessOpenAction,
};

const connector = connect(mapState, mapDispatch);

const taskHistoryPollingMilliseconds = 20000;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const TasksHistory = (props: Props) => {
    const { initErr, loading, data, triggerForceRefresh } = useDataPollingFetching<TasksHistoryType>(
        '/tasks/api/history',
        { tasks: [] },
        taskHistoryPollingMilliseconds
    );

    const [dataDialog, setDataDialog] = useState<TaskHistoryType | null>(null);
    const [outputDialog, setOutputDialog] = useState<TaskHistoryType | null>(null);
    const [errorDialog, setErrorDialog] = useState<TaskHistoryType | null>(null);

    const [reRunTask, setReRunTask] = useState<TaskHistoryType | null>(null);

    if (loading) {
        return <CenteredCircularProgress />;
    }
    if (initErr) {
        return <ErrorPage msg={initErr} />;
    }
    const reRunTaskAction = (taskRun: TaskHistoryType) => {
        fetcher<TaskCreateResType>({
            method: taskRun.method,
            url: `/tasks/all${taskRun.task_route}_queue`,
            params: taskRun.request_args,
            data: taskRun.request_json,
            headers: {
                'Swiss-Tech-Auth-Token': getAuthUser(props.authUser).swiss_token,
            },
            onStart: () => {
                setReRunTask(taskRun);
            },
            onSuccess: (res) => {
                props.siteSuccessOpenAction({ message: `Task ${res.task_id} created` });
                triggerForceRefresh();
            },
            onFail: (err) => {
                props.siteErrorOpenAction({ message: err.parsedMsg });
            },
            onFinal: () => {
                setReRunTask(null);
            },
        });
    };

    return (
        <div>
            <div style={{ overflow: 'auto' }}>
                <Tooltip title={'Refresh task history'} placement="top" interactive>
                    <IconButton
                        style={{ float: 'right' }}
                        disabled={reRunTask !== null}
                        onClick={() => {
                            triggerForceRefresh();
                        }}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Route</TableCell>
                            <TableCell>Exec</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell style={{ maxWidth: 100 }}>Params/Data</TableCell>
                            <TableCell style={{ maxWidth: 100 }}>Output</TableCell>
                            <TableCell style={{ maxWidth: 100 }}>Error</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell style={{ minWidth: 100 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.tasks.map((taskRun, i) => (
                            <TableRow key={i}>
                                <TableCell>{taskRun.task_route}</TableCell>
                                <TableCell>
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                <b>Task ID: </b>
                                                {taskRun.task_id}
                                            </React.Fragment>
                                        }
                                        placement="top"
                                        interactive>
                                        <span>{executor(taskRun)}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <TaskCreatedDateCell taskRun={taskRun} />
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={taskRun.auth_email} placement="top" interactive>
                                        <span>{taskRun.auth_name}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    style={{ maxWidth: 100, cursor: 'pointer' }}
                                    onClick={() => {
                                        setDataDialog(taskRun);
                                    }}>
                                    <pre
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>
                                        {Object.keys(taskRun.request_args).map((key, i) => (
                                            <div key={i}>
                                                <span>
                                                    <b>{key}:</b> {taskRun.request_args[key].toString()}
                                                </span>
                                            </div>
                                        ))}
                                        {taskRun.request_json !== null && <span>{JSON.stringify(taskRun.request_json)}</span>}
                                    </pre>
                                </TableCell>
                                <TableCell
                                    style={{ maxWidth: 100, cursor: 'pointer' }}
                                    onClick={() => {
                                        setOutputDialog(taskRun);
                                    }}>
                                    {taskRun.output !== null && (
                                        <pre
                                            key={i}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {taskRun.output}
                                        </pre>
                                    )}
                                </TableCell>
                                <TableCell
                                    style={{ maxWidth: 100, cursor: 'pointer' }}
                                    onClick={() => {
                                        setErrorDialog(taskRun);
                                    }}>
                                    {taskRun.error_trace !== null && (
                                        <pre
                                            key={i}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {taskRun.error_trace}
                                        </pre>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {taskRun.calculated_execution_seconds !== null &&
                                        (taskRun.calculated_execution_seconds < 1 ? '<1' : taskRun.calculated_execution_seconds.toFixed(0))}
                                </TableCell>
                                <TableCell>
                                    <TaskStateCell taskRun={taskRun} />
                                </TableCell>
                                <TableCell>
                                    <ButtonSpinner
                                        loading={reRunTask?.task_id === taskRun.task_id}
                                        disabled={reRunTask !== null}
                                        onClick={() => {
                                            reRunTaskAction(taskRun);
                                        }}>
                                        Re-Run
                                    </ButtonSpinner>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <OutputDialog outputDialog={outputDialog} setOutputDialog={setOutputDialog} />
            <ErrorDialog errorDialog={errorDialog} setErrorDialog={setErrorDialog} />
            <DataDialog dataDialog={dataDialog} setDataDialog={setDataDialog} />
        </div>
    );
};

function executor(taskRun: TaskHistoryType) {
    if (taskRun.queue_name !== null) {
        return taskRun.queue_name;
    }
    return taskRun.executor;
}

function OutputDialog(props: { outputDialog: TaskHistoryType | null; setOutputDialog: (newVal: TaskHistoryType | null) => void }) {
    const closeDialog = () => {
        props.setOutputDialog(null);
    };
    if (props.outputDialog === null) {
        return null; // Dialog is closed
    }
    if (props.outputDialog.output === null) {
        return (
            <TaskSimpleDialog title={'Task Output'} closeDialog={closeDialog}>
                <div>Output was probably not redirected/saved. Check the request args</div>
            </TaskSimpleDialog>
        );
    }
    if (props.outputDialog.output.length === 0) {
        return (
            <TaskSimpleDialog title={'Task Output'} closeDialog={closeDialog}>
                <div>Task didn't emit anything to standard out</div>
            </TaskSimpleDialog>
        );
    }

    return (
        <TaskSimpleDialog title={'Task Output'} closeDialog={closeDialog}>
            <pre>{props.outputDialog.output}</pre>
        </TaskSimpleDialog>
    );
}

function ErrorDialog(props: { errorDialog: TaskHistoryType | null; setErrorDialog: (newVal: TaskHistoryType | null) => void }) {
    const closeDialog = () => {
        props.setErrorDialog(null);
    };
    if (props.errorDialog === null) {
        return null; // Dialog is closed
    }
    if (props.errorDialog.error_type === null) {
        return (
            <TaskSimpleDialog title={'Task Error'} closeDialog={closeDialog}>
                <div>Task didn't have any errors</div>
            </TaskSimpleDialog>
        );
    }
    if (props.errorDialog.error_trace === null || props.errorDialog.error_scope == null) {
        throw Error(
            `error_trace ${props.errorDialog.error_trace} or error_scope ${props.errorDialog.error_scope} are null when error_type is not. It's either all or none of them that should be null`
        );
    }

    return (
        <TaskSimpleDialog title={'Task Error'} closeDialog={closeDialog}>
            <div>
                <Typography variant="subtitle1">Scope:</Typography>
                <pre>{props.errorDialog.error_scope}</pre>
                <Typography variant="subtitle1">Type:</Typography>
                <pre>{props.errorDialog.error_type}</pre>
                <Typography variant="subtitle1">Dictionary:</Typography>
                <div>
                    {Object.keys(props.errorDialog.error_dict).map((key, index) => (
                        <pre key={index}>
                            <b>{key}</b>: {props.errorDialog?.error_dict[key]}
                        </pre>
                    ))}
                </div>
                <Typography variant="subtitle1">Trace:</Typography>
                <pre>{props.errorDialog.error_trace}</pre>
            </div>
        </TaskSimpleDialog>
    );
}

function DataDialog(props: { dataDialog: TaskHistoryType | null; setDataDialog: (newVal: TaskHistoryType | null) => void }) {
    const closeDialog = () => {
        props.setDataDialog(null);
    };
    if (props.dataDialog === null) {
        return null; // Dialog is closed
    }
    if (!props.dataDialog.request_args && !props.dataDialog.request_json) {
        return (
            <TaskSimpleDialog title={'Task Params/Data'} closeDialog={closeDialog}>
                <div>No Params or Data</div>
            </TaskSimpleDialog>
        );
    }

    return (
        <TaskSimpleDialog title={'Task Params/Data'} closeDialog={closeDialog}>
            <div>
                <Typography variant="subtitle1">Method:</Typography>
                <div style={{ marginBottom: '16px' }}>{props.dataDialog.method}</div>
                <Typography variant="subtitle1">URL Params:</Typography>
                <div>
                    {Object.keys(props.dataDialog.request_args).map((key, index) => (
                        <pre key={index}>
                            <b>{key}</b>: {props.dataDialog?.request_args[key]}
                        </pre>
                    ))}
                </div>
                <Typography variant="subtitle1" style={{ display: 'inline-flex' }}>
                    JSON:
                </Typography>
                <FileCopyIcon
                    style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(props.dataDialog?.request_json)).then();
                    }}
                />
                {props.dataDialog.request_json && (
                    <div>
                        <ReactJson src={props.dataDialog.request_json} />
                    </div>
                )}
            </div>
        </TaskSimpleDialog>
    );
}

function TaskSimpleDialog(props: { title: string; closeDialog: () => void; children: ReactChild }) {
    return (
        <Dialog maxWidth={'lg'} open={true} onClose={props.closeDialog}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>{props.children}</DialogContent>
        </Dialog>
    );
}

export default connector(TasksHistory);
