import {FetchCashPlanningAccountStoreType, useZusStore} from "../../reducers/appStore";
import {initNewFetchData, NewFetchStatus} from "../../common/fetcher";
import {SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow, TableSize} from "../../tables/types";
import moment from "moment-timezone";
import {AggFuncName} from "../../common/cellRangeAgg";
import {exposuresNumFormatter} from "../../common/exposures";
import SimpleTable from "../../tables/SimpleTable";
import React from "react";
import {dollarVal2D, kFormatterWithParenthesis, redOnlyHundred} from "../../containers/pivotHelper";
import {UniqueTableName} from "../../common/uniqueTableName";
import {Grid} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";

const CashPlanningTransactions = () => {
    const { fetchCashPlanningAccount, fetchCashPlanningAccountUpdate } = useZusStore((state) => ({
        fetchCashPlanningAccount: state.fetchCashPlanningAccount,
        fetchCashPlanningAccountUpdate: state.fetchCashPlanningAccountUpdate,
    }));
    const closeDrawer = () => fetchCashPlanningAccountUpdate(initNewFetchData());

    if (fetchCashPlanningAccount.status !== NewFetchStatus.SUCCESS) return null;
    return (
        <Drawer
            anchor={'right'}
            open={true}
            PaperProps={{ style: { width: '90%' } }}
            // style={{ zIndex: 1201 }}
            onClose={(_, reason) => {
                if (reason === 'escapeKeyDown') return;
                closeDrawer();
            }}>
            <div className={'MuiToolbar-dense'} />
            <Grid container spacing={2} justify="center" style={{ padding: '20px' }}>
                <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '25px' }}>
                    <DanaosTable fetchCashPlanningAccount={fetchCashPlanningAccount.data!}/>
                </Grid>
            </Grid>
        </Drawer>
    )
}

const DanaosTable = ({fetchCashPlanningAccount}: {fetchCashPlanningAccount: FetchCashPlanningAccountStoreType}) => {
    const { showFractions } = useZusStore((state) => ({
        showFractions: state.cashPlanningFilters.showFractions,
    }));

    const columns: Array<SimpleTableColumnConfig> = [
        {
            name: 'journal_date',
            title: 'Journal Date',
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return moment(rawVal['date'] as string).format('DD MMM YY');
            },
        },
        { name: 'document_type', title: 'Type' },
        { name: 'from_to', title: 'Ledger' },
        { name: 'company_fixed_name', title: 'Company' },
        {
            name: 'narrative',
            title: 'Narrative',
            // headerCellClassName: classes.cardColumn,
            // bodyCellsClassName: classes.cardColumn,
        },
        {
            name: 'amount',
            title: 'Amount',
            bodyTemplate: (val) => {
                return showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
        },
        // {
        //     name: 'description',
        //     title: 'Description',
        //     bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
        //         return rawVal['description'].substring(0, 60); // Make sure not to exceed 60 characters as it gets too long
        //     },
        // },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.BACashPlanningDanaosTransactions,
        title: 'Danaos Transactions',
        size: TableSize.Medium,
        columns: columns,
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
    };

    return <SimpleTable data={fetchCashPlanningAccount.danaos_transactions} config={config} />;
};

export default CashPlanningTransactions