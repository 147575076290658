import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { bankImgAndName, currencyToLetter } from './common';
import SimpleTable from '../../tables/SimpleTable';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const BankAccountsTableComp = (props: Props) => {
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'680px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let data = respData.bank_accounts;
    let institutions = respData.bank_institutions;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'bic',
            title: 'Bank',
            customDisplay: (row, val) => {
                if (val === 'Total') return <div>Total</div>;
                return bankImgAndName(val as string, 'bic', institutions);
            },
        },
        {
            name: 'bank_account_no',
            title: 'IBAN',
            hoverElement: (row) => {
                return <div style={{ width: '500px' }}>{row['service_account_no']}</div>;
            },
        },
        {
            name: 'currency',
            title: 'Currency',
            bodyTemplate2: (val, _) => {
                return currencyToLetter(val);
            },
        },
        {
            name: 'owner',
            title: 'Owner',
        },
        {
            name: 'product',
            title: 'Name',
            bodyTemplate2: (val, row) => {
                return val ? val : row['account_name'];
            },
        },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.BankAccountsTable,
        title: 'Bank Accounts',
        size: TableSize.Medium,
        columns: columnsConfig,
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        stickyHeader: true,
    };

    return <SimpleTable data={data} config={config} />;
};
export default accFetchConnector(BankAccountsTableComp);
