import {ERROR_CLOSE, ERROR_OPEN, SiteErrorActionTypes, SUCCESS_CLOSE, SUCCESS_OPEN} from '../actions/siteAlerts';
import {SnackbarOrigin} from "@material-ui/core";
import {Color} from "@material-ui/lab";

export interface SiteAlertState {
    message: string;
    autoHideDuration: number;

    severity: Color;
    anchorOrigin: SnackbarOrigin;
}

interface SiteAlertsState {
    successAlert: SiteAlertState | null,
    errorAlert: SiteAlertState | null,
}

const initialState: SiteAlertsState = {successAlert: null, errorAlert: null};
const siteAlerts = (state = initialState, action: SiteErrorActionTypes): SiteAlertsState => {
    switch (action.type) {
        case ERROR_OPEN:
            return {
                ...state,
                errorAlert: {
                    severity: 'error',
                    anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
                    autoHideDuration: action.payload.autoHideDuration === undefined ? 10000 : action.payload.autoHideDuration,
                    message: action.payload.message

                },
            };
        case ERROR_CLOSE:
            return {
                ...state,
                errorAlert: null,
            };
        case SUCCESS_OPEN:
            return {
                ...state,
                successAlert: {
                    severity: 'success',
                    anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
                    autoHideDuration: action.payload.autoHideDuration === undefined ? 5000 : action.payload.autoHideDuration,
                    message: action.payload.message

                },
            };
        case SUCCESS_CLOSE:
            return {
                ...state,
                successAlert: null,
            };
        default:
            return state;
    }
};

export default siteAlerts;
