import { SimpleTableRow } from '../../tables/types';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { dollarVal2D, kFormatter, kFormatterWithParenthesis } from '../../containers/pivotHelper';
import TrendDirection2, { TrendDirection2Size } from '../../common/TrendDirection2';
import { SetFiltersPayloadType } from '../../actions/accounting';
import chroma from 'chroma-js';
import { SwissColors } from '../../common/stylesConsts';
import { MoveData } from '../../pnlAnalytics/fetchTypes';

export const CostCellComp = (
    filters: SetFiltersPayloadType,
    companyName: string,
    row: SimpleTableRow,
    rawVal: any,
    moveData: MoveData,
    comparisonFontSize: string,
    reportValFormatter: (val: number) => string = kFormatterWithParenthesis,
    moveValFormatter: (val: number) => string = kFormatter,
    zeroThreshold: number = 100
) => {
    if (rawVal === undefined || rawVal === null) return <span>{''}</span>;
    let report = row[`${companyName}|amount_report`] as number;
    let compare = row[`${companyName}|amount_compare`] as number;
    let compare_diff = row[`${companyName}|compare_diff`] as number;
    let budget = row[`${companyName}|amount_budget`] as number;
    let budget_diff = row[`${companyName}|budget_diff`] as number;

    let customMainStyles: any = {
        width: '50px',
        display: 'inline-block',
    };
    // Negative move is good as cost reduction. Positive is bad as cost increase
    let compareMinMax: number = compare_diff < 0 ? moveData.compare_min_diff : moveData.compare_max_diff;
    let compareScale = compare_diff > 0 ? chroma.scale(['white', SwissColors.Red]) : chroma.scale(['white', SwissColors.Green]);
    let comparePercent = (compare_diff as number) / compareMinMax;
    let compareBackgroundColor = compareScale(comparePercent).hex();
    let compareText = compare_diff > 0 ? 'Cost Increased by' : 'Cost Decreased by';

    // Negative move is good as cost reduction. Positive is bad as cost increase
    let budgetMinMax: number = budget_diff < 0 ? moveData.budget_min_diff : moveData.budget_max_diff;
    let budgetScale = budget_diff > 0 ? chroma.scale(['white', SwissColors.Red]) : chroma.scale(['white', SwissColors.Green]);
    let budgetPercent = (budget_diff as number) / budgetMinMax;
    let budgetBackgroundColor = budgetScale(budgetPercent).hex();

    let customMoveStyles: any = { fontSize: comparisonFontSize };
    customMoveStyles['backgroundColor'] = filters.compareWithBudget ? budgetBackgroundColor : compareBackgroundColor;
    let diff = filters.compareWithBudget ? budget_diff : compare_diff;

    let valueFormatter = filters.showFractions ? dollarVal2D : reportValFormatter;

    return (
        <Tooltip
            style={{ width: '900px' }}
            placement="top"
            enterTouchDelay={0}
            title={
                <div>
                    <p>
                        Cost for: <strong>{dollarVal2D(report)}$</strong>
                    </p>
                    <p style={{ paddingTop: '20px' }}>
                        Compare with: <strong>{dollarVal2D(compare)}$</strong>
                    </p>
                    <p>
                        {compareText}: <strong>{dollarVal2D(compare_diff)}$</strong>
                    </p>
                    <p>
                        {compareText}: <strong>{Math.round((compare_diff / compare) * 100)}%</strong>
                    </p>
                    <p style={{ paddingTop: '20px' }}>
                        Budget: <strong>{dollarVal2D(budget)}$</strong>
                    </p>
                    <p>
                        {budget_diff < 0 ? 'Under Budget by' : 'Over Budget by'}: <strong>{dollarVal2D(budget_diff)}$</strong>
                    </p>
                    <p>
                        {budget_diff < 0 ? 'Under Budget by' : 'Over Budget by'}:
                        <strong> {Math.round((budget_diff / report) * 100)}%</strong>
                    </p>
                </div>
            }>
            <span style={{ width: '90px' }}>
                <span style={customMainStyles}>{valueFormatter(report)}</span>{' '}
                {filters.displayCompare && (
                    <span style={{ marginLeft: '10px', width: '50px', display: 'inline-block' }}>
                        <TrendDirection2 downIsBad={false} size={TrendDirection2Size.Tiny} zeroThreshold={zeroThreshold} val={diff} />
                        <span style={customMoveStyles}>{moveValFormatter(diff)}</span>
                    </span>
                )}
            </span>
        </Tooltip>
    );
};
