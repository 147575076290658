import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import { siteErrorCloseAction, siteErrorOpenAction } from './actions/siteAlerts';
import { CenteredCircularProgress, useInitDataFetching } from './common/common';
import { ErrorPage } from './common/error';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import { SelectedTaskDialogType, TasksListType, TaskType, UrlParamType } from './common/taskCommon';
import TaskRun from './TaskRun';
import { TaskCreatedDateCell, TaskStateCell } from './containers/TaskRuns';
import { useLocation } from 'react-router-dom';

const mapState = (_: RootState) => ({});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteErrorCloseAction: siteErrorCloseAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const TasksList = (_: Props) => {
    let query = new URLSearchParams(useLocation().search);
    let tasksListFilter = query.get('filter') as string;
    const { initErr, loading, data } = useInitDataFetching<TasksListType>(
        '/tasks/api/list',
        { tasks: [] },
        {
            filter: tasksListFilter,
        }
    );

    const [selectedTaskDialog, setSelectedTaskDialog] = useState<SelectedTaskDialogType | null>(null);

    if (loading) {
        return <CenteredCircularProgress />;
    }
    if (initErr) {
        return <ErrorPage msg={initErr} />;
    }

    const handleClickOpen = (newTask: TaskType) => {
        let newUrlParamValues: { [_: string]: any } = {};
        newTask.url_params.forEach((urlParam: UrlParamType) => {
            if (urlParam.default_val === undefined) return;
            newUrlParamValues[urlParam.name] = urlParam.default_val;
        });
        setSelectedTaskDialog({
            task: newTask,
            urlParamValues: newUrlParamValues,
            methods: newTask.methods,
            requestJson: newTask.request_json,
        });
    };

    return (
        <div>
            <Paper style={{ maxWidth: '800px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Task Route</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Last Run Date</TableCell>
                            <TableCell>Last Run Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.tasks.map((task, i) => (
                            <TableRow key={i}>
                                <TableCell>{task.route}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleClickOpen(task);
                                        }}>
                                        Run Task
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {task.last_run && <TaskCreatedDateCell taskRun={task.last_run} />}
                                </TableCell>
                                <TableCell>{task.last_run && <TaskStateCell taskRun={task.last_run} />}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {selectedTaskDialog !== null && (
                <TaskRun selectedTaskDialog={selectedTaskDialog} setSelectedTaskDialog={setSelectedTaskDialog} />
            )}
        </div>
    );
};

export default connector(TasksList);
