import { AUTH_USER, AuthUserActionTypes, SIGN_OUT } from '../actions/authUser';

export enum AuthStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    VERIFIED = 'VERIFIED',
}

export interface AuthUserState {
    auth_status: AuthStatus;
    auth_user?: AuthUser;
}

export interface AuthUser {
    auth_user_data: AuthUserData;
    token: string;
    swiss_token: string;
    exp: number;
}

export enum UserType {
    REAL_USER = 'REAL_USER',
    BOT = 'BOT',
}

export interface AuthUserData {
    user_type: UserType;
    user: RealUser | BotUser;
}

export enum RealUserProviderType {
    GOOGLE = 'GOOGLE',
}

export interface RealUser {
    provider_type: RealUserProviderType;
    service_id: string;
    email: string;
    given_name: string;
    name: string;
    family_name: string;
}

export enum BotUserServiceType {
    CRON = 'CRON',
    TRIGGER = 'TRIGGER',
}

export interface BotUser {
    service_type: BotUserServiceType;
    usage: string;
}

const initialState: AuthUserState = {
    auth_status: AuthStatus.NOT_VERIFIED,
};

const authUser = (state = initialState, action: AuthUserActionTypes): AuthUserState => {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...action.payload,
            };
        case SIGN_OUT:
            return initialState;
        default:
            return state;
    }
};

export default authUser;
