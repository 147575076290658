import { TrendDirection2Config } from '../common/TrendDirection2';
import { UniqueTableName } from '../common/uniqueTableName';
import { AggFormatVal } from '../common/cellRangeAgg';
import { CSSProperties, ReactElement } from 'react';
import { ColumnMenuProps, ColumnOnClickProps } from './SimpleTable';

export type SimpleTableRow = {
    [_: string]: number | string | boolean;
};

export type SimpleTableData = Array<SimpleTableRow>;

export enum TableSize {
    Small = 'Small',
    Medium = 'Medium',
    MediumSmall = 'MediumSmall',
    Large = 'Large',
}

export type SimpleTableColumnConfig = {
    name: string;
    title: string;
    headerCellClassName?: string;
    headerBackgroundColor?: string;
    headerTextColor?: string;
    bodyTemplate?: (val: any) => string;
    bodyTemplate2?: (val: any, row: SimpleTableRow) => string;
    bodyCellsClassName?: string;
    bodyBackgroundColor?: (row: SimpleTableRow, rawVal: any) => string;
    textColor?: (val: any) => null | string;
    bodyCustomStyle?: (row: SimpleTableRow, rawVal: any) => CSSProperties;
    trendDirection?: TrendDirection2Config;
    customDisplay?: (row: SimpleTableRow, rawVal: any) => ReactElement;
    hoverElement?: (row: SimpleTableRow, rawVal: any) => React.ReactNode;
    headerHoverElement?: (name: string, title: string) => React.ReactNode;
    menuElement?: (props: ColumnMenuProps) => React.ReactNode;
    onClick?: (props: ColumnOnClickProps) => void;
};

export type ToggleExpandedRow = (row: SimpleTableRow) => void;

export type SimpleTableConfig = {
    uniqueTableName: UniqueTableName;
    title: string;
    size: TableSize;
    columns: Array<SimpleTableColumnConfig>;
    rowBodyBackgroundColor?: (row: SimpleTableRow) => string;
    grandTotal: boolean;
    aggFormatVal: AggFormatVal;
    pivotDepth: number;
    stickyHeader?: boolean;
    customRowClassName?: (row: SimpleTableRow) => string;
};
