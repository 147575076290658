import React from 'react';
import {
    CorpBookColor,
    ForwardBookColor,
    PhysBookColor,
    ProductBookColor,
    ShippingBookColor,
    TotalBookColor,
} from '../../common/stylesConsts';
import moment from 'moment';
import { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import CommonPNLSummaryGraph from './CommonPNLSummaryGraphs';
import { kFormatter } from '../../containers/pivotHelper';

let graphConfig: LineGraphConfig = {
    title: 'Expired/Prompt/Deferred Historical PNL',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => moment(d).format('DD MMM'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    chartsSettings: [
        { chartType: ChartType.AreaAsLine, key: 'Product', color: ProductBookColor, title: 'Product', hiddenOnLoad: true },
        { chartType: ChartType.AreaAsLine, key: 'Shipping', color: ShippingBookColor, title: 'Shipping', hiddenOnLoad: true },
        { chartType: ChartType.AreaAsLine, key: 'Forward', color: ForwardBookColor, title: 'Forward', hiddenOnLoad: true },
        { chartType: ChartType.AreaAsLine, key: 'Corp', color: CorpBookColor, title: 'Corp' },
        { chartType: ChartType.AreaAsLine, key: 'Physical', color: PhysBookColor, title: 'Physical' },
        { chartType: ChartType.AreaAsLine, key: 'Total', color: TotalBookColor, title: 'Total' },
    ],
};

const PNLGraph = () => {
    return <CommonPNLSummaryGraph historicalPNLPerBookMetricsDataKey={'ltd_summary_graph'} config={graphConfig} />;
};

export default PNLGraph;
