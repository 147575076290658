import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import { setFiltersAction, SetFiltersPayloadType } from '../actions/accounting';
import { RootReducerStateType } from '../reducers';
import { clearAccDataAction } from '../actions/pnlAnalytics';
import { SwissColors } from '../common/stylesConsts';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { AccountingDanaosVessels } from '../pnlAnalytics/fetchTypes';
import { AccQuickDate } from '../backAccounting/dataFetcher';

type Props = {};

export type CompanyMeta = {
    title: string;
    backgroundColor: string;
    textColor: string;
};

export const shortColumnName = new Map<string, CompanyMeta>();
shortColumnName.set(' AEOLIAN ARROW', {
    title: 'Arrow',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
shortColumnName.set(' AEOLIAN FORTUNE', {
    title: 'Fortune',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
shortColumnName.set(' AEOLIAN GRACE', {
    title: 'Grace',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
shortColumnName.set(' CLEAR SKY SM', {
    title: 'Sky',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
shortColumnName.set(' SUN SM', {
    title: 'Sun',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
shortColumnName.set(' SWISSMARINE INC', {
    title: 'SwissMarine',
    backgroundColor: '#75d8ff',
    textColor: 'black',
});
shortColumnName.set('SWISSMARINE INVE', {
    title: 'SwissMarine Inv',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
shortColumnName.set('APTERA-PROPTECS ', {
    title: 'Aptera',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
shortColumnName.set('ESTATE LEGRENA', {
    title: 'Legrena',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
shortColumnName.set('SARIANO LIMITED', {
    title: 'Sariano',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
shortColumnName.set(' SWISSCHEMGAS', {
    title: 'SwissChemGas',
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
});
shortColumnName.set('SWISSCH. HOLDING', {
    title: 'SCG Holding',
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
});

export const companyFixedNamesMap = new Map<string, CompanyMeta>();
companyFixedNamesMap.set('Fortune', {
    title: 'Fortune',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
});
companyFixedNamesMap.set('Arrow', {
    title: 'Arrow',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('Sun', {
    title: 'Sun',
    backgroundColor: SwissColors.Yellow,
    textColor: 'black',
});
companyFixedNamesMap.set('Sky', {
    title: 'Sky',
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
});
companyFixedNamesMap.set('Grace', {
    title: 'Grace',
    backgroundColor: SwissColors.Cyan,
    textColor: 'black',
});
companyFixedNamesMap.set('Victory', {
    title: 'Victory',
    backgroundColor: SwissColors.Pink,
    textColor: 'black',
});
companyFixedNamesMap.set('Pearl', {
    title: 'Pearl',
    backgroundColor: SwissColors.Orange,
    textColor: 'black',
});
companyFixedNamesMap.set('SwissMarine', {
    title: 'SwissMarine',
    backgroundColor: '#75d8ff',
    textColor: 'black',
});
companyFixedNamesMap.set('SwissMarine Inv', {
    title: 'SwissMarine Inv',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('SwissChemGas', {
    title: 'SwissChemGas',
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
});
companyFixedNamesMap.set('Aptera', {
    title: 'Aptera',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('Legrena', {
    title: 'Legrena',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('Sariano', {
    title: 'Sariano',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('Total', {
    title: 'Total',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('Shareholders', {
    title: 'Shareholders',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});
companyFixedNamesMap.set('SwissChemGas Holding', {
    title: 'SCG Holding',
    backgroundColor: SwissColors.Green,
    textColor: 'black',
});

// =========== OLD ============
companyFixedNamesMap.set('Breeze', {
    title: 'Breeze',
    backgroundColor: SwissColors.Pink,
    textColor: 'black',
});
companyFixedNamesMap.set('Sky Araxos', {
    title: 'Sky Araxos',
    backgroundColor: SwissColors.Yellow,
    textColor: 'black',
});
companyFixedNamesMap.set('Sun Araxos', {
    title: 'Sun Araxos',
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
});

export const companyGroupNamesMap = new Map<string, CompanyMeta>();
companyGroupNamesMap.set('Vessel', {
    title: 'Vessels',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
});
companyGroupNamesMap.set('SwissChemGas', {
    title: 'SwissChemGas Total',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
});
companyGroupNamesMap.set('Investment', {
    title: 'Investments',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
});
companyGroupNamesMap.set('Property', {
    title: 'Properties',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
});

const accQuickDateToUserStr = (quickDate: AccQuickDate) =>
    `${quickDate.company} ${quickDate.year.toString().substring(2, 4)}|${quickDate.period} (${quickDate.start_date} -> ${
        quickDate.end_date
    })`;

const FiltersComp = (_: Props) => {
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);
    const vesselData = useSelector<RootReducerStateType, AccountingDanaosVessels | undefined>(
        (state) => state.pnlAnalytics.accounting_danaos_vessels.data,
        shallowEqual
    );

    const dispatch = useDispatch();

    let quickDateToolTip = vesselData?.days_in_report
        ? `${vesselData?.days_in_report} days in report and ${vesselData?.days_in_compare} in compare ${filters.quickDateSelection!.key}`
        : '';

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', marginBottom: '25px' }}>
                <Tooltip title={quickDateToolTip} placement={'top'}>
                    <FormControl style={{ margin: 0, marginLeft: '16px', minWidth: '300px' }}>
                        <InputLabel>Quick Dates</InputLabel>
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            value={filters.quickDateSelection!.key}
                            onChange={(e) => {
                                let strValue = e.target.value as string;
                                let quickDate = filters.quickDateOptions!.find((quickDate) => quickDate.key === strValue)!;
                                dispatch(
                                    setFiltersAction({
                                        ...filters,
                                        quickDateSelection: quickDate,
                                    })
                                );
                            }}>
                            {filters.quickDateOptions!.map((quickDate) => {
                                return (
                                    <MenuItem key={quickDate.key} value={quickDate.key}>
                                        {accQuickDateToUserStr(quickDate)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Tooltip>
                <Button
                    style={{ marginLeft: '50px' }}
                    onClick={() => {
                        dispatch(clearAccDataAction());
                    }}
                    variant="contained"
                    color="primary"
                    size={'small'}>
                    Generate Report
                </Button>
                <Tooltip
                    title={`Last Danaos Sync: ${moment(filters.lastSyncDatetime).format('DD MMM YYYY HH:mm:ss')}`}
                    placement="top"
                    interactive>
                    <span style={{ marginLeft: '50px' }}>
                        Last Entry Date:{' '}
                        <b>
                            <Moment format="DD MMMM YYYY HH:mm:ss">{filters.lastEntryDatetime!}</Moment>
                        </b>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.hideMinorAdminCompanies}
                            onChange={(event) => {
                                dispatch(
                                    setFiltersAction({
                                        ...filters,
                                        hideMinorAdminCompanies: event.target.checked,
                                    })
                                );
                            }}
                            name="hideMinorAdminCompanies"
                            color="primary"
                        />
                    }
                    label="Hide Minor Admin Companies"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.displayCompare}
                            onChange={(event) => {
                                let newFractionValue = event.target.checked ? false : filters.showFractions;
                                dispatch(
                                    setFiltersAction({
                                        ...filters,
                                        displayCompare: event.target.checked,
                                        showFractions: newFractionValue,
                                    })
                                );
                            }}
                            name="showCompareNumbers"
                            color="primary"
                        />
                    }
                    label="Show Comparison Diff"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.showFractions}
                            onChange={(event) => {
                                let newCompareValue = event.target.checked ? false : filters.displayCompare;
                                let newBudgetValue = event.target.checked ? false : filters.compareWithBudget;
                                dispatch(
                                    setFiltersAction({
                                        ...filters,
                                        showFractions: event.target.checked,

                                        displayCompare: newCompareValue,
                                        compareWithBudget: newBudgetValue,
                                    })
                                );
                            }}
                            name="showFractions"
                            color="primary"
                        />
                    }
                    label="Show Fractions"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.compareWithBudget}
                            onChange={(event) => {
                                let newFractionValue = event.target.checked ? false : filters.showFractions;
                                let newCompareValue = event.target.checked ? true : filters.displayCompare;
                                dispatch(
                                    setFiltersAction({
                                        ...filters,
                                        compareWithBudget: event.target.checked,
                                        showFractions: newFractionValue,
                                        displayCompare: newCompareValue,
                                    })
                                );
                            }}
                            name="compareWithBudget"
                            color="primary"
                        />
                    }
                    label="Compare with Budget Diff"
                />
            </Grid>
        </React.Fragment>
    );
};

export default FiltersComp;
