import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import LookAheadSelectCompose, { LookAheadState } from '../common/LookAheadSelectCompose';
import PerBookExp from '../common/perBookExposures/PerBookExp';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PhysicalLookahead = (_: Props) => {
    return (
        <LookAheadSelectCompose>
            {(lookAheadState: LookAheadState) => (
                <React.Fragment>
                    <PerBookExp lookAheadState={lookAheadState} />
                </React.Fragment>
            )}
        </LookAheadSelectCompose>
    );
};

export default fetchConnector(PhysicalLookahead);
