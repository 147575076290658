import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { commissionError } from '../../newTrade/common';

type CommissionFieldProps = {
    value: string;
    handleChange: (newValue: string) => void;
    errorText: string;
    disabled?: boolean;
    marginLeft: string;
    label: string;
    autofocus: boolean;
};

export function CommissionField(props: CommissionFieldProps) {
    const [hasBlurred, setHasBlurred] = React.useState(false);
    let commissionErrorText: string = "";
    if (hasBlurred && !!props.value) {
        commissionErrorText = commissionError(props.value);
    }
    return (
        <TextField
            required={false}
            disabled={props.disabled}
            autoFocus={props.autofocus}
            error={commissionErrorText !== ''}
            style={{ verticalAlign: 'bottom', minHeight: '5rem', marginLeft: props.marginLeft }}
            label={props.label}
            value={props.value}
            onChange={event => {
                props.handleChange(event.target.value);
            }}
            helperText={commissionErrorText}
            name="numberformat"
            InputProps={{
                inputComponent: NumberFormatCustom as any
            }}
            onBlur={() => {
                setHasBlurred(true);
            }}
        />
    );
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            isNumericString
            inputMode={'text'}
        />
    );
}
