import React from 'react';
import { SwissColors } from '../../common/stylesConsts';
import moment from 'moment';
import LineGraph, { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import { kFormatter } from '../../containers/pivotHelper';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { STANDARD_GRAPH_ELEMENT_HEIGHT } from '../../common/common';
import { ClearerRunDateDataResp } from '../fetchTypes';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

let graphConfig: LineGraphConfig = {
    title: 'ED&F Historical Cash',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    referenceLines: [{ y: 0, label: '', stroke: 'black' }],
    xAxisFormatter: (d) => moment(d).format('DD MMM'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    chartsSettings: [
        { chartType: ChartType.AreaAsLine, key: 'OTE', color: SwissColors.Blue, title: 'OTE' },
        { chartType: ChartType.AreaAsLine, key: 'Ending Balance', color: SwissColors.Green, title: 'Ending Balance' },
        { chartType: ChartType.AreaAsLine, key: 'IMR', color: SwissColors.Yellow, title: 'IMR' },
        { chartType: ChartType.AreaAsLine, key: 'Excess/Deficit', color: SwissColors.Red, title: 'Excess/Deficit' },
    ],
};

const ClearerHistGraph = (props: Props) => {
    const clearerMetricsData = props.pnlAnalytics.clearer_run_date;

    if (clearerMetricsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics}
                data={clearerMetricsData}
                minHeight={STANDARD_GRAPH_ELEMENT_HEIGHT}
            />
        );
    }

    let respData = clearerMetricsData.data! as ClearerRunDateDataResp;

    return <LineGraph {...graphConfig} values={respData.clearer_hist_graph} />;
};

export default fetchConnector(ClearerHistGraph);
