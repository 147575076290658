import { AuthUserState } from '../reducers/authUser';

export const AUTH_USER = 'AUTH_USER';

interface AuthUserActionType {
    type: typeof AUTH_USER;
    payload: AuthUserState;
}

export const authUserAction = (payload: AuthUserState): AuthUserActionType => ({
    type: AUTH_USER,
    payload: payload,
});

export const SIGN_OUT = 'SIGN_OUT';

interface SignOutActionType {
    type: typeof SIGN_OUT;
}

export const signOutAction = (): SignOutActionType => ({
    type: SIGN_OUT,
});

export type AuthUserActionTypes = AuthUserActionType | SignOutActionType;
