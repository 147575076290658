import React, { useEffect } from 'react';
import { siteErrorOpenAction } from './actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { getFetcher } from './common/fetcher';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StepperStateDataType, StepperStateType, TradeEntryUserType, TradeOptionsBackendType } from './newTrade/Types';
import RegularTradeEntry from './newTrade/RegularTrade';
import Internal from './newTrade/Internal';
import { getRealUser } from './common/auth';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    newTradeType: NewTradeType;
    closeDialog: () => void;
};

export enum NewTradeType {
    REGULAR = 'Regular',
    INTERNAL = 'Internal',
}

const NewTradeDialog = (props: Props) => {
    const [stepperState, setStepperState] = React.useState<StepperStateDataType>({
        state: StepperStateType.FIRST_INIT_STATE,
    });

    useEffect(() => {
        if (stepperState.state !== StepperStateType.FIRST_INIT_STATE) {
            return;
        }
        getFetcher<TradeOptionsBackendType>({
            url: `/trades/entry_data`,
            onStart: () => {
                setStepperState({
                    ...stepperState,
                    state: StepperStateType.FIRST_INIT_LOADING_STATE,
                });
            },
            onSuccess: (res) => {
                let traders: Array<TradeEntryUserType> = res.users.filter((user) => user.is_authorized_trader);
                let tradersNickNames: Array<string> = traders.map((user) => user.nick_name);

                // In case if we haven't added the user's short name, use their email
                let authUserNickName: string = getRealUser(props.authUser).email;

                const authUser = res.users.find((user) => user.email === authUserNickName);
                const defaultUser = res.users.find((user) => user.default_trader);
                const defaultTrader =
                    (authUser && authUser.is_authorized_trader && authUser.nick_name) || (defaultUser && defaultUser.nick_name);

                if (!defaultTrader) throw Error('No default Trader found');

                setStepperState({
                    ...stepperState,
                    state: StepperStateType.FIRST_INIT_LOADED_DATA,
                    trade_options: {
                        ...res,
                        tradersNickNames: tradersNickNames,
                        defaultTraderNickName: defaultTrader,
                        authUserNickName: authUserNickName,
                    },
                });
            },
            onFail: (err) => {
                props.siteErrorOpenAction({ message: err.parsedMsg });
                setStepperState({
                    ...stepperState,
                    state: StepperStateType.FIRST_INIT_FAILED_DATA,
                });
            },
            onFinal: () => {
                // Leave it empty as when we change route the component will no longer be mounted
            },
        });
    }, [props, stepperState]);

    return (
        <Dialog maxWidth={'xl'} fullWidth={true} scroll={'paper'} open={true} onClose={props.closeDialog} disableEscapeKeyDown={true}>
            <DialogTitle>{`New ${props.newTradeType} Trade`}</DialogTitle>
            {stepperState.state === StepperStateType.FIRST_INIT_LOADING_STATE && (
                <div>
                    <DialogContent>
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                    </DialogContent>
                </div>
            )}
            {stepperState.state === StepperStateType.FIRST_INIT_FAILED_DATA && (
                <DialogContent>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setStepperState({
                                    ...stepperState,
                                    state: StepperStateType.FIRST_INIT_STATE,
                                });
                            }}>
                            ReTry
                        </Button>
                    </div>
                </DialogContent>
            )}
            {stepperState.state === StepperStateType.FIRST_INIT_LOADED_DATA &&
                stepperState.trade_options !== undefined &&
                props.newTradeType === NewTradeType.REGULAR && (
                    <RegularTradeEntry options={stepperState.trade_options} closeDialog={props.closeDialog} />
                )}
            {stepperState.state === StepperStateType.FIRST_INIT_LOADED_DATA &&
                stepperState.trade_options !== undefined &&
                props.newTradeType === NewTradeType.INTERNAL && (
                    <Internal options={stepperState.trade_options} closeDialog={props.closeDialog} />
                )}
        </Dialog>
    );
};

export default connector(NewTradeDialog);
