export const ERROR_OPEN = 'ERROR_OPEN';
export const ERROR_CLOSE = 'ERROR_CLOSE';
export const SUCCESS_OPEN = 'SUCCESS_OPEN';
export const SUCCESS_CLOSE = 'SUCCESS_CLOSE';

export interface SiteAlertOpenPayloadType {
    message: string,
    autoHideDuration?: number;
}

export interface SiteAlertOpenActionType {
    type: typeof ERROR_OPEN | typeof SUCCESS_OPEN
    payload: SiteAlertOpenPayloadType
}

export interface SiteAlertCloseActionType {
    type: typeof ERROR_CLOSE | typeof SUCCESS_CLOSE
}

export type SiteErrorOpenActionType = (payload: SiteAlertOpenPayloadType) => SiteAlertOpenActionType

export const siteErrorOpenAction = (payload: SiteAlertOpenPayloadType): SiteAlertOpenActionType => ({
    type: ERROR_OPEN,
    payload: payload,
});

export const siteErrorCloseAction = (): SiteAlertCloseActionType => ({
    type: ERROR_CLOSE,
});

export const siteSuccessOpenAction = (payload: SiteAlertOpenPayloadType): SiteAlertOpenActionType => ({
    type: SUCCESS_OPEN,
    payload: payload,
});

export const siteSuccessCloseAction = (): SiteAlertCloseActionType => ({
    type: SUCCESS_CLOSE,
});

export type SiteErrorActionTypes = SiteAlertOpenActionType | SiteAlertCloseActionType;