// Converts numbers with thousand separators
import { SwissColors } from '../common/stylesConsts';

function numberWithCommas(x: string) {
    // https://stackoverflow.com/a/2901298
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export type KFormatterFractionDigits = {
    billions: number;
    millions: number;
    thousands: number;
    hundreds: number;
};

export const defaultFractionDigits: KFormatterFractionDigits = { billions: 3, millions: 2, thousands: 0, hundreds: 1 };

export const kFormatterWithSign = (val: number) => kFormatter(val, false);
export const kFormatterWithParenthesis = (val: number) => kFormatter(val, true, defaultFractionDigits, true);

export const dollarVal2D = (val: number) => optNumberFormat(val, 2, false, false, false);
export const dollarValFin2D = (val: number) => optNumberFormat(val, 2, false, true, true);

// Converts thousands and millions to short format
export function kFormatter(
    num?: number,
    removeSign: boolean = true,
    digits: KFormatterFractionDigits = defaultFractionDigits,
    withFinancialParenthesis: boolean = false
): string {
    if (num === undefined || num === null) return '';
    let addParenthesis = num < 0 && withFinancialParenthesis;
    let res = 'NotCalc';
    let newNum = removeSign ? Math.abs(num) : num;
    let helperFormat = kFormatterHelper(num, digits);
    // On Feb 9 2023 we noticed that 999,617 would be translated to 1,000K instead of 1M
    // This is because toFixed changes the number. So we need to first bring the number to the correct toFixed
    // and then evaluate which group it goes to. This way 999,617 is evaluated to 1,000,000 which becomes 1M
    num = Number((num / helperFormat.multiplier).toFixed(helperFormat.digits)) * helperFormat.multiplier;
    // TODO: we can replace the bellow logic eventually (after Mid feb 2023) to use the helperFunction and not
    // reproduce the calculation
    if (Math.abs(num) > 999_999_999) {
        num = newNum / 1_000_000_000;
        res = numberWithCommas(num.toFixed(digits.billions)) + 'B';
    } else if (Math.abs(num) > 999_999) {
        num = newNum / 1_000_000;
        res = numberWithCommas(num.toFixed(digits.millions)) + 'M';
    } else if (Math.abs(num) > 999) {
        num = newNum / 1_000;
        res = numberWithCommas(num.toFixed(digits.thousands)) + 'K';
    } else if (num === 0 || Math.abs(num) < 100) {
        // For less than 100 just put 0
        res = '0';
    } else {
        // If less than 1k add it with one decimal.
        num = newNum / 1000;
        res = numberWithCommas(num.toFixed(digits.hundreds)) + 'K';
    }
    if (!addParenthesis) return res;
    return `(${res})`;
}

type KFormatterHelperResType = {
    multiplier: number;
    kFormat: string;
    digits: number;
};

function kFormatterHelper(num: number, digits: KFormatterFractionDigits): KFormatterHelperResType {
    num = Math.abs(num);
    if (num > 999_999_999) {
        return { multiplier: 1_000_000_000, kFormat: 'B', digits: digits.billions };
    } else if (num > 999_999) {
        return { multiplier: 1_000_000, kFormat: 'M', digits: digits.millions };
    } else if (num > 999) {
        return { multiplier: 1_000, kFormat: 'K', digits: digits.thousands };
    } else if (num === 0 || num < 100) {
        return { multiplier: 100, kFormat: '', digits: 0 };
    } else {
        return { multiplier: 100, kFormat: '', digits: digits.hundreds };
    }
}

export function optNumberFormat(
    num: number | null | undefined,
    fractionDigits: number = 2,
    currency: boolean = false,
    removeSign: boolean = true,
    withFinancialParenthesis: boolean = false
): string {
    if (num === undefined || num === null) return '';
    return numberFormat(num, fractionDigits, currency, removeSign, withFinancialParenthesis);
}

export function accountingNumberFormat(num: number | null | undefined): string {
    return optNumberFormat(num, 2, false, true, true);
}

export function numberFormat(
    num: number,
    fractionDigits: number = 2,
    currency: boolean = false,
    removeSign: boolean = true,
    withFinancialParenthesis: boolean = false
): string {
    let updatedNum: number = removeSign ? Math.abs(num) : num;
    if (num === undefined || num.toString() === '') return '';
    let decimalsStr = updatedNum.toFixed(fractionDigits);
    let thousands = numberWithCommas(decimalsStr);
    let result = !currency ? thousands : '$' + thousands;
    result = withFinancialParenthesis && num < 0 ? `(${result})` : result;
    return result;
}

export const redOnly = (val: number, zeroThresh: number = 1): string | null => {
    if (Math.abs(val) < zeroThresh) return null;
    return val < 0 ? SwissColors.Red : null;
};

export const redOnlyHundred = (val: number) => redOnly(val, 100);

export const greenOnlyReversed = (val: number, zeroThresh: number = 1): string | null => {
    if (Math.abs(val) < zeroThresh) return null;
    return val < 0 ? SwissColors.Green : null;
};

export const greenOnlyReversedHundred = (val: number) => greenOnlyReversed(val, 100);

export const redGreen = (val: number, zeroThresh: number = 1): string | null => {
    if (Math.abs(val) < zeroThresh) return null;
    return val < 0 ? SwissColors.Red : SwissColors.Green;
};

export const redGreenHundred = (val: number) => redGreen(val, 100);

export const redGreenReversed = (val: number, zeroThresh: number = 1): string | null => {
    if (Math.abs(val) < zeroThresh) return null;
    return val > 0 ? SwissColors.Red : SwissColors.Green;
};

export const redGreenReversedHundred = (val: number) => redGreenReversed(val, 100);
