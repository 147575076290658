import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Overview from '../common/Overview';
import PTPerStrategyPhysSummaryTable from './PTPerStrategyPhysSummary';
import { PNLMetaDataResp } from '../fetchTypes';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from '../dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import { MIN_12_12_EL_WIDTH, MIN_7_12_EL_WIDTH } from '../../common/common';
import { isAuthScreenShotBot } from '../../common/auth';
import PhysicalLookahead from './PhysicalLookAhead';
import DetailExpPerTradeKind from './DetailExpPerTradeKind';
import DetailExpPerBook from '../common/DetailExpPerBook';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PhysicalTradingIndex = (props: Props) => {
    let metaData = props.pnlAnalytics.meta.data! as PNLMetaDataResp;
    let isScreenShotBot = isAuthScreenShotBot(props.authUser);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AllTradesRunDate,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.Exposures,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    return (
        <Grid container spacing={3} justify="center">
            {!isScreenShotBot && (
                <Grid item xs={7} style={{ backgroundColor: 'clear', minWidth: MIN_7_12_EL_WIDTH }}>
                    <Overview />
                </Grid>
            )}
            <Grid item xs={12} style={{ backgroundColor: 'clear', minWidth: MIN_12_12_EL_WIDTH }}>
                <PTPerStrategyPhysSummaryTable />
            </Grid>
            <PhysicalLookahead />
            <Grid item xs={12} style={{ backgroundColor: 'clear', paddingTop: '45px', minWidth: MIN_12_12_EL_WIDTH }}>
                <DetailExpPerTradeKind />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', paddingTop: '45px', minWidth: MIN_12_12_EL_WIDTH }}>
                <DetailExpPerBook />
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: 'clear', paddingTop: '45px', minWidth: MIN_12_12_EL_WIDTH }}>
                <DetailExpPerBook withDeals />
            </Grid>
        </Grid>
    );
};

export default fetchConnector(PhysicalTradingIndex);
