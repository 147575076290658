import React, { ReactChild, useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { siteErrorOpenAction } from './actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import EDFFilters, { defaultFilters, INIT_SEL_VERIFIED_STATE } from './EdfFilters';
import { InitFetchState } from './Types';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
    EDFFilterTypes,
    fetchTradesAsync,
    INIT_PAGE_NUM,
    INIT_PAGE_SIZE,
    TradesDataType,
    VerifiedState,
} from './common/edfCommon';
import EdfTable from './containers/EdfTable';
import { ErrorPage, UserErrorPage } from './common/error';
import { CenteredCircularProgress, queryString } from './common/common';
import SecondaryAppBar from './containers/SecondaryAppBar';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            width: '100%',
            overflowX: 'auto',
        },
    })
);

const mapState = (_: RootState) => ({});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const EdfTrades = (_: Props) => {
    // console.log('edf props');
    // console.log(Object.keys(props));
    // console.log(`index props num of trades ${props.trades.length}`);
    const classes = useStyles({});

    let userError = null;
    // Because all useState and useEffect must be called on all initialization until we verify that the no URL errors
    // occur we set it to complete to not fetch data
    let urlInitFetchState = InitFetchState.InitFetchCompleted as InitFetchState;

    // We can't just assign the default state because we will mutate as we pass the parameters
    // So for now we stringify it and then put it back to an object
    let initFilters = JSON.parse(JSON.stringify(defaultFilters));
    let query = new URLSearchParams(useLocation().search);
    let history = useHistory();

    // Verified State
    let urlVerifiedState = query.get('verified_state') as VerifiedState;
    if (urlVerifiedState) {
        const validStates = Object.values(VerifiedState);
        if (!validStates.includes(urlVerifiedState)) {
            userError = `You asked for trades of verified_state => '${urlVerifiedState}' which is not of valid type. Valid types are ${validStates}`;
        } else {
            initFilters.verifiedState = urlVerifiedState;
        }
    }
    // Selected Date
    let urlTradeDate = query.get('run_date');
    if (urlTradeDate) {
        const urlDateFormat = 'YYYY-MM-DD';
        let parsedUrlTradeDate = moment(urlTradeDate, urlDateFormat);
        if (!parsedUrlTradeDate.isValid()) {
            userError = `You asked for trades with run_date => '${urlTradeDate}' but that is not a valid data. Format is '${urlDateFormat}'`;
        } else {
            initFilters.selectedDate = parsedUrlTradeDate;
        }
    }
    // Trade Ids
    let urlTradeIds = query.get('trade_ids');
    if (urlTradeIds) {
        // We don't run any validation. If it's right it's right
        initFilters.tradeIdsSubmit = urlTradeIds;
    }

    if (!userError) {
        urlInitFetchState = InitFetchState.ShouldInitFetch;
    }

    const [initFetchState, setInitFetchState] = useState(urlInitFetchState);
    const [initFetchErr, setInitFetchErr] = useState('');
    const [data, setData] = useState<TradesDataType>({ trades: [] });

    const [filters, setFilters] = useState<EDFFilterTypes>(initFilters);

    const updateUrlParamsToReflectState = useCallback(
        (currFilters: EDFFilterTypes) => {
            // JS can't do value to value comparison of Objects so we just stringify it
            if (JSON.stringify(currFilters) === JSON.stringify(defaultFilters)) {
                history.push({});
                return;
            }
            let params: { [string: string]: string } = {
                verified_state: currFilters.verifiedState,
            };
            if (currFilters.selectedDate) {
                params['run_date'] = moment(currFilters.selectedDate).format('YYYY-MM-DD');
            }
            if (currFilters.tradeIdsSubmit) {
                params['trade_ids'] = currFilters.tradeIdsSubmit;
            }
            history.push({ search: `?${queryString(params)}` });
        },
        [history]
    );

    const onFilterChange = (newFilters: EDFFilterTypes) => {
        setFilters(newFilters);
        setInitFetchState(InitFetchState.ShouldInitFetch);
    };

    const [canLoadMoreTrades, setCanLoadMoreTrades] = useState(true);
    const [tradeIdCopied, setTradeIdCopied] = useState<string>('');
    const [rowUpdating, setRowUpdating] = useState('');

    function modificationsDisabled(): boolean {
        return isInitLoading() || rowUpdating !== '';
    }

    function isInitLoading(): boolean {
        return (
            initFetchState === InitFetchState.ShouldInitFetch ||
            initFetchState === InitFetchState.WaitingInitFetchResponse
        );
    }

    // const [pageNum, setPageNum] = useState(INIT_PAGE_NUM);

    useEffect(() => {
        // console.log(`use effect loading state ${initFetchState} and verified state ${filters.verifiedState}`);
        if (initFetchState !== InitFetchState.ShouldInitFetch) {
            return;
        }
        setInitFetchState(InitFetchState.WaitingInitFetchResponse);

        fetchTradesAsync({
            pageSize: INIT_PAGE_SIZE,
            pageNum: INIT_PAGE_NUM,
            ...filters,
            onSuccess: (jsonData) => {
                // Avoid infinite loader from making API call again if we don't have more data
                // At the same time this code runs every time we change the filter so make sure
                // we mark is has hasMore data to load as we might have changed the filter
                setCanLoadMoreTrades(!(jsonData.trades.length < INIT_PAGE_SIZE));
                setData({ trades: jsonData.trades });
                setInitFetchState(InitFetchState.InitFetchCompleted);
            },
            onFail: (err) => {
                setInitFetchState(InitFetchState.InitFetchFailed);
                setInitFetchErr(err.parsedMsg);
            },
        });
        updateUrlParamsToReflectState(filters);
    }, [updateUrlParamsToReflectState, filters, initFetchState, initFetchErr]);

    if (userError) {
        return <UserErrorPage msg={userError} />;
    }

    if (initFetchState === InitFetchState.InitFetchFailed) {
        return <ErrorPage msg={initFetchErr} />;
    }

    function filterAreInitialLoad() {
        return filters.verifiedState === INIT_SEL_VERIFIED_STATE && !filters.selectedDate && !filters.tradeIdsSubmit;
    }

    if (!modificationsDisabled() && data.trades.length === 0 && filterAreInitialLoad()) {
        return (
            <MainWrapper modificationsDisabled={modificationsDisabled} filters={filters} setFilters={onFilterChange}>
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <img src={require('./static/all-done-kid.jpeg').default} alt={'All Done'} />
                    <h1>You have verified all the trades, now go get Vaccinated</h1>
                </div>
            </MainWrapper>
        );
    }

    return (
        <MainWrapper modificationsDisabled={modificationsDisabled} filters={filters} setFilters={onFilterChange}>
            <div>
                {isInitLoading() && <CenteredCircularProgress />}
                {initFetchState === InitFetchState.InitFetchCompleted && (
                    <div>
                        <Paper className={classes.root}>
                            <EdfTable
                                modificationsDisabled={modificationsDisabled}
                                filters={filters}
                                canLoadMoreTrades={canLoadMoreTrades}
                                setCanLoadMoreTrades={setCanLoadMoreTrades}
                                data={data}
                                setData={setData}
                                rowUpdating={rowUpdating}
                                setRowUpdating={setRowUpdating}
                                tradeIdCopied={tradeIdCopied}
                                setTradeIdCopied={setTradeIdCopied}
                            />
                        </Paper>
                        {!canLoadMoreTrades && (
                            <div style={{ textAlign: 'center', marginTop: '16px' }}>
                                Fetched all {data.trades.length} trades matching the filters
                            </div>
                        )}
                    </div>
                )}
                <Snackbar
                    key={tradeIdCopied}
                    open={tradeIdCopied !== ''}
                    autoHideDuration={5000}
                    onClose={() => {
                        setTradeIdCopied('');
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert elevation={6} severity="info" variant="filled" icon={<FileCopyIcon fontSize="inherit" />}>
                        Trade {tradeIdCopied} copied to clipboard
                    </Alert>
                </Snackbar>
            </div>
        </MainWrapper>
    );
};

function MainWrapper({
    modificationsDisabled,
    filters,
    setFilters,
    children,
}: {
    modificationsDisabled: () => boolean;
    filters: EDFFilterTypes;
    setFilters: (f: EDFFilterTypes) => void;
    children: ReactChild;
}) {
    return (
        <div>
            {/* Add the dense toolbar height */}
            <div className={'MuiToolbar-dense'} />
            <SecondaryAppBar title={'EDF Trades'} backButtonUrl={null} />
            <Box className={'MuiToolbar-gutters'} my={2}>
                <EDFFilters modificationsDisabled={modificationsDisabled} setFilters={setFilters} filters={filters} />
                {children}
            </Box>
        </div>
    );
}

export default connector(EdfTrades);
