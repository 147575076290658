import React, { useCallback, useMemo } from 'react';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type MainSnackbarMessage = string;
export type ShowSimpleSnackbarType = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

export interface MainSnackbar {
    showInfoSnackbar: ShowSimpleSnackbarType;
    showSuccessSnackbar: ShowSimpleSnackbarType;
    showErrorSnackbar: (message: MainSnackbarMessage) => void;
}

export const useMainSnackbar = (): MainSnackbar => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = useMemo(
        () => (key?: SnackbarKey) =>
            (
                <IconButton
                    size="small"
                    onClick={() => {
                        closeSnackbar(key);
                    }}>
                    <CloseIcon style={{ width: '15px', height: '15px' }} />
                </IconButton>
            ),
        [closeSnackbar]
    );

    const showErrorSnackbar = useCallback(
        (message: MainSnackbarMessage) => {
            enqueueSnackbar(message, {
                variant: 'error',
                action,
            });
        },
        [enqueueSnackbar, action]
    );

    const showInfoSnackbar = useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, {
                ...options,
                variant: 'info',
                action,
            }),
        [enqueueSnackbar, action]
    );

    const showSuccessSnackbar = useCallback(
        (message: SnackbarMessage, options?: OptionsObject) =>
            enqueueSnackbar(message, {
                ...options,
                variant: 'success',
                action,
            }),
        [enqueueSnackbar, action]
    );

    return useMemo(
        () => ({
            showInfoSnackbar,
            showSuccessSnackbar,
            showErrorSnackbar,
        }),
        [showInfoSnackbar, showSuccessSnackbar, showErrorSnackbar]
    );
};
