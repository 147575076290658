enum OperatingSystem {
    Mac = 'Mac',
    IOS = 'IOS',
    Windows = 'Windows',
    Android = 'Android',
    Linux = 'Linux'
}

function getOS(): null | OperatingSystem {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        return OperatingSystem.Mac;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return OperatingSystem.IOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return OperatingSystem.Windows;
    } else if (/Android/.test(userAgent)) {
        return OperatingSystem.Android;
    } else if (/Linux/.test(platform)) {
        return OperatingSystem.Linux;
    }

    return os;
}

export enum KeyCode {
    LeftKey = 37,
    UpKey = 38,
    RightKey = 39,
    DownKey = 40
}

export const isCtrlKey = (e: any): boolean => {
    // Mac OS does not act correctly on KeyUp and while on KeyDown
    // .metaKey is set to True (telling us that CMD is pressed) onKeyUp it is set to false
    // but the .key property is set in both down and up
    // Windows is simpler and just uses control
    return (getOS() === OperatingSystem.Mac && e.key === 'Meta') || (getOS() === OperatingSystem.Windows && e.ctrlKey);
};

export const isShiftKey = (e: any): boolean => e.key === 'Shift';
export const isEscKey = (e: any): boolean => e.key === 'Escape';

export const isArrowKey = (e: any): boolean => e.keyCode >= KeyCode.LeftKey && e.keyCode <= KeyCode.DownKey;
