import React from 'react';
import { AllTradesPropsType } from '../Types';
import { ExecutingForField } from './ExecutingFor';
import { NotesField } from './Notes';

interface ProductFieldsProps extends AllTradesPropsType {}

export function MiscFields(props: ProductFieldsProps) {
    return (
        <span>
            <ExecutingForField {...props} />
            <br />
            <NotesField {...props} />
        </span>
    );
}
