import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { companyFixedNamesMap } from '../Filters';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter, exposuresNumFormatterNoParenthesis } from '../../common/exposures';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import { makeStyles } from '@material-ui/core/styles';
import SimpleTable from '../../tables/SimpleTable';
import { kFormatter, kFormatterWithParenthesis } from '../../containers/pivotHelper';
import { CellComp } from '../common/CellContainer';

const useStyles = makeStyles(() => ({
    totalColumnHeader: {
        fontSize: '16px !important',
        backgroundColor: '#fffded !important',
    },
    totalColumnBody: {
        fontSize: '16px !important',
        backgroundColor: '#fffded',
    },
    firstLevelTotalRow: {
        borderTop: '5px solid black',
        borderBottom: '0px',
        fontSize: '17px',
        fontWeight: 'bold',
        padding: '10px 2px 10px 2px !important',
    },
    secondLevelTotalRow: {
        borderTop: '3px double black',
        borderBottom: '0px',
        fontSize: '15px',
        fontWeight: 'bold',
        padding: '6px 2px 6px 2px !important',
    },
    thirdLevelTotalRow: {
        borderTop: '2px solid black',
        borderBottom: '0px',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '6px 2px 6px 2px !important',
    },
    revenuePerDayRow: {
        borderTop: 'none !important',
        padding: '25px 2px 6px 2px !important',
        fontSize: '12px !important',
    },
    commercialResultsRow: {
        fontSize: '12px !important',
        padding: '6px 2px 6px 2px !important',
    },
    firstRowAfterBreakRow: {
        padding: '25px 2px 6px 2px !important',
    },
    firstRowAfterAlmostNewTable: {
        borderTop: '2px dashed #a8a8a8',
        padding: '35px 2px 6px 2px !important',
    },
    incomeBeforeDepreciation: {
        padding: '25px 2px 6px 2px !important',
        fontWeight: 'bold',
    },
    standardRow: {
        padding: '6px 2px 6px 2px !important',
    },
}));

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

export const staticColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'pnl_group',
        title: 'P&L',
        hoverElement: (row, _) => {
            return (
                <div>
                    <p>{row['description']}</p>
                </div>
            );
        },
    },
];

const ShippingPNLComp = (props: Props) => {
    const classes = useStyles();
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingDanaosVessels,
            params: {
                start_date: filters.quickDateSelection!.start_date,
                end_date: filters.quickDateSelection!.end_date,
                cmp_start_date: filters.quickDateSelection!.cmp_start_date,
                cmp_end_date: filters.quickDateSelection!.cmp_end_date,
                company: filters.quickDateSelection!.company,
            },
        });
    }, [props, filters]);

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'1017px'}
            />
        );
    }

    // Construct the vessel config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;
    console.log(respData.summary_table);

    let totalIndexes: Array<number> = [];
    let columnsArray: Array<string> = respData.pnl_sorted_vessel_names.concat(['Vessel Total', 'SwissMarine', 'Shipping Total']);
    let columnNames: Array<SimpleTableColumnConfig> = columnsArray.map((columnName, i) => {
        if (columnName.endsWith('Total')) totalIndexes.push(i);
        let companyMeta = companyFixedNamesMap.get(columnName);
        let title: string = companyMeta ? companyMeta.title : columnName;
        return {
            name: `${columnName}|amount_report`,
            title: title,
            customDisplay: (row: SimpleTableRow, rawVal: any) => {
                let isDailyRow = ['Revenue Per Day', 'Commercial Results', 'Daily Running Expenses'].includes(row['pnl_group'] as string);
                let valueFormatters = isDailyRow ? exposuresNumFormatter : kFormatterWithParenthesis;
                let moveFormatters = isDailyRow ? exposuresNumFormatterNoParenthesis : kFormatter;
                return CellComp(filters, columnName, row, rawVal, '12px', valueFormatters, moveFormatters);
            },
        };
    });
    totalIndexes.forEach((columnIdx) => {
        columnNames[columnIdx].headerCellClassName = classes.totalColumnHeader;
        columnNames[columnIdx].bodyCellsClassName = classes.totalColumnBody;
    });

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.AccShippingPNL,
        title: 'Shipping P&L',
        size: TableSize.Medium,
        columns: staticColumns.concat(columnNames),
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
        stickyHeader: true,
        customRowClassName: (row) => {
            switch (row['pnl_group']) {
                case 'Total Net Revenue':
                    return classes.thirdLevelTotalRow;
                case 'Revenue Per Day':
                    return classes.revenuePerDayRow;
                case 'Commercial Results':
                    return classes.commercialResultsRow;
                case 'Voyage Expenses':
                    return classes.firstRowAfterBreakRow;
                case 'Running Expenses':
                    return classes.thirdLevelTotalRow;
                case 'Daily Running Expenses':
                    return classes.commercialResultsRow;
                case 'Dry Dock Expenses':
                    return classes.firstRowAfterBreakRow;
                case 'Gross Operating Income':
                    return classes.secondLevelTotalRow;
                case 'Interest Loan Expense':
                    return classes.firstRowAfterBreakRow;
                case 'Total Financing Costs':
                    return classes.thirdLevelTotalRow;
                case 'Income from Operation':
                    return classes.secondLevelTotalRow;
                case 'Net Income Before Depreciation':
                    return classes.incomeBeforeDepreciation;
                case 'Net Income for the Period':
                    return classes.firstLevelTotalRow;
                case 'Capital Installments':
                    return classes.firstRowAfterAlmostNewTable;
                case 'Cash Balance':
                    return classes.firstLevelTotalRow;
                default:
                    return classes.standardRow;
            }
        },
    };

    return (
        <Grid item xs={12} style={{ backgroundColor: 'clear', minWidth: MIN_12_12_EL_WIDTH, marginTop: '50px' }}>
            <SimpleTable data={respData.summary_table} config={config} filters={filters} />
        </Grid>
    );
};
export default accFetchConnector(ShippingPNLComp);
