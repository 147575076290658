import React from 'react';
import { SwissColors } from './stylesConsts';

export enum TrendDirection2Size {
    Tiny = 'tiny',
    Small = 'small',
    Medium = 'medium',
}

type SizeMapType2 = {
    borderSides: { [_: string]: number };
    borderVertical: { [_: string]: number };
    marginVertical: { [_: string]: number };
    marginRight: { [_: string]: number };
};

const _sizeMap: SizeMapType2 = {
    borderSides: {
        tiny: 3,
        small: 4,
        medium: 0.4375,
    },
    borderVertical: {
        tiny: 4,
        small: 7,
        medium: 0.6875,
    },
    marginVertical: {
        tiny: 0,
        small: 0,
        medium: 5,
    },
    marginRight: {
        tiny: 1,
        small: 2,
        medium: 5,
    },
};

export type TrendDirection2Config = {
    downIsBad: boolean;
    size: TrendDirection2Size;
    zeroThreshold: number;
};

export type TrendDirection2Props = TrendDirection2Config & {
    val: number;
};

const TrendDirection2 = (props: TrendDirection2Props) => {
    if (Math.abs(props.val) < props.zeroThreshold) return null;
    let trendArrow: { [_: string]: any } = {
        borderRight: `${_sizeMap.borderSides[props.size]}px solid transparent`,
        borderLeft: `${_sizeMap.borderSides[props.size]}px solid transparent`,
        display: 'inline-block',
        width: 0,
        height: 0,
        content: ' ',
        marginTop: `${_sizeMap.marginVertical[props.size]}px`,
        marginBottom: `${_sizeMap.marginVertical[props.size]}px`,
        verticalAlign: 'middle',
        marginRight: `${_sizeMap.marginRight[props.size]}px`,
    };
    let directionBorder = props.val >= 0 ? 'borderBottom' : 'borderTop';
    // Very smart approach from Dimitris, but read it carefully. It's true when both are false
    // eslint-disable-next-line
    const color = props.val < 0 === props.downIsBad ? SwissColors.Red : SwissColors.Green;
    trendArrow[directionBorder] = `${_sizeMap.borderVertical[props.size]}px solid ${color}`;
    return <span style={trendArrow} />;
};

export default TrendDirection2;
