import { TaskHistoryType } from '../common/taskCommon';
import React from 'react';
import Moment from 'react-moment';

export function TaskStateCell({ taskRun }: { taskRun: TaskHistoryType }) {
    return <span style={{ backgroundColor: stateColor(taskRun.state) }}>{taskRun.state}</span>;
}

export function TaskCreatedDateCell({ taskRun }: { taskRun: TaskHistoryType }) {
    return (
        <Moment format="DD MMM HH:mm:ss" local={true}>
            {taskRun.created_at}
        </Moment>
    );
}

function stateColor(state: string) {
    switch (state) {
        case 'SUCCESS':
            return '#00fc19';
        case 'FAIL':
            return 'red';
        case 'KILLED':
            return '#ff8522';
        default:
            return 'transparent';
    }
}
