import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import {
    P_ARA,
    P_ARA_C4,
    P_BLPG,
    P_BLPG3,
    P_BRT,
    P_CP,
    P_CP_C4,
    P_EPC,
    P_EPC_C4,
    P_FEI,
    P_FEI_C4,
    P_LST,
    P_MOPJ,
    P_NWE_NAPHTHA,
    P_WTI,
    productMap,
    ProductMeta,
    VP_PETRO_CHEM,
} from '../../common/products';
import { SimpleTableColumnConfig, TableSize } from '../../tables/types';
import { exposuresNumFormatter } from '../../common/exposures';
import { redOnly } from '../../containers/pivotHelper';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { ExposuresDataResp } from '../fetchTypes';

type Props = FetchPNLAnalyticsPropsFromRedux & { withDeals?: boolean };

const pivotColumns: Array<SimpleTableColumnConfig> = [
    { name: 'book', title: 'Book' },
    {
        name: 'strategy',
        title: 'Strategy',
        hoverElement: (row) => {
            return <div>{row['strategy']}</div>;
        },
    },
    {
        name: 'deal',
        title: 'Deal',
        hoverElement: (row) => {
            return <div>{row['deal']}</div>;
        },
    },
    {
        name: 'term_month_date',
        title: 'Term',
        bodyTemplate: (val: any) => {
            if (val === '') return val;
            return moment(val).format('MMM YY');
        },
    },
];

const productColumns = [
    P_FEI,
    P_FEI_C4,
    P_LST,
    P_EPC,
    P_CP,
    P_CP_C4,
    P_ARA,
    P_ARA_C4,
    P_NWE_NAPHTHA,
    P_MOPJ,
    P_EPC_C4,
    P_BLPG,
    P_BLPG3,
    P_WTI,
    P_BRT,
    VP_PETRO_CHEM,
];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productName) => {
    let productMeta: ProductMeta = productMap.get(productName)!;
    return {
        name: productName,
        title: productMeta.title,
        bodyTemplate: exposuresNumFormatter,
        textColor: redOnly,
        headerBackgroundColor: productMeta.backgroundColor,
        headerTextColor: productMeta.textColor,
    };
});

const allColumns = pivotColumns.concat(productsColumnConfig);

const DetailExpPerBook = (props: Props) => {
    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.ExpDetailPerBookWithDeals,
        title: 'Per Book and Deal Detail Exposures',
        size: TableSize.Small,
        columns: allColumns,
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 3,
        defaultExpandable: 1,
        stickyHeader: true,
    };

    const exposureData = props.pnlAnalytics.exposures;

    if (exposureData.status !== PNLFetchStatus.COMPLETED) {
        return <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.Exposures} data={exposureData} minHeight={'462px'} />;
    }

    if (!props.withDeals) {
        config.columns = config.columns.filter((column) => column.name !== 'deal');
        config.pivotDepth = 2;
        config.uniqueTableName = UniqueTableName.ExpDetailPerBook;
        config.title = 'Per Book Detail Exposures';
    }

    let respData = exposureData.data! as ExposuresDataResp;

    return (
        <ExpandableTable
            data={props.withDeals ? respData.detail_exp_per_book_deal_final_df : respData.detail_exp_per_book_final_df}
            config={config}
        />
    );
};

export default fetchConnector(DetailExpPerBook);
