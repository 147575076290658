import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import AdminRunningCost from './AdminCost';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const AdminComp = (props: Props) => {
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingDanaosVessels,
            params: {
                start_date: filters.quickDateSelection!.start_date,
                end_date: filters.quickDateSelection!.end_date,
                cmp_start_date: filters.quickDateSelection!.cmp_start_date,
                cmp_end_date: filters.quickDateSelection!.cmp_end_date,
                company: filters.quickDateSelection!.company,
            },
        });
    }, [props, filters]);

    return (
        <React.Fragment>
            <AdminRunningCost {...props} />
        </React.Fragment>
    );
};

export default accFetchConnector(AdminComp);
