import { Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { Variant } from '@material-ui/core/styles/createTypography';
import CSS from 'csstype';

type BaseBigValueHelperTextType = {
    text: React.ReactNode;
    style?: CSSProperties;
};

type BaseBigValueProps = {
    text: React.ReactNode;
    headerVariant: Variant;
    style?: CSSProperties;

    helperText?: BaseBigValueHelperTextType;
};

export const BaseBigValue = (props: BaseBigValueProps) => {
    return (
        <>
            {props.helperText && <div style={props.helperText!.style}>{props.helperText!.text}</div>}
            <Typography variant="h5" style={props.style}>
                {props.text}
            </Typography>
        </>
    );
};

export enum SimpleBigValueSize {
    Medium = 'Medium',
}

const simpleBigValueSizeMap = new Map<SimpleBigValueSize, { headerVariant: Variant; helperTextSize: string }>();
simpleBigValueSizeMap.set(SimpleBigValueSize.Medium, {
    headerVariant: 'h5',
    helperTextSize: '14px',
});

type SimpleBigValueProps = {
    text: React.ReactNode;
    size: SimpleBigValueSize;

    helperText?: {
        text: React.ReactNode;
    };

    textAlign?: CSS.TextAlignProperty;
    textColor?: () => CSS.Color;
    textFontWeight?: CSS.FontWeightProperty;
};

export const SimpleBigValue = (props: SimpleBigValueProps) => {
    let bigValueSize = simpleBigValueSizeMap.get(props.size)!;
    let textStyle: CSSProperties = {};
    if (props.textAlign) {
        textStyle['textAlign'] = props.textAlign;
    }
    if (props.textColor) {
        textStyle['color'] = props.textColor();
    }
    if (props.textFontWeight) {
        textStyle['fontWeight'] = props.textFontWeight;
    }

    let helperText: BaseBigValueHelperTextType | undefined = undefined;
    if (props.helperText) {
        let helperTextStyle: CSSProperties = { fontSize: bigValueSize.helperTextSize, marginBottom: '10px' };
        if (props.textAlign) {
            helperTextStyle['textAlign'] = props.textAlign;
        }
        helperText = {
            text: props.helperText!.text,
            style: helperTextStyle,
        };
    }

    let text = props.text ? props.text : '-';

    return <BaseBigValue text={text} headerVariant={bigValueSize.headerVariant} style={textStyle} helperText={helperText} />;
};
