import { FetchEditLotReconcileRespType, FetchEditLotReconcileStoreType, useZusStore } from '../../reducers/appStore';
import { Divider, Drawer, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { initNewFetchData, NewFetchData, NewFetchStatus } from '../../common/fetcher';
import SimpleTable from '../../tables/SimpleTable';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableData, SimpleTableRow, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import moment from 'moment-timezone';
import { numberFormat } from '../../containers/pivotHelper';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleBigValue, SimpleBigValueSize } from '../../common/BigValue';
import { accPNLPhysDiffNumFormat } from './AccPNLPhysDiff';
import { SwissColors } from '../../common/stylesConsts';

const useStyles = makeStyles(() => ({
    uuid: {
        fontSize: '12px !important',
    },
}));

export const shortCompFriendlyMap = new Map<string, string>();
shortCompFriendlyMap.set('broker_sum', 'Broker');
shortCompFriendlyMap.set('canal_insurance_inspector_sum', 'Canal/Ins/Inspect');
shortCompFriendlyMap.set('demurrage_sum', 'Demurrage');
shortCompFriendlyMap.set('freight_diff_sum', 'Freight Diff');
shortCompFriendlyMap.set('income_expense', 'Income/Expense');
shortCompFriendlyMap.set('lc_sum', 'LC');
shortCompFriendlyMap.set('total_sum', 'Total');

const SELECTED_PNL_COLUMN_COLOR = '#c9c9c9';

const EditLotReconcile = (props: {}) => {
    const { fetchEditLotReconcile, fetchEditLotReconcileUpdate } = useZusStore((state) => ({
        fetchEditLotReconcile: state.fetchEditLotReconcile,
        fetchEditLotReconcileUpdate: state.fetchEditLotReconcileUpdate,
    }));

    if (fetchEditLotReconcile.status !== NewFetchStatus.SUCCESS) return null;
    let tableRowData = fetchEditLotReconcile.data! as FetchEditLotReconcileStoreType;
    const closeDrawer = () => fetchEditLotReconcileUpdate(initNewFetchData());

    return (
        <Drawer
            anchor={'right'}
            open={true}
            PaperProps={{ style: { width: '90%' } }}
            // style={{ zIndex: 1201 }}
            onClose={(_, reason) => {
                if (reason === 'escapeKeyDown') return;
                closeDrawer();
            }}>
            <div className={'MuiToolbar-dense'} />
            <Grid container spacing={2} justify="center" style={{ padding: '20px' }}>
                <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '25px' }}>
                    <Typography variant="h3" component="div">
                        Lot {fetchEditLotReconcile.data!.lot_num} {shortCompFriendlyMap.get(fetchEditLotReconcile.data!.scg_pnl_column)!}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <SimpleBigValue
                        text={accPNLPhysDiffNumFormat(tableRowData.traders_ltd_val)}
                        size={SimpleBigValueSize.Medium}
                        textAlign={'center'}
                        helperText={{ text: 'Traders LTD PNL' }}
                    />
                </Grid>
                <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />
                <Grid item xs={4}>
                    <SimpleBigValue
                        text={accPNLPhysDiffNumFormat(tableRowData.danaos_ltd_val)}
                        size={SimpleBigValueSize.Medium}
                        textAlign={'center'}
                        helperText={{ text: 'Danaos LTD PNL' }}
                    />
                </Grid>
                <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />
                <Grid item xs={4}>
                    <SimpleBigValue
                        text={accPNLPhysDiffNumFormat(tableRowData.ltd_diff)}
                        size={SimpleBigValueSize.Medium}
                        textAlign={'center'}
                        textColor={() => {
                            if (Math.abs(tableRowData.ltd_diff) < 0.001) {
                                return 'black'; // When difference is less than a tenth of cent we consider it 0
                            } else {
                                return tableRowData.ltd_diff > 0 ? SwissColors.Green : SwissColors.Red;
                            }
                        }}
                        helperText={{ text: 'Traders - Acc Diff' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider orientation={'horizontal'} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '35px' }}>
                    <TradersTable
                        tableName={UniqueTableName.TradersLTDTrades}
                        tableTitle={'Traders LTD Trades'}
                        data={fetchEditLotReconcile.data!.traders_ltd_df}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider orientation={'horizontal'} />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '35px' }}>
                    <DanaosTable
                        tableName={UniqueTableName.DanaosLTDRecords}
                        tableTitle={'Danaos LTD Records'}
                        data={fetchEditLotReconcile.data!.danaos_ltd_df}
                    />
                </Grid>
            </Grid>
        </Drawer>
    );
};

const priceTemplate = (num: number): string => {
    if (num === null) return '';
    return numberFormat(num, 4, false, false, false);
};

type TradersTableProps = {
    tableName: UniqueTableName;
    tableTitle: string;
    data: SimpleTableData;
    tradeLinesIsFromPrevPNL?: boolean;
};

const TradersTable = ({ tableName, tableTitle, data, tradeLinesIsFromPrevPNL }: TradersTableProps) => {
    const classes = useStyles();
    const { fetchEditLotReconcile } = useZusStore((state) => ({
        fetchEditLotReconcile: state.fetchEditLotReconcile,
    }));

    const columns: Array<SimpleTableColumnConfig> = [
        {
            name: 'trade_uuid',
            title: 'Trade UUID',
            bodyCellsClassName: classes.uuid,
            bodyBackgroundColor: () => (tradeLinesIsFromPrevPNL ? 'orange' : 'clear'),
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return rawVal['trade_uuid'].substr(rawVal['trade_uuid'].length - 8);
            },
            hoverElement: () =>
                tradeLinesIsFromPrevPNL ? <div>This LOT was accidentally deleted and the lines you see are from previous year</div> : '',
        },
        {
            name: 'grouped_trades_uuid',
            title: 'Group',
            bodyCellsClassName: classes.uuid,
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return rawVal['grouped_trades_uuid'] ? rawVal['grouped_trades_uuid'].substr(rawVal['trade_uuid'].length - 8) : '';
            },
        },
        {
            name: 'internal_grouped_trades_uuid',
            title: 'Internal',
            bodyCellsClassName: classes.uuid,
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return rawVal['internal_grouped_trades_uuid']
                    ? rawVal['internal_grouped_trades_uuid'].substr(rawVal['trade_uuid'].length - 4)
                    : '';
            },
        },
        {
            name: 'pipe_run_date',
            title: 'Run Date',
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return moment(rawVal['pipe_run_date'] as string).format('DD MMM YY');
            },
        },
        { name: 'swiss_year', title: 'Trade Year' },
        {
            name: 'trade_date',
            title: 'Trade Date',
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return moment(rawVal['trade_date'] as string).format('DD MMM YY');
            },
        },
        {
            name: 'physical_trade_type',
            title: 'Type',
            bodyBackgroundColor: (row) => {
                if (row['physical_trade_type'] === 'Adjustment') return 'orange';
                return '';
            },
            hoverElement: (row) => {
                if (row['physical_trade_type'] === 'Adjustment')
                    return <div>This is a manual adjustment/addition to the Traders results</div>;
                return '';
            },
        },
        { name: 'book', title: 'Book' },
        { name: 'counterparty', title: 'Counter Party' },
        { name: 'vessel_name', title: 'Vessel' },
        {
            name: 'pricing_start_date',
            title: 'Dates',
            customDisplay: (row: SimpleTableRow, _: any) => {
                if (!row['pricing_start_date']) return <span />;
                return (
                    <span>
                        {moment(row['pricing_start_date'] as string).format('DD MMM YY')}-
                        {moment(row['pricing_end_date'] as string).format('DD MMM YY')}
                    </span>
                );
            },
        },
        { name: 'product', title: 'Product' },
        { name: 'contract_terms', title: 'Terms' },
        { name: 'premium_discount', title: 'Prem/Disc', bodyTemplate: priceTemplate },
        { name: 'fixed_price', title: 'Fixed Price', bodyTemplate: priceTemplate },
        { name: 'freight_diff', title: 'Freight Diff', bodyTemplate: priceTemplate },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: tableName,
        title: tableTitle,
        size: TableSize.Small,
        columns: columns.concat(getScgPnlColumns(fetchEditLotReconcile)),
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
    };

    return <SimpleTable data={data} config={config} />;
};

type DanaosTableProps = {
    tableName: UniqueTableName;
    tableTitle: string;
    data: SimpleTableData;
};

const DanaosTable = ({ tableName, tableTitle, data }: DanaosTableProps) => {
    const { fetchEditLotReconcile } = useZusStore((state) => ({
        fetchEditLotReconcile: state.fetchEditLotReconcile,
    }));

    const columns: Array<SimpleTableColumnConfig> = [
        { name: 'entry_type', title: 'Entry' },
        {
            name: 'date',
            title: 'Journal Date',
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return moment(rawVal['date'] as string).format('DD MMM YY');
            },
        },
        { name: 'swiss_year', title: 'Year' },
        { name: 'ledger_card', title: 'Card' },
        {
            name: 'description',
            title: 'Description',
            bodyTemplate2: (_: SimpleTableRow, rawVal: any) => {
                return rawVal['description'].substring(0, 60); // Make sure not to exceed 60 characters as it gets too long
            },
            bodyBackgroundColor: (row) => {
                if (row['entry_type'] === 'Adjustment') return 'orange';
                if (row['op_code_2']) return 'yellow';
                return '';
            },
            hoverElement: (row) => {
                if (row['entry_type'] === 'Adjustment') return <div>This is a manual adjustment/addition to the Danaos results</div>;
                if (row['op_code_2']) return <div>This is an internal virtual cash movement</div>;
                return '';
            },
        },
        { name: 'journal_key', title: 'Journal Key' },
        { name: 'scg_rec_group', title: 'SCG Rec Group' },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: tableName,
        title: tableTitle,
        size: TableSize.Small,
        columns: columns.concat(getScgPnlColumns(fetchEditLotReconcile)),
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
    };

    return <SimpleTable data={data} config={config} />;
};

const getScgPnlColumns = (fetchEditLotReconcile: NewFetchData<FetchEditLotReconcileRespType>): Array<SimpleTableColumnConfig> => {
    let scgPnlColumns = Array.from(shortCompFriendlyMap.keys()).filter((val) => val !== 'total_sum');
    return scgPnlColumns.map((compColumn) => {
        return {
            name: compColumn,
            title: shortCompFriendlyMap.get(compColumn)!,
            bodyBackgroundColor: () => {
                return compColumn === fetchEditLotReconcile.data!.scg_pnl_column ? SELECTED_PNL_COLUMN_COLOR : 'clear';
            },
            bodyTemplate: priceTemplate,
        };
    });
};

export default EditLotReconcile;
