export enum SwissColors {
    Red = '#e95968',
    Green = '#25b261',
    Yellow = '#edb421',
    Blue = '#6296bc',
    Cyan = '#7FD7C1',
    Pink = '#f6bdd1',
    GreyOut = '#d4d4d4',
    Orange = '#d75a00',
    Black = '#000000',
}

export const TotalBookColor = SwissColors.Blue;
export const PhysBookColor = SwissColors.Green;
export const ProductBookColor = SwissColors.Green;
export const ShippingBookColor = SwissColors.Pink;
export const ForwardBookColor = SwissColors.Cyan;
export const CorpBookColor = SwissColors.Yellow;
export const PhysTradeKindsFadedColor = '#e3ffef';
export const PaperTradeKindsFadedColor = '#fff8e3';
export const PaperTradeKindColor = SwissColors.Orange;

export const IntraDayPNLColor = '#e3dcfc';
export const IntraDayPreliminaryPNLColor = '#fceddc';
export const PrelimPNLColor = '#fcfbdc';
export const VerifiedPNLColor = '#dcfce0';
export const COBPNLColor = 'white';
export const ScenarioPNLColor = '#fcdcdc';

// Exposures Colors
export const ExpFEI = SwissColors.Blue;
export const ExpCP = 'black';
export const ExpCPC4 = '#7a7a7a';
export const ExpARA = '#75d8ff';
export const ExpMTB = SwissColors.Green;
export const ExpNaphtha = '#c4a176';
export const ExpBLPG = '#ca86d9';
export const ExpCrude = '#f5dec1';
export const ExpFlatPrice = SwissColors.Red;

// Product Colors
export const ExpLST = '#25b23c';
export const ExpMOPJ = '#d4947f';
export const ExpWTI = '#f5efc1';
export const ExpBRT = '#f5ccc1';
