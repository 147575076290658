import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { bankImgAndName, currencyToLetter } from './common';
import { dollarValFin2D, numberFormat, redOnly } from '../../containers/pivotHelper';
import moment from 'moment-timezone';
import SimpleTable from '../../tables/SimpleTable';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    bank: {
        minWidth: '95px',
    },
    bankTransactionID: {
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bookingDate: {
        width: '75px',
    },
    valueDate: {
        width: '75px',
    },
}));

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const BankTransactionsTableComp = (props: Props) => {
    const classes = useStyles();
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'680px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let data = respData.bank_transactions;
    let institutions = respData.bank_institutions;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'service_account_no',
            title: 'Bank',
            headerCellClassName: classes.bank,
            customDisplay: (row, val) => {
                if (val === 'Total') return <div>Total</div>;
                return bankImgAndName(row['bank_name'] as string, 'bank_name', institutions);
            },
        },
        {
            name: 'bank_account_no',
            title: 'IBAN',
            bodyTemplate: (val) => {
                return `...${val.slice(-6)}`;
            },
            hoverElement: (row, val) => {
                return (
                    <div style={{ width: '2000px' }}>
                        <div>IBAN: {val}</div>
                        <div>svc: {row['service_account_no']}</div>
                    </div>
                );
            },
        },
        {
            name: 'bank_transaction_id',
            bodyCellsClassName: classes.bankTransactionID,
            title: 'Bank Transaction ID',
        },
        {
            name: 'remittance_information',
            title: 'Description',
        },
        {
            name: 'bank_transaction_code',
            title: 'Info Code',
        },
        {
            name: 'creditor_name',
            title: 'Creditor',
            hoverElement: (row) => {
                return <div>{row['creditor_bank_account_no']}</div>;
            },
        },
        {
            name: 'booking_date',
            title: 'Book Date',
            headerCellClassName: classes.bookingDate,
            bodyTemplate2: (val, row) => {
                return moment(val).format('DD MMM YY');
            },
        },
        {
            name: 'value_date',
            title: 'Value Date',
            headerCellClassName: classes.valueDate,
            bodyTemplate2: (val, row) => {
                return moment(val).format('DD MMM YY');
            },
        },
        {
            name: 'amount',
            title: 'Amount',
            bodyTemplate2: (val, row) => {
                if (row['service_account_no'] === 'Total') return '';
                let amount = dollarValFin2D(val);
                let currency = currencyToLetter(row['currency'] as string);
                return `${amount}${currency}`;
            },
            textColor: (val) => redOnly(val, 0),
        },
        {
            name: 'amount_usd',
            title: '$ Amount',
            bodyTemplate2: (val, _) => {
                return dollarValFin2D(val);
            },
            hoverElement: (row, _) => {
                let fx_rate = row['fx_rate'];
                if (!fx_rate) return <div>No FX Rate available</div>;
                return <div>FX Rate: {numberFormat(row['fx_rate'] as number)}</div>;
            },
            textColor: (val) => redOnly(val, 0),
        },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.BankTransactionsTable,
        title: `Bank Transactions`,
        size: TableSize.Medium,
        columns: columnsConfig,
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        stickyHeader: true,
    };

    return <SimpleTable data={data} config={config} />;
};
export default accFetchConnector(BankTransactionsTableComp);
