import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { bankImgAndName, currencyToLetter } from './common';
import { dollarVal2D, kFormatterWithParenthesis, numberFormat, redOnly } from '../../containers/pivotHelper';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import moment from 'moment-timezone';
import SimpleTable from '../../tables/SimpleTable';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const BankBalancesTableComp = (props: Props) => {
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);
    console.log(filters);
    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'680px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let data = respData.bank_balances;
    let institutions = respData.bank_institutions;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'service_account_no',
            title: 'Bank',
            customDisplay: (row, val) => {
                if (val === 'Total') return <div>Total</div>;
                return bankImgAndName(row['bank_name'] as string, 'bank_name', institutions);
            },
        },
        {
            name: 'bank_account_no',
            title: 'IBAN',
            hoverElement: (row) => {
                return <div style={{ width: '500px' }}>{row['service_account_no']}</div>;
            },
        },
        {
            name: 'amount',
            title: 'Amount',
            bodyTemplate2: (val, row) => {
                if (row['service_account_no'] === 'Total') return '';
                let amount = filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
                let currency = currencyToLetter(row['currency'] as string);
                return `${amount}${currency}`;
            },
            textColor: (val) => redOnly(val, 0),
        },
        {
            name: 'amount_usd',
            title: '$ Amount',
            bodyTemplate2: (val, _) => {
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            hoverElement: (row, _) => {
                let fx_rate = row['fx_rate'];
                if (!fx_rate) return <div>No FX Rate available</div>;
                return <div>FX Rate: {numberFormat(row['fx_rate'] as number)}</div>;
            },
            textColor: (val) => redOnly(val, 0),
        },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.BankBalancesTable,
        title: `Bank Balances for ${moment(respData.bank_balances_run_date_str).format('DD MMM YY')}`,
        size: TableSize.Medium,
        columns: columnsConfig,
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        stickyHeader: true,
    };

    return <SimpleTable data={data} config={config} />;
};
export default accFetchConnector(BankBalancesTableComp);
