import React from 'react';
import { CorpBookColor, ProductBookColor, ShippingBookColor, TotalBookColor } from '../../common/stylesConsts';
import moment from 'moment';
import { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import CommonPNLSummaryGraph from './CommonPNLSummaryGraphs';
import { kFormatter } from '../../containers/pivotHelper';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { STANDARD_GRAPH_ELEMENT_HEIGHT } from '../../common/common';
import { PNLMetaDataResp } from '../fetchTypes';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';

let graphConfig: LineGraphConfig = {
    title: '20 Day Rolling Drawdown',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => moment(d).format('DD MMM'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: [-2_400_000, 0],
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    referenceLines: [
        { y: 0, label: '', stroke: 'black' },
        { y: -1_700_000, label: '', tooltipLabel: 'Alert Stop Limit', stroke: '#edd500', strokeWidth: 3, strokeDasharray: '3 3' },
        { y: -2_200_000, label: '', tooltipLabel: 'Stop Limit', stroke: 'red', strokeWidth: 3, strokeDasharray: '3 3' },
    ],
    chartsSettings: [
        { chartType: ChartType.AreaAsLine, key: 'rolling_scg_dd', color: TotalBookColor, title: 'Total' },
        { chartType: ChartType.AreaAsLine, key: 'rolling_product_dd', color: ProductBookColor, title: 'Product' },
        { chartType: ChartType.AreaAsLine, key: 'rolling_shipping_dd', color: ShippingBookColor, title: 'Shipping' },
        { chartType: ChartType.AreaAsLine, key: 'rolling_corp_dd', color: CorpBookColor, title: 'Corp' },
    ],
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const DrawdownGraph = (props: Props) => {
    const metaData = props.pnlAnalytics.meta;

    if (metaData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics}
                data={metaData}
                minHeight={STANDARD_GRAPH_ELEMENT_HEIGHT}
            />
        );
    }

    let respData = metaData.data! as PNLMetaDataResp;

    let alertStopLimit = -7_500_000;
    let stopLimit = -10_000_000;
    if (respData.selected.pipe_run_date <= '2023-06-30') {
        alertStopLimit = -1_700_000;
        stopLimit = -2_200_000;
    }
    graphConfig['yAxis']['yLimit'][1] = stopLimit - 100_000;
    graphConfig['referenceLines']![1]['y'] = alertStopLimit;
    graphConfig['referenceLines']![2]['y'] = stopLimit;

    return <CommonPNLSummaryGraph historicalPNLPerBookMetricsDataKey={'pnl_summary_graphs'} config={graphConfig} />;
};

export default fetchConnector(DrawdownGraph);
