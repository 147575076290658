import { RootState } from '../reducers';
import { clearAllDataAction, fetchCompletedAction, fetchFailedAction, fetchLoadingAction } from '../actions/pnlAnalytics';
import { siteErrorOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { AccQuickDate } from '../backAccounting/dataFetcher';

const mapState = (state: RootState) => ({
    pnlAnalytics: state.pnlAnalytics,
    authUser: state.authUser,
});

export interface AccMetaData {
    quick_dates: Array<AccQuickDate>;
    default_quick_date: AccQuickDate;

    last_entry_datetime: string;
    last_sync_datetime: string;
}

const mapDispatch = {
    fetchLoadingAction: fetchLoadingAction,
    fetchCompletedAction: fetchCompletedAction,
    fetchFailedAction: fetchFailedAction,
    siteErrorOpenAction: siteErrorOpenAction,
    clearAllDataAction: clearAllDataAction,
};

export const accFetchConnector = connect(mapState, mapDispatch);
export type FetchAccAnalyticsPropsFromRedux = ConnectedProps<typeof accFetchConnector>;
