import moment from 'moment';

export const processMonthChange = (tradeDate: string, newMonth: string, yearB: string): Array<string> => {
    if (newMonth === '') {
        return ['', yearB];
    }
    let promptMonthAMonth: string;
    switch (newMonth) {
        case 'Q1':
            promptMonthAMonth = 'Jan';
            break;
        case 'Q2':
            promptMonthAMonth = 'Apr';
            break;
        case 'Q3':
            promptMonthAMonth = 'Jul';
            break;
        case 'Q4':
            promptMonthAMonth = 'Oct';
            break;
        case 'H1':
            promptMonthAMonth = 'Jan';
            break;
        case 'H2':
            promptMonthAMonth = 'Jul';
            break;
        default:
            promptMonthAMonth = newMonth;
    }
    let currentMonth = moment(tradeDate).format('MMM');
    const currentMonthNumber = parseInt(
        moment()
            .month(currentMonth)
            .format('M')
    );
    const promptMonthANumber = parseInt(
        moment()
            .month(promptMonthAMonth)
            .format('M')
    );
    const currentYearNumber = parseInt(moment(tradeDate).format('YYYY'));
    let promptYear = promptMonthANumber >= currentMonthNumber ? currentYearNumber : currentYearNumber + 1;
    return [promptMonthAMonth, promptYear.toString()];
};
