import React from 'react';
import { AGG_FUNC_NAMES, AggFuncName, CellRangeAgg } from '../common/cellRangeAgg';
import { RootState } from '../reducers';
import { connect, ConnectedProps } from 'react-redux';

const mapState = (state: RootState) => ({
    footerCellAgg: state.footerCellAggg
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TableAggFooterProps = PropsFromRedux & {
    cellRangeAgg: CellRangeAgg;
};

function TableAggFooter(props: TableAggFooterProps) {
    let footerCellAgg = props.footerCellAgg;
    if (footerCellAgg.uniqueTableName === null) return null;
    if (!props.cellRangeAgg.isSameTable(footerCellAgg.uniqueTableName)) return null;
    if (!props.cellRangeAgg.hasNumberSelectedCells()) return null;
    return (
        <div
            style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                width: '100%',
                textAlign: 'right',
                padding: '5px 20px 5px 20px',
                backgroundColor: '#f1f3f4',
                borderTop: '1px solid #e8eaed',
                zIndex: 100 // Make sure this displays above the graphs
            }}>
            {AGG_FUNC_NAMES.map(aggFuncName => (
                <TableAggFooterElement
                    key={aggFuncName.toString()}
                    aggFuncName={aggFuncName}
                    cellRangeAgg={props.cellRangeAgg}
                />
            ))}
        </div>
    );
}

type TableAggFooterElementProps = {
    aggFuncName: AggFuncName;
    cellRangeAgg: CellRangeAgg;
};

function TableAggFooterElement(props: TableAggFooterElementProps) {
    // Calculated the reduced value
    let resVal = props.cellRangeAgg.calcReducedVal(props.aggFuncName);
    // If we have a display formatter apply it
    let resDisplayVal = props.cellRangeAgg.formatVal ? props.cellRangeAgg.formatVal(props.aggFuncName, resVal) : resVal;
    return (
        <span style={{ marginLeft: '10px' }}>
            {props.aggFuncName.toString()}: <strong>{resDisplayVal}</strong>
        </span>
    );
}

export default connector(TableAggFooter);
