import React from 'react';
import { CorpBookColor, ForwardBookColor, ProductBookColor, ShippingBookColor, TotalBookColor } from '../../common/stylesConsts';
import moment from 'moment';
import { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import CommonPNLSummaryGraph from './CommonPNLSummaryGraphs';
import { kFormatter } from '../../containers/pivotHelper';

let graphConfig: LineGraphConfig = {
    title: 'SwissChemGas PNL',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => moment(d).format('DD MMM'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    chartsSettings: [
        { chartType: ChartType.AreaAsLine, key: 'scg_net', color: TotalBookColor, title: 'Total' },
        {
            chartType: ChartType.AreaAsLine,
            key: 'scg_net_m_y1',
            color: TotalBookColor,
            title: 'Total Y-1',
            strokeDasharray: '6 6',
            strokeOpacity: 0.5,
            fillOpacity: 0,
        },

        { chartType: ChartType.AreaAsLine, key: 'product_net', color: ProductBookColor, title: 'Product' },
        { chartType: ChartType.AreaAsLine, key: 'shipping_net', color: ShippingBookColor, title: 'Shipping' },
        { chartType: ChartType.AreaAsLine, key: 'forward_net', color: ForwardBookColor, title: 'Forward', hiddenOnLoad: true },

        { chartType: ChartType.AreaAsLine, key: 'corp_net', color: CorpBookColor, title: 'Corp' },
        {
            chartType: ChartType.AreaAsLine,
            key: 'corp_net_m_y1',
            color: CorpBookColor,
            title: 'Corp Y-1',
            strokeDasharray: '6 6',
            strokeOpacity: 0.3,
            fillOpacity: 0,
            hiddenOnLoad: true,
        },
    ],
};

const PNLGraph = () => {
    return <CommonPNLSummaryGraph historicalPNLPerBookMetricsDataKey={'pnl_summary_graphs'} config={graphConfig} />;
};

export default PNLGraph;
