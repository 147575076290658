import React from 'react';
import { SwissColors } from '../../common/stylesConsts';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import moment from 'moment';
import { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import CommonPNLSummaryGraph from './CommonPNLSummaryGraphs';
import { kFormatter, numberFormat } from '../../containers/pivotHelper';
import { PNLFetchStatus } from '../../actions/pnlAnalytics';
import { HistoricalPNLPerBookMetricsDataResp } from '../fetchTypes';

let graphConfig: LineGraphConfig = {
    title: 'Monthly PnL/Sharpe',
    dataKey: 'pipe_run_date',
    oyLabel: '',
    oxLabel: '',
    xAxisFormatter: (d) => moment(d).format('MMM YY'),
    xTooltipFormatter: (d) => moment(d).format('MMM YYYY'),
    yAxis: {
        yLimit: ['auto', 'auto'], // On Dec 08 2021 to auto but on Nov 2 2022 changed it bellow to adapt
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    referenceLines: [{ y: 0, label: '', stroke: 'black', yAxisId: 'left' }],
    ySecondaryAxis: {
        yLimit: ['auto', 'auto'], // On Dec 08 2021 to auto but on Nov 2 2022 changed it bellow to adapt
        yAxisFormatter: (v) => numberFormat(v, 0, false, false),
    },
    yTooltipFormatter: (v) => numberFormat(v, 2, false, false),
    chartsSettings: [
        { chartType: ChartType.Bar, key: 'scg_net', color: SwissColors.Blue, title: 'Net PnL' },
        { chartType: ChartType.Bar, key: 'scg_sharpe', color: SwissColors.Green, title: 'Sharpe', secondary: true },
    ],
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PnlSharpeGraph = (props: Props) => {
    const histPNLPerBookMetricsData = props.pnlAnalytics.historical_pnl_per_book_metrics;

    // I can't get the 0 axis to align every time. Sometimes it works other times it doesn't
    // The only way I have found it to always (at least I think) work is to mirror the absolute min max for both axis
    if (histPNLPerBookMetricsData.status === PNLFetchStatus.COMPLETED) {
        let sharpe_graph = (histPNLPerBookMetricsData.data! as HistoricalPNLPerBookMetricsDataResp).pnl_sharpe_graph;
        let scg_net_max = Math.max(...sharpe_graph.map((o) => o.scg_net));
        let scg_net_min = Math.min(...sharpe_graph.map((o) => o.scg_net));
        let scg_net_limit = Math.max(Math.abs(scg_net_max), Math.abs(scg_net_min));
        graphConfig.yAxis.yLimit = [scg_net_limit * -1, scg_net_limit];

        let scg_sharpe_max = Math.max(...sharpe_graph.map((o) => o.scg_sharpe));
        let scg_sharpe_min = Math.min(...sharpe_graph.map((o) => o.scg_sharpe));
        let scg_sharpe_limit = Math.max(Math.abs(scg_sharpe_max), Math.abs(scg_sharpe_min));
        graphConfig.ySecondaryAxis!.yLimit = [scg_sharpe_limit * -1, scg_sharpe_limit];
    }

    return <CommonPNLSummaryGraph historicalPNLPerBookMetricsDataKey={'pnl_sharpe_graph'} config={graphConfig} />;
};

export default fetchConnector(PnlSharpeGraph);
