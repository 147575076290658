import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux, SCGPhysicalResp } from '../dataFetcher';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import { numberFormat } from '../../containers/pivotHelper';

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps;

export const staticColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'lot_num',
        title: 'LOT',
    },
    { name: 'description', title: 'Description' },
];

type amountColumnsMetaType = {
    name: string;
    title: string;
};

const amountColumnsMeta: Array<amountColumnsMetaType> = [
    { name: 'Gross Income Product', title: 'Gross' },
    { name: 'Freight Expenses', title: 'Freight Exp' },
    { name: 'Freight Income', title: 'Freight Inc' },
    { name: 'Demurrage Expenses', title: 'Dem Exp' },
    { name: 'Demurrage Income', title: 'Dem Income' },
    { name: 'LC Costs', title: 'LC Costs' },
    { name: 'Financing Costs', title: 'Fin Cost' },
    { name: 'Commission Product', title: 'Commission' },
    { name: 'Hire Sub-Chartering', title: 'Hire Sub' },
    { name: 'Hire Commission', title: 'Hire Comm' },
    { name: 'Coolant', title: 'Coolant' },
    { name: 'Canal Tolls', title: 'Canal Tolls' },
    { name: 'Voyage Expenses', title: 'Voyage' },
    { name: 'Inspection Costs', title: 'Inspection' },
    { name: 'Port Expenses', title: 'Port' },
    { name: 'Shifting Costs', title: 'Shifting' },
    { name: 'Insurance', title: 'Insurance' },
    { name: 'Other Services', title: 'Other' },
    { name: 'Legal Expenses', title: 'Legal' },
    { name: 'Total', title: 'Total' },
];

const FlatPhysRecComp = (props: Props) => {
    const scgPhysical = props.pnlAnalytics.back_accounting_scg_physical;

    if (scgPhysical.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.BAccountingSCGPhysical}
                data={scgPhysical}
                minHeight={'500px'}
            />
        );
    }

    // Construct the config columns
    let respData = scgPhysical.data! as SCGPhysicalResp;

    let amountColumnsConfig: Array<SimpleTableColumnConfig> = amountColumnsMeta.map((columnMeta) => {
        return {
            name: columnMeta.name,
            title: columnMeta.title,
            headerHoverElement: (name, _) => {
                let ledgerCards = respData.scg_rec_group_mapping[name];
                if (!ledgerCards) return <p>_</p>;
                let tooManyLedgerCards = ledgerCards.length > 20;
                ledgerCards = ledgerCards.length > 20 ? ledgerCards.slice(-20) : ledgerCards;
                return (
                    <div>
                        <p>Ledger Cards: </p>
                        <p>{ledgerCards.toString()}</p>
                        {tooManyLedgerCards && <p>Some ledger cards are not show as we have too many</p>}
                    </div>
                );
            },
            customDisplay: (row: SimpleTableRow, _: any) => {
                let rawFloat = row[columnMeta.name] as number;
                let displayVal = rawFloat === 0 ? 0 : numberFormat(rawFloat, 2, false, true, true);
                // let displayVal = kFormatter(rawFloat, true, defaultFractionDigits, true);
                let detailVal = numberFormat(rawFloat, 2, false, true, true);

                return (
                    <Tooltip
                        interactive
                        placement="top"
                        title={
                            <React.Fragment>
                                <p>
                                    Amount: <strong>{detailVal}</strong>
                                </p>
                            </React.Fragment>
                        }>
                        <span>{displayVal}</span>
                    </Tooltip>
                );
            },
        };
    });

    let allColumns = staticColumns.concat(amountColumnsConfig);

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.SCGPhysRecFlat,
        title: 'SCG Physical Trade Reconciliation',
        size: TableSize.Small,
        columns: allColumns,
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        defaultExpandable: 0,
        stickyHeader: true,
    };

    return (
        <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
            <ExpandableTable data={respData.scg_rec_physical_flat} config={config} />
        </Grid>
    );
};
export default bAccFetchConnector(FlatPhysRecComp);
