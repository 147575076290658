import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { HistoricalPNLPerBookMetricsDataResp } from '../fetchTypes';
import { SimpleTableConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { perBookMetricsStyles } from '../common/common';
import { kFormatter } from '../../containers/pivotHelper';
import TrendDirection2, { TrendDirection2Config, TrendDirection2Size } from '../../common/TrendDirection2';
import SimpleTable from '../../tables/SimpleTable';
import { SwissColors } from '../../common/stylesConsts';
import { kFormatterAggWithSign } from '../common/common';

const displayWithArrow = (row: SimpleTableRow, rawVal: any) => {
    let textValue = kFormatter(rawVal);
    let color: null | string = null;
    // Default
    if (rawVal < 0) {
        color = SwissColors.Red;
    } else if (rawVal < 100) {
        color = SwissColors.Black;
    } else if (rawVal > 100) {
        color = SwissColors.Green;
    }
    let trend: TrendDirection2Config = {
        downIsBad: true,
        size: TrendDirection2Size.Small,
        zeroThreshold: 100,
    };
    let customStyles: any = {
        color: color,
    };
    return (
        <div style={customStyles}>
            <TrendDirection2 {...trend} val={rawVal as number} />
            <span>{textValue}</span>
        </div>
    );
};

const dateDisplay = (_: SimpleTableRow, rawVal: any) => <span>{moment(rawVal).format('DD MMM YY')}</span>;

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.PnlBookMetrics,
    title: 'SwissChemGas Book Metrics',
    size: TableSize.Small,
    columns: [
        { name: 'book', title: 'Book' },
        {
            name: 'pct_win',
            title: '% Win',
        },
        {
            name: 'median_win',
            title: 'Median Win',
            customDisplay: displayWithArrow,
        },
        {
            name: 'best_pnl',
            title: 'Best PnL',
            customDisplay: displayWithArrow,
        },
        {
            name: 'best_day',
            title: 'Best Day',
            customDisplay: dateDisplay,
        },
        {
            name: 'pct_loss',
            title: '% Loss',
        },
        {
            name: 'median_loss',
            title: 'Median Loss',
            customDisplay: displayWithArrow,
        },
        {
            name: 'worst_pnl',
            title: 'Worst PnL',
            customDisplay: displayWithArrow,
        },
        {
            name: 'worst_day',
            title: 'Worst Day',
            customDisplay: dateDisplay,
        },
        {
            name: 'trading_days',
            title: 'Trading Days',
        },
    ],
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PNLBookMetricsTableHelper = (props: Props) => {
    const classes = perBookMetricsStyles();
    const histPnlPerBookMetricsData = props.pnlAnalytics.historical_pnl_per_book_metrics;

    if (histPnlPerBookMetricsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics}
                data={histPnlPerBookMetricsData}
                minHeight={'270px'}
            />
        );
    }

    let respData = histPnlPerBookMetricsData.data! as HistoricalPNLPerBookMetricsDataResp;
    // profit vs loss divider
    config.columns[5].headerCellClassName = classes.cellWithLeftBorder;
    config.columns[5].bodyCellsClassName = classes.cellWithLeftBorder;
    // loss vs trading days divider
    config.columns[9].headerCellClassName = classes.cellWithLeftBorder;
    config.columns[9].bodyCellsClassName = classes.cellWithLeftBorder;

    return <SimpleTable data={respData.pnl_book_metrics} config={config} />;
};

export default fetchConnector(PNLBookMetricsTableHelper);
