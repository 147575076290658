import React from 'react';
import { siteErrorOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { SwissColors } from '../common/stylesConsts';
import moment, { Moment } from 'moment-timezone';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import TimerIcon from '@material-ui/icons/Timer';
import { BlotterStateType, BlotterTrade } from './types';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    blotterState: BlotterStateType;
    setTradeIdCopied: (id: string) => void;
};

const ClearerBlotterRawTrades = ({ blotterState, setTradeIdCopied }: Props) => {
    let currOrderNum: string | null = null;
    let prevRunDate: Moment | null = null;

    return (
        <Box className={'MuiToolbar-gutters'} my={2}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Run Date</TableCell>
                            <TableCell>Execution Time (UTC)</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Is Mini</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Code</TableCell>
                            <TableCell>Prompt Month</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Exchange</TableCell>
                            <TableCell>Trader</TableCell>
                            <TableCell>Trade ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blotterState.blotterResponse!.blotter.map((blotterTrade, index) => {
                            let runDate = moment(blotterTrade.pipe_run_date);
                            let balmoDate = null;
                            let balmoStyle: any = {};
                            if (blotterTrade.balmo_date !== null) {
                                balmoDate = moment(blotterTrade.balmo_date).format('Do MMM');
                                balmoStyle['color'] = SwissColors.Blue;
                            }
                            // let balmoDate =
                            //     blotterTrade.balmo_date != null
                            //         ? moment(blotterTrade.balmo_date).format('Do MMM')
                            //         : '';
                            let term_month_date = moment(blotterTrade.term_month_date).format('MMM YY');
                            // let productName = !blotterTrade.product_b
                            //     ? blotterTrade.product_a
                            //     : `${blotterTrade.product_a}/${blotterTrade.product_b}`;
                            // let promptMonth = `${blotterTrade.month_a} ${blotterTrade.year_a}`;
                            // let volume = numberFormat(blotterTrade.quoted_volume, 0, false, true, true);
                            let volumeStyle: any = {};
                            if (blotterTrade.quoted_volume < 0) {
                                volumeStyle['color'] = SwissColors.Red;
                            }
                            let rowStyle: any = {};
                            if (currOrderNum === null || currOrderNum !== blotterTrade.order_num) {
                                let borderColor = '#808080';
                                let borderWidth = '3px';
                                if (prevRunDate === null || !runDate.isSame(prevRunDate, 'day')) {
                                    if (prevRunDate !== null) {
                                        borderColor = 'blue';
                                        borderWidth = '7px';
                                    }
                                    prevRunDate = runDate;
                                }
                                if (index !== 0) {
                                    rowStyle['borderTop'] = `${borderWidth} solid ${borderColor}`;
                                }
                                currOrderNum = blotterTrade.order_num;
                            }
                            return (
                                <TableRow
                                    style={rowStyle}
                                    key={`${blotterTrade.pipe_run_date}-${blotterTrade.clearer_trade_id}-${blotterTrade.trade_type}`}>
                                    <TableCell>
                                        <div>{moment(blotterTrade.pipe_run_date).format('YYYY-MM-DD')}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{moment(blotterTrade.trade_time).utc().format('lll')}</div>
                                    </TableCell>
                                    <TableCell>
                                        <TradeType row={blotterTrade} />
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip placement={'top'} title={blotterTrade.clearer_name}>
                                            <span>{blotterTrade.clearer_account_short_name}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell style={volumeStyle}>
                                        <Tooltip placement={'top'} title={blotterTrade.quoted_volume_unit}>
                                            <span>{blotterTrade.quoted_volume}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{blotterTrade.mult_factor === 100 ? 'Yes' : ''}</TableCell>
                                    <TableCell>{blotterTrade.product_name}</TableCell>
                                    <TableCell>{blotterTrade.clearer_contract_code_combo}</TableCell>
                                    <TableCell>
                                        {!balmoDate && <span>{term_month_date}</span>}
                                        {!!balmoDate && (
                                            <span style={balmoStyle}>
                                                <Tooltip placement={'top'} title={balmoDate || ''}>
                                                    <span>{term_month_date}</span>
                                                </Tooltip>
                                            </span>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip placement={'top'} title={blotterTrade.quoted_trade_price_unit}>
                                            <span>{Number(blotterTrade.quoted_trade_price.toFixed(3))}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{blotterTrade.exchange}</TableCell>
                                    <TableCell>{blotterTrade.trader_nick_name}</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            disableFocusListener={true}
                                            title={`Copy Trade ID (${blotterTrade.clearer_trade_id}) to Clipboard`}
                                            placement="top">
                                            <span>
                                                <FileCopyIcon
                                                    style={{
                                                        width: 14,
                                                        height: 14,
                                                        marginLeft: 10,
                                                        cursor: 'pointer',
                                                        marginRight: 10,
                                                    }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(blotterTrade.clearer_trade_id).then();
                                                        setTradeIdCopied(blotterTrade.clearer_trade_id);
                                                    }}
                                                />
                                                {blotterTrade.clearer_trade_id}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

function TradeType({ row }: { row: BlotterTrade }) {
    let isTPATransfer = row.trade_type.includes('TPA Transfer');
    let isExpiring = ['P&S', 'Delivery', 'Trade Settlement'].includes(row.trade_type);
    if (isTPATransfer) {
        let msg =
            row.clearer_account_short_name === 'SCG'
                ? 'closing on SCG account'
                : `opening on TPA (${row.clearer_account_short_name}) account`;
        return (
            <div style={{ marginLeft: 10 }}>
                <Tooltip title={`This is an internal TPA Transfer ${msg}`} placement="top" interactive>
                    <SwapHoriz fontSize="small" htmlColor={'#ef6c00'} />
                </Tooltip>
            </div>
        );
    }
    if (isExpiring) {
        let expiringColor = '#FF2222FF'; // Default to P&S color
        if (row.trade_type === 'Delivery') {
            expiringColor = '#FFD322FF';
        } else if (row.trade_type === 'Trade Settlement') {
            expiringColor = '#FF8522FF';
        }
        return (
            <div style={{ marginLeft: 10 }}>
                <Tooltip
                    title={`This is an ${row.trade_type}, expired on ${moment(row.last_trading_date).format('Do MMM')}`}
                    placement="top"
                    interactive>
                    <TimerIcon fontSize="small" htmlColor={expiringColor} />
                </Tooltip>
            </div>
        );
    }
    return <span>{row.trade_type}</span>;
}

export default connector(ClearerBlotterRawTrades);
