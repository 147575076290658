import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateType } from '@date-io/type';
import moment from 'moment';
import React, { ReactNode } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {getBalmoDateSummary, handleAllTradesSimpleFieldChange} from '../common';
import {AllTradesPropsType} from '../Types';

function BalmoDate(props: {
    isBalmoTrade: boolean;
    value: string;
    changeValue: (newValue: string) => void;
    disabled: boolean;
}) {
    let displayValue = props.value === '' ? null : props.value;
    let tooltipText = 'Enabled if Prompt Date A is the same as Trade Date';
    let label = props.isBalmoTrade ? 'Balmo Date *' : 'Balmo Date';
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Tooltip
                title={tooltipText}
                placement={'bottom'}
                disableHoverListener={props.isBalmoTrade}
                disableFocusListener={props.isBalmoTrade}>
                <span>
                    <DatePicker
                        disabled={!props.isBalmoTrade || props.disabled}
                        style={{ margin: 0, marginLeft: '16px' }}
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        label={label}
                        value={displayValue}
                        autoOk={true}
                        allowKeyboardControl={false}
                        onAccept={(date: DateType | null) => {
                            if (date === null) return;
                            let momentDate = moment(date).startOf('day');
                            let dateStr = momentDate.format('YYYY-MM-DD');
                            props.changeValue(dateStr);
                        }}
                        onChange={(_: Date | null, __?: string | null) => {}}
                    />
                </span>
            </Tooltip>
        </MuiPickersUtilsProvider>
    );
}

interface BalmoFieldProps extends AllTradesPropsType {}

export function BalmoField(props: BalmoFieldProps) {
    const { state, setState } = props;
    return (
        <BalmoDate
            isBalmoTrade={state.balmoDate.value !== ''}
            value={state.balmoDate.value}
            changeValue={(newValue: string) => {
                let summary: ReactNode = state.balmoDate.value === '' ? null : getBalmoDateSummary(state.balmoDate.value);
                handleAllTradesSimpleFieldChange(state, setState, 'balmoDate', {
                    value: newValue,
                    error: '',
                    summary: summary,
                    required: state.balmoDate.required
                });
            }}
            disabled={props.disabled}
        />
    );
}
