import React, { ReactNode } from 'react';
import {SingleVolumeTradesPropsType} from '../Types';
import VolumeField from '../base/VolumeField';
import {derivedVolume, volumeRangeError} from "../common";

interface VolumeWrapperFieldProps extends SingleVolumeTradesPropsType {}

export function VolumeWrapperField(props: VolumeWrapperFieldProps) {
    const { state, setState } = props;
    return (
        <VolumeField
            autofocus={true}
            marginLeft={'16px'}
            label={"Volume"}
            required={state.volume.required}
            errorText={state.volume.error}
            disabled={props.disabled}
            value={state.volume.value}
            handleChange={newValue => {
                let errorText = volumeRangeError(newValue);
                // Summary volume
                let summary: ReactNode = null;
                if (!errorText) {
                    let volumeNumber: number = Number.parseInt(newValue);
                    let tradeDirection: string = volumeNumber > 0 ? 'Bought' : 'Sold';
                    let volumeK = Math.abs(volumeNumber) / 1000;
                    let volumeMetric = derivedVolume(props.options, props.state) === 'BBL' ? 'kb' : 'kt';
                    summary = (
                        <span>
                            {tradeDirection} {volumeK}
                            {volumeMetric}
                        </span>
                    );
                }
                setState({
                    ...state,
                    volume: { value: newValue, error: errorText, summary: summary, required: state.volume.required }
                });
            }}
        />
    );
}
