import React from 'react';
import {AllTradesPropsType} from '../Types';
import {handleAllTradesSimpleFieldChange} from '../common';
import {TextField} from "@material-ui/core";

interface NotesFieldProps extends AllTradesPropsType {}

export function NotesField(props: NotesFieldProps) {
    const { state, setState } = props;

    return (
        <TextField
            error={Boolean(state.notes.error)}
            helperText={state.notes.error}
            disabled={props.disabled}
            placeholder="Notes"
            value={state.notes.value}
            style={{ width: 240 }}
            onChange={event => {
                let newValue: string = event.target.value;
                let notesErrorText = '';
                // We disabled multiline edits in notes, but just in case to avoid crashing the app
                if (newValue.includes('\n') || newValue.includes('\t')) {
                    notesErrorText = 'Invalid characters \\. Dont add new lines or tabs';
                }
                handleAllTradesSimpleFieldChange(state, setState, 'notes', {
                    value: newValue,
                    error: notesErrorText,
                    summary: null,
                    required: state.notes.required
                });
            }}
        />
    );
}
