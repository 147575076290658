import React from 'react';
import { SimpleTableColumnConfig, TableSize, SimpleTableRow } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { greenOnlyReversedHundred, kFormatter, redGreenHundred, redOnlyHundred } from '../../containers/pivotHelper';
import { kFormatterAggWithSign, kFormatterWithSign } from '../common/common';
import { PaperTradeKindsFadedColor, PhysTradeKindsFadedColor } from '../../common/stylesConsts';
import { commonPNLSummaryTableColumns } from '../common/CommonPNLSummaryTable';
import { TrendDirection2Size } from '../../common/TrendDirection2';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';


const managementPerTradeKindColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'physical_gross_pnl',
        title: 'Phys Gross',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: PhysTradeKindsFadedColor,
    },
    {
        name: 'physical_total_cost',
        title: 'Phys Cost',
        bodyTemplate: kFormatterWithSign,
        textColor: greenOnlyReversedHundred,
        headerBackgroundColor: PhysTradeKindsFadedColor,
    },
    {
        name: 'physical_net_pnl',
        title: 'Phys Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: PhysTradeKindsFadedColor,
    },
    {
        name: 'physical_delta_net_pnl',
        title: 'Phys DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: PhysTradeKindsFadedColor,
    },
    {
        name: 'paper_gross_pnl',
        title: 'Paper Gross',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: PaperTradeKindsFadedColor,
    },
    {
        name: 'paper_total_cost',
        title: 'Paper Cost',
        bodyTemplate: kFormatterWithSign,
        textColor: greenOnlyReversedHundred,
        headerBackgroundColor: PaperTradeKindsFadedColor,
    },
    {
        name: 'paper_net_pnl',
        title: 'Paper Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: PaperTradeKindsFadedColor,
    },
    {
        name: 'paper_delta_net_pnl',
        title: 'Paper DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: PaperTradeKindsFadedColor,
    },
];

const columns: Array<SimpleTableColumnConfig> = [
    {
        name: 'strategy',
        title: 'Strategy',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['strategy']}</div>;
        }
    }, {
        name: 'deal',
        title: 'Deal',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['deal']}</div>;
        }
    },
    ...managementPerTradeKindColumns,
    ...commonPNLSummaryTableColumns
].filter((column) => !['var_95', 'delta_var_95'].includes(column.name));

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.MPerStrategyPhysSummary,
        title: 'Physical Summary',
        size: TableSize.Small,
        columns: columns,
        grandTotal: false,
        aggFormatVal: kFormatterAggWithSign,
        pivotDepth: 1,
        defaultExpandable: 0,
        stickyHeader: true,
    };

const PTPerStrategyPhysSummaryTable = (props: FetchPNLAnalyticsPropsFromRedux) => {
    const allTradesResp = props.pnlAnalytics.all_trades_run_date;

    if (allTradesResp.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.AllTradesRunDate} data={allTradesResp} minHeight={'604px'} />
        );
    }
    const respData = allTradesResp.data! as AllTradesRunDateDataResp;
    return (
        <ExpandableTable data={respData['per_strategy_phys_pnl_summary']} config={config} />
    );
};

export default fetchConnector(PTPerStrategyPhysSummaryTable);
