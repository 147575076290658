import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import Overview from '../common/Overview';
import { MIN_3_12_EL_WIDTH, MIN_5_12_EL_WIDTH, MIN_6_12_EL_WIDTH, MIN_9_12_EL_WIDTH, MIN_12_12_EL_WIDTH } from '../../common/common';
import PerStrategyCorpSummary from '../common/PerStrategyCorpSummary';
import { PNLMetaDataResp } from '../fetchTypes';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from '../dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import PaperLookAhead from './PaperLookAhead';
import { isAuthScreenShotBot } from '../../common/auth';
import SpreadsByStructure from './SpreadsByStructure';
import SpreadByStructureGraph from './SpreadByStructureGraph';
import DetailExpPerBook from '../common/DetailExpPerBook';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const AVAILABLE_TABLES = [
    'Overview',
    'Per Strategy Corp Summary',
    'Spreads By Structure',
    'Spreads By Structure Graph',
    'Paper Look Ahead',
    'Detail Exp Per Book',
];

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PaperTradingIndex = (props: Props) => {
    const [selectedTables, setSelectedTables] = React.useState<string[]>([]);
    let metaData = props.pnlAnalytics.meta.data! as PNLMetaDataResp;
    let isScreenShotBot = isAuthScreenShotBot(props.authUser);

    // Set selected tables from local storage or default ones on mount
    useEffect(() => {
        let paperTabTables = AVAILABLE_TABLES;
        if (typeof window !== 'undefined') {
            try {
                const localTables = window.localStorage.getItem('paperTabTables');
                if (localTables) {
                    paperTabTables = JSON.parse(localTables);
                }
            } catch (error) {
                console.warn(`Error setting localStorage key paperTabTables:`, error);
            }
        }
        setSelectedTables(paperTabTables);
    }, []);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AllTradesRunDate,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.Exposures,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    const handleChange = (event: SelectChangeEvent<typeof selectedTables>) => {
        const {
            target: { value },
        } = event;
        window.localStorage.setItem('paperTabTables', JSON.stringify(value));
        setSelectedTables(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const memoisedTables = useMemo(() => {
        const tableJSX = selectedTables.map((table) => {
            switch (table) {
                case 'Overview':
                    if (isScreenShotBot) return null;
                    return (
                        <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH, maxWidth: 800 }}>
                            <Overview />
                        </Grid>
                    );
                case 'Per Strategy Corp Summary':
                    if (isScreenShotBot) return null;
                    return (
                        <Grid item xs={5} style={{ backgroundColor: 'clear', minWidth: MIN_5_12_EL_WIDTH, maxWidth: 600 }}>
                            <PerStrategyCorpSummary />
                        </Grid>
                    );
                case 'Spreads By Structure':
                    return (
                        <Grid item xs={3} style={{ backgroundColor: 'clear', minWidth: MIN_3_12_EL_WIDTH }}>
                            <SpreadsByStructure />
                        </Grid>
                    );
                case 'Spreads By Structure Graph':
                    return (
                        <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_9_12_EL_WIDTH, maxWidth: 700 }}>
                            <SpreadByStructureGraph />
                        </Grid>
                    );
                case 'Paper Look Ahead':
                    return <PaperLookAhead />;
                case 'Detail Exp Per Book':
                    if (isScreenShotBot) return null;
                    return (
                        <Grid item xs={12} style={{ backgroundColor: 'clear', minWidth: MIN_12_12_EL_WIDTH }}>
                            <DetailExpPerBook />
                        </Grid>
                    );
                default:
                    return null;
            }
        });
        return tableJSX;
    }, [isScreenShotBot, selectedTables]);

    return (
        <>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>Tables</InputLabel>
                <Select
                    multiple
                    value={selectedTables}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tables" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}>
                    {AVAILABLE_TABLES.map((table) => (
                        <MenuItem key={table} value={table}>
                            <Checkbox checked={selectedTables.indexOf(table) > -1} />
                            <ListItemText primary={table} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                {memoisedTables}
            </Grid>
        </>
    );
};

export default fetchConnector(PaperTradingIndex);
