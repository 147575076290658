import { AggFuncName } from '../../common/cellRangeAgg';
import { kFormatter } from '../../containers/pivotHelper';
import { makeStyles } from '@material-ui/core/styles';

export const kFormatterWithSign = (val: number) => kFormatter(val, false);

export const kFormatterAggWithSign = (aggFuncName: AggFuncName, val: number) => {
    return aggFuncName === AggFuncName.Count ? val : kFormatterWithSign(val);
};

export const perStrategyMediumVarUseStyles = makeStyles(() => ({
    headerVaRCell: {
        fontSize: '12px !important',
        borderLeft: 'black solid 1px',
    },
    bodyVaRCell: {
        fontSize: '12px !important',
        borderLeft: 'black solid 1px',
    },
    headerDtDVaRCell: {
        fontSize: '12px !important',
    },
    bodyDtDVaRCell: {
        fontSize: '12px !important',
    },
}));

export const perBookMetricsStyles = makeStyles(() => ({
    cellWithLeftBorder: {
        borderLeft: 'black solid 1px',
    },
}));
