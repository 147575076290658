import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ListItemText, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux, SCGPhysicalResp } from '../dataFetcher';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { kFormatterWithSign } from '../../pnlAnalytics/common/common';
import SimpleTable, { ColumnMenuProps } from '../../tables/SimpleTable';
import { SwissColors } from '../../common/stylesConsts';
import { makeStyles } from '@material-ui/core/styles';
import { optNumberFormat } from '../../containers/pivotHelper';
import { swissUseSelector } from '../../reducers';
import { BackAccountingFiltersState } from '../../reducers/backAccounting';
import { FetchEditLotReconcileRespType, FetchEditLotReconcileStoreType, useZusStore } from '../../reducers/appStore';
import { newFetcher } from '../../common/fetcher';
import { AuthUserState } from '../../reducers/authUser';
import { useMainSnackbar } from '../../common/SwissSnackBar';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 24px;
`;

const useStyles = makeStyles(() => ({
    compColumnHeaderClassName: {
        width: '750px !important',
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));

type Props = FetchBackAccAnalyticsPropsFromRedux &
    RouteComponentProps & {
        tableDataKey: string;
    };

const compFriendlyMap = new Map<string, string>();
compFriendlyMap.set('broker_sum', 'Broker');
compFriendlyMap.set('canal_insurance_inspector_sum', 'Canal Insurance Inspector');
compFriendlyMap.set('demurrage_sum', 'Demurrage');
compFriendlyMap.set('freight_diff_sum', 'Freight Diff');
compFriendlyMap.set('income_expense', 'Income/Expense');
compFriendlyMap.set('lc_sum', 'LC');
compFriendlyMap.set('total_sum', 'Total');

export const accPNLPhysDiffNumFormat = (val: number | null | undefined) => optNumberFormat(val, 2, false, false, false);

const EditMenu = ({ row, columnConfig, anchorEl, onClose, uniqueTableName }: ColumnMenuProps) => {
    const appStore = useZusStore((state) => state);
    const authUser = swissUseSelector<AuthUserState>((state) => state.authUser);
    const bAFilterState = swissUseSelector<BackAccountingFiltersState>((state) => state.backAccounting);
    const mainSnackbar = useMainSnackbar();

    if (anchorEl === null) return null;
    return (
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClick={() => onClose()}>
            <MenuItem
                style={{ width: '150px' }}
                onClick={() => {
                    newFetcher<FetchEditLotReconcileRespType, FetchEditLotReconcileStoreType>({
                        method: 'GET',
                        authUserState: authUser,
                        appStore: appStore,
                        mainSnackbar: mainSnackbar,
                        withBackdropSpinner: true,
                        withErrorSnackbar: true,
                        appStoreDataKey: 'fetchEditLotReconcile',
                        url: '/acc_analytics/back_acc/ltd_reconcile',
                        params: {
                            lot_num: row['lot_num'] as string,
                            scg_pnl_column: columnConfig.name.split('-')[0],
                            include_adj_proj: bAFilterState.includeAdjProj,
                            table_unique_name: uniqueTableName,
                        },
                        onStoreSuccess: (resp) => {
                            return {
                                ...resp,
                                row: row,
                            };
                        },
                    });
                }}>
                <ListItemText>Edit</ListItemText>
            </MenuItem>
        </Menu>
    );
};

const AccPNLPhysDiffComp = (props: Props) => {
    const classes = useStyles();
    const scgPhysical = props.pnlAnalytics.back_accounting_scg_physical;

    if (scgPhysical.status !== PNLFetchStatus.COMPLETED) {
        return (
            <StyledContainer>
                <PNLGoodiesNonCompletedResponses
                    endpoint={PNLAnalyticsEndpoint.BAccountingSCGPhysical}
                    data={scgPhysical}
                    minHeight={'500px'}
                />
                <PNLGoodiesNonCompletedResponses
                    endpoint={PNLAnalyticsEndpoint.BAccountingSCGPhysical}
                    data={scgPhysical}
                    minHeight={'500px'}
                />
                <PNLGoodiesNonCompletedResponses
                    endpoint={PNLAnalyticsEndpoint.BAccountingSCGPhysical}
                    data={scgPhysical}
                    minHeight={'500px'}
                />
            </StyledContainer>
        );
    }

    // Construct the config columns
    let respData = scgPhysical.data! as SCGPhysicalResp;
    let fixedColumns: Array<SimpleTableColumnConfig> = [
        {
            name: 'lot_num',
            title: 'LOT',
            headerCellClassName: classes.compColumnHeaderClassName,
            hoverElement: (row) => {
                if (row['lot_num'] === 'Total') return '';
                return row['lot_num'];
            },
        },
    ];

    let compColumnConfigs: Array<SimpleTableColumnConfig> = Array.from(compFriendlyMap.keys()).map((compColumn) => {
        const diffColumn = `${compColumn}-amount_diff`;
        return {
            name: diffColumn,
            title: compFriendlyMap.get(compColumn)!,
            headerCellClassName: classes.compColumnHeaderClassName,
            menuElement: compColumn !== 'total_sum' ? EditMenu : undefined,
            customDisplay: (row, _) => {
                const getVal = (key: string): number | null => row[key] as number | null;
                const getDisplayVal = (key: string): string => accPNLPhysDiffNumFormat(getVal(key));
                const diffDisplayVal = row[diffColumn] as number | null;
                const diffVal = diffDisplayVal || 0;

                let customStyles: any = { padding: '2px 5px', borderRadius: '5px' };
                if (diffVal !== 0) {
                    if (Math.abs(diffVal) < 0.001) {
                    } else if (Math.abs(diffVal) <= respData.min_rounding_diff_ok) {
                        customStyles['backgroundColor'] = SwissColors.Yellow;
                    } else {
                        customStyles['backgroundColor'] = diffVal > 0 ? SwissColors.Green : SwissColors.Red;
                    }
                }
                return (
                    <>
                        <span style={customStyles}>
                            <Tooltip
                                placement="top"
                                interactive={true}
                                classes={{ tooltip: classes.noMaxWidth }}
                                title={
                                    <React.Fragment>
                                        <p>
                                            Danaos: <strong>{getDisplayVal(`${compColumn}-amount_danaos`)}</strong>
                                        </p>
                                        <p>
                                            Traders: <strong>{getDisplayVal(`${compColumn}-amount_traders`)}</strong>
                                        </p>
                                        <p>
                                            Diff: <strong>{accPNLPhysDiffNumFormat(diffDisplayVal)}</strong>
                                        </p>
                                        <br />
                                    </React.Fragment>
                                }>
                                <span>{diffDisplayVal == null ? '' : kFormatterWithSign(diffDisplayVal)}</span>
                            </Tooltip>
                        </span>
                    </>
                );
            },
        };
    });

    // {respData[props.tableDataKey as keyof SCGPhysicalResp]

    const closedLotconfig: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.LTDLotsReconcileClosed,
        title: 'Closed LOTs',
        size: TableSize.Small,
        columns: fixedColumns.concat(compColumnConfigs),
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
        stickyHeader: true,
    };

    const ongoingLotsConfig: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.LTDLotsReconcileOngoing,
        title: `Ongoing LOTs`,
        size: TableSize.Small,
        columns: fixedColumns.concat(compColumnConfigs),
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
        stickyHeader: true,
    };

    const outsideLotsConfig: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.LTDLotsReconcileOutside,
        title: `Outside LOTs`,
        size: TableSize.Small,
        columns: fixedColumns.concat(compColumnConfigs),
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
        stickyHeader: true,
    };

    return (
        <StyledContainer>
            <SimpleTable
                data={respData['closed_lots' as keyof SCGPhysicalResp] as Array<any>}
                config={closedLotconfig}
                style={{ height: 'fit-content' }}
            />
            <SimpleTable
                data={respData['ongoing_lots' as keyof SCGPhysicalResp] as Array<any>}
                config={ongoingLotsConfig}
                style={{ height: 'fit-content' }}
            />
            <SimpleTable
                data={respData['outside_lots' as keyof SCGPhysicalResp] as Array<any>}
                config={outsideLotsConfig}
                style={{ height: 'fit-content' }}
            />
        </StyledContainer>
    );
};
export default bAccFetchConnector(AccPNLPhysDiffComp);
