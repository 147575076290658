import React from 'react';
import { CircularProgress, Paper } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../actions/pnlAnalytics';
import { getEndpointTitle } from './common';
import { PNLFetchData } from '../reducers/pnlAnalytics';
import { swissAssert } from '../common/swissAssert';

type BasePaperPlaceHolderProps = {
    msg: string;
    minHeight: string;
    loading?: boolean;
    isError?: boolean;
};

const PaperPlaceHolder = (props: BasePaperPlaceHolderProps) => {
    let customStyles: any = {
        display: 'table',
        textAlign: 'center',
        width: '100%',
        minHeight: props.minHeight,
        // Add standard margins that Grids have so that the loading and actual papers have the same borders
        marginTop: '12px',
        marginBottom: '12px',
    };
    return (
        <Paper style={customStyles}>
            <div style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px', paddingRight: ' 15px' }}>
                {props.loading && <CircularProgress style={{ marginBottom: '10px' }} size={20} />}
                {props.isError && <ErrorIcon />}
                <div>{props.msg}</div>
            </div>
        </Paper>
    );
};

type StandardPaperPlaceHolderProps = {
    endpoint: PNLAnalyticsEndpoint;
    minHeight: string;
};

const InitializingPaperPlaceHolder = (props: StandardPaperPlaceHolderProps) => {
    return <PaperPlaceHolder msg={`Initializing ...`} minHeight={props.minHeight} loading={true} />;
};

const LoadingPNLGoodiesPaperPlaceHolder = (props: StandardPaperPlaceHolderProps) => {
    return <PaperPlaceHolder msg={`Fetching you PNL goodies ... 📈`} minHeight={props.minHeight} loading={true} />;
};

type FailedPaperPlaceHolderProps = {
    errMsg: string;
} & StandardPaperPlaceHolderProps;

const ErrorPNLGoodiesPaperPlaceHolder = (props: FailedPaperPlaceHolderProps) => {
    return (
        <PaperPlaceHolder msg={`${getEndpointTitle(props.endpoint)} failed: ${props.errMsg}`} minHeight={props.minHeight} isError={true} />
    );
};

type PNLGoodiesNonCompletedResponsesProps = {
    data: PNLFetchData;
    endpoint: PNLAnalyticsEndpoint;
    minHeight: string;
};

export const PNLGoodiesNonCompletedResponses = (props: PNLGoodiesNonCompletedResponsesProps) => {
    return <PNLGoodiesNonReadyResponses status={props.data.status} {...props} />;
};

type PNLGoodiesNonReadyResponsesProps = {
    status: PNLFetchStatus;
    data: PNLFetchData;
    endpoint: PNLAnalyticsEndpoint;
    minHeight: string;
};

export const PNLGoodiesNonReadyResponses = (props: PNLGoodiesNonReadyResponsesProps) => {
    if (props.status === PNLFetchStatus.INIT) {
        return <InitializingPaperPlaceHolder endpoint={props.endpoint} minHeight={props.minHeight} />;
    }
    if (props.status === PNLFetchStatus.LOADING) {
        return <LoadingPNLGoodiesPaperPlaceHolder endpoint={props.endpoint} minHeight={props.minHeight} />;
    }
    if (props.status === PNLFetchStatus.FAILED) {
        let returnedErrorMsg: undefined | any = props.data.err!.jsonError['err_msg'];
        let errMsg: string = returnedErrorMsg ? (returnedErrorMsg as string) : props.data.err!.parsedMsg;
        return <ErrorPNLGoodiesPaperPlaceHolder errMsg={errMsg} endpoint={props.endpoint} minHeight={props.minHeight} />;
    }
    swissAssert(false, 'we should never get a completed response here');
    return null;
};
