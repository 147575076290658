import { ReactNode } from 'react';

export enum StepperStateType {
    FIRST_INIT_STATE = 'FIRST_INIT',
    FIRST_INIT_LOADING_STATE = 'FIRST_INIT_LOADING_STATE',
    FIRST_INIT_LOADED_DATA = 'FIRST_INIT_LOADED_DATA',
    FIRST_INIT_FAILED_DATA = 'FIRST_INIT_FAILED_DATA',
}

export type BooksStrategiesType = {
    name: string;
    strategies: Record<string, string[]>;
};

export type TradeEntryUserType = {
    email: string;
    nick_name: string;
    is_authorized_trader: boolean;
    default_trader: boolean;
};

export type TradeOptionsBackendType = {
    mtb_products: Array<string>;
    crude_products: Array<string>;
    users: Array<TradeEntryUserType>;
    products: Array<string>;
    books: Array<BooksStrategiesType>;
    brokers: Array<string>;
    exchanges: Array<string>;
    tenor_months: Array<string>;
    tenor_years: Array<string>;
    volume_metrics: Array<string>;
};

export type TradeOptions = TradeOptionsBackendType & {
    tradersNickNames: Array<string>;
    defaultTraderNickName: string;
    authUserNickName: string;
};

export type StepperStateDataType = {
    state: StepperStateType;
    trade_options?: TradeOptions;
};

export interface FieldType<T> {
    value: T;
    error: string;
    required: boolean;
    summary: ReactNode;
}

export interface AllTradesType {
    tradeDate: FieldType<string>;
    monthA: FieldType<string>;
    balmoDate: FieldType<string>;
    yearA: FieldType<string>;
    monthB: FieldType<string>;
    yearB: FieldType<string>;
    priceB: FieldType<string>;
    productA: FieldType<string>;
    productB: FieldType<string>;
    priceA: FieldType<string>;
    executingFor: FieldType<string>;
    notes: FieldType<string>;
}

export interface AllTradesPropsType {
    state: AllTradesType;
    setState: (newState: AllTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface SingleVolumeTradesType extends AllTradesType {
    volume: FieldType<string>;
}

export interface SingleVolumeTradesPropsType {
    state: SingleVolumeTradesType;
    setState: (newState: SingleVolumeTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface ExchangeTradesType extends AllTradesType {
    diff: FieldType<boolean>;
    mini: FieldType<boolean>;
    convertedLegVolume: FieldType<string>;
    exchange: FieldType<string>;
    broker: FieldType<string>;
    commissionOverride: FieldType<string>;
    clearerTradeIDs: FieldType<string>;
}

export interface ExchangeTradesPropsType {
    state: ExchangeTradesType;
    setState: (newState: ExchangeTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface RegularTradesType extends ExchangeTradesType {
    volume: FieldType<string>;
    book: FieldType<string>;
    strategy: FieldType<string>;
    deal: FieldType<string>;
}

export interface RegularTradesPropsType {
    state: RegularTradesType;
    setState: (newState: RegularTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface SplitPerBookFieldType {
    volume: FieldType<string>;
    book: FieldType<string>;
    strategy: FieldType<string>;
}

export interface SplitPerBookMap {
    [key: string]: SplitPerBookFieldType;
}

export interface SplitTradesType extends ExchangeTradesType {
    perBook: SplitPerBookMap;
}

export interface SplitTradesPropsType {
    state: SplitTradesType;
    setState: (newState: SplitTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface InternalTradesType extends SingleVolumeTradesType, AllTradesType {
    buyFromBook: FieldType<string>;
    buyFromStrategy: FieldType<string>;
    buyFromDeal: FieldType<string>;
    sellToBook: FieldType<string>;
    sellToStrategy: FieldType<string>;
    sellToDeal: FieldType<string>;
}

export interface InternalTradesPropsType {
    state: InternalTradesType;
    setState: (newState: InternalTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}

export interface NaftomarTradesType extends AllTradesType, SingleVolumeTradesType {
    commissionOverride: FieldType<string>;
}

export interface NaftomarTradesPropsType {
    state: NaftomarTradesType;
    setState: (newState: NaftomarTradesType) => void;
    options: TradeOptions;
    disabled: boolean;
}
