import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux } from '../dataFetcher';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import CashWoW from './CashWoW';
import Filters from './Filters';
import { useZusStore } from '../../reducers/appStore';
import CashPlanningTransactions from './CashPlanningTransactions';
import SCGCashMovesTable from './SCGCashMovesTable';
import SCGShowCashMovesEntries from './SCGShowCashMovesEntries';
import { Grid } from '@material-ui/core';
import { MIN_12_12_EL_WIDTH } from '../../common/common';

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const BackAccounting = (props: Props) => {
    const { withBuffer, withBlocked, runDate } = useZusStore((state) => state.cashPlanningFilters);
    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.BACashPlanning,
            params: {
                run_date: runDate,
                with_buffer: withBuffer,
                with_blocked: withBlocked,
            },
        });
    }, [props, withBuffer, withBlocked, runDate]);
    return (
        <div>
            <Filters />
            <Grid container spacing={2} justify="center" style={{ padding: '20px' }}>
                <Grid item xs={12} style={{ marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                    <CashWoW {...props} />
                </Grid>
                <Grid item xs={8} style={{ marginTop: '50px' }}>
                    <SCGCashMovesTable {...props} />
                </Grid>
            </Grid>
            <CashPlanningTransactions />
            <SCGShowCashMovesEntries />
        </div>
    );
};

export default bAccFetchConnector(BackAccounting);
