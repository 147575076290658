import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { dollarVal2D, kFormatterWithParenthesis, redOnlyHundred } from '../../containers/pivotHelper';

const useStyles = makeStyles(() => ({
    groupCostColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '80px !important',
        // fontSize: '15px !important',
    },
    balanceSheetColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '300px !important',
        // fontSize: '15px !important',
    },
    cardColumn: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '300px !important',
        // fontSize: '15px !important',
    },
    totalColumn: {
        borderLeft: '#bfbfbf solid 1px',
    },
}));

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const PaymentsAndICComp = (props: Props) => {
    const classes = useStyles();
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'680px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let data = respData.cash_payments_ic;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'company_code',
            title: 'Company',
            bodyTemplate2: (val, row) => {
                return row['company_fixed_name'] ? `[${val}] ${row['company_fixed_name']}` : val;
            },
            headerCellClassName: classes.groupCostColumn,
            bodyCellsClassName: classes.groupCostColumn,
        },
        {
            name: 'entry_type',
            title: 'Type',
            headerCellClassName: classes.balanceSheetColumn,
            bodyCellsClassName: classes.balanceSheetColumn,
        },
        {
            name: 'ledger_card_code',
            title: 'Ledger Card',
            headerCellClassName: classes.cardColumn,
            bodyCellsClassName: classes.cardColumn,
            bodyTemplate2: (val, row) => {
                return val ? `[${val}] ${row['ledger_card_name']}` : val;
            },
        },
        {
            name: 'narrative',
            title: 'Narrative',
            headerCellClassName: classes.cardColumn,
            bodyCellsClassName: classes.cardColumn,
        },
        {
            name: 'book_value',
            title: 'Amount',
            bodyTemplate: (val) => {
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
        },
    ];

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.PaymentsAndIC,
        title: 'Payments & Inter Company Entries',
        size: TableSize.Medium,
        columns: columnsConfig, // staticColumns.concat(compColumnsConfig),
        grandTotal: true,
        // grandTotalRowName: GRAND_TOTAL_ROW,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 3,
        defaultExpandable: 0,
        stickyHeader: true,
    };

    return <ExpandableTable data={data} config={config} />;
};
export default accFetchConnector(PaymentsAndICComp);
