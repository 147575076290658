import { numberFormat } from '../containers/pivotHelper';

export const exposuresNumFormatter = (num: number | undefined): string => {
    if (num === undefined || num === null) return '';
    return numberFormat(num, 0, false, true, true);
};

export const exposuresNumFormatterNoParenthesis = (num: number | undefined): string => {
    if (num === undefined || num === null) return '';
    return numberFormat(num, 0, false, true, false);
};

export const exposuresNumFormatterNoZeros = (num: number | undefined): string => {
    num = num === 0 ? undefined : num;
    return exposuresNumFormatter(num);
};
