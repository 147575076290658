import { UniqueTableName } from './uniqueTableName';

export type Cell = {
    row: number;
    col: number;
};

const _cellKey = (cell: Cell): string => `${cell.row}|${cell.col}`;

type CellMeta = Cell & CellParserRes;

export type CellRange = {
    start: Cell;
    end: Cell;
};

export type CellParserRes = {
    shouldAgg: boolean;
    valText: string;
    val: number;
};

export type CellParser = (cell: Cell) => CellParserRes;

export type AggFormatVal = (aggFuncName: AggFuncName, val: number) => any;

export enum AggFuncName {
    Sum = 'Sum',
    Avg = 'Avg',
    Count = 'Count',
    Max = 'Max',
    Min = 'Min'
}

type ArrayNumReducer = (rawValues: Array<number>) => number;

const sumReducer = (rawValues: Array<number>): number => {
    return rawValues.reduce(function(accumulator, currentValue) {
        return accumulator + currentValue;
    }, 0);
};

const avgReducer = (rawValues: Array<number>): number => {
    let sum = rawValues.reduce(function(accumulator, currentValue) {
        return accumulator + currentValue;
    }, 0);

    return sum / rawValues.length;
};

const countReducer = (rawValues: Array<number>): number => rawValues.length;

const maxReducer = (rawValues: Array<number>): number => Math.max(...rawValues);

const minReducer = (rawValues: Array<number>): number => Math.min(...rawValues);

const _aggFunctions: { [_: string]: ArrayNumReducer } = {};
_aggFunctions[AggFuncName.Sum] = sumReducer;
_aggFunctions[AggFuncName.Avg] = avgReducer;
_aggFunctions[AggFuncName.Count] = countReducer;
_aggFunctions[AggFuncName.Max] = maxReducer;
_aggFunctions[AggFuncName.Min] = minReducer;

export const AGG_FUNC_NAMES: Array<AggFuncName> = [
    AggFuncName.Sum,
    AggFuncName.Avg,
    AggFuncName.Count,
    AggFuncName.Max,
    AggFuncName.Min
];

export class CellRangeAgg {
    _uniqueTableName: UniqueTableName;
    _ranges: Array<CellRange>;
    _selectedCells: Map<string, CellMeta>;
    _selectedRows: Set<number>;
    _selectedVals: Array<number>;
    _cellParser: CellParser;
    formatVal: AggFormatVal;

    constructor(uniqueTableName: UniqueTableName, cellParser: CellParser, formatVal: AggFormatVal) {
        this._uniqueTableName = uniqueTableName;
        this._ranges = [];
        this._selectedCells = new Map();
        this._selectedRows = new Set<number>();
        this._selectedVals = [];
        this._cellParser = cellParser;
        this.formatVal = formatVal;
    }

    deepCopy(uniqueTableName: UniqueTableName, cellParser: CellParser, formatVal: AggFormatVal): CellRangeAgg {
        let cellRangeAgg = new CellRangeAgg(uniqueTableName, cellParser, formatVal);
        // Only copy the ranges if we are making a copy of the same Agg
        if (this._uniqueTableName === cellRangeAgg._uniqueTableName) {
            this.formatVal = formatVal;
            // No need to copy the ranges unless we actually have ranges
            if (this._ranges.length !== 0) {
                let tmpRanges = [...this._ranges];
                // Only the latest range can ever be mutated so we can just copy that
                tmpRanges[tmpRanges.length - 1] = {
                    start: { ...tmpRanges[tmpRanges.length - 1].start },
                    end: { ...tmpRanges[tmpRanges.length - 1].end }
                };
                cellRangeAgg._ranges = [...tmpRanges];
            }
        }
        return cellRangeAgg;
    }

    isSameTable(uniqueTableName: UniqueTableName): boolean {
        return this._uniqueTableName === uniqueTableName;
    }

    isRowSelected(rowIdx: number): boolean {
        return this._selectedRows.has(rowIdx);
    }

    cleanCopy(): CellRangeAgg {
        return new CellRangeAgg(this._uniqueTableName, this._cellParser, this.formatVal);
    }

    getLatestCellRange(): CellRange {
        if (this._selectedCells.size === 0)
            throw Error('Calling getLatestCellRange with no selected Cells should not be happening');
        return { ...this._ranges[this._ranges.length - 1] };
    }

    mutateClearAndAddCellRange(cellRange: CellRange) {
        this._ranges = [cellRange];
        this.updateSelectedCells();
    }

    mutateAppendCellRange(cellRange: CellRange) {
        this._ranges.push(cellRange);
        this.updateSelectedCells();
    }

    mutateUpdateLatestCellRange(end: Cell) {
        if (this._ranges.length === 0) throw Error('We cant mutateUpdateLatestCellRange without any cells in _ranges');
        this._ranges[this._ranges.length - 1].end = end;
        this.updateSelectedCells();
    }

    mutateMoveLatestCellRange(end: Cell) {
        if (this._ranges.length === 0) throw Error('We cant mutateMoveLatestCellRange without any cells in _ranges');
        this._ranges[this._ranges.length - 1].start = end;
        this._ranges[this._ranges.length - 1].end = end;
        this.updateSelectedCells();
    }

    updateSelectedCells() {
        let selectedCells: Map<string, CellMeta> = new Map();
        let tmpSelectedRows = new Set<number>();
        this._ranges.forEach(cellRange => {
            let minMax: CellRange = {
                start: {
                    row: Math.min(cellRange.start.row, cellRange.end.row),
                    col: Math.min(cellRange.start.col, cellRange.end.col)
                },
                end: {
                    row: Math.max(cellRange.start.row, cellRange.end.row),
                    col: Math.max(cellRange.start.col, cellRange.end.col)
                }
            };
            for (let rowIndex = minMax.start.row; rowIndex <= minMax.end.row; rowIndex++) {
                for (let colIndex = minMax.start.col; colIndex <= minMax.end.col; colIndex++) {
                    // We use cancel out for overlaps
                    // This means that if we see a cell
                    // 1 time it is added
                    // 2 times it is removed
                    // 3 times it is added again
                    // and so on ...
                    let cell: Cell = { row: rowIndex, col: colIndex };
                    let cellKey = _cellKey(cell);
                    if (selectedCells.has(cellKey)) {
                        selectedCells.delete(cellKey);
                    } else {
                        tmpSelectedRows.add(rowIndex);
                        let cellParserRes = this._cellParser(cell);
                        let cellMeta: CellMeta = { ...cell, ...cellParserRes };
                        selectedCells.set(cellKey, cellMeta);
                    }
                }
            }
        });
        this._selectedCells = selectedCells;
        this._selectedRows = tmpSelectedRows;
        // Also save the raw numbers for quick aggregation
        let tmpSelectedVals: Array<number> = [];
        selectedCells.forEach(selectedCell => {
            if (!selectedCell.shouldAgg) return;
            tmpSelectedVals.push(selectedCell.val);
        });
        this._selectedVals = tmpSelectedVals;
    }

    isCellSelected(cell: Cell) {
        return this._selectedCells.has(_cellKey(cell));
    }

    hasSelectedCells(): boolean {
        return this._selectedCells.size !== 0;
    }

    hasNumberSelectedCells(): boolean {
        return this._selectedVals.length !== 0;
    }

    calcReducedVal(funcName: AggFuncName): number {
        if (this._selectedCells.size === 0)
            throw Error('Calling calcReducedVal with no selected Cells should not be happening');
        return _aggFunctions[funcName.toString()](this._selectedVals);
    }
}
