import { combineReducers } from 'redux';
import { useSelector as _useSelector } from 'react-redux';
import authUser, { AuthUserState } from './authUser';
import siteAlerts from './siteAlerts';
import footerCellAgg from './footerCellAgg';
import pnlAnalyticsReducer, { PNLAnalyticsState } from './pnlAnalytics';
import accountingReducer from './accounting';
import { SetFiltersPayloadType } from '../actions/accounting';
import { backAccountingReducer } from './backAccounting';

export interface RootReducerStateType {
    authUser: AuthUserState;
    accounting: SetFiltersPayloadType;
    pnlAnalytics: PNLAnalyticsState;
}

export const rootReducer = combineReducers({
    authUser: authUser,
    siteAlerts: siteAlerts,
    footerCellAggg: footerCellAgg,
    pnlAnalytics: pnlAnalyticsReducer,
    accounting: accountingReducer,
    backAccounting: backAccountingReducer,
});

export function swissUseSelector<T>(fn: (store: RootState) => T): T {
    return _useSelector<RootState, T>(fn);
}

export type RootState = ReturnType<typeof rootReducer>;
