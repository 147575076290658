import React, { ReactNode } from 'react';
import { AllTradesPropsType, ExchangeTradesPropsType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import {
    handleAllTradesSimpleFieldChange,
    handleExchangeSimpleFieldChange,
    handleExchangeProductChange
} from '../common';
import PriceField from '../base/PriceField';

interface ProductAFieldProps extends ExchangeTradesPropsType {}

export function ProductAField(props: ProductAFieldProps) {
    const { state, setState, options } = props;

    return (
        <AutoCompleteField
            options={options.products}
            inputValue={state.productA.value}
            handleChange={(newVal: string) => {
                handleExchangeProductChange(
                    props,
                    {
                        value: newVal,
                        error: '',
                        required: state.productA.required,
                        summary: <span>{newVal}</span>
                    },
                    state.productB
                );
            }}
            handlePartialChange={partialValue => {
                handleExchangeSimpleFieldChange(state, setState, 'productA', {
                    value: partialValue,
                    error: '',
                    summary: null,
                    required: state.productA.required
                });
            }}
            required={state.productA.required}
            label={'Product A'}
            width={130}
            marginLeft={''}
            disabled={props.disabled}
        />
    );
}

interface PriceAFieldProps extends AllTradesPropsType {}

export function PriceAField(props: PriceAFieldProps) {
    const { state, setState } = props;

    return (
        <PriceField
            value={state.priceA.value}
            handleChange={(newValue: string) => {
                const hasPriceA = Boolean(newValue);
                let summary: ReactNode = hasPriceA ? <span>@{newValue}</span> : <span>MOC</span>;
                handleAllTradesSimpleFieldChange(state, setState, 'priceA', {
                    value: newValue,
                    error: '',
                    summary: summary,
                    required: state.priceA.required
                });
            }}
            label={'Price'}
            required={state.priceA.required}
            width={100}
            marginLeft={'76px'}
            disabled={props.disabled}
        />
    );
}
