import { Tooltip } from '@material-ui/core';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import React from 'react';

export const DanaosLastUpdateLabel = (props: { lastSyncDatetime: string; lastEntryDatetime: string }) => {
    return (
        <Tooltip title={`Last Danaos Sync: ${moment(props.lastSyncDatetime).format('DD MMM YYYY HH:mm:ss')}`} placement="top" interactive>
            <div style={{ margin: '12px 0px', fontSize: '14px' }}>
                Last Entry Date:{' '}
                <b>
                    <Moment format="DD MMMM YYYY HH:mm:ss">{props.lastEntryDatetime!}</Moment>
                </b>
            </div>
        </Tooltip>
    );
};
