import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import {siteErrorCloseAction, siteSuccessCloseAction} from './actions/siteAlerts';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {SiteAlertState} from "./reducers/siteAlerts";

const mapState = (state: RootState) => ({
    siteAlerts: state.siteAlerts
});

const mapDispatch = {
    siteErrorCloseAction: siteErrorCloseAction,
    siteSuccessCloseAction: siteSuccessCloseAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SiteAlertsBars = (props: Props) => {
    return (
        <div>
            <SiteAlertBar siteAlertState={props.siteAlerts.errorAlert} siteAlertCloseAction={props.siteErrorCloseAction}/>
            <SiteAlertBar siteAlertState={props.siteAlerts.successAlert} siteAlertCloseAction={props.siteSuccessCloseAction}/>
        </div>
    );
};

const SiteAlertBar = ({siteAlertState, siteAlertCloseAction}: {siteAlertState: SiteAlertState | null, siteAlertCloseAction: () => void}) => {
    return (
        <Snackbar open={siteAlertState !== null} autoHideDuration={siteAlertState?.autoHideDuration} onClose={siteAlertCloseAction} key={siteAlertState?.message} anchorOrigin={siteAlertState?.anchorOrigin}>
            <Alert onClose={siteAlertCloseAction} severity={siteAlertState?.severity}>
                {siteAlertState?.message}
            </Alert>
        </Snackbar>
    );
};

export default connector(SiteAlertsBars);
