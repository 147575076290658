import React, { useEffect, useState } from 'react';
import { siteErrorOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import SecondaryAppBar from '../containers/SecondaryAppBar';
import { Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ErrorPage } from '../common/error';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NewTradeDialog, { NewTradeType } from '../NewTradeDialog';
import { InitFetchState } from '../Types';
import { getFetcher } from '../common/fetcher';
import { CenteredCircularProgress } from '../common/common';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ClearerBlotterRawTrades from './ClearerBlotterRawTrades';
import { BlotterHomeRespType, BlotterStateType } from './types';
import ClearerBlotter from './ClearerBlotter';
import { useZusStore } from '../reducers/appStore';

const BLOTTER_TABS = {
    CLEARER: 'CLEARER',
    RAW_TRADES: 'RAW_TRADES',
};

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type BlotterHomeProps = PropsFromRedux & {};

const BlotterHome = ({ siteErrorOpenAction }: BlotterHomeProps) => {
    const [newTradeType, setNewTradeType] = useState<NewTradeType | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { setAllClearerBlotterTrades, clearerBlotterTrades } = useZusStore((state) => ({
        setAllClearerBlotterTrades: state.setAllClearerBlotterTrades,
        clearerBlotterTrades: state.clearerBlotterTrades,
    }));
    const [blotterState, setBlotterState] = React.useState<BlotterStateType>({
        status: InitFetchState.ShouldInitFetch,
    });

    const [tradeIdCopied, setTradeIdCopied] = useState<string>('');

    const [blotterTab, setBlotterTab] = React.useState<string>(BLOTTER_TABS.CLEARER);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setBlotterTab(newValue);
    };

    useEffect(() => {
        if (blotterState.status !== InitFetchState.ShouldInitFetch) {
            return;
        }
        getFetcher<BlotterHomeRespType>({
            url: `/blotter/list`,
            onStart: () => {
                setBlotterState({
                    status: InitFetchState.WaitingInitFetchResponse,
                });
            },
            onSuccess: (res) => {
                setBlotterState({
                    status: InitFetchState.InitFetchCompleted,
                    blotterResponse: res,
                });
                setAllClearerBlotterTrades(res.blotter_real);
            },
            onFail: (err) => {
                siteErrorOpenAction({ message: err.parsedMsg });
                setBlotterState({
                    status: InitFetchState.InitFetchFailed,
                    err: err,
                });
            },
        });
    }, [siteErrorOpenAction, blotterState, setAllClearerBlotterTrades]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeDialog = () => {
        setNewTradeType(null);
    };

    if (blotterState.status === InitFetchState.InitFetchFailed) {
        return <ErrorPage msg={blotterState.err!.parsedMsg} />;
    }

    if (
        blotterState.status === InitFetchState.ShouldInitFetch ||
        blotterState.status === InitFetchState.WaitingInitFetchResponse ||
        clearerBlotterTrades === null
    ) {
        return <CenteredCircularProgress />;
    }

    return (
        <div>
            {/* Add the dense toolbar height */}
            <div className={'MuiToolbar-dense'} />
            <SecondaryAppBar title={'Blotter'} backButtonUrl={null}>
                <span>
                    <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                        Add Trade
                    </Button>
                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem
                            onClick={() => {
                                setNewTradeType(NewTradeType.REGULAR);
                                handleClose();
                            }}>
                            ICE/CME Trade
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setNewTradeType(NewTradeType.INTERNAL);
                                handleClose();
                            }}>
                            Internal
                        </MenuItem>
                    </Menu>
                </span>
            </SecondaryAppBar>
            <Tabs
                style={{ backgroundColor: 'clear' }}
                value={blotterTab}
                onChange={handleTabChange}
                indicatorColor="secondary"
                centered
                textColor="secondary">
                <Tab value={BLOTTER_TABS.CLEARER} label={<span>Clearer Blotter</span>} />
                <Tab value={BLOTTER_TABS.RAW_TRADES} label={<span>Clearer Raw Trades</span>} />
            </Tabs>
            <div>
                {blotterTab === BLOTTER_TABS.CLEARER && (
                    <ClearerBlotter books={blotterState.blotterResponse!.books} brokers={blotterState.blotterResponse!.brokers} />
                )}
                {blotterTab === BLOTTER_TABS.RAW_TRADES && (
                    <ClearerBlotterRawTrades blotterState={blotterState} setTradeIdCopied={setTradeIdCopied} />
                )}
            </div>
            {newTradeType !== null && <NewTradeDialog closeDialog={closeDialog} newTradeType={newTradeType} />}
            <Snackbar
                key={tradeIdCopied}
                open={tradeIdCopied !== ''}
                autoHideDuration={5000}
                onClose={() => {
                    setTradeIdCopied('');
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert elevation={6} severity="info" variant="filled" icon={<FileCopyIcon fontSize="inherit" />}>
                    Trade {tradeIdCopied} copied to clipboard
                </Alert>
            </Snackbar>
        </div>
    );
};

export default connector(BlotterHome);
