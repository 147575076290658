import React from 'react';
import { useDispatch } from 'react-redux';
import { swissUseSelector } from '../../reducers';
import { Button, FormControl, Grid, InputLabel, Select, Tooltip } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import { BackAccountingFiltersState, setBAFiltersAction } from '../../reducers/backAccounting';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateType } from '@date-io/type';
import { clearAccDataAction } from '../../actions/pnlAnalytics';
import { DanaosLastUpdateLabel } from '../../accounting/common/DanaosLastUpdateLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { QuickDate } from '../dataFetcher';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

type Props = {};

export type CompanyMeta = {
    title: string;
    backgroundColor: string;
    textColor: string;
};

const quickDateToUserStr = (quickDate: QuickDate) => `(${quickDate.start_date} - ${quickDate.end_date}) ${quickDate.trade_date}`;
export const quickDateToStr = (quickDate: QuickDate) => `${quickDate.start_date} ${quickDate.end_date} ${quickDate.trade_date}`;

export const FilterDates = (_: Props) => {
    const bAFilterState = swissUseSelector<BackAccountingFiltersState>((state) => state.backAccounting);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ backgroundColor: 'clear', margin: '50px 0px', display: 'flex', justifyContent: 'center' }}>
                <FormControl style={{ margin: 0, marginLeft: '16px', minWidth: '300px' }}>
                    <InputLabel>Quick Dates</InputLabel>
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        value={bAFilterState.quickDateSelection}
                        onChange={(e) => {
                            let strValue = e.target.value as string;
                            if (strValue === '-') {
                                dispatch(setBAFiltersAction({ ...bAFilterState, quickDateSelection: strValue }));
                                return;
                            }
                            let valueArr = strValue.split(' ');
                            let startDate = moment(valueArr[0]).startOf('day');
                            let endDate = moment(valueArr[1]).startOf('day');
                            let tradeDate = moment(valueArr[2]).startOf('day');
                            dispatch(
                                setBAFiltersAction({
                                    ...bAFilterState,
                                    startDate: startDate,
                                    endDate: endDate,
                                    tradeDate: tradeDate,
                                    quickDateSelection: strValue,
                                })
                            );
                        }}>
                        {bAFilterState.quickDateOptions?.map((quickDate) => {
                            return (
                                <MenuItem key={quickDateToStr(quickDate)} value={quickDateToStr(quickDate)}>
                                    {quickDateToUserStr(quickDate)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Tooltip title={'Currently only allowing to use quick dates. If you need more dates let Tech know'} placement={'top'}>
                    <span>
                        <ADate
                            disabled={true}
                            value={bAFilterState.startDate!}
                            changeValue={(startDate) => {
                                dispatch(setBAFiltersAction({ ...bAFilterState, startDate: startDate, quickDateSelection: '-' }));
                            }}
                            label={'Accounting Start Date'}
                        />
                        <ADate
                            disabled={true}
                            value={bAFilterState.endDate!}
                            changeValue={(endDate) => {
                                dispatch(setBAFiltersAction({ ...bAFilterState, endDate: endDate, quickDateSelection: '-' }));
                            }}
                            label={'Accounting End Date'}
                        />
                        <ADate
                            disabled={true}
                            value={bAFilterState.tradeDate!}
                            changeValue={(tradeDate) => {
                                dispatch(setBAFiltersAction({ ...bAFilterState, tradeDate: tradeDate, quickDateSelection: '-' }));
                            }}
                            label={'PNL Date'}
                            validDates={bAFilterState.validTradeDates!}
                        />
                    </span>
                </Tooltip>
                <Button
                    style={{ marginLeft: '50px' }}
                    onClick={() => {
                        dispatch(clearAccDataAction());
                    }}
                    variant="contained"
                    color="primary"
                    size={'small'}>
                    Generate Report
                </Button>
            </Grid>
            <DanaosLastUpdateLabel
                lastSyncDatetime={bAFilterState.lastSyncDatetime!}
                lastEntryDatetime={bAFilterState.lastEntryDatetime!}
            />
            <Grid item xs={12} style={{ backgroundColor: 'clear' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={bAFilterState.includeAdjProj}
                            onChange={(event) => {
                                dispatch(setBAFiltersAction({ ...bAFilterState, includeAdjProj: event.target.checked }));
                                dispatch(clearAccDataAction());
                            }}
                            name="includeAdjProj"
                            color="primary"
                        />
                    }
                    label="Include Adjustments & Projections"
                />
                {/*<Tooltip title={'When selected it will compare YTD PNLs otherwise it compares the entire history PNLs'} placement="top">*/}
                {/*    <FormControlLabel*/}
                {/*        control={*/}
                {/*            <Checkbox*/}
                {/*                checked={bAFilterState.reversePrevYear}*/}
                {/*                onChange={(event) => {*/}
                {/*                    dispatch(setBAFiltersAction({ ...bAFilterState, reversePrevYear: event.target.checked }));*/}
                {/*                    dispatch(clearAccDataAction());*/}
                {/*                }}*/}
                {/*                name="reversePrevYear"*/}
                {/*                color="primary"*/}
                {/*            />*/}
                {/*        }*/}
                {/*        label="YTD PNLs" // "With Previous Year Reverse"*/}
                {/*    />*/}
                {/*</Tooltip>*/}
            </Grid>
        </React.Fragment>
    );
};

type ADataProps = {
    value: Moment;
    changeValue: (newValue: Moment) => void;
    label: string;
    validDates?: Array<string>;
    disabled?: boolean;
};

export function ADate(props: ADataProps) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                shouldDisableDate={
                    props.validDates === undefined
                        ? undefined
                        : (aDate) => {
                              let momentDate = moment(aDate).startOf('day');
                              let dateStr = momentDate.format('YYYY-MM-DD');
                              return !props.validDates!.includes(dateStr);
                          }
                }
                disabled={props.disabled}
                style={{ margin: 0, marginLeft: '16px' }}
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                label={props.label}
                value={props.value}
                autoOk={true}
                allowKeyboardControl={false}
                onAccept={(date: DateType | null) => {
                    if (date === null) return;
                    let momentDate = moment(date).startOf('day');
                    props.changeValue(momentDate);
                }}
                onChange={(_: Date | null, __?: string | null) => {}}
            />
        </MuiPickersUtilsProvider>
    );
}
