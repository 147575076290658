import { AuthUserState } from '../reducers/authUser';
import { getRealUser } from '../common/auth';

export enum MainTabs {
    Overview = 'Summary P&L',
    Admin = 'Admin Cost',
    Vessels = 'Vessels OPEX',
    Cash = 'Cash',
    BankAccounts = 'Bank Accounts',
    BankTransactions = 'Bank Transactions',
}

export const canAccessAccounting = (authUserState: AuthUserState): boolean => {
    let realUser = getRealUser(authUserState);
    return [
        'john.theodorakis@swisschemgas.com',
        'dimitris.theodorakis@swisschemgas.com',
        'eva.panousou@swisschemgas.com',
        'ravraam@swissmarine.gr',
        'emkaragounis@swissmarine.gr',
        'elias.constantinou@swisschemgas.com',
        'anna.constantinou@swisschemgas.com',
        'dimitris.liakakos@swisschemgas.com',
        'filip.theodorakis@swisschemgas.com',
        'skatsikis@swissmarine.gr',
    ].includes(realUser.email);
};

export const canAccessBackAccounting = (authUserState: AuthUserState): boolean => {
    let realUser = getRealUser(authUserState);
    return [
        'john.theodorakis@swisschemgas.com',
        'dimitris.theodorakis@swisschemgas.com',
        'eva.panousou@swisschemgas.com',
        'mstavridi@swissmarine.gr',
        'ravraam@swissmarine.gr',
        'emkaragounis@swissmarine.gr',
        'elias.constantinou@swisschemgas.com',
        'anna.constantinou@swisschemgas.com',
        'dimitris.liakakos@swisschemgas.com',
        'filip.theodorakis@swisschemgas.com',
        'venetia.xenaki@swisschemgas.com',
        'skatsikis@swissmarine.gr',
        'gkoropoulis@swissmarine.gr',
        'ontokatzis@swisschemgas.com',
        'ggiannoulopoulos@swissmarine.gr',
        'ksouliotis@swisschemgas.com',
    ].includes(realUser.email);
};
