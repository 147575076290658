import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';

type PriceFieldProps = {
    value: string;
    handleChange: (newValue: string) => void;
    label: string;
    required: boolean;
    width: number;
    marginLeft: string;
    disabled?: boolean;
    tooltipText?: string;
};

export function priceRangeError(priceValue: string): string {
    let priceErrorText: string = '';
    let parsedVolume = parseInt(priceValue);
    if (isNaN(parsedVolume)) {
        priceErrorText = 'Needs to be a number';
    } else if (parsedVolume < -10000) {
        priceErrorText = 'Must be > -10,000';
    } else if (parsedVolume > 10000) {
        priceErrorText = 'Must be < 10,000';
    }
    return priceErrorText;
}

export default function PriceField(props: PriceFieldProps) {
    const [hasBlurred, setHasBlurred] = React.useState(false);
    let priceErrorText: string = '';
    if (hasBlurred) {
        if (!props.value) {
            if (hasBlurred && props.required) {
                priceErrorText = 'Price is Required';
            }
        } else {
            priceErrorText = priceRangeError(props.value);
        }
    }
    return (
        <Tooltip
            title={props.tooltipText || ''}
            placement={'top'}
            disableHoverListener={!props.disabled}
            disableFocusListener={!props.disabled}>
            <TextField
                disabled={props.disabled}
                required={props.required}
                error={priceErrorText !== ''}
                style={{
                    verticalAlign: 'bottom',
                    minHeight: '5rem',
                    width: props.width,
                    marginLeft: props.marginLeft,
                }}
                label={props.label}
                value={props.value}
                onChange={(event) => {
                    props.handleChange(event.target.value);
                }}
                helperText={priceErrorText}
                name="numberformat"
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                }}
                onBlur={() => {
                    setHasBlurred(true);
                }}
            />
        </Tooltip>
    );
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            inputMode={'text'}
        />
    );
}
