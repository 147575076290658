import React from 'react';
import LineGraph, { ChartType, LineGraphConfig } from '../../containers/LineGraph';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import { fetchConnector } from '../../pnlAnalytics/dataFetcher';
import { FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { kFormatter } from '../../containers/pivotHelper';
import moment from 'moment/moment';
import { TotalBookColor } from '../../common/stylesConsts';

let graphConfig: LineGraphConfig = {
    title: 'SwissChemGas Cash',
    dataKey: 'journal_date',
    oyLabel: '',
    oxLabel: '',
    referenceLines: [{ y: 0, label: '', stroke: 'black' }],
    xAxisFormatter: (d) => moment(d).format('MMM YY'),
    xTooltipFormatter: (d) => moment(d).format('ddd MMM Do YYYY'),
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => kFormatter(v, false, { billions: 2, millions: 1, thousands: 0, hundreds: 0 }),
    },
    yTooltipFormatter: (v) => kFormatter(v, false),
    chartsSettings: [
        {
            chartType: ChartType.AreaAsLine,
            key: '95|?',
            color: '#9e9e9e',
            title: '?',
        },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Audit and Legal Expenses SCG',
            color: '#e07e75',
            title: 'Audit&Legal Exp',
        },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Broker Commissions SCG',
            color: '#f7ac20',
            title: 'Phys Brokers',
        },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|EXDF + Bank',
            color: '#f2cd66',
            title: 'EXDF+Bank',
        },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Financial Expenses',
            color: '#92fc4c',
            title: 'Financial Exp',
        },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Intercompany SCG',
            color: '#3ed1fa',
            title: 'Intercompany',
        },
        { chartType: ChartType.AreaAsLine, key: '95|Interest', color: '#fc3de9', title: 'Interest' },
        { chartType: ChartType.AreaAsLine, key: '95|LC costs SCG', color: '#fc3de9', title: 'LC' },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Naftomar',
            color: '#6a2999',
            title: 'Naftomar',
        },
        { chartType: ChartType.AreaAsLine, key: '95|Office expenses', color: '#f2fc3d', title: 'Office' },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Paper trading SCG-Hedging',
            color: '#3b353a',
            title: 'Paper & Hedging',
        },
        { chartType: ChartType.AreaAsLine, key: '95|Physical LOTS', color: '#3047f2', title: 'LOTs' },
        { chartType: ChartType.AreaAsLine, key: '95|Running', color: '#b4e866', title: 'Running' },
        { chartType: ChartType.AreaAsLine, key: '95|Shipping SCG', color: '#6389ba', title: 'Shipping' },
        {
            chartType: ChartType.AreaAsLine,
            key: '95|Total',
            color: TotalBookColor,
            title: 'Total',
            hiddenOnLoad: true,
        },
    ],
};

type Props = FetchAccAnalyticsPropsFromRedux & {};

const CashBalanceTimelineGraph = (props: Props) => {
    const histVeselCostData = props.pnlAnalytics.accounting_danaos_vessels;

    if (histVeselCostData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={histVeselCostData}
                minHeight={'500px'}
            />
        );
    }

    let respData = histVeselCostData.data! as AccountingDanaosVessels;

    // graphConfig.chartsSettings = respData.cost_sorted_vessel_names.map((companyCode) => {
    //     let companyMapping = companyFixedNamesMap.get(companyCode);
    //     return {
    //         chartType: ChartType.AreaAsLine,
    //         key: `${companyCode}|per_day_amount`,
    //         color: companyMapping!.backgroundColor,
    //         title: companyMapping!.title,
    //     };
    // });

    return <LineGraph {...graphConfig} values={respData.hist_cash_balance} />;
};

export default fetchConnector(CashBalanceTimelineGraph);
