import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import { TableSize, SimpleTableColumnConfig } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatter, redGreenHundred, redOnlyHundred } from '../../containers/pivotHelper';
import { TrendDirection2Size } from '../../common/TrendDirection2';
import { kFormatterAggWithSign } from '../common/common';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';

const CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT = '270px';

const useStyles = makeStyles(() => ({
    headerTotalCell: {
        borderLeft: 'black solid 1px',
    },
    bodyTotalCell: {
        borderLeft: 'black solid 1px',
    },
}));

const allColumns: Array<SimpleTableColumnConfig> = [
    { name: 'book', title: 'Book' },
    { name: 'strategy', title: 'Strategy' },
    { name: 'deal', title: 'Deal' },
    {
        name: 'combined_net|expired',
        title: 'Bank Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: '#d9e1fd',
    },
    {
        name: 'delta_combined_net|expired',
        title: 'Bank DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#d9e1fd',
    },
    {
        name: 'combined_net|prompt',
        title: 'Prompt Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: '#e3ffef',
    },
    {
        name: 'delta_combined_net|prompt',
        title: 'Prompt DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#e3ffef',
    },
    {
        name: 'combined_net|deferred',
        title: 'Deferred Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
        headerBackgroundColor: '#fff9ce',
    },
    {
        name: 'delta_combined_net|deferred',
        title: 'Deferred DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
        headerBackgroundColor: '#fff9ce',
    },
    {
        name: 'combined_net|total',
        title: 'Total Net',
        bodyTemplate: kFormatter,
        textColor: redOnlyHundred,
    },
    {
        name: 'delta_combined_net|total',
        title: 'Total DtD Net',
        bodyTemplate: kFormatter,
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
    },
];

const config: ExpandableTableConfig = {
    uniqueTableName: UniqueTableName.CashEqTable,
    title: 'Cash Equivalent PNL',
    size: TableSize.MediumSmall,
    columns: allColumns,
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 2,
    defaultExpandable: 0,
    stickyHeader: false,
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const ExpiredLiveTable = (_: Props) => {
    return <ExpiredLiveTableHelperWrapper />;
};

const ExpiredLiveTableHelper = (props: Props) => {
    const classes = useStyles();
    const allTradesRunDateData = props.pnlAnalytics.all_trades_run_date;

    if (allTradesRunDateData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.ClearerRunDate}
                data={allTradesRunDateData}
                minHeight={CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT}
            />
        );
    }

    let respData = allTradesRunDateData.data! as AllTradesRunDateDataResp;
    if (respData.expired_prompt_ltd_table.length === 0) {
        return (
            <Paper style={{ padding: '10px', textAlign: 'center' }}>
                <h3>No Expired/Live for this date</h3>
            </Paper>
        );
    }

    config.columns[config.columns.length - 2].headerCellClassName = classes.headerTotalCell;
    config.columns[config.columns.length - 2].bodyCellsClassName = classes.bodyTotalCell;

    return <ExpandableTable data={respData.cash_eq_pnl_table} config={config} />;
};

const ExpiredLiveTableHelperWrapper = fetchConnector(ExpiredLiveTableHelper);

export default fetchConnector(ExpiredLiveTable);
