import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { ClearerRunDateDataResp, PNLMetaDataResp, PNLRunTypes3 } from '../fetchTypes';
import { SimpleTableConfig, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatter } from '../../containers/pivotHelper';
import TrendDirection2, { TrendDirection2Config, TrendDirection2Size } from '../../common/TrendDirection2';
import SimpleTable from '../../tables/SimpleTable';
import { SwissColors } from '../../common/stylesConsts';
import { kFormatterAggWithSign } from '../common/common';
import { Paper } from '@material-ui/core';

const CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT = '270px';

export const shouldHaveEDFBalances = (runType: PNLRunTypes3) =>
    runType !== PNLRunTypes3.INTRADAY && runType !== PNLRunTypes3.SCENARIO;

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.EDFBalanceSummary,
    title: '',
    size: TableSize.Medium,
    columns: [
        { name: 'account_type', title: 'Type' },
        {
            name: 'today',
            title: 'Today',
            bodyTemplate: (num: number): string => {
                return kFormatter(num, false);
            },
        },
        {
            name: 'dtd',
            title: 'DtD',
            customDisplay: (row, rawVal) => {
                let textValue = kFormatter(rawVal);
                let color: null | string = null;
                // Default
                if (rawVal < 100) {
                    color = SwissColors.Red;
                } else if (rawVal > 100) {
                    color = SwissColors.Green;
                }
                let trend: TrendDirection2Config = {
                    downIsBad: true,
                    size: TrendDirection2Size.Small,
                    zeroThreshold: 100,
                };
                if (row['account_type'] === 'IMR') {
                    // When we switched to Marex we switched all out IMRs to be negative so now down is bad
                    trend.downIsBad = true;
                    color = rawVal > 0 ? SwissColors.Green : SwissColors.Red;
                }
                let customStyles: any = {
                    color: color,
                };
                return (
                    <div style={customStyles}>
                        <TrendDirection2 {...trend} val={rawVal as number} />
                        <span>{textValue}</span>
                    </div>
                );
            },
        },
    ],
    grandTotal: false,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const ClearerBalanceTable = (props: Props) => {
    let metaData = props.pnlAnalytics.meta.data as PNLMetaDataResp;
    if (!shouldHaveEDFBalances(metaData.selected.run_type)) {
        return (
            <Paper style={{ padding: '10px' }}>
                <h3>No Clearer data for {props.pnlAnalytics.meta.data.selected.run_type} PNL</h3>
            </Paper>
        );
    }
    if (metaData.is_day_with_no_clearer_statements) {
        return (
            <Paper style={{ padding: '10px' }}>
                <h3>
                    No Clearer data exists as {metaData.selected.pipe_run_date} is marked as a day with no clearer
                    statements
                </h3>
            </Paper>
        );
    }
    return <ClearerBalanceTableHelperWrapper />;
};

const ClearerBalanceTableHelper = (props: Props) => {
    const clearerRunDateData = props.pnlAnalytics.clearer_run_date;
    const metaData = props.pnlAnalytics.meta.data as PNLMetaDataResp;

    if (clearerRunDateData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.ClearerRunDate}
                data={clearerRunDateData}
                minHeight={CLEARER_BALANCE_TABLE_PLACEHOLDER_HEIGHT}
            />
        );
    }

    let respData = clearerRunDateData.data! as ClearerRunDateDataResp;

    if (respData.clearer_balance_table_run_date !== metaData.selected.pipe_run_date) {
        return (
            <Paper style={{ padding: '10px' }}>
                <h3>
                    Latest Clearing Date and display date don't match so not showing Clearing Balance to avoid confusion
                </h3>
            </Paper>
        );
    }

    let clearer_name = respData.clearer_name;
    if (clearer_name === 'MAREX') clearer_name = 'Marex';
    config.title = `${clearer_name} Balance`;

    return <SimpleTable data={respData.clearer_balance_table} config={config} />;
};

const ClearerBalanceTableHelperWrapper = fetchConnector(ClearerBalanceTableHelper);

export default fetchConnector(ClearerBalanceTable);
