import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { exposuresNumFormatter } from '../../common/exposures';
import { redOnly } from '../../containers/pivotHelper';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonReadyResponses } from '../PaperPlaceHolder';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { productMap } from '../../common/products';
import { MIN_12_12_EL_WIDTH } from '../../common/common';
import { LookAheadState } from '../common/LookAheadSelectCompose';

type Props = FetchPNLAnalyticsPropsFromRedux & {
    lookAheadState: LookAheadState;
};

const useStyles = makeStyles(() => ({
    productCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '60px !important',
        fontSize: '12px !important',
    },
    strategyCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '95px !important',
        fontSize: '12px !important',
    },
    dealCell: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '80px !important',
        fontSize: '12px !important',
    },
    termDatesHeaderCells: {
        fontSize: '15px !important',
    },
    totalRow: {
        fontWeight: 'bold',
    },
}));

type PerBookConfig = {
    uniqueTableName: UniqueTableName;
    title: string;
    tableDataKey: string;
    defaultHeight: string;
};

const perBooksConfigs: Array<PerBookConfig> = [
    {
        uniqueTableName: UniqueTableName.CorpPaperExpByMonth,
        title: 'Corp Paper Exposure By Month',
        tableDataKey: 'corpPaperByMonthFiltered',
        defaultHeight: '815px',
    },
    {
        uniqueTableName: UniqueTableName.PhysPaperExpByMonth,
        title: 'Phys Paper Exposure By Month',
        tableDataKey: 'physPaperByMonthFiltered',
        defaultHeight: '700px',
    },
];

const pivotColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'product',
        title: 'Product',
        bodyBackgroundColor: (row, rawValue) => {
            let productMeta = productMap.get(rawValue);
            if (!productMeta) return '';
            return productMeta.backgroundColor;
        },
        textColor: (rawValue) => {
            let productMeta = productMap.get(rawValue);
            if (!productMeta) return null;
            return productMeta.textColor;
        },
    },
    { 
        name: 'strategy',
        title: 'Strategy',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['strategy']}</div>;
        }
    },
    { 
        name: 'deal',
        title: 'Deal',
        hoverElement: (row: SimpleTableRow, val: any) => {
            return <div>{row['deal']}</div>;
        }
    },
];

type PerBookExpProps = FetchPNLAnalyticsPropsFromRedux & {
    perBookConfig: PerBookConfig;
    lookAheadState: LookAheadState;
};

const PerBookExpComp = (props: PerBookExpProps) => {
    const classes = useStyles();
    const exposureData = props.pnlAnalytics.exposures;

    // We might have a completed state but the filter is not yet read
    let status = props.lookAheadState.dataReceived ? exposureData.status : PNLFetchStatus.LOADING;
    if (status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonReadyResponses
                status={status}
                endpoint={PNLAnalyticsEndpoint.Exposures}
                data={exposureData}
                minHeight={props.perBookConfig.defaultHeight}
            />
        );
    }

    let date_columns: Array<SimpleTableColumnConfig> = props.lookAheadState.transposedProductsTermMonths.map((term_month_date) => {
        return {
            name: term_month_date,
            title: moment(term_month_date).format('MMM YY'),
            bodyTemplate: exposuresNumFormatter,
            headerCellClassName: classes.termDatesHeaderCells,
            textColor: redOnly,
        };
    });
    // Make sure that product and header cells are aligned so that paper and corp tables are aligned
    pivotColumns[0].headerCellClassName = classes.productCell;
    pivotColumns[0].bodyCellsClassName = classes.productCell;
    pivotColumns[1].headerCellClassName = classes.strategyCell;
    pivotColumns[1].bodyCellsClassName = classes.strategyCell;
    pivotColumns[2].headerCellClassName = classes.dealCell;
    pivotColumns[2].bodyCellsClassName = classes.dealCell;

    let config: ExpandableTableConfig = {
        uniqueTableName: props.perBookConfig.uniqueTableName,
        title: props.perBookConfig.title,
        size: TableSize.Small,
        columns: pivotColumns.concat(date_columns),
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        customRowClassName: (row) => {
            let key: string = row['_key'] as string;
            if (!key.endsWith('|Total')) return '';
            return classes.totalRow;
        },
        pivotDepth: 2,
        defaultExpandable: 1,
        stickyHeader: true,
    };

    return (
        <Grid item xs={12} style={{ backgroundColor: 'clear', minWidth: MIN_12_12_EL_WIDTH }}>
            <ExpandableTable
                data={props.lookAheadState[props.perBookConfig.tableDataKey as keyof LookAheadState] as Array<any>}
                config={config}
            />
        </Grid>
    );
};

const PerBookExp = fetchConnector(PerBookExpComp);

const PaperExpByMonth = (props: Props) => {
    return (
        <React.Fragment>
            {perBooksConfigs.map((perBookConfig) => {
                return <PerBookExp key={perBookConfig.tableDataKey} perBookConfig={perBookConfig} lookAheadState={props.lookAheadState} />;
            })}
        </React.Fragment>
    );
};

export default fetchConnector(PaperExpByMonth);
