import { RootState } from '../reducers';
import { connect, ConnectedProps } from 'react-redux';
import {
    clearAllDataAction,
    fetchCompletedAction,
    FetchCompletedActionType,
    FetchCompletedPayloadType,
    fetchFailedAction,
    FetchFailedActionType,
    FetchFailedPayloadType,
    fetchLoadingAction,
    FetchLoadingActionType,
    FetchLoadingPayloadType,
    PNLAnalyticsEndpoint,
    PNLFetchStatus,
} from '../actions/pnlAnalytics';
import { getFetcher } from '../common/fetcher';
import { PNLAnalyticsState } from '../reducers/pnlAnalytics';
import { AuthUserState } from '../reducers/authUser';
import { SiteAlertOpenActionType, SiteAlertOpenPayloadType, siteErrorOpenAction } from '../actions/siteAlerts';
import { swissAssert } from '../common/swissAssert';
import { getAuthUser } from '../common/auth';

const mapState = (state: RootState) => ({
    pnlAnalytics: state.pnlAnalytics,
    authUser: state.authUser,
});

const mapDispatch = {
    fetchLoadingAction: fetchLoadingAction,
    fetchCompletedAction: fetchCompletedAction,
    fetchFailedAction: fetchFailedAction,
    siteErrorOpenAction: siteErrorOpenAction,
    clearAllDataAction: clearAllDataAction,
};

export const fetchConnector = connect(mapState, mapDispatch);
export type FetchPNLAnalyticsPropsFromRedux = ConnectedProps<typeof fetchConnector>;

const endpointMap = new Map<PNLAnalyticsEndpoint, string>();
endpointMap.set(PNLAnalyticsEndpoint.Meta, '/pnl_analytics/meta');
endpointMap.set(PNLAnalyticsEndpoint.AllTradesRunDate, '/pnl_analytics/all_trades_run_date');
endpointMap.set(PNLAnalyticsEndpoint.ClearerRunDate, '/pnl_analytics/clearer_run_date');
endpointMap.set(PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics, '/pnl_analytics/historical_pnl_per_book_metrics');
endpointMap.set(PNLAnalyticsEndpoint.OpenPnlLastYear, '/pnl_analytics/open_pnl_last_year');
endpointMap.set(PNLAnalyticsEndpoint.Exposures, '/pnl_analytics/exposures');
endpointMap.set(PNLAnalyticsEndpoint.BackOfficeMisc, '/pnl_analytics/back_office_misc');
endpointMap.set(PNLAnalyticsEndpoint.AccountingMeta, '/acc_analytics/meta');
endpointMap.set(PNLAnalyticsEndpoint.AccountingDanaosVessels, '/acc_analytics/danaos/vessels');
endpointMap.set(PNLAnalyticsEndpoint.BAccountingMeta, '/acc_analytics/back_meta');
endpointMap.set(PNLAnalyticsEndpoint.BAccountingSCGPhysical, '/acc_analytics/report/scg_physical');
endpointMap.set(PNLAnalyticsEndpoint.BACashPlanning, '/acc_analytics/report/cash_planning');

export type PrepareDataFakeProps = {
    endpoint: PNLAnalyticsEndpoint;
    urlPostFix?: string;
    authUser: AuthUserState;
    pnlAnalytics: PNLAnalyticsState;
    fetchLoadingAction: (payload: FetchLoadingPayloadType) => FetchLoadingActionType;
    fetchCompletedAction: (payload: FetchCompletedPayloadType) => FetchCompletedActionType;
    fetchFailedAction: (payload: FetchFailedPayloadType) => FetchFailedActionType;
    siteErrorOpenAction: (payload: SiteAlertOpenPayloadType) => SiteAlertOpenActionType;
    onSuccess?: (data: any) => void;
    params?: any;
};

export const prepareData = (props: PrepareDataFakeProps) => {
    const {
        endpoint,
        authUser,
        pnlAnalytics,
        fetchLoadingAction,
        fetchCompletedAction,
        fetchFailedAction,
        siteErrorOpenAction,
        onSuccess,
        params,
    } = props;
    let endpointState = pnlAnalytics[endpoint];
    if (endpointState.status !== PNLFetchStatus.INIT) {
        // If we have completed the data call onSuccess so we trigger any change from default data
        // For example we will display the lookahead data and allow for look ahead selection
        if (endpointState.status === PNLFetchStatus.COMPLETED && onSuccess) onSuccess(endpointState.data);
        return;
    }
    swissAssert(endpointMap.get(endpoint) !== undefined, 'We do not have an endpoint url match');
    let url = endpointMap.get(endpoint)!;
    if (props.urlPostFix !== undefined) url += props.urlPostFix;
    getFetcher<any>({
        url: url,
        params: params,
        headers: {
            'Swiss-Tech-Auth-Token': getAuthUser(authUser).swiss_token,
        },
        onStart: (cancelRequest) => {
            fetchLoadingAction({ endpoint: endpoint, cancelRequest: cancelRequest });
        },
        onSuccess: (res) => {
            if (onSuccess) onSuccess(res);
            fetchCompletedAction({ endpoint: endpoint, data: res });
        },
        onFail: (err) => {
            siteErrorOpenAction({ message: `Failed to fetch PNL Analytics ${endpoint} route: ${err.parsedMsg}` });
            fetchFailedAction({ endpoint: endpoint, err: err });
        },
        onFinal: () => {
            // Leave it empty as when we change route the component will no longer be mounted
        },
    });
};
