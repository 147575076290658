import React, { ReactChild } from 'react';
import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

type Props = {
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
    marginLeft?: string;
    buttonProps?: ButtonProps;
    children: ReactChild;
};

const ButtonSpinner = ({ onClick, disabled, loading, marginLeft, buttonProps, children }: Props) => {
    return (
        <Button style={{marginLeft: marginLeft}} disabled={disabled || loading} variant="contained" color="primary" onClick={onClick} {...buttonProps}>
            {loading && (
                <CircularProgress
                    size={24}
                    style={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                    }}
                />
            )}
            {children}
        </Button>
    );
};
export default ButtonSpinner;
