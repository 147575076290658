import React from 'react';
import { BooksStrategiesType, FieldType, TradeOptions } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';

interface BookStrategyFieldProps {
    marginLeft: string;
    options: TradeOptions;
    disabled: boolean;
    book: FieldType<string>;
    bookLabel: string;
    strategy: FieldType<string>;
    strategyLabel: string;
    setBookStrategy: (newBook: FieldType<string>, newStrategy: FieldType<string>) => void;
}

export function bookAndStrategyOptions(currentBook: string, books: Array<BooksStrategiesType>): Array<Array<string>> {
    const books_options: Array<string> = [];
    const strategies_options: Array<string> = [];
    books.forEach((book) => {
        if (book.name === 'Naftomar') return;
        books_options.push(book.name);
        if (currentBook === book.name && book.strategies) {
            strategies_options.push(...Object.keys(book.strategies));
        }
    });
    return [books_options, strategies_options];
}

export function getDealOptions(currentStrategy: string, strategies?: Record<string, string[]>): string[] {
    return strategies?.[currentStrategy] ? strategies[currentStrategy] : [];
}

export function BookStrategyField(props: BookStrategyFieldProps) {
    const { book, strategy, options } = props;

    let [bookOptions, strategyOptions] = bookAndStrategyOptions(book.value, options.books);

    return (
        <span>
            <AutoCompleteField
                options={bookOptions}
                inputValue={book.value}
                handleChange={(newValue) => {
                    let bookSummary = <span>{newValue}</span>;
                    props.setBookStrategy(
                        {
                            value: newValue,
                            error: book.error,
                            required: book.required,
                            summary: bookSummary,
                        },
                        { value: '', error: '', required: strategy.required, summary: '' }
                    );
                }}
                handlePartialChange={(partialValue) => {
                    props.setBookStrategy(
                        {
                            value: partialValue,
                            error: '',
                            summary: '',
                            required: book.required,
                        },
                        strategy
                    );
                }}
                required={book.required}
                label={props.bookLabel}
                width={140}
                marginLeft={props.marginLeft}
                disabled={props.disabled}
            />
            <AutoCompleteField
                options={strategyOptions}
                inputValue={strategy.value}
                handleChange={(newValue: string) => {
                    let strategySummary = <span>/{newValue}</span>;
                    props.setBookStrategy(book, {
                        value: newValue,
                        error: '',
                        summary: strategySummary,
                        required: strategy.required,
                    });
                }}
                required={strategy.required}
                label={props.strategyLabel}
                width={180}
                marginLeft={'16px'}
                disabled={props.disabled}
            />
        </span>
    );
}
