export enum UniqueTableName {
    Overview = 'Overview',
    EDFBalanceSummary = 'EDFBalanceSummary',
    ExpiredLiveTable = 'ExpiredLiveTable',
    CashEqTable = 'CashEqTable',
    MPerStrategyPhysSummary = 'MPerStrategyPhysSummary',
    PerStrategyCorpSummary = 'PerStrategyCorpSummary',
    PhysSummaryPNL = 'PhysSummaryPNL',
    CorpSummaryPNL = 'CorpSummaryPNL',
    ExpiredYearBookStrategyTable = 'ExpiredYearBookStrategyTable',
    ExpiredYearKindCategoryTable = 'ExpiredYearKindCategoryTable',
    LiveBookStrategyTable = 'LiveBookStrategyTable',
    LiveKindCategoryTable = 'LiveKindCategoryTable',
    //
    ExpPro = 'ExpPro',
    ExpCorp = 'ExpCorp',
    ExpPhys = 'ExpPhys',
    ExpShip = 'ExpShip',
    ExpFB = 'ExpFB',
    ExpTotal = 'ExpTotal',
    ExpDetailPhysBookPerProductKind = 'expDetailPhysBookPerProductKind',
    ExpDetailPerBook = 'ExpDetailPerBook',
    ExpDetailPerBookWithDeals = 'ExpDetailPerBookWithDeals',
    ExpDtd = 'ExpDtd',
    PricesDtD = 'PricesDtD',
    TodaysMtMPrices = 'TodaysMtMPrices',
    PnLDtD = 'PnLDtD',
    PTPerStrategyPhysSummary = 'PTPerStrategyPhysSummary',
    ExpDetailTotal = 'ExpDetailTotal',
    ExpPerProduct = 'ExposuresPerProduct',
    CorpPaperExpByMonth = 'CorpPaperExpByMonth',
    PhysPaperExpByMonth = 'PhysPaperExpByMonth',
    SpreadsByStructure = 'SpreadsByStructure',
    PnlBookMetrics = 'PnlBookMetrics',
    AccVesselComp = 'AccVesselComp',
    AccShippingPNL = 'AccShippingPNL',
    AccAdminCardComp = 'AccAdminCardComp',
    AccMgmtSummary = 'AccMgmtSummary',
    AccInvestmentCardComp = 'AccInvestmentCardComp',
    AdjPNL = 'AdjPNL',
    SCGPhysRecFlat = 'SCGPhysRecFlat',
    LTDLotsReconcileClosed = 'LTDLotsReconcileClosed',
    LTDLotsReconcileOngoing = 'LTDLotsReconcileOngoing',
    LTDLotsReconcileOutside = 'LTDLotsReconcileOutside',
    YTDLotsReconcile = 'YTDLotsReconcile',
    SCGPhysRecSummary = 'SCGPhysRecSummary',
    TradersAccMgmtReport = 'TradersAccMgmtReport',
    PastProductsReconTable = 'PastProductsReconTable',
    AdminRunningCost = 'AdminRunningCost',
    YearlyVesselRunningCost = 'YearlyVesselRunningCost',
    DailyVesselRunningCost = 'DailyVesselRunningCost',
    CashBankBalances = 'CashBankBalances',
    PaymentsAndIC = 'PaymentsAndIC',
    CashRecGroups = 'CashRecGroups',
    SCGOurCashMoves = 'SCGOurCashMoves',
    // Edit Lot Reconciliation
    TradersLTDTrades = 'TradersLTDTrades',
    TradersPrevYearLTDTrades = 'TradersPrevYearLTDTrades',
    DanaosLTDRecords = 'DanaosLTDRecords',
    DanaosYTDRecords = 'DanaosYTDRecords',
    BankAccountsTable = 'BankAccountsTable',
    BankBalancesTable = 'BankBalancesTable',
    BankTransactionsTable = 'BankTransactionsTable',
    BACashPlanningWoW = 'BACashPlanningWoW',
    BACashPlanningDanaosTransactions = 'BACashPlanningDanaosTransactions',
    SCGCashMovesTable = 'SCGCashMovesTable',
    BASCGCashMovesDanaosEntries = 'BASCGCashMovesDanaosEntries',
}
