import { AccQuickDate } from '../backAccounting/dataFetcher';

export const SET_FILTERS = 'SET_FILTERS';

export interface SetFiltersPayloadType {
    quickDateSelection: null | AccQuickDate;
    quickDateOptions: null | Array<AccQuickDate>;

    lastEntryDatetime: null | string;
    lastSyncDatetime: null | string;

    hideMinorAdminCompanies: boolean;
    displayCompare: boolean;
    compareWithBudget: boolean;
    showFractions: boolean;
}

export interface SetFiltersActionType {
    type: typeof SET_FILTERS;
    payload: SetFiltersPayloadType;
}

export const setFiltersAction = (payload: SetFiltersPayloadType): SetFiltersActionType => ({
    type: SET_FILTERS,
    payload: payload,
});

export type SetFiltersActionTypes = SetFiltersActionType;
