import { SET_FILTERS, SetFiltersActionTypes, SetFiltersPayloadType } from '../actions/accounting';

const initialState: SetFiltersPayloadType = {
    lastEntryDatetime: null,
    lastSyncDatetime: null,

    quickDateSelection: null,
    quickDateOptions: null,

    hideMinorAdminCompanies: true,
    displayCompare: true,
    compareWithBudget: false,
    showFractions: false,
};

const accounting = (state = initialState, action: SetFiltersActionTypes): SetFiltersPayloadType => {
    switch (action.type) {
        case SET_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default accounting;
