import React, { useEffect, useState } from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { ExposuresDataResp, PNLMetaDataResp } from '../fetchTypes';
import { Grid, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { swissAssert } from '../../common/swissAssert';

const defaultLookAhead = '0';

type ExposuresTablesGraphsTopProps = FetchPNLAnalyticsPropsFromRedux & {
    children: any;
};

export type LookAheadState = {
    dataReceived: boolean;
    lookAheadVal: string;
    productFilteredData: Array<any>;
    corpFilteredData: Array<any>;
    physicalFilteredData: Array<any>;
    forwardContractFilteredData: Array<any>;
    shippingFilteredData: Array<any>;
    scgFilteredData: Array<any>;
    productFilteredGraphData: Array<any>;
    physicalFilteredGraphData: Array<any>;
    corpFilteredGraphData: Array<any>;
    forwardContractFilteredGraphData: Array<any>;
    shippingFilteredGraphData: Array<any>;
    scgFilteredGraphData: Array<any>;
    transposedProductsTermMonths: Array<string>;
    corpPaperByMonthFiltered: Array<any>;
    physPaperByMonthFiltered: Array<any>;
};

const LookAheadSelectCompose = (props: ExposuresTablesGraphsTopProps) => {
    const processLookAheadChange = (myData: ExposuresDataResp, lookAhead: string, dataReceived: boolean): LookAheadState => {
        const productFilteredData = myData.exposures_look_ahead_product_table_graph.filter((el) => el.look_ahead === lookAhead);
        const corpFilteredData = myData.exposures_look_ahead_corp_table_graph.filter((el) => el.look_ahead === lookAhead);
        const physicalFilteredData = myData.exposures_look_ahead_phys_table_graph.filter((el) => el.look_ahead === lookAhead);
        const forwardContractFilteredData = myData.exposures_look_ahead_forward_contract_table_graph.filter(
            (el) => el.look_ahead === lookAhead
        );
        const shippingFilteredData = myData.exposures_look_ahead_shipping_table_graph.filter((el) => el.look_ahead === lookAhead);
        const scgFilteredData = myData.exposures_look_ahead_scg_table_graph.filter((el) => el.look_ahead === lookAhead);
        const corpPaperByMonthFiltered = myData.corp_paper_by_month.filter((el) => el.look_ahead === lookAhead);
        const physPaperByMonthFiltered = myData.physical_paper_by_month.filter((el) => el.look_ahead === lookAhead);
        return {
            dataReceived: dataReceived,
            lookAheadVal: lookAhead,
            productFilteredData: productFilteredData,
            corpFilteredData: corpFilteredData,
            physicalFilteredData: physicalFilteredData,
            forwardContractFilteredData: forwardContractFilteredData,
            shippingFilteredData: shippingFilteredData,
            scgFilteredData: scgFilteredData,
            productFilteredGraphData: productFilteredData.filter((el) => el.term_month_date !== 'Total'),
            physicalFilteredGraphData: physicalFilteredData.filter((el) => el.term_month_date !== 'Total'),
            corpFilteredGraphData: corpFilteredData.filter((el) => el.term_month_date !== 'Total'),
            forwardContractFilteredGraphData: forwardContractFilteredData.filter((el) => el.term_month_date !== 'Total'),
            shippingFilteredGraphData: shippingFilteredData.filter((el) => el.term_month_date !== 'Total'),
            scgFilteredGraphData: scgFilteredData.filter((el) => el.term_month_date !== 'Total'),
            transposedProductsTermMonths: myData.transposed_products_term_months,
            corpPaperByMonthFiltered,
            physPaperByMonthFiltered,
        };
    };
    const metaData = props.pnlAnalytics.meta.data! as PNLMetaDataResp;
    const exposureData = props.pnlAnalytics.exposures.data as ExposuresDataResp | undefined;
    const [lookAheadState, setLookAheadState] = useState<LookAheadState>({
        dataReceived: false,
        lookAheadVal: defaultLookAhead,
        productFilteredData: [],
        corpFilteredData: [],
        physicalFilteredData: [],
        forwardContractFilteredData: [],
        shippingFilteredData: [],
        scgFilteredData: [],
        productFilteredGraphData: [],
        physicalFilteredGraphData: [],
        corpFilteredGraphData: [],
        forwardContractFilteredGraphData: [],
        shippingFilteredGraphData: [],
        scgFilteredGraphData: [],
        transposedProductsTermMonths: [],
        corpPaperByMonthFiltered: [],
        physPaperByMonthFiltered: [],
    });
    useEffect(() => {
        // Terrible Hack. I hate it
        // So the problem is that we removed on January 13th 2021 all the useEffect/prepareData from functions
        // and put them in the index only to avoid multiple concurrent requests. However here we use it
        // as a way to set the look ahead data with the look ahead filters so we could not remove it
        // to avoid the request to be triggered we wait until the status has completed and then onsuccess will simply
        // be called
        // Ideally we should be using some sort of middleware that will deal with fetching data/redux/components
        if (props.pnlAnalytics[PNLAnalyticsEndpoint.Exposures].status === PNLFetchStatus.COMPLETED) {
            prepareData({
                ...props,
                endpoint: PNLAnalyticsEndpoint.Exposures,
                urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
                onSuccess: (data: any) => {
                    // We need to update our state now that we got data so we display the correct look ahead data
                    let myData = data as ExposuresDataResp;
                    setLookAheadState(processLookAheadChange(myData, lookAheadState.lookAheadVal, true));
                },
            });
        }
    }, [props, metaData.selected.pipe_run_date, lookAheadState.lookAheadVal]);

    const selectLookAhead = (e: any) => {
        let newLookAheadVal: string = e.target.value;
        swissAssert(exposureData !== undefined, 'Cannot select look ahead with no exposure data');
        setLookAheadState(processLookAheadChange(exposureData!, newLookAheadVal, true));
    };

    return (
        <React.Fragment>
            <Grid item xs={12} style={{ minWidth: '100px', textAlign: 'center', marginTop: '20px' }}>
                <InputLabel>Look ahead Days</InputLabel>
                <Select
                    MenuProps={{ style: { zIndex: 10000 } }}
                    disabled={!lookAheadState.dataReceived}
                    style={{ minWidth: '100px' }}
                    value={lookAheadState.lookAheadVal}
                    onChange={selectLookAhead}>
                    {metaData.look_aheads.map((lookAheadOption) => {
                        return (
                            <MenuItem key={lookAheadOption} value={lookAheadOption}>
                                {lookAheadOption}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            {props.children(lookAheadState)}
        </React.Fragment>
    );
};

export default fetchConnector(LookAheadSelectCompose);
