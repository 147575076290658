import { SimpleTableRow } from '../../tables/types';
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { dollarVal2D, kFormatter, kFormatterWithParenthesis } from '../../containers/pivotHelper';
import TrendDirection2, { TrendDirection2Size } from '../../common/TrendDirection2';
import { SetFiltersPayloadType } from '../../actions/accounting';

export const CellComp = (
    filters: SetFiltersPayloadType,
    vesselName: string,
    row: SimpleTableRow,
    rawVal: any,
    comparisonFontSize: string,
    reportValFormatter: (val: number) => string = kFormatterWithParenthesis,
    moveValFormatter: (val: number) => string = kFormatter
) => {
    if (rawVal === undefined || rawVal === null) return <span>{''}</span>;
    let report = row[`${vesselName}|amount_report`] as number;
    let compare = row[`${vesselName}|amount_compare`] as number;
    let move = row[`${vesselName}|move`] as number;
    let valueFormatter = filters.showFractions ? dollarVal2D : reportValFormatter;
    let valueStyle: any = { display: 'inline-block' };
    // Is needed to ensure that when we show fractions we don't have overlapping numbers
    valueStyle['width'] = filters.showFractions ? '60px' : '50px';
    let hasCompare = row['row_category'] !== 'Cash';
    return (
        <Tooltip
            placement="top"
            title={
                <React.Fragment>
                    <p>
                        Report for: <strong>{dollarVal2D(report)}</strong>
                    </p>
                    {hasCompare && (
                        <React.Fragment>
                            <p>
                                Compare with: <strong>{dollarVal2D(compare)}</strong>
                            </p>
                            <p>
                                Change: <strong>{dollarVal2D(move)}</strong>
                            </p>
                            <p>
                                % Change: <strong>{Math.round((move / compare) * 100)}%</strong>
                            </p>
                        </React.Fragment>
                    )}
                </React.Fragment>
            }>
            <span style={{ width: '90px' }}>
                <span style={valueStyle}>{valueFormatter(report)}</span>{' '}
                {filters.displayCompare && hasCompare && (
                    <span style={{ marginLeft: '10px', width: '50px', display: 'inline-block' }}>
                        <TrendDirection2 downIsBad={true} size={TrendDirection2Size.Tiny} zeroThreshold={100} val={move} />
                        <span style={{ fontSize: comparisonFontSize }}>{moveValFormatter(move)}</span>
                    </span>
                )}
            </span>
        </Tooltip>
    );
};
