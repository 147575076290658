import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../../dataFetcher';
import { Grid } from '@material-ui/core';
import { UniqueTableName } from '../../../common/uniqueTableName';
import { MIN_6_12_EL_WIDTH } from '../../../common/common';
import { LookAheadState } from '../LookAheadSelectCompose';
import Table from './Table';
import Graph from './Graph';

type PerBookConfig = {
    uniqueTableName: UniqueTableName;
    title: string;
    tableDataKey: string;
    graphDataKey: string;
    defaultTableHeight: string;
};

const perBooksConfigs: Array<PerBookConfig> = [
    {
        uniqueTableName: UniqueTableName.ExpCorp,
        title: 'Corp Book',
        tableDataKey: 'corpFilteredData',
        graphDataKey: 'corpFilteredGraphData',
        defaultTableHeight: '602px',
    },
    {
        uniqueTableName: UniqueTableName.ExpPro,
        title: 'Product Book',
        tableDataKey: 'productFilteredData',
        graphDataKey: 'productFilteredGraphData',
        defaultTableHeight: '602px',
    },
    {
        uniqueTableName: UniqueTableName.ExpShip,
        title: 'Shipping Book',
        tableDataKey: 'shippingFilteredData',
        graphDataKey: 'shippingFilteredGraphData',
        defaultTableHeight: '602px',
    },
    {
        uniqueTableName: UniqueTableName.ExpFB,
        title: 'Forward Contract Book',
        tableDataKey: 'forwardContractFilteredData',
        graphDataKey: 'forwardContractFilteredGraphData',
        defaultTableHeight: '602px',
    },
    {
        uniqueTableName: UniqueTableName.ExpPhys,
        title: 'Total Physical Books',
        tableDataKey: 'physicalFilteredData',
        graphDataKey: 'physicalFilteredGraphData',
        defaultTableHeight: '602px',
    },
    {
        uniqueTableName: UniqueTableName.ExpTotal,
        title: 'Total SwissChemGas',
        tableDataKey: 'scgFilteredData',
        graphDataKey: 'scgFilteredGraphData',
        defaultTableHeight: '602px',
    },
];

type PerBookExpProps = FetchPNLAnalyticsPropsFromRedux & {
    lookAheadState: LookAheadState;
};

const PerBookExp = (props: PerBookExpProps) => {
    return (
        <React.Fragment>
            {perBooksConfigs.map((perBookConfig) => (
                <Grid
                    key={perBookConfig.uniqueTableName}
                    container
                    wrap={'nowrap'}
                    spacing={3}
                    justify="center"
                    style={{ paddingTop: '20px' }}>
                    <Grid item xs={7} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                        <Table
                            uniqueTableName={perBookConfig.uniqueTableName}
                            title={perBookConfig.title}
                            data={props.lookAheadState[perBookConfig.tableDataKey as keyof LookAheadState] as Array<any>}
                            defaultTableHeight={perBookConfig.defaultTableHeight}
                        />
                    </Grid>
                    <Grid item xs={5} style={{ minWidth: MIN_6_12_EL_WIDTH }}>
                        <Graph
                            data={props.lookAheadState[perBookConfig.graphDataKey as keyof LookAheadState] as Array<any>}
                            title={perBookConfig.title}
                        />
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default fetchConnector(PerBookExp);
