import React from 'react';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatterAggWithSign, perStrategyMediumVarUseStyles } from './common';
import CommonPNLSummaryTable, { commonPNLSummaryTableColumns, isInvalidInactiveStrategy } from './CommonPNLSummaryTable';

const PER_STRATEGY_CORP_SUMMARY_PLACEHOLDER_HEIGHT = '571px';

let columns: Array<SimpleTableColumnConfig> = [
    {
        name: 'strategy',
        title: 'Strategy',
        hoverElement: (row: SimpleTableRow, _: any) => {
            if (isInvalidInactiveStrategy(row)) {
                return <div>This strategy is supposed to be inactive and should not have had a DtD move</div>;
            }
            return '';
        },
    },
];
columns = columns.concat(commonPNLSummaryTableColumns);

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.PerStrategyCorpSummary,
    title: 'Corp Summary',
    size: TableSize.Medium,
    columns: columns,
    rowBodyBackgroundColor: (row) => (isInvalidInactiveStrategy(row) ? '#FFEEEDFF' : ''),
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

const PerStrategyCorpSummary = () => {
    const classes = perStrategyMediumVarUseStyles();
    return (
        <CommonPNLSummaryTable
            allTradesRunDateDataTableKey={'per_strategy_corp_pnl_summary'}
            config={config}
            classes={classes}
            defaultTableHeight={PER_STRATEGY_CORP_SUMMARY_PLACEHOLDER_HEIGHT}
        />
    );
};

export default PerStrategyCorpSummary;
