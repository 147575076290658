import { CancelRequest, ErrorContentType } from '../common/fetcher';
import {
    PNL_ANALYTICS_CLEAR_ACC_DATA,
    PNL_ANALYTICS_CLEAR_ALL_DATA,
    PNL_ANALYTICS_FETCH_COMPLETED,
    PNL_ANALYTICS_FETCH_FAILED,
    PNL_ANALYTICS_FETCH_LOADING,
    PNLAnalyticsActionTypes,
    PNLFetchStatus,
} from '../actions/pnlAnalytics';

export interface PNLFetchData {
    status: PNLFetchStatus;
    data?: any;
    err?: ErrorContentType;
    cancelRequest?: CancelRequest;
}

export interface PNLAnalyticsState {
    meta: PNLFetchData;
    all_trades_run_date: PNLFetchData;
    clearer_run_date: PNLFetchData;
    historical_pnl_per_book_metrics: PNLFetchData;
    open_pnl_last_year: PNLFetchData;
    exposures: PNLFetchData;
    back_office_misc: PNLFetchData;
    // Accounting
    accounting_meta: PNLFetchData;
    accounting_danaos_vessels: PNLFetchData;
    // Back Office Accounting
    back_accounting_meta: PNLFetchData;
    back_accounting_scg_physical: PNLFetchData;
    back_accounting_cash_planning: PNLFetchData;
}

const initialState: PNLAnalyticsState = {
    meta: { status: PNLFetchStatus.INIT },
    all_trades_run_date: { status: PNLFetchStatus.INIT },
    clearer_run_date: { status: PNLFetchStatus.INIT },
    historical_pnl_per_book_metrics: { status: PNLFetchStatus.INIT },
    open_pnl_last_year: { status: PNLFetchStatus.INIT },
    exposures: { status: PNLFetchStatus.INIT },
    back_office_misc: { status: PNLFetchStatus.INIT },
    accounting_meta: { status: PNLFetchStatus.INIT },
    accounting_danaos_vessels: { status: PNLFetchStatus.INIT },
    back_accounting_meta: { status: PNLFetchStatus.INIT },
    back_accounting_scg_physical: { status: PNLFetchStatus.INIT },
    back_accounting_cash_planning: { status: PNLFetchStatus.INIT },
};
const pnlAnalyticsReducer = (state = initialState, action: PNLAnalyticsActionTypes): PNLAnalyticsState => {
    switch (action.type) {
        case PNL_ANALYTICS_FETCH_LOADING:
            let keyNameLoading = action.payload.endpoint as string;
            let keyStateLoading = state[keyNameLoading as keyof PNLAnalyticsState];
            return {
                ...state,
                [keyNameLoading]: {
                    ...keyStateLoading,
                    status: PNLFetchStatus.LOADING,
                    cancelRequest: action.payload.cancelRequest,
                },
            };
        case PNL_ANALYTICS_FETCH_COMPLETED:
            let keyNameCompleted = action.payload.endpoint as string;
            let keyStateCompleted = state[keyNameCompleted as keyof PNLAnalyticsState];
            return {
                ...state,
                [keyNameCompleted]: {
                    ...keyStateCompleted,
                    data: action.payload.data,
                    status: PNLFetchStatus.COMPLETED,
                    err: undefined,
                    cancelRequest: undefined,
                },
            };
        case PNL_ANALYTICS_FETCH_FAILED:
            let keyNameFailed = action.payload.endpoint as string;
            let keyStateFailed = state[keyNameFailed as keyof PNLAnalyticsState];
            return {
                ...state,
                [keyNameFailed]: {
                    ...keyStateFailed,
                    status: PNLFetchStatus.FAILED,
                    err: action.payload.err,
                    cancelRequest: undefined,
                },
            };
        case PNL_ANALYTICS_CLEAR_ALL_DATA:
            return { ...initialState };
        case PNL_ANALYTICS_CLEAR_ACC_DATA:
            return {
                ...state,
                accounting_danaos_vessels: { status: PNLFetchStatus.INIT },
                back_accounting_scg_physical: { status: PNLFetchStatus.INIT },
                back_accounting_cash_planning: { status: PNLFetchStatus.INIT },
            };
        default:
            return state;
    }
};

export default pnlAnalyticsReducer;
