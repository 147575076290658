import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleTableConfig, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatterAggWithSign } from './common';
import CommonPNLSummaryTable, { commonPNLSummaryTableColumns } from './CommonPNLSummaryTable';

const OVERVIEW_PLACEHOLDER_HEIGHT = '264px';

const useStyles = makeStyles(() => ({
    headerVaRCell: {
        fontSize: '14px !important',
        borderLeft: 'black solid 1px',
    },
    bodyVaRCell: {
        fontSize: '14px !important',
        borderLeft: 'black solid 1px',
    },
    headerDtDVaRCell: {
        fontSize: '14px !important',
    },
    bodyDtDVaRCell: {
        fontSize: '14px !important',
    },
}));

const columns = [{ name: 'book', title: 'Book' }].concat(commonPNLSummaryTableColumns);

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.Overview,
    title: 'Year To Date PNL',
    size: TableSize.Large,
    columns: columns,
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

const OverviewComp = () => {
    const classes = useStyles();
    return (
        <CommonPNLSummaryTable
            allTradesRunDateDataTableKey={'overview_table'}
            config={config}
            classes={classes}
            defaultTableHeight={OVERVIEW_PLACEHOLDER_HEIGHT}
        />
    );
};

export default OverviewComp;
