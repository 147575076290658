import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

type AutoCompleteFieldProps = {
    options: Array<string>;
    inputValue: string;
    handleChange: (newValue: string) => void;
    handlePartialChange?: (newValue: string) => void;
    required: boolean;
    errorText?: string;
    label: string;
    width: number;
    marginLeft: string;
    defaultValue?: string;
    disabled?: boolean;
    tooltipText?: string;
    autoHighlight?: boolean;
    minHeight?: string;
    styles?: Record<string, string>;
};

const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option,
});

export default function AutoCompleteField(props: AutoCompleteFieldProps) {
    const [hasBlurred, setHasBlurred] = React.useState(false);
    const [justFocused, setJustFocused] = React.useState(false);

    let errorText: string = props.errorText || '';
    if (!errorText) errorText = hasBlurred && props.required && props.inputValue === '' ? 'Required' : '';

    let defaultValue: string = props.defaultValue ? props.defaultValue : '';

    // If text already exists we want to auto-highlight the first value
    let tooltipText: string = '';
    let disableTooltip: boolean = true;
    if (props.tooltipText) {
        tooltipText = props.tooltipText;
        disableTooltip = false;
    }
    let hasText = Boolean(props.inputValue);
    // Default autoHighlight to true
    let autoHighlight = props.autoHighlight === undefined ? true : props.autoHighlight;
    autoHighlight = autoHighlight && !justFocused && hasText;
    return (
        <Autocomplete
            defaultValue={defaultValue === '' ? undefined : defaultValue}
            onBlur={() => {
                setHasBlurred(true);
            }}
            onFocus={() => {
                setJustFocused(true);
            }}
            filterOptions={filterOptions}
            // openOnFocus={!hasText}
            options={props.options}
            disabled={props.disabled}
            autoComplete={true}
            autoHighlight={autoHighlight}
            autoSelect
            selectOnFocus
            blurOnSelect={true}
            closeIcon={null}
            inputValue={props.inputValue}
            onInputChange={(event, value, _) => {
                // We commented out this line because we used defaultValue and that is enough to make it work on load with existing  value
                if (event === null) return; // For some reason onInputChange is called on load so we have to ignore it as it sets it to empty
                // If it was just focused don't set the value to empty
                if (justFocused) {
                    setJustFocused(false);
                    if (!value) return;
                }
                if (value !== '' && !props.options.includes(value) && props.handlePartialChange) return props.handlePartialChange(value);
                props.handleChange(value);
            }}
            style={{
                width: props.width,
                display: 'inline-flex',
                verticalAlign: 'bottom',
                minHeight: props.minHeight ? props.minHeight : '5rem',
                marginLeft: props.marginLeft,
                ...props.styles,
            }}
            renderInput={(params) => (
                <Tooltip title={tooltipText} placement={'top'} disableHoverListener={disableTooltip} disableFocusListener={disableTooltip}>
                    <TextField
                        {...params}
                        required={props.required}
                        error={errorText !== ''}
                        helperText={errorText}
                        label={props.label}
                        margin="none"
                    />
                </Tooltip>
            )}
        />
    );
}
