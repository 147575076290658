import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { BACashPlanning } from '../../pnlAnalytics/fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { dollarVal2D, dollarValFin2D, kFormatterWithParenthesis, optNumberFormat, redOnly } from '../../containers/pivotHelper';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux } from '../dataFetcher';
import moment from 'moment-timezone';
import { FetchCashPlanningAccountRespType, FetchCashPlanningAccountStoreType, useZusStore } from '../../reducers/appStore';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { newFetcher } from '../../common/fetcher';
import { swissUseSelector } from '../../reducers';
import { AuthUserState } from '../../reducers/authUser';
import { useMainSnackbar } from '../../common/SwissSnackBar';

const useStyles = makeStyles(() => ({
    accountName: {
        cursor: 'pointer !important',
    },
}));

const dollarVal3D = (val: number) => optNumberFormat(val, 3, false, false, false);

const getIncludedInSumOpacity = (row: SimpleTableRow): CSSProperties => {
    if (row['_depth'] !== 2 || row['include_in_sum_final'] !== 0) return {};
    return { opacity: 0.4 };
};

function isNumeric(str: any) {
    if (typeof str != 'string') return false; // we only process strings!
    return (
        !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const CashWoWComp = (props: Props) => {
    const classes = useStyles();
    const appStore = useZusStore((state) => state);
    const authUser = swissUseSelector<AuthUserState>((state) => state.authUser);
    const mainSnackbar = useMainSnackbar();

    const amountFormatter = (val: number) =>
        appStore.cashPlanningFilters.showFractions ? dollarValFin2D(val) : kFormatterWithParenthesis(val);
    const cashPlanningData = props.pnlAnalytics.back_accounting_cash_planning;

    if (cashPlanningData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.BACashPlanning} data={cashPlanningData} minHeight={'680px'} />
        );
    }

    // Construct the config columns
    let respData = cashPlanningData.data! as BACashPlanning;

    let data = respData.cash_planning_wow;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'group_company_final',
            title: 'Group',
        },
        {
            name: 'institution_final',
            title: 'Institution',
        },
        {
            name: 'id',
            title: 'Account Name',
            bodyCellsClassName: classes.accountName,
            onClick: (props) => {
                console.log(props.row);
                if (props.row['_depth'] !== 2) return;
                newFetcher<FetchCashPlanningAccountRespType, FetchCashPlanningAccountStoreType>({
                    method: 'GET',
                    authUserState: authUser,
                    appStore: appStore,
                    mainSnackbar: mainSnackbar,
                    withBackdropSpinner: true,
                    withErrorSnackbar: true,
                    appStoreDataKey: 'fetchCashPlanningAccount',
                    url: '/acc_analytics/report/cash_planning_account',
                    params: {
                        start_date: respData.prev_date,
                        end_date: respData.latest_date,
                        ledger_card: props.row['ledger_card_code_final'] as string,
                    },
                    onStoreSuccess: (resp) => {
                        return {
                            ...resp,
                            row: props.row,
                        };
                    },
                });
            },
            bodyCustomStyle: (row) => getIncludedInSumOpacity(row),
            bodyTemplate2: (val, row) => {
                if (row['_depth'] !== 2) return val;
                const account_name = row['account_name_final'] as string;
                let account_name_val = '';
                if (account_name) {
                    if (row['entry_type_final'] === 'Buffer') {
                        account_name_val = account_name;
                    } else {
                        let lastFour = '';
                        if ((row['ledger_card_code_final'] as string).startsWith('B')) {
                            const lastFourTmp = account_name.substr(account_name.length - 4);
                            if (isNumeric(lastFourTmp)) lastFour = `**${lastFourTmp}`;
                        }
                        account_name_val = `${row['holding_company_final']} ${row['account_type_final']} ${lastFour}`;
                    }
                }
                return `[${row['ledger_card_code_final']}-${row['currency_final']}] (${row['entry_type_final']}) ${account_name_val}`;
            },
            hoverElement: (row) => {
                if (row['_depth'] !== 2 || !row['ledger_card_code_final']) return '';
                return (
                    <div>
                        <div>Ledger Card: {row['ledger_card_code_final']}</div>
                        {row['include_in_sum_final'] === 0 && <div>This account is not included in the summations</div>}
                        {row['notes_final'] && (
                            <>
                                <br />
                                <div>Notes: {row['notes_final']}</div>
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            name: 'amount_latest',
            title: 'Local Amount',
            bodyCustomStyle: (row) => getIncludedInSumOpacity(row),
            bodyTemplate2: (val, row) => {
                if (row['_depth'] !== 2 || row['currency_final'] === 'USD') return '';
                return appStore.cashPlanningFilters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            hoverElement: (row, rawVal) => {
                if (row['_depth'] !== 2 || row['currency_final'] === 'USD') return '';
                return <div>{dollarVal2D(row['amount_latest'] as number)}</div>;
            },
            textColor: redOnly,
        },
        {
            name: 'amount_wow',
            title: 'WoW',
            bodyCustomStyle: (row) => getIncludedInSumOpacity(row),
            bodyTemplate2: (val, row) => {
                if (row['_depth'] !== 2 || row['currency_final'] === 'USD' || val === 0) return '';
                return amountFormatter(val);
            },
            hoverElement: (row, rawVal) => {
                if (row['_depth'] !== 2 || row['currency_final'] === 'USD') return '';
                return <div>{dollarVal2D(row['amount_wow'] as number)}</div>;
            },
            textColor: redOnly,
        },
        {
            name: 'amount_usd_latest',
            title: 'Curr USD',
            bodyCustomStyle: (row) => getIncludedInSumOpacity(row),
            bodyTemplate: (val) => {
                return amountFormatter(val);
            },
            hoverElement: (row) => {
                if (row['_depth'] !== 2 || row['fx_rate_latest'] === 1) return '';
                return <div>FX: {dollarVal3D(row['fx_rate_latest'] as number)}</div>;
            },
            textColor: redOnly,
        },
        {
            name: 'amount_usd_prev',
            title: 'Prev USD',
            bodyCustomStyle: (row) => getIncludedInSumOpacity(row),
            bodyTemplate2: (val, _) => {
                return amountFormatter(val);
            },
            hoverElement: (row) => {
                if (row['_depth'] !== 2 || row['fx_rate_prev'] === 1) return '';
                return <div>FX: {dollarVal3D(row['fx_rate_prev'] as number)}</div>;
            },
            textColor: redOnly,
        },
        {
            name: 'amount_usd_wow',
            title: 'WoW USD',
            customDisplay: (row, rawVal) => {
                let customStyles: any = {
                    color: redOnly(rawVal),
                };
                if (row['amount_wow'] === 0) {
                    customStyles['opacity'] = 0.3;
                }
                customStyles = { ...getIncludedInSumOpacity(row), ...customStyles };
                return (
                    <div>
                        <Tooltip placement={'top'} title={dollarVal2D(row['amount_usd_wow'] as number) || ''}>
                            <span style={customStyles}>{amountFormatter(rawVal)}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.BACashPlanningWoW,
        title: `Cash Week over Week:  ${moment(respData.latest_date).format('ddd Do MMM')} -- ${moment(respData.prev_date).format(
            'ddd Do MMM'
        )}`,
        size: TableSize.Medium,
        columns: columnsConfig,
        grandTotal: true,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 2,
        defaultExpandable: 0,
        stickyHeader: true,
    };

    return <ExpandableTable data={data} config={config} />;
};
export default bAccFetchConnector(CashWoWComp);
