import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { okta } from './config';

const OktaSignInWidget = ({ onSuccess, onError }: any) => {
    const widgetRef: any = useRef();
    useEffect(() => {
        if (!widgetRef.current) {
            return;
        }

        const widget: any = new OktaSignIn(okta.widget);

        // Search for URL Parameters to see if a user is being routed to the application to recover password
        let searchParams = new URL(window.location.href).searchParams;
        widget.otp = searchParams.get('otp');
        widget.state = searchParams.get('state');
        widget.logo = '/favicon.ico';
        widget
            .showSignInToGetTokens({
                el: widgetRef.current,
            })
            .then(onSuccess)
            .catch(onError);

        return () => widget.remove();
    }, [onSuccess, onError]);

    return (
        <div style={{ marginTop: '50px' }}>
            <div ref={widgetRef} />
        </div>
    );
};

export default OktaSignInWidget;
