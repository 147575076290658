import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import {
    P_ARA,
    P_ARA_C4,
    P_BLPG,
    P_BLPG3,
    P_BRT,
    P_CP,
    P_CP_C4,
    P_EPC,
    P_EPC_C4,
    P_FEI,
    P_LST,
    P_NWE_NAPHTHA,
    P_WTI,
    productMap,
    ProductMeta,
} from '../../common/products';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import { exposuresNumFormatter } from '../../common/exposures';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { BackOfficeMiscDataResp } from '../fetchTypes';
import { makeStyles } from '@material-ui/core/styles';
import SimpleTable from '../../tables/SimpleTable';
import { numberFormat } from '../../containers/pivotHelper';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const useStyles = makeStyles(() => ({
    bodyProductCell: {
        padding: '0 !important',
        border: '0 !important',
        height: '10px !important', // Needed so that the 100% height will work
    },
}));

const pivotColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'term_month_date',
        title: 'Term',
        bodyTemplate: (val: any) => {
            if (val === '') return val;
            return moment(val).format('MMM YY');
        },
    },
];

const productColumns = [P_FEI, P_LST, P_EPC, P_CP, P_CP_C4, P_ARA, P_ARA_C4, P_NWE_NAPHTHA, P_EPC_C4, P_BLPG, P_BLPG3, P_WTI, P_BRT];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productName) => {
    let productMeta: ProductMeta = productMap.get(productName)!;
    return {
        name: `${productName}`,
        title: productMeta.title,
        textColor: (_) => 'black',
        headerBackgroundColor: productMeta.backgroundColor,
        headerTextColor: productMeta.textColor,
        bodyTemplate: (num: number): string => {
            if (num === null) return '';
            return numberFormat(num, 2, false, false, false);
        },
    };
});

const allColumns = pivotColumns.concat(productsColumnConfig);

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.TodaysMtMPrices,
    title: 'Todays MtM Prices',
    size: TableSize.Small,
    columns: allColumns,
    grandTotal: false,
    aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
        return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
    },
    pivotDepth: 0,
    stickyHeader: true,
};

const TodaysMtMPrices = (props: Props) => {
    const classes = useStyles();
    const backOfficeMiscData = props.pnlAnalytics.back_office_misc;

    if (backOfficeMiscData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.BackOfficeMisc} data={backOfficeMiscData} minHeight={'552px'} />
        );
    }
    config.columns.forEach((el, _) => {
        el.bodyCellsClassName = classes.bodyProductCell;
    });

    let respData = backOfficeMiscData.data! as BackOfficeMiscDataResp;

    return <SimpleTable data={respData.todays_mtm_prices} config={config} />;
};

export default fetchConnector(TodaysMtMPrices);
