import { FetchShowSCGCashMovesStoreType, useZusStore } from '../../reducers/appStore';
import { initNewFetchData, NewFetchStatus } from '../../common/fetcher';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../tables/types';
import moment from 'moment-timezone';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import SimpleTable from '../../tables/SimpleTable';
import React from 'react';
import { dollarVal2D, kFormatterWithParenthesis, redOnlyHundred } from '../../containers/pivotHelper';
import { UniqueTableName } from '../../common/uniqueTableName';
import { Grid } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

const SCGShowCashMoveEntries = () => {
    const { fetchShowSCGCashMoves, fetchShowSCGCashMovesUpdate } = useZusStore((state) => ({
        fetchShowSCGCashMoves: state.fetchShowSCGCashMoves,
        fetchShowSCGCashMovesUpdate: state.fetchShowSCGCashMovesUpdate,
    }));
    const closeDrawer = () => fetchShowSCGCashMovesUpdate(initNewFetchData());

    if (fetchShowSCGCashMoves.status !== NewFetchStatus.SUCCESS) return null;
    return (
        <Drawer
            anchor={'right'}
            open={true}
            PaperProps={{ style: { width: '90%' } }}
            // style={{ zIndex: 1201 }}
            onClose={(_, reason) => {
                if (reason === 'escapeKeyDown') return;
                closeDrawer();
            }}>
            <div className={'MuiToolbar-dense'} />
            <Grid container spacing={2} justify="center" style={{ padding: '20px' }}>
                <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '25px' }}>
                    <DanaosTable fetchShowSCGCashMoves={fetchShowSCGCashMoves.data!} />
                </Grid>
            </Grid>
        </Drawer>
    );
};

const DanaosTable = ({ fetchShowSCGCashMoves }: { fetchShowSCGCashMoves: FetchShowSCGCashMovesStoreType }) => {
    const { showFractions } = useZusStore((state) => ({
        showFractions: state.cashPlanningFilters.showFractions,
    }));

    const columns: Array<SimpleTableColumnConfig> = [
        {
            name: 'journal_date',
            title: 'Journal Date',
            bodyTemplate2: (val) => {
                return moment(val as string).format('DD MMM YY');
            },
        },
        { name: 'ledger_card_code', title: 'Ledger' },
        { name: 'narrative', title: 'Narrative' },
        { name: 'journal_company', title: 'Journal Comp' },
        { name: 'journal_series', title: 'Journal Series' },
        { name: 'journal_number', title: 'Journal Number' },
        { name: 'cash_rec_group', title: 'Ledger Group' },
        {
            name: 'op_code_2',
            title: 'Op Code 2',
            bodyBackgroundColor: (_, rawVal) => {
                return rawVal ? 'yellow' : '';
            },
            hoverElement: (_, rawVal) => {
                if (!rawVal) return <div />;
                return (
                    <div>
                        This is an Internal Cash Transaction that is marked by accounting as being Cash even though no Cash is involved
                        (e.g. create internal shipping rates between products and vessels)
                    </div>
                );
            },
        },
        {
            name: 'lot_num',
            title: 'LOT',
            hoverElement: (row) => {
                return <div>{row['new_fmt_lot_num']}</div>;
            },
        },
        {
            name: 'book_value',
            title: 'Amount',
            bodyTemplate: (val) => {
                return showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
            hoverElement: (row, rawVal) => {
                console.log(row);
                return <div>{row['book_value']}</div>;
            },
        },
    ];

    const config: SimpleTableConfig = {
        uniqueTableName: UniqueTableName.BASCGCashMovesDanaosEntries,
        title: `Danaos Transactions for ${fetchShowSCGCashMoves.group}/${fetchShowSCGCashMoves.subgroup}`,
        size: TableSize.Medium,
        columns: columns,
        grandTotal: false,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 0,
    };

    return <SimpleTable data={fetchShowSCGCashMoves.entries} config={config} />;
};

export default SCGShowCashMoveEntries;
