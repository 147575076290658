import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CenteredCircularProgress, MIN_PNL_DISPLAY_WIDTH } from '../common/common';
import { ErrorPage, NotFoundPage, UserErrorPage } from '../common/error';
import {
    COBPNLColor,
    IntraDayPNLColor,
    IntraDayPreliminaryPNLColor,
    PrelimPNLColor,
    ScenarioPNLColor,
    VerifiedPNLColor,
} from '../common/stylesConsts';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from './dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../actions/pnlAnalytics';
import { Box, Grid, Select, Tab, Tabs } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { PNLMetaDataResp, PNLRunTypes3 } from './fetchTypes';
import moment from 'moment-timezone';
import { Business, Description, LocalGasStation, SupervisorAccount } from '@material-ui/icons';
import { PNLTabSelector } from './common';
import ManagementIndex from './management';
import PhysicalTradingIndex from './physicalTrading';
import BackOfficeIndex from './backOffice';
import PaperTradingIndex from './paperTrading';
import { isMobile } from 'react-device-detect';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { makeStyles } from '@material-ui/core/styles';
import { PNLAnalyticsState } from '../reducers/pnlAnalytics';
import { getRealUser, isAuthScreenShotBot, isRealUser } from '../common/auth';

type runTypeMapType = {
    humanText: string;
    color: string;
};

const runTypeMap: { [_: string]: runTypeMapType } = {};
runTypeMap[`${PNLRunTypes3.INTRADAY}-Preliminary`] = {
    humanText: 'Intraday Preliminary',
    color: IntraDayPreliminaryPNLColor,
};
runTypeMap[`${PNLRunTypes3.INTRADAY}`] = { humanText: 'Intraday', color: IntraDayPNLColor };
runTypeMap[PNLRunTypes3.PRELIM] = { humanText: 'Prelim', color: PrelimPNLColor };
runTypeMap[PNLRunTypes3.VERIFIED] = { humanText: 'Verified', color: VerifiedPNLColor };
runTypeMap[PNLRunTypes3.COB] = { humanText: 'COB', color: COBPNLColor };
runTypeMap[PNLRunTypes3.COB_LIKELY] = { humanText: 'Likely COB', color: COBPNLColor };
runTypeMap[PNLRunTypes3.SCENARIO] = { humanText: 'Scenario', color: ScenarioPNLColor };

const userDefaultViewMap = new Map<string, PNLTabSelector>();
userDefaultViewMap.set('othonas.serafetinidis@swisschemgas.com', PNLTabSelector.PaperTrading);
userDefaultViewMap.set('george.karapanos@swisschemgas.com', PNLTabSelector.PhysicalTrading);
userDefaultViewMap.set('kyriakos.lymperis@swisschemgas.com', PNLTabSelector.PhysicalTrading);
userDefaultViewMap.set('filip.theodorakis@swisschemgas.com', PNLTabSelector.Management);

type PNLAnalyticsParams = {
    pipe_run_date?: string;
};

type Props = FetchPNLAnalyticsPropsFromRedux &
    RouteComponentProps & {
        desiredRunDate: string | undefined;
        selectRunDate: (newRunDate: string | undefined) => void;
    };

const PNLAnalyticsComp = (props: Props) => {
    let defaultTab: PNLTabSelector = PNLTabSelector.Management;
    if (isRealUser(props.authUser) && userDefaultViewMap.has(getRealUser(props.authUser).email)) {
        defaultTab = userDefaultViewMap.get(getRealUser(props.authUser).email)!;
    }
    let isScreenShotBot = isAuthScreenShotBot(props.authUser);
    const [pnlTab, setPNLTab] = React.useState<PNLTabSelector>(defaultTab);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: PNLTabSelector) => {
        setPNLTab(newValue);
    };

    useEffect(() => {
        let defaultPostfix = '/latest';
        if (props.desiredRunDate !== undefined) defaultPostfix = `/date/${props.desiredRunDate}`;

        prepareData({ ...props, endpoint: PNLAnalyticsEndpoint.Meta, urlPostFix: defaultPostfix });
    }, [props]);
    const metaData = props.pnlAnalytics.meta;

    // Check that all the requests have finished loading
    const allTradesRunDateData = props.pnlAnalytics.all_trades_run_date;
    const clearerRunDateData = props.pnlAnalytics.clearer_run_date;
    const histPNLPerBookMetricsData = props.pnlAnalytics.historical_pnl_per_book_metrics;
    const exposureData = props.pnlAnalytics.exposures;

    const allRequestsCompleted =
        allTradesRunDateData.status === PNLFetchStatus.COMPLETED &&
        clearerRunDateData.status === PNLFetchStatus.COMPLETED &&
        histPNLPerBookMetricsData.status === PNLFetchStatus.COMPLETED &&
        exposureData.status === PNLFetchStatus.COMPLETED;

    if (metaData.status === PNLFetchStatus.INIT) {
        return <CenteredCircularProgress msg={'Initializing ...'} />;
    }
    if (metaData.status === PNLFetchStatus.LOADING) {
        return <CenteredCircularProgress msg={'Fetching PNL metadata ...'} />;
    }
    if (metaData.status === PNLFetchStatus.FAILED) {
        let returnedErrorMsg: undefined | any = metaData.err!.jsonError['err_msg'];
        let errMsg: string = returnedErrorMsg ? (returnedErrorMsg as string) : metaData.err!.parsedMsg;
        switch (metaData.err?.status) {
            case 400:
                return <UserErrorPage msg={errMsg} />;
            case 404:
                return <NotFoundPage msg={errMsg} />;
            default:
                return <ErrorPage msg={errMsg} />;
        }
    }

    if (!metaData.data) {
        return <ErrorPage msg={'While we should have had PNL data for some reason we do not. This is our own bug'} />;
    }

    let respData = metaData.data! as PNLMetaDataResp;

    let colorKey = respData.selected.run_type as string;
    if (colorKey === PNLRunTypes3.INTRADAY) {
        colorKey =
            respData.selected.preliminary_pnl_run !== null && !respData.selected.preliminary_pnl_run
                ? colorKey
                : `${PNLRunTypes3.INTRADAY}-Preliminary`;
    }

    return (
        <div
            style={{
                backgroundColor: `${runTypeMap[colorKey].color}`,
                minWidth: MIN_PNL_DISPLAY_WIDTH,
                minHeight: '100vh',
            }}>
            <div className={'MuiToolbar-dense'} />
            <Box className={'MuiToolbar-gutters'} my={2} style={{ backgroundColor: 'clear' }}>
                {allRequestsCompleted && <span id="completedAllRequests" />}
                <Grid container justify={'center'} spacing={3}>
                    <Grid item xs={3} style={{ backgroundColor: 'clear' }}>
                        <img src={require('../static/scgLogoLogoOnlyTransparent60.png')} alt={''} />
                    </Grid>
                    <Grid style={{ textAlign: 'center', backgroundColor: 'clear' }} item xs={6}>
                        <h1>
                            {moment(respData.selected.pipe_run_date).format('dddd MMM Do YYYY')} {runTypeMap[colorKey].humanText} PNL
                        </h1>
                        {respData.found_selected_run && (
                            <div>
                                <span style={{ fontStyle: 'italic' }}>Run By:</span> {respData.selected.author_nick_name}{' '}
                                {moment.tz(respData.selected.created_at, 'GMT').startOf('minute').fromNow()}{' '}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right', backgroundColor: 'clear' }}>
                        <Select
                            MenuProps={{ style: { zIndex: 10000 } }}
                            value={respData.selected.pipe_run_date}
                            onChange={(e) => props.selectRunDate(e.target.value as string)}>
                            {!respData.found_selected_run && (
                                <MenuItem key={respData.selected.pipe_run_date} value={respData.selected.pipe_run_date}>
                                    {moment(respData.selected.pipe_run_date).format('DD MMM')}{' '}
                                    {runTypeMap[respData.selected.run_type].humanText}
                                </MenuItem>
                            )}
                            {respData.pnl_list.map((pnlMeta) => {
                                return (
                                    <MenuItem key={pnlMeta.pipe_run_date} value={pnlMeta.pipe_run_date}>
                                        {moment(pnlMeta.pipe_run_date).format('DD MMM')} {runTypeMap[pnlMeta.run_type].humanText} (
                                        {pnlMeta.author_nick_name})
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    {!isScreenShotBot && (
                        <Grid item xs={12} style={{ backgroundColor: 'clear' }}>
                            <Tabs
                                style={{ backgroundColor: 'clear' }}
                                value={pnlTab}
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                centered
                                textColor="secondary">
                                <Tab value={PNLTabSelector.Management} label={<PNLTab selection={PNLTabSelector.Management} />} />
                                <Tab value={PNLTabSelector.PhysicalTrading} label={<PNLTab selection={PNLTabSelector.PhysicalTrading} />} />
                                <Tab value={PNLTabSelector.PaperTrading} label={<PNLTab selection={PNLTabSelector.PaperTrading} />} />
                                <Tab value={PNLTabSelector.BackOffice} label={<PNLTab selection={PNLTabSelector.BackOffice} />} />
                            </Tabs>
                        </Grid>
                    )}
                </Grid>
                {isScreenShotBot && (
                    <React.Fragment>
                        <ManagementIndex />
                        <h1 style={{ textAlign: 'center', marginTop: '100px' }}>Physical</h1>
                        <PhysicalTradingIndex />
                        <h1 style={{ textAlign: 'center', marginTop: '100px' }}>Paper</h1>
                        <PaperTradingIndex />
                    </React.Fragment>
                )}
                {!isScreenShotBot && (
                    <div style={{ marginTop: '20px' }}>
                        {pnlTab === PNLTabSelector.Management && <ManagementIndex />}
                        {pnlTab === PNLTabSelector.PhysicalTrading && <PhysicalTradingIndex />}
                        {pnlTab === PNLTabSelector.PaperTrading && <PaperTradingIndex />}
                        {pnlTab === PNLTabSelector.BackOffice && <BackOfficeIndex />}
                    </div>
                )}
            </Box>
        </div>
    );
};

const PNLTab = (props: { selection: PNLTabSelector }) => {
    return (
        <span style={{ alignItems: 'center', display: 'flex', marginLeft: '20px', marginRight: '50px' }}>
            {props.selection === PNLTabSelector.Management && <SupervisorAccount />}
            {props.selection === PNLTabSelector.PhysicalTrading && <LocalGasStation />}
            {props.selection === PNLTabSelector.PaperTrading && <Description />}
            {props.selection === PNLTabSelector.BackOffice && <Business />}
            <span style={{ paddingLeft: '10px' }}>{props.selection}</span>
        </span>
    );
};

const PNLAnalytics = fetchConnector(PNLAnalyticsComp);

const useStyles = makeStyles(() => ({
    pullToRefresh: {
        marginTop: '48px',
        overscrollBehaviorY: 'contain',
    },
}));

type PNLAnalyticsHOCProps = FetchPNLAnalyticsPropsFromRedux & RouteComponentProps & {};

const PNLAnalyticsHOC = (props: PNLAnalyticsHOCProps) => {
    const classes = useStyles();
    let history = useHistory();
    let params = props.match.params as PNLAnalyticsParams;
    let desiredRunDate: string | undefined = undefined;
    if (params.pipe_run_date) desiredRunDate = params.pipe_run_date;

    const stateRefreshPromise = (newRunDate: string | undefined): Promise<string> => {
        return new Promise((res) => {
            setTimeout(() => {
                res(selectRunDate(newRunDate));
            }, 1500);
        });
    };

    const selectRunDate = (newRunDate: string | undefined): string => {
        let analyticsState = props.pnlAnalytics as PNLAnalyticsState;
        let endpoints = Object.keys(analyticsState);
        endpoints.forEach((endpoint) => {
            let endpointState = analyticsState[endpoint as keyof PNLAnalyticsState];
            if (endpointState.cancelRequest !== undefined) {
                endpointState.cancelRequest('Cancelling due to change of date');
            }
        });
        let url = newRunDate === undefined ? '/' : `/date/${newRunDate}`;
        history.push(url);
        props.clearAllDataAction();
        return url;
    };

    if (isMobile) {
        return (
            <div style={{ minWidth: MIN_PNL_DISPLAY_WIDTH }}>
                <PullToRefresh
                    pullDownThreshold={80}
                    onRefresh={() => stateRefreshPromise(desiredRunDate)}
                    className={classes.pullToRefresh}>
                    <PNLAnalytics {...props} selectRunDate={selectRunDate} desiredRunDate={desiredRunDate} />
                </PullToRefresh>
            </div>
        );
    }
    return <PNLAnalytics {...props} selectRunDate={selectRunDate} desiredRunDate={desiredRunDate} />;
};

export default fetchConnector(PNLAnalyticsHOC);
