import React from 'react';
import { ExchangeTradesPropsType, FieldType } from '../Types';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface MiniFieldProps extends ExchangeTradesPropsType {}

export function MiniField(props: MiniFieldProps) {
    const { state, setState } = props;

    const handleMiniChange = (newMini: boolean) => {
        let newMiniField: FieldType<boolean> = {
            value: newMini,
            error: '',
            required: state.mini.required,
            summary: null,
        };
        setState({
            ...state,
            mini: newMiniField,
        });
    };

    return (
        <Tooltip title={'Mini product of 100s instead of 1,000s'} placement={'top'}>
            <FormControlLabel
                disabled={props.disabled}
                style={{ marginBottom: '28px', marginLeft: '16px' }}
                control={
                    <Checkbox
                        checked={state.mini.value}
                        onChange={(event) => {
                            handleMiniChange(event.target.checked);
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') handleMiniChange(!state.diff.value);
                        }}
                        color="primary"
                    />
                }
                label="Mini"
            />
        </Tooltip>
    );
}
