import React, { memo, CSSProperties } from 'react';
import { TableCell, TableRow, Tooltip, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UndoIcon from '@material-ui/icons/Undo';
import { SwissColors } from '../common/stylesConsts';
import moment from 'moment-timezone';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AutoCompleteField from '../newTrade/base/AutoCompleteField';
import IconButton from '@material-ui/core/IconButton';
import { numberFormat, optNumberFormat } from '../containers/pivotHelper';
import { ClearerBlotterTrade } from './types';
import { BooksStrategiesType } from '../newTrade/Types';
import { bookAndStrategyOptions, getDealOptions } from '../newTrade/regular/BookStrategy';

// Turn empty string to null instead of 0
const convertToNumber = (value: string) => (value.length ? Number(value) : null);

type TradeRowProps = {
    index: number;
    blotterTrade: ClearerBlotterTrade;
    books: BooksStrategiesType[];
    brokers: string[];
    handleUndo: (index: number, swissOrderNum: string) => void;
    handleSubmit: (index: number, blotterTrade: ClearerBlotterTrade) => void;
    updateClearerBlotterTradeBroker: (idx: number, value: string) => void;
    updateClearerBlotterTradeStrategy: (idx: number, value: string | null) => void;
    updateClearerBlotterTradeBook: (idx: number, value: string) => void;
    updateClearerBlotterTradeCommission: (idx: number, value: number | null) => void;
    updateClearerBlotterTradeDeal: (idx: number, value: string | null) => void;
    blueBorder: boolean;
};

export const TradeRow = memo(
    ({
        index,
        blotterTrade,
        books,
        brokers,
        blueBorder,
        handleUndo,
        handleSubmit,
        updateClearerBlotterTradeBroker,
        updateClearerBlotterTradeBook,
        updateClearerBlotterTradeStrategy,
        updateClearerBlotterTradeCommission,
        updateClearerBlotterTradeDeal,
    }: TradeRowProps) => {
        const rowStyle: CSSProperties = {};
        if (blotterTrade.submitted_to_blotter) {
            rowStyle['backgroundColor'] = 'rgba(0, 247, 66, 0.15)';
        }
        if (blueBorder) {
            rowStyle['borderTop'] = `blue solid 5px`;
        }

        const volumeStyle: Record<string, string> = {};
        if (blotterTrade.volume < 0) {
            volumeStyle['color'] = SwissColors.Red;
        }

        const productName = blotterTrade.product_b ? `${blotterTrade.product_a} / ${blotterTrade.product_b}` : blotterTrade.product_a;

        let tenor = `${blotterTrade.month_a} ${blotterTrade.year_a.toString().substring(2, 4)}`;
        if (blotterTrade.month_b) {
            tenor += ` / ${blotterTrade.month_b} ${blotterTrade.year_b!.toString().substring(2, 4)}`;
        }

        const [bookOptions, strategyOptions] = bookAndStrategyOptions(blotterTrade.book || '', books);
        const strategyToolTip = strategyOptions.length === 0 ? 'Select Book First' : '';

        const dealOptions = getDealOptions(blotterTrade.strategy || '', books.find((book) => book.name === blotterTrade.book)?.strategies);

        const canSubmit =
            (blotterTrade.broker !== null || !blotterTrade.has_broker) &&
            blotterTrade.book !== null &&
            blotterTrade.strategy !== null &&
            blotterTrade.deal !== null;

        const addTooltipText = canSubmit ? '' : `To submit fill in ${blotterTrade.has_broker ? 'Broker, ' : ''}Book, Strategy and Deal`;

        let brokerToolTipText = '';
        if (!blotterTrade.submitted_to_blotter) {
            // Only show tooltip when the trade has not been submitted
            brokerToolTipText = blotterTrade.has_broker
                ? `Broker is required for ${blotterTrade.product_a}`
                : `${blotterTrade.product_a} is not a brokered product`;
        }

        return (
            <TableRow style={rowStyle}>
                <TableCell>
                    {!!blotterTrade.submitted_to_blotter && (
                        <IconButton
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                                handleUndo(index, blotterTrade.swiss_order_num);
                            }}>
                            <UndoIcon />
                        </IconButton>
                    )}
                    {!blotterTrade.submitted_to_blotter && (
                        <Tooltip placement={'top'} title={addTooltipText}>
                            <span>
                                <IconButton
                                    color={'primary'}
                                    size={'small'}
                                    disabled={!canSubmit}
                                    onClick={() => handleSubmit(index, blotterTrade)}>
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>
                    <AutoCompleteField
                        options={brokers}
                        inputValue={blotterTrade.broker || ''}
                        handleChange={(newValue) => {
                            updateClearerBlotterTradeBroker(index, newValue);
                        }}
                        required={blotterTrade.has_broker}
                        label={'Brokers'}
                        disabled={!blotterTrade.has_broker || !!blotterTrade.submitted_to_blotter}
                        tooltipText={brokerToolTipText}
                        width={160}
                        marginLeft={'2px'}
                        minHeight={'0'}
                    />
                </TableCell>
                <TableCell>
                    <AutoCompleteField
                        options={bookOptions}
                        inputValue={blotterTrade.book || ''}
                        autoHighlight={false}
                        handleChange={(newValue) => {
                            updateClearerBlotterTradeBook(index, newValue);
                        }}
                        required={true}
                        label={'Book'}
                        width={100}
                        marginLeft={'2px'}
                        disabled={!!blotterTrade.submitted_to_blotter}
                        minHeight={'0'}
                    />
                </TableCell>
                <TableCell>
                    <AutoCompleteField
                        options={strategyOptions}
                        inputValue={blotterTrade.strategy || ''}
                        handleChange={(newValue) => {
                            updateClearerBlotterTradeStrategy(index, newValue);
                        }}
                        required={true}
                        label={'Strategy'}
                        width={200}
                        marginLeft={'2px'}
                        disabled={strategyOptions.length === 0 || !!blotterTrade.submitted_to_blotter}
                        tooltipText={strategyToolTip}
                        minHeight={'0'}
                    />
                </TableCell>
                <TableCell>
                    <AutoCompleteField
                        options={dealOptions}
                        inputValue={blotterTrade.deal || ''}
                        handleChange={(newValue) => {
                            updateClearerBlotterTradeDeal(index, newValue);
                        }}
                        required={true}
                        label={'Deal'}
                        width={200}
                        marginLeft={'2px'}
                        disabled={dealOptions.length === 0 || !!blotterTrade.submitted_to_blotter}
                        tooltipText={strategyToolTip}
                        minHeight={'0'}
                        styles={{ size: '7px' }}
                    />
                </TableCell>

                <TableCell>
                    <TextField
                        variant="standard"
                        label="Commission"
                        type="number"
                        disabled={!!blotterTrade.submitted_to_blotter}
                        value={blotterTrade.commission_override}
                        onChange={(e) => updateClearerBlotterTradeCommission(index, convertToNumber(e.target.value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </TableCell>
                <TableCell style={{ fontSize: '12px', width: '120px' }}>
                    <Tooltip
                        placement={'top'}
                        title={
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Run Date: </span>
                                <span>{moment(blotterTrade.pipe_run_date).format('YYYY-MM-DD')}</span>
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Trade Date: </span>
                                <span>{moment(blotterTrade.trade_date).format('YYYY-MM-DD')}</span>
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Trade Time UTC: </span>
                                <span>{moment(blotterTrade.trade_time).utc().format('YYYY-MM-DD HH:mm')}</span>
                            </div>
                        }>
                        <span>
                            {moment(blotterTrade.pipe_run_date).format('YYYY-MM-DD')} {moment(blotterTrade.trade_time).format('HH:mm')}
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell>{blotterTrade.exchange}</TableCell>
                <TableCell style={volumeStyle}>
                    <span>{numberFormat(blotterTrade.volume, 0, false, false, false)}</span>
                </TableCell>
                <TableCell style={{ fontSize: '12px', width: '80px' }}>{productName}</TableCell>
                <TableCell style={{ fontSize: '12px', width: '140px' }}>{tenor}</TableCell>
                <TableCell>
                    <span>{Number(blotterTrade.price_a.toFixed(3))}</span>
                </TableCell>
                <TableCell>{blotterTrade.balmo_date && <span>{moment(blotterTrade.balmo_date).format('Do MMM')}</span>}</TableCell>
                <TableCell style={volumeStyle}>
                    <span>{optNumberFormat(blotterTrade.converted_leg_volume, 0, false, false, false)}</span>
                </TableCell>
                <TableCell>
                    <Tooltip
                        disableFocusListener={true}
                        interactive
                        title={
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Trade Ids: </span>
                                <span>{blotterTrade.clearer_trade_ids}</span>
                                <br />
                                <br />
                                <span style={{ fontWeight: 'bold' }}>Order #: </span>
                                <span>{blotterTrade.swiss_order_num}</span>
                            </div>
                        }
                        placement="top">
                        <span
                            style={{
                                display: 'block',
                                width: '140px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}>
                            <FileCopyIcon
                                style={{
                                    width: 14,
                                    height: 14,
                                    marginLeft: 10,
                                    cursor: 'pointer',
                                    marginRight: 10,
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(blotterTrade.clearer_trade_ids).then();
                                }}
                            />
                            {blotterTrade.clearer_trade_ids}
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
);
