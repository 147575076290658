import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { initNewFetchData, NewFetchData } from '../common/fetcher';
import { SimpleTableRow } from '../tables/types';
import { SCGPhysicalResp } from '../backAccounting/dataFetcher';
import { ClearerBlotterTrade } from '../blotter/types';
import { produce } from 'immer';

export type JSON_DATAFRAME_TYPE = Array<{ [_: string]: any }>;

export interface FetchCrudAdjustmentRespType {
    reconcile_lot_column: FetchEditLotReconcileRespType;
    reconcile_all_view: SCGPhysicalResp;
}

export interface FetchEditLotReconcileRespType {
    lot_num: string;
    scg_pnl_column: string;
    traders_ltd_val: number;
    danaos_ltd_val: number;
    ltd_diff: number;
    danaos_ltd_df: JSON_DATAFRAME_TYPE;
    traders_ltd_df: JSON_DATAFRAME_TYPE;
}

export interface EditablePnlAdjustments {
    swiss_year: number;
    adjustment_date: string;
    lot_num: string;
    scg_pnl_column: string;
    adjustment_type: string;
    description: string;
    amount: number;
    is_user_selectable: boolean;
    is_accounting_locked: boolean;
}

export interface FetchEditLotReconcileStoreType extends FetchEditLotReconcileRespType {
    row: SimpleTableRow;
}

export interface FetchShowSCGCashMovesRespType {
    group: string;
    subgroup: string;
    entries: JSON_DATAFRAME_TYPE;
}

export interface FetchShowSCGCashMovesStoreType extends FetchShowSCGCashMovesRespType {}

export interface FetchCashPlanningAccountRespType {
    danaos_transactions: Array<any>;
}

export interface FetchCashPlanningAccountStoreType extends FetchCashPlanningAccountRespType {
    row: SimpleTableRow;
}

export interface CashPlanningFilters {
    runDate?: string;
    withBuffer: boolean;
    withBlocked: boolean;
    showFractions: boolean;
}

export interface AppStore {
    backdropSpinner: boolean;

    cashPlanningFilters: CashPlanningFilters;
    toggleCashPlanningBufferFilter: () => void;
    toggleCashPlanningBlockedFilter: () => void;
    toggleCashPlanningFractionsFilter: () => void;
    setCashPlanningDateFilter: (aDate: string) => void;

    enableBackdropSpinner: () => void;
    disableBackdropSpinner: () => void;

    fetchCashPlanningAccount: NewFetchData<FetchCashPlanningAccountStoreType>;
    fetchCashPlanningAccountUpdate: (authFetchData: NewFetchData<FetchCashPlanningAccountStoreType>) => void;

    fetchEditLotReconcile: NewFetchData<FetchEditLotReconcileStoreType>;
    fetchEditLotReconcileUpdate: (authFetchData: NewFetchData<FetchEditLotReconcileStoreType>) => void;

    fetchShowSCGCashMoves: NewFetchData<FetchShowSCGCashMovesStoreType>;
    fetchShowSCGCashMovesUpdate: (authFetchData: NewFetchData<FetchShowSCGCashMovesStoreType>) => void;

    clearerBlotterTrades: Array<ClearerBlotterTrade> | null;
    setAllClearerBlotterTrades: (trades: Array<ClearerBlotterTrade>) => void;
    updateClearerBlotterTradeBroker: (idx: number, value: string) => void;
    updateClearerBlotterTradeBook: (idx: number, value: string) => void;
    updateClearerBlotterTradeStrategy: (idx: number, value: string | null) => void;
    updateClearerBlotterTradeDeal: (idx: number, value: string | null) => void;
    updateClearerBlotterTradeSubmitted: (idx: number, value: number) => void;
    updateClearerBlotterTradeCommission: (idx: number, value: number | null) => void;
}

export const useZusStore = create(
    devtools<AppStore>((set) => ({
        backdropSpinner: false,

        cashPlanningFilters: { withBuffer: true, withBlocked: true, showFractions: false },
        toggleCashPlanningBufferFilter: () =>
            set((state) => ({
                ...state,
                cashPlanningFilters: { ...state.cashPlanningFilters, withBuffer: !state.cashPlanningFilters.withBuffer },
            })),
        toggleCashPlanningBlockedFilter: () =>
            set((state) => ({
                ...state,
                cashPlanningFilters: { ...state.cashPlanningFilters, withBlocked: !state.cashPlanningFilters.withBlocked },
            })),
        toggleCashPlanningFractionsFilter: () =>
            set((state) => ({
                ...state,
                cashPlanningFilters: { ...state.cashPlanningFilters, showFractions: !state.cashPlanningFilters.showFractions },
            })),
        setCashPlanningDateFilter: (aDate) =>
            set((state) => ({
                ...state,
                cashPlanningFilters: { ...state.cashPlanningFilters, runDate: aDate },
            })),

        enableBackdropSpinner: () => set((state) => ({ ...state, backdropSpinner: true })),
        disableBackdropSpinner: () => set((state) => ({ ...state, backdropSpinner: false })),

        fetchCashPlanningAccount: initNewFetchData(),
        fetchCashPlanningAccountUpdate: (editCashPlanningAccount: NewFetchData<FetchCashPlanningAccountStoreType>) =>
            set((state) => ({ ...state, fetchCashPlanningAccount: editCashPlanningAccount })),

        fetchEditLotReconcile: initNewFetchData(),
        fetchEditLotReconcileUpdate: (editLotReconcileData: NewFetchData<FetchEditLotReconcileStoreType>) =>
            set((state) => ({ ...state, fetchEditLotReconcile: editLotReconcileData })),

        fetchShowSCGCashMoves: initNewFetchData(),
        fetchShowSCGCashMovesUpdate: (data: NewFetchData<FetchShowSCGCashMovesStoreType>) =>
            set((state) => ({ ...state, fetchShowSCGCashMoves: data })),

        clearerBlotterTrades: null,
        setAllClearerBlotterTrades: (trades: Array<ClearerBlotterTrade>) => set((state) => ({ ...state, clearerBlotterTrades: trades })),
        updateClearerBlotterTradeBroker: (idx: number, value: string) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].broker = value;
                })
            ),
        updateClearerBlotterTradeBook: (idx: number, value: string) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].strategy = null;
                    state.clearerBlotterTrades![idx].book = value;
                })
            ),
        updateClearerBlotterTradeStrategy: (idx: number, value: string | null) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].strategy = value;
                })
            ),
        updateClearerBlotterTradeDeal: (idx: number, value: string | null) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].deal = value;
                })
            ),
        updateClearerBlotterTradeSubmitted: (idx: number, value: number) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].submitted_to_blotter = value;
                })
            ),
        updateClearerBlotterTradeCommission: (idx: number, value: number | null) =>
            set(
                produce<AppStore>((state) => {
                    state.clearerBlotterTrades![idx].commission_override = value;
                })
            ),
    }))
);
