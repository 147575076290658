import React, { ReactNode } from 'react';
import {AllTradesPropsType} from '../Types';
import TradeDate from '../base/TradeDate';
import moment from 'moment';
import {DEFAULT_OPT_FIELD_STR, DEFAULT_REQ_FIELD_STR} from '../common';

interface TradeDateWrapperFieldProps extends AllTradesPropsType {}

export function TradeDateWrapperField(props: TradeDateWrapperFieldProps) {
    return (
        <TradeDate
            disabled={props.disabled}
            value={props.state.tradeDate.value}
            changeValue={(newValue: string) => {
                // Backdate if other date
                let newValueMoment = moment(newValue);
                let backlogTradeMsg: ReactNode = null;
                if (moment().isAfter(newValueMoment, 'day')) {
                    backlogTradeMsg = <span>[BACKLOGGED Trade of {newValueMoment.format('LL')}]</span>;
                }
                // Reset the state for the prompts
                props.setState({
                    ...props.state,
                    tradeDate: {
                        value: newValue,
                        error: '',
                        summary: backlogTradeMsg,
                        required: props.state.tradeDate.required
                    },
                    monthA: DEFAULT_REQ_FIELD_STR,
                    yearA: DEFAULT_OPT_FIELD_STR,
                    balmoDate: DEFAULT_OPT_FIELD_STR
                });
            }}
        />
    );
}
