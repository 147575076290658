import React, { ReactNode } from 'react';
import { siteErrorOpenAction, siteSuccessOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { AllTradesPropsType, TradeOptions, InternalTradesType, SingleVolumeTradesPropsType } from './Types';
import moment from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TradeDateWrapperField } from './regular/TradeDateWrapper';
import { DEFAULT_OPT_FIELD_STR, DEFAULT_PRICE_A_FIELD, DEFAULT_REQ_FIELD_STR, derivedVolume, executingForSummary } from './common';
import { SubmitButtons } from './regular/SubmitButtons';
import { postFetcher } from '../common/fetcher';
import { PromptFields } from './regular/PromptFields';
import { MiscFields } from './regular/MiscFields';
import { VolumeWrapperField } from './regular/VolumeWrapperField';
import { VolumeMetricField } from './regular/VolumeMetricField';
import { PriceAField } from './regular/ProductA';
import { InternalProductAField } from './internal/InternalProductA';
import { BookStrategyField, getDealOptions } from './regular/BookStrategy';
import Typography from '@material-ui/core/Typography';
import { InternalProductBField } from './internal/InternalProductB';
import { getAuthUser } from '../common/auth';
import AutoCompleteField from '../newTrade/base/AutoCompleteField';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteSuccessOpenAction: siteSuccessOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    options: TradeOptions;
    closeDialog: () => void;
};

type InternalTradeData = {
    fields: InternalTradesType;
    isSubmittingAddClose: boolean;
    isSubmittingAddNext: boolean;
};

class Internal extends React.Component<Props, InternalTradeData> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fields: {
                tradeDate: { value: moment().format(), error: '', summary: null, required: true },
                volume: DEFAULT_REQ_FIELD_STR,
                monthA: DEFAULT_REQ_FIELD_STR,
                yearA: DEFAULT_REQ_FIELD_STR,
                balmoDate: DEFAULT_OPT_FIELD_STR,
                monthB: DEFAULT_OPT_FIELD_STR,
                yearB: DEFAULT_OPT_FIELD_STR,
                productA: DEFAULT_REQ_FIELD_STR,
                productB: DEFAULT_OPT_FIELD_STR,
                priceA: DEFAULT_PRICE_A_FIELD,
                priceB: DEFAULT_OPT_FIELD_STR,
                buyFromBook: DEFAULT_REQ_FIELD_STR,
                buyFromStrategy: DEFAULT_REQ_FIELD_STR,
                buyFromDeal: DEFAULT_REQ_FIELD_STR,
                sellToBook: DEFAULT_REQ_FIELD_STR,
                sellToStrategy: DEFAULT_REQ_FIELD_STR,
                sellToDeal: DEFAULT_REQ_FIELD_STR,
                executingFor: {
                    value: props.options.defaultTraderNickName,
                    error: '',
                    required: true,
                    summary: executingForSummary(props.options.authUserNickName, props.options.defaultTraderNickName),
                },
                notes: DEFAULT_OPT_FIELD_STR,
            },
            isSubmittingAddClose: false,
            isSubmittingAddNext: false,
        };
    }

    submitTrade = (isSubmittingAddClose: boolean): void => {
        let fields = this.state.fields;
        let isSubmittingAddNext = !isSubmittingAddClose; // We can only submit one of the two
        let executingForEmail: string = '';
        this.props.options.users.forEach(function (user, _) {
            if (user.nick_name === fields.executingFor.value) {
                executingForEmail = user.email;
            }
        });
        if (executingForEmail === '') {
            throw Error(`Impossible to not have found email for trader ${fields.executingFor.value}`);
        }

        postFetcher<any>({
            url: `/tasks/all/sheets/blotter/enter_internal_trade`,
            data: {
                trade_date: moment(fields.tradeDate.value).format('YYYY-MM-DD'),
                volume: fields.volume.value,
                buy_from_book: fields.buyFromBook.value,
                buy_from_strategy: fields.buyFromStrategy.value,
                buy_from_deal: fields.buyFromDeal.value,
                sell_to_book: fields.sellToBook.value,
                sell_to_strategy: fields.sellToStrategy.value,
                sell_to_deal: fields.sellToDeal.value,
                month_a: fields.monthA.value,
                year_a: fields.yearA.value,
                balmo_date: fields.balmoDate.value !== '' ? moment(fields.balmoDate.value).format('YYYY-MM-DD') : null,
                month_b: fields.monthB.value !== '' ? fields.monthB.value : null,
                year_b: fields.yearB.value !== '' ? fields.yearB.value : null,
                product_a: fields.productA.value,
                price_a: fields.priceA.value !== '' ? fields.priceA.value : null,
                product_b: fields.productB.value !== '' ? fields.productB.value : null,
                executing_for: executingForEmail,
                notes: fields.notes.value !== '' ? fields.notes.value : null,
            },
            headers: {
                'Swiss-Tech-Auth-Token': getAuthUser(this.props.authUser).swiss_token,
            },
            onStart: () => {
                this.setState({
                    ...this.state,
                    isSubmittingAddClose: isSubmittingAddClose,
                    isSubmittingAddNext: isSubmittingAddNext,
                });
            },
            onSuccess: () => {
                this.props.siteSuccessOpenAction({ message: 'Trade was entered in Blotter' });
                if (isSubmittingAddClose) {
                    this.props.closeDialog();
                } else {
                    this.setState({
                        ...this.state,
                        isSubmittingAddNext: false,
                        fields: {
                            ...this.state.fields,
                            volume: DEFAULT_REQ_FIELD_STR,
                            notes: DEFAULT_OPT_FIELD_STR,
                        },
                    });
                }
            },
            onFail: (err) => {
                this.setState({
                    ...this.state,
                    isSubmittingAddClose: false,
                    isSubmittingAddNext: false,
                });
                this.props.siteErrorOpenAction({ message: err.parsedMsg });
            },
        });
    };

    render() {
        let dataInputError: string = '';
        for (let [key, field] of Object.entries(this.state.fields)) {
            let capitalizedKey: string = key.charAt(0).toUpperCase() + key.slice(1);
            if (field.required && field.value === '') {
                dataInputError = `${capitalizedKey}: is Required`;
                break;
            }
            if (field.error !== '') {
                dataInputError = `${capitalizedKey}: ${field.error}`;
                break;
            }
        }

        let isSubmitting = this.state.isSubmittingAddClose || this.state.isSubmittingAddNext;

        let allTradesProps: AllTradesPropsType = {
            state: this.state.fields,
            setState: (newFields) => {
                this.setState({ ...this.state, fields: { ...this.state.fields, ...newFields } });
            },
            options: this.props.options,
            disabled: isSubmitting,
        };

        let singleVolumeTradesProps: SingleVolumeTradesPropsType = {
            state: this.state.fields,
            setState: (newFields) => {
                this.setState({ ...this.state, fields: { ...this.state.fields, ...newFields } });
            },
            options: this.props.options,
            disabled: isSubmitting,
        };

        let fields = this.state.fields;

        let disableSubmitButton = Boolean(dataInputError) || isSubmitting;

        let volumeText: ReactNode = null;
        if (!dataInputError) {
            let volumeNumber: number = Number.parseInt(fields.volume.value);
            let volumeK = volumeNumber / 1000;
            let volumeMetric = derivedVolume(this.props.options, this.state.fields) === 'BBL' ? 'kb' : 'kt';
            volumeText = (
                <span>
                    bought {volumeK}
                    {volumeMetric} from {fields.buyFromBook.value}/{fields.buyFromStrategy.value} and sold to {fields.sellToBook.value}/
                    {fields.sellToStrategy.value}
                </span>
            );
        }

        const buyFromDealOptions = getDealOptions(
            fields.buyFromStrategy.value || '',
            this.props.options!.books.find((book) => book.name === fields.buyFromBook.value )?.strategies
        );

        const sellToDealOptions = getDealOptions(
            fields.sellToStrategy.value || '',
            this.props.options!.books.find((book) => book.name === fields.sellToBook.value )?.strategies
        );
        return (
            <div>
                <DialogContent>
                    <TradeDateWrapperField {...allTradesProps} />
                    <VolumeWrapperField {...singleVolumeTradesProps} />
                    <VolumeMetricField {...allTradesProps} />
                    <PromptFields {...allTradesProps} />
                    <div>
                        <InternalProductAField {...allTradesProps} />
                        <InternalProductBField {...allTradesProps} />
                        <PriceAField {...allTradesProps} />
                    </div>
                    <BookStrategyField
                        marginLeft={'0px'}
                        options={this.props.options}
                        disabled={isSubmitting}
                        book={fields.buyFromBook}
                        bookLabel={'Book that buys'}
                        strategy={fields.buyFromStrategy}
                        strategyLabel={'Strategy that buys'}
                        setBookStrategy={(newBook, newStrategy) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, buyFromBook: newBook, buyFromStrategy: newStrategy },
                            });
                        }}
                    />
                    <AutoCompleteField
                        options={buyFromDealOptions}
                        inputValue={fields.buyFromDeal.value || ''}
                        handleChange={(newValue) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, buyFromDeal: {...fields.buyFromDeal, value: newValue}},
                            });
                        }}
                        required={true}
                        label={'Deal that buys'}
                        width={200}
                        marginLeft={'18px'}
                        minHeight={'0'}
                        styles={{verticalAlign: 'top'}}
                    />
                    <BookStrategyField
                        marginLeft={'16px'}
                        options={this.props.options}
                        disabled={isSubmitting}
                        book={fields.sellToBook}
                        bookLabel={'Book that sells'}
                        strategy={fields.sellToStrategy}
                        strategyLabel={'Strategy that sells'}
                        setBookStrategy={(newBook, newStrategy) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, sellToBook: newBook, sellToStrategy: newStrategy },
                            });
                        }}
                    />
                    <AutoCompleteField
                        options={sellToDealOptions}
                        inputValue={fields.sellToDeal.value || ''}
                        handleChange={(newValue) => {
                            this.setState({
                                ...this.state,
                                fields: { ...this.state.fields, sellToDeal: {...fields.sellToDeal, value: newValue}},
                            });
                        }}
                        required={true}
                        label={'Deal that sells'}
                        width={200}
                        marginLeft={'18px'}
                        minHeight={'0'}
                        styles={{verticalAlign: 'top'}}
                    />
                    <MiscFields {...allTradesProps} />
                    {dataInputError === '' && (
                        <Typography variant="h6" style={{ marginTop: '24px', minHeight: '24px', textAlign: 'center' }}>
                            {fields.tradeDate.summary} {fields.executingFor.summary} {volumeText}
                            {fields.balmoDate.summary} {fields.monthA.summary}
                            {fields.monthB.summary} {fields.productA.summary}
                            {fields.productB.summary} {fields.priceA.summary}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <SubmitButtons
                        dataInputError={dataInputError}
                        disableSubmitButton={disableSubmitButton}
                        isSubmittingAddClose={this.state.isSubmittingAddClose}
                        isSubmittingAddNext={this.state.isSubmittingAddNext}
                        submitTrade={this.submitTrade}
                    />
                </DialogActions>
            </div>
        );
    }
}

export default connector(Internal);
