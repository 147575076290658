import React, { CSSProperties, ReactChild, useEffect, useRef, useState } from 'react';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableData, SimpleTableRow, TableSize, ToggleExpandedRow } from './types';
import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TrendDirection2 from '../common/TrendDirection2';
import { Cell, CellParserRes, CellRange, CellRangeAgg } from '../common/cellRangeAgg';
import { swissAssert } from '../common/swissAssert';
import { RootState } from '../reducers';
import { setActiveTableAction } from '../actions/footerCellAgg';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import TableAggFooter from '../containers/TableAggFooter';
import { isArrowKey, isCtrlKey, isEscKey, isShiftKey, KeyCode } from './keyActionHelpers';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ExpandableTableTitle, SimpleTitle } from './TableTitle';
import { UniqueTableName } from '../common/uniqueTableName';

const useLargeTableStyles = makeStyles(() => ({
    root: {
        '& th': {
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '10px 12px 8px 10px',
        },
        '& td': {
            fontSize: '20px',
            padding: '10px 12px 8px 10px',
            userSelect: 'none',
            cursor: 'cell',
            borderBottom: 'none',
            borderTop: '1px solid #e0e0e0',
        },
    },
    grandTotal: {
        borderTop: '5px solid black',
        '& td': {
            fontSize: '22px',
            fontWeight: 'bold',
            borderBottom: 'none',
        },
    },
}));

const useMediumTableStyles = makeStyles(() => ({
    root: {
        '& th': {
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '6px 7px 5px 6px',
        },
        '& td': {
            fontSize: '15px',
            padding: '6px 7px 5px 6px',
            userSelect: 'none',
            cursor: 'cell',
            borderBottom: 'none',
            borderTop: '1px solid #e0e0e0',
        },
    },
    grandTotal: {
        borderTop: '5px solid black',
        '& td': {
            fontSize: '17px',
            fontWeight: 'bold',
            borderBottom: 'none',
        },
    },
}));

const useMediumSmallTableStyles = makeStyles(() => ({
    root: {
        '& th': {
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '3px 4px 2px 3px',
        },
        '& td': {
            fontSize: '14px',
            padding: '3px 4px 2px 3px',
            userSelect: 'none',
            cursor: 'cell',
            borderBottom: 'none',
            borderTop: '1px solid #e0e0e0',
        },
    },
    grandTotal: {
        borderTop: '5px solid black',
        '& td': {
            fontSize: '16px',
            fontWeight: 'bold',
            borderBottom: 'none',
        },
    },
}));

const useSmallTableStyles = makeStyles(() => ({
    root: {
        '& th': {
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px 2px 2px 2px',
        },
        '& td': {
            fontSize: '12px',
            padding: '2px 2px 2px 2px',
            userSelect: 'none',
            cursor: 'cell',
            borderBottom: 'none',
            borderTop: '1px solid #e0e0e0',
        },
    },
    grandTotal: {
        borderTop: '5px solid black',
        '& td': {
            fontSize: '12px',
            fontWeight: 'bold',
            borderBottom: 'none',
        },
    },
}));

const useTableStyles = makeStyles(() => ({
    paper: {
        padding: '5px 20px 10px 20px',
    },
    stickyHeader: {
        position: 'sticky',
        top: '50px',
    },
}));

const mapState = (state: RootState) => ({
    footerCellAgg: state.footerCellAggg,
});

const mapDispatch = {
    setActiveTableAction: setActiveTableAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SelectingState = {
    cellRangeAgg: CellRangeAgg;
    isMouseSelecting: boolean;
    hasCtrKeyPressed: boolean;
    hasShiftKeyPressed: boolean;
};

type SimpleTableProps = PropsFromRedux & {
    allData?: SimpleTableData;
    data: SimpleTableData;
    config: SimpleTableConfig;
    toggleExpandedRow?: ToggleExpandedRow;
    changeExpansion?: (newExpansionDepth?: number) => void;
    style?: CSSProperties;
    filters?: any; // used for re-render purposes only
};

const SimpleTable = (props: SimpleTableProps) => {
    const tableClasses = useTableStyles();
    let smallClasses = useSmallTableStyles();
    let mediumSmallClasses = useMediumSmallTableStyles();
    let mediumClasses = useMediumTableStyles();
    let largeClasses = useLargeTableStyles();
    let sizeClasses = mediumClasses; // default is Medium sized
    if (props.config.size === TableSize.Small) {
        sizeClasses = smallClasses;
    } else if (props.config.size === TableSize.MediumSmall) {
        sizeClasses = mediumSmallClasses;
    } else if (props.config.size === TableSize.Large) {
        sizeClasses = largeClasses;
    }

    const cellParser = (cell: Cell): CellParserRes => {
        let columnName = props.config.columns[cell.col].name;
        let cellVal = props.data[cell.row][columnName];
        // cellVal can only be undefined if we are trying to display a column that is not even in the data
        // otherwise we should always have something
        if (cellVal === undefined) cellVal = '';
        // swissAssert(cellVal !== undefined, 'Column Row val cannot be undefined');
        let valNum = typeof cellVal === 'number' ? (cellVal as number) : Number.MAX_SAFE_INTEGER;
        let shouldAgg = typeof cellVal === 'number';
        return {
            shouldAgg: shouldAgg,
            // TODO: can be removed
            valText: `${cellVal}`,
            val: valNum,
        };
    };

    const newCellRangeAgg = (): CellRangeAgg => new CellRangeAgg(props.config.uniqueTableName, cellParser, props.config.aggFormatVal);
    const deepCopyCellRangeAgg = (cellRangeAgg: CellRangeAgg): CellRangeAgg =>
        cellRangeAgg.deepCopy(props.config.uniqueTableName, cellParser, props.config.aggFormatVal);

    const initSelectingState: SelectingState = {
        cellRangeAgg: newCellRangeAgg(),
        isMouseSelecting: false,
        hasCtrKeyPressed: false,
        hasShiftKeyPressed: false,
    };
    const [selectingState, setSelectingState] = useState<SelectingState>(initSelectingState);

    // We use useRef as otherwise we would need ot register the event listeners
    // on each state change as they are not binded to selectingState causing them
    // to have a stale state
    const refSelectingState = useRef(selectingState);
    useEffect(() => {
        refSelectingState.current = selectingState;
    }, [selectingState]);

    const refIsCurrentTableSelected = useRef(false);
    useEffect(() => {
        refIsCurrentTableSelected.current =
            props.footerCellAgg.uniqueTableName !== null && selectingState.cellRangeAgg.isSameTable(props.footerCellAgg.uniqueTableName);
    }, [props, selectingState]);

    const onMouseDown = (rowIndex: number, colIndex: number) => {
        // If you click on non selectable cells and you are not selecting just reset the selection to clear
        // things up
        if (colIndex <= props.config.pivotDepth && !refSelectingState.current.isMouseSelecting) {
            setSelectingState({ ...initSelectingState });
        }
        if (colIndex <= props.config.pivotDepth) return;
        // We might move cursor away from element causing the mouse up not to be triggered
        // in this case simply on the next time we mouse click it will restart from the beginning
        let cellRange: CellRange = {
            start: { row: rowIndex, col: colIndex },
            end: { row: rowIndex, col: colIndex },
        };
        // If someone CTRL, clicks on cells, presses escape, and ten while holding CTR still
        // click here there previous items will be kept. So if we are just selecting
        // it for the first time now start with a clear cellRangeAgg
        let cellRangeAgg = refIsCurrentTableSelected.current
            ? deepCopyCellRangeAgg(refSelectingState.current.cellRangeAgg)
            : newCellRangeAgg();
        refSelectingState.current.hasCtrKeyPressed
            ? cellRangeAgg.mutateAppendCellRange(cellRange)
            : cellRangeAgg.mutateClearAndAddCellRange(cellRange);
        setSelectingState({ ...refSelectingState.current, isMouseSelecting: true, cellRangeAgg: cellRangeAgg });
        props.setActiveTableAction({ uniqueTableName: props.config.uniqueTableName });
    };

    const onMouseOver = (rowIndex: number, colIndex: number) => {
        if (colIndex <= props.config.pivotDepth) return;
        // console.log(`onmouse over actually called ${refIsCurrentTableSelected}`);
        if (!refIsCurrentTableSelected || !refSelectingState.current.isMouseSelecting) return;
        // console.log(`is mouse selecting over: ${refSelectingState.current.isMouseSelecting}`);
        swissAssert(
            refSelectingState.current.cellRangeAgg._ranges.length > 0,
            'We cannot onMouseOver over a table we are selecting and not have any range'
        );
        let cellRangeAgg = deepCopyCellRangeAgg(refSelectingState.current.cellRangeAgg);
        cellRangeAgg.mutateUpdateLatestCellRange({ row: rowIndex, col: colIndex });
        setSelectingState({ ...refSelectingState.current, cellRangeAgg: cellRangeAgg });
    };

    const onMouseUp = () => {
        if (!refIsCurrentTableSelected || !refSelectingState.current.isMouseSelecting) return;
        setSelectingState({ ...refSelectingState.current, isMouseSelecting: false });
    };

    const handleKeyDown = (e: any) => {
        if (!refIsCurrentTableSelected) return;
        if (isCtrlKey(e)) {
            setSelectingState({ ...refSelectingState.current, hasCtrKeyPressed: true });
            return;
        } else if (isShiftKey(e)) {
            setSelectingState({ ...refSelectingState.current, hasShiftKeyPressed: true });
            return;
        } else if (isArrowKey(e) && refSelectingState.current.cellRangeAgg.hasSelectedCells()) {
            e.preventDefault(); // Don't enable scrolling of the page when we are selecting cell
            const keyCode = e.keyCode;
            let latestCellRange = refSelectingState.current.cellRangeAgg.getLatestCellRange();
            // Set the end column according to which key is pressed
            // Remember not to go off bounds for < 0 or > than the max col/row dimensions
            switch (keyCode) {
                case KeyCode.LeftKey:
                    latestCellRange.end.col = Math.max(latestCellRange.end.col - 1, props.config.pivotDepth + 1);
                    break;
                case KeyCode.UpKey:
                    latestCellRange.end.row = Math.max(latestCellRange.end.row - 1, 0);
                    break;
                case KeyCode.RightKey:
                    latestCellRange.end.col = Math.min(latestCellRange.end.col + 1, props.config.columns.length - 1);
                    break;
                case KeyCode.DownKey:
                    latestCellRange.end.row = Math.min(latestCellRange.end.row + 1, props.data.length - 1);
                    break;
                default:
                    throw Error(`Arrow Key is pressed but we dont recognize ${keyCode}`);
            }
            // Moving keys means always only one have 1 cell range
            let cellRangeAgg = newCellRangeAgg();
            if (!refSelectingState.current.hasShiftKeyPressed) {
                // If we are not pressing shift then just select on cell by setting them be the same
                // make sure to copy it otherwise we will only get a reference
                latestCellRange.start = { ...latestCellRange.end };
            }
            cellRangeAgg.mutateClearAndAddCellRange(latestCellRange);
            setSelectingState({ ...refSelectingState.current, cellRangeAgg: cellRangeAgg });
        }
    };

    const clearAllSelections = () => {
        setSelectingState({ ...initSelectingState });
        props.setActiveTableAction({ uniqueTableName: null });
    };

    const handleKeyUp = (e: any) => {
        if (isEscKey(e)) {
            // Clear everything no matter what to ensure everything gets unselected
            clearAllSelections();
            return;
        }

        if (isCtrlKey(e)) {
            setSelectingState({ ...refSelectingState.current, hasCtrKeyPressed: false });
            return;
        } else if (isShiftKey(e)) {
            setSelectingState({ ...refSelectingState.current, hasShiftKeyPressed: false });
            return;
        } else if (isArrowKey(e)) {
            return;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    });

    let isCurrentTableSelectedDontUseButInRender =
        props.footerCellAgg.uniqueTableName !== null &&
        refSelectingState.current.cellRangeAgg.isSameTable(props.footerCellAgg.uniqueTableName);

    let columnNames = props.config.columns.map((el) => el.name);

    return (
        <Paper className={tableClasses.paper} style={props.style}>
            {props.changeExpansion === undefined && (
                <SimpleTitle data={props.data} allData={props.data} title={props.config.title} columnNames={columnNames} />
            )}
            {props.changeExpansion !== undefined && (
                <ExpandableTableTitle
                    allData={props.allData}
                    data={props.data}
                    title={props.config.title}
                    pivotDepth={props.config.pivotDepth}
                    changeExpansion={props.changeExpansion}
                    columnNames={columnNames}
                />
            )}
            <Table className={sizeClasses.root}>
                <TableHead>
                    <TableRow>
                        {props.config.columns.map((columnConfig) => {
                            let headerClassNames = classNames({
                                [`${columnConfig.headerCellClassName}`]: columnConfig.headerCellClassName,
                                [`${tableClasses.stickyHeader}`]: props.config.stickyHeader,
                            });
                            let customStyles: any = {
                                backgroundColor: 'white',
                            };
                            if (columnConfig.headerBackgroundColor) {
                                customStyles['backgroundColor'] = columnConfig.headerBackgroundColor;
                            }
                            if (columnConfig.headerTextColor) {
                                customStyles['color'] = columnConfig.headerTextColor;
                            }
                            return (
                                <TableCell key={columnConfig.name} style={customStyles} className={headerClassNames}>
                                    <Tooltip
                                        placement="top"
                                        interactive
                                        title={
                                            columnConfig.headerHoverElement === undefined
                                                ? ''
                                                : (columnConfig!.headerHoverElement!(columnConfig.name, columnConfig.title) as ReactChild)
                                        }>
                                        <span>
                                            <span>{columnConfig.title}</span>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row, rowIdx) => {
                        // Make sure to use a table unique key not just the rowIdx as this changes for expandable rows
                        // For multilevel expandable tables this would be an issue
                        let key = row['_key'] ? (row['_key'] as string) : `${rowIdx}`;
                        return (
                            <SimpleTableRowComp
                                key={key}
                                data={props.data}
                                row={row}
                                rowIdx={rowIdx}
                                lastRowIdx={props.data.length - 1}
                                isCurrentTableSelected={isCurrentTableSelectedDontUseButInRender}
                                pivotDepth={props.config.pivotDepth}
                                toggleExpandedRow={props.toggleExpandedRow}
                                cellRangeAgg={selectingState.cellRangeAgg}
                                onMouseDown={onMouseDown}
                                onMouseOver={onMouseOver}
                                onMouseUp={onMouseUp}
                                config={props.config}
                                grandTotalClass={sizeClasses.grandTotal}
                                filters={props.filters}
                                clearAllSelections={clearAllSelections}
                            />
                        );
                    })}
                </TableBody>
            </Table>
            <TableAggFooter cellRangeAgg={selectingState.cellRangeAgg} />
        </Paper>
    );
};

type SimpleTableRowCommon = {
    data: SimpleTableData;
    rowIdx: number;
    row: SimpleTableRow;
    lastRowIdx: number;
    isCurrentTableSelected: boolean;
    cellRangeAgg: CellRangeAgg;
    onMouseDown: (rowIdx: number, columnIdx: number) => void;
    onMouseOver: (rowIdx: number, columnIdx: number) => void;
    onMouseUp: () => void;
    filters?: any; // used for re-render purposes
    clearAllSelections: () => void;
};

type SimpleTableRowCompProps = SimpleTableRowCommon & {
    config: SimpleTableConfig;
    pivotDepth: number;
    toggleExpandedRow?: ToggleExpandedRow;
    grandTotalClass: string;
};

const SimpleTableRowRawComp = (props: SimpleTableRowCompProps) => {
    let isTableExpandable = props.pivotDepth > 0;
    let isRowExpandable = isTableExpandable && (props.row['_isExpandable'] as boolean);
    // We apply the custom row class name in the row and in the cell. See where we apply it in the cell on why on both
    let hasCustomRowClassName = props.config.customRowClassName !== undefined;
    let customClassName: string = hasCustomRowClassName ? props.config.customRowClassName!(props.row) : '';
    let rowClasses = classNames({
        [`${props.grandTotalClass}`]: props.config.grandTotal && props.rowIdx === props.lastRowIdx,
        [`${customClassName}`]: hasCustomRowClassName,
    });
    let rowStyle: CSSProperties = {};
    if (props.config.rowBodyBackgroundColor) {
        rowStyle['backgroundColor'] = props.config.rowBodyBackgroundColor(props.row);
    }
    return (
        <TableRow hover className={rowClasses} style={rowStyle}>
            {props.config.columns.map((columnConfig, columnIdx) => {
                if (isTableExpandable && columnIdx <= props.pivotDepth! && (columnIdx as number | string) < props.row['_depth']) {
                    return (
                        <td
                            key={`${props.row['_key']}-${columnConfig.name}`}
                            style={{ border: 'none', background: 'none' }}
                            onMouseDown={() => {
                                // cannot set pointerEvents CSS to none or the onMouseDow will not get triggered
                                // Still listen to on mouse down so that we deselect the cell
                                props.onMouseDown(props.rowIdx, columnIdx);
                            }}
                        />
                    );
                }
                return (
                    <SimpleTableCellComp
                        key={columnIdx}
                        {...props}
                        rawVal={props.row[columnConfig.name]}
                        columnConfig={columnConfig}
                        isCellExpandable={isRowExpandable && props.row['_depth'] === columnIdx}
                        cellDepth={props.row['_depth'] as number}
                        toggleExpandedRow={() => {
                            props.toggleExpandedRow!(props.row);
                        }}
                        isCellExpanded={props.row['_isExpanded'] === true}
                        columnIdx={columnIdx}
                    />
                );
            })}
        </TableRow>
    );
};

function tableRowsAreEqual(prevProps: SimpleTableRowCompProps, nextProps: SimpleTableRowCompProps) {
    // We need to compare the data as when the data itself changes (collapsing/expanding columns)
    // we need to redraw everything
    return (
        prevProps.filters === nextProps.filters &&
        prevProps.data === nextProps.data &&
        !prevProps.cellRangeAgg.isRowSelected(prevProps.rowIdx) &&
        !nextProps.cellRangeAgg.isRowSelected(prevProps.rowIdx)
    );
}

const SimpleTableRowComp = React.memo(SimpleTableRowRawComp, tableRowsAreEqual);

const tableCellStyles = makeStyles(() => ({
    selected: {
        /*border: 1px double rgb(33, 133, 208);*/
        /*transition: none;*/
        boxShadow: 'inset 0 -100px 0 rgba(33, 133, 208, 0.15)',
    },
}));

type SimpleTableCellCompProps = SimpleTableRowCommon & {
    rawVal: any;
    config: SimpleTableConfig;
    columnIdx: number;
    cellDepth: number;
    isCellExpandable: boolean;
    isCellExpanded: boolean;
    toggleExpandedRow: () => void;
    columnConfig: SimpleTableColumnConfig;
};

export type ColumnMenuProps = {
    uniqueTableName: UniqueTableName;
    columnConfig: SimpleTableColumnConfig;
    row: SimpleTableRow;
    anchorEl: HTMLElement | null;
    onClose: () => void;
};

export type ColumnOnClickProps = {
    columnConfig: SimpleTableColumnConfig;
    row: SimpleTableRow;
};

const SimpleTableCellRawComp = (props: SimpleTableCellCompProps) => {
    const classes = tableCellStyles();
    let isSelected =
        props.isCurrentTableSelected &&
        props.cellRangeAgg.isCellSelected({
            row: props.rowIdx,
            col: props.columnIdx,
        });
    // Unfortunately if we want to apply a custom row class we have to apply it both in the row classes and in the
    // cell classes. Because for example the cell overrides the font-weight so if we apply a class (let's say bold)
    // in the row it will be overridden by .MuiTableCell-root which has different font weight
    let hasCustomRowClassName = props.config.customRowClassName !== undefined;
    let customClassName: string = hasCustomRowClassName ? props.config.customRowClassName!(props.row) : '';
    let cellClasses = classNames({
        [`${props.columnConfig.bodyCellsClassName}`]: props.columnConfig.bodyCellsClassName !== undefined,
        [`${classes.selected}`]: isSelected,
        [`${customClassName}`]: hasCustomRowClassName,
    });

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <TableCell
                className={cellClasses}
                onMouseDown={() => {
                    props.onMouseDown(props.rowIdx, props.columnIdx);
                }}
                onMouseOver={() => {
                    // console.log('mouse over call');
                    props.onMouseOver(props.rowIdx, props.columnIdx);
                }}
                onMouseUp={() => {
                    if (props.columnConfig.onClick) {
                        props.columnConfig.onClick({ row: props.row, columnConfig: props.columnConfig });
                    }
                    props.onMouseUp();
                }}
                onContextMenu={(e) => {
                    if (props.columnConfig.menuElement) {
                        e.preventDefault();
                        props.clearAllSelections();
                        setAnchorEl(e.currentTarget);
                    }
                }}>
                <SimpleTableCellRawValueComp {...props} />
            </TableCell>
            {props.columnConfig.menuElement &&
                props.columnConfig.menuElement({
                    row: props.row,
                    columnConfig: props.columnConfig,
                    anchorEl,
                    onClose,
                    uniqueTableName: props.config.uniqueTableName,
                })}
        </React.Fragment>
    );
};

function tableCellsAreEqual(prevProps: SimpleTableCellCompProps, nextProps: SimpleTableCellCompProps) {
    return (
        prevProps.filters === nextProps.filters &&
        prevProps.data === nextProps.data &&
        !prevProps.cellRangeAgg.isCellSelected({ row: prevProps.rowIdx, col: prevProps.columnIdx }) &&
        !nextProps.cellRangeAgg.isCellSelected({ row: nextProps.rowIdx, col: nextProps.columnIdx })
    );
}

const SimpleTableCellRawValueComp = (props: SimpleTableCellCompProps) => {
    let displayVal = props.rawVal;
    if (props.columnConfig.customDisplay !== undefined) {
        return props.columnConfig.customDisplay(props.row, displayVal);
    }
    let customStyles: any = { display: 'flex', alignItems: 'center' };
    let customOuterStyles: any = { padding: '2px 5px', borderRadius: '5px' };
    if (props.columnConfig.textColor && props.columnConfig.textColor(props.rawVal) != null) {
        customStyles['color'] = props.columnConfig.textColor(props.rawVal);
    }
    if (props.columnConfig.bodyTemplate) {
        displayVal = props.columnConfig.bodyTemplate(props.rawVal);
    } else if (props.columnConfig.bodyTemplate2) {
        displayVal = props.columnConfig.bodyTemplate2(props.rawVal, props.row);
    }
    // The background color we want it to apply to the outter div
    if (props.columnConfig.bodyBackgroundColor !== undefined) {
        customOuterStyles['backgroundColor'] = props.columnConfig.bodyBackgroundColor!(props.row, props.rawVal);
    }
    if (props.columnConfig.bodyCustomStyle !== undefined) {
        customOuterStyles = { ...customOuterStyles, ...props.columnConfig.bodyCustomStyle!(props.row, props.rawVal) };
    }
    return (
        <div style={customOuterStyles}>
            <div style={customStyles}>
                {props.isCellExpandable && (
                    <IconButton component="span" style={{ padding: 0 }} onClick={() => props.toggleExpandedRow()}>
                        {props.isCellExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                )}
                <Tooltip
                    placement="top"
                    title={
                        props.columnConfig.hoverElement === undefined
                            ? ''
                            : (props.columnConfig!.hoverElement(props.row, displayVal) as ReactChild)
                    }>
                    <span>
                        {props.columnConfig.trendDirection && (
                            <TrendDirection2 {...props.columnConfig.trendDirection} val={props.rawVal as number} />
                        )}
                        <span>{displayVal}</span>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

const SimpleTableCellComp = React.memo(SimpleTableCellRawComp, tableCellsAreEqual);

export default connector(SimpleTable);
