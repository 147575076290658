import { SwissColors } from './stylesConsts';
import { ChartType } from '../containers/LineGraph';

export const P_FEI = 'FEI';
export const P_FEI_C4 = 'FEI C4';
export const P_CP = 'CP';
export const P_CP_C4 = 'CP C4';
export const P_ARA = 'ARA';
export const P_ARA_C4 = 'ARA C4';
export const P_NWE_NAPHTHA = 'NWE Naptha';
export const P_MOPJ = 'MOPJ';
export const P_LST = 'LST';
export const P_EPC = 'EPC';
export const P_EPC_C4 = 'EPC C4';
export const P_BLPG = 'BLPG';
export const P_BLPG3 = 'BLPG3';
export const P_WTI = 'WTI';
export const P_BRT = 'BRT';
export const P_VLSFO = 'VLSFO';

export const VP_MTB = 'MTB';
export const VP_NAPHTHA = 'NAPHTHA';
export const VP_CRUDE = 'CRUDE';
export const VP_PETRO_CHEM = 'PETRO_CHEM';

export type ProductMeta = {
    title: string;
    backgroundColor: string;
    textColor: string;
    chartType?: ChartType;
    stackId?: string;
    strokeDasharray?: string;
    fillOpacity?: number;
};

export const productMap = new Map<string, ProductMeta>();
productMap.set(P_FEI, {
    title: P_FEI,
    backgroundColor: SwissColors.Blue,
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_FEI_C4, {
    title: P_FEI_C4,
    backgroundColor: '#547187',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_CP, {
    title: P_CP,
    backgroundColor: 'black',
    textColor: 'white',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_CP_C4, {
    title: P_CP_C4,
    backgroundColor: '#6e6f70',
    textColor: 'white',
});
productMap.set(P_ARA, {
    title: P_ARA,
    backgroundColor: '#75d8ff',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_ARA_C4, {
    title: P_ARA_C4,
    backgroundColor: '#00befd',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_NWE_NAPHTHA, {
    title: 'NWE Nap',
    backgroundColor: '#e6b170',
    textColor: 'black',
});
productMap.set(P_MOPJ, {
    title: P_MOPJ,
    backgroundColor: '#f5d362',
    textColor: 'black',
});
productMap.set(P_LST, {
    title: P_LST,
    backgroundColor: '#25b23c',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_EPC, {
    title: P_EPC,
    backgroundColor: '#b8d678',
    textColor: 'black',
});
productMap.set(P_EPC_C4, {
    title: P_EPC_C4,
    backgroundColor: '#dbdb4f',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(P_BLPG, {
    title: P_BLPG,
    backgroundColor: '#ca86d9',
    textColor: 'black',
    chartType: ChartType.AreaAsLine,
    strokeDasharray: '5 5',
    fillOpacity: 0,
});
productMap.set(P_BLPG3, {
    title: P_BLPG3,
    backgroundColor: '#f280d0',
    textColor: 'black',
    chartType: ChartType.AreaAsLine,
    strokeDasharray: '5 5',
    fillOpacity: 0,
});
productMap.set(P_WTI, {
    title: P_WTI,
    backgroundColor: '#f5efc1',
    textColor: 'black',
});
productMap.set(P_BRT, {
    title: P_BRT,
    backgroundColor: '#f5ccc1',
    textColor: 'black',
});
productMap.set(P_VLSFO, {
    title: P_VLSFO,
    backgroundColor: '#f5822a',
    textColor: 'black',
});
productMap.set(VP_MTB, {
    title: VP_MTB,
    backgroundColor: SwissColors.Green,
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(VP_CRUDE, {
    title: 'Crude',
    backgroundColor: '#f5dec1',
    textColor: 'black',
});
productMap.set(VP_NAPHTHA, {
    title: 'All Naphtha',
    backgroundColor: '#c4a176',
    textColor: 'black',
    chartType: ChartType.Bar,
    stackId: 'a',
});
productMap.set(VP_PETRO_CHEM, {
    title: 'Flat Price',
    backgroundColor: SwissColors.Red,
    textColor: 'black',
    chartType: ChartType.AreaAsLine,
    fillOpacity: 0,
});
