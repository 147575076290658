import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import VesselRunningCost from './VesselRunningCost';
import { UniqueTableName } from '../../common/uniqueTableName';
import { kFormatter, kFormatterWithParenthesis } from '../../containers/pivotHelper';
import { exposuresNumFormatter } from '../../common/exposures';
import VesselHistCostGraph from './VesselHistCostGraph';
import { Grid } from '@material-ui/core';
import { MIN_12_12_EL_WIDTH } from '../../common/common';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const VesselsComp = (props: Props) => {
    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AccountingDanaosVessels,
            params: {
                start_date: filters.quickDateSelection!.start_date,
                end_date: filters.quickDateSelection!.end_date,
                cmp_start_date: filters.quickDateSelection!.cmp_start_date,
                cmp_end_date: filters.quickDateSelection!.cmp_end_date,
                company: filters.quickDateSelection!.company,
            },
        });
    }, [props, filters]);

    return (
        <React.Fragment>
            <VesselRunningCost
                uniqueTableName={UniqueTableName.DailyVesselRunningCost}
                title={'Daily Vessel Running Cost'}
                tableDataKey={'vessel_cost_daily'}
                reportValFormatter={exposuresNumFormatter}
                moveValFormatter={exposuresNumFormatter}
                zeroThreshold={1}
                {...props}
            />
            <VesselRunningCost
                uniqueTableName={UniqueTableName.YearlyVesselRunningCost}
                title={'Yearly Vessel Running Cost'}
                tableDataKey={'vessel_cost_yearly'}
                reportValFormatter={kFormatterWithParenthesis}
                moveValFormatter={kFormatter}
                zeroThreshold={100}
                {...props}
            />
            <Grid item xs={12} style={{ backgroundColor: 'clear', marginTop: '50px', minWidth: MIN_12_12_EL_WIDTH }}>
                <VesselHistCostGraph {...props} />
            </Grid>
        </React.Fragment>
    );
};

export default accFetchConnector(VesselsComp);
