import React, { ReactNode } from 'react';
import {
    AllTradesType,
    RegularTradesType,
    ExchangeTradesType,
    FieldType,
    TradeOptions,
    ExchangeTradesPropsType,
    AllTradesPropsType,
} from './Types';
import moment from 'moment';
import { validatePromptB } from './regular/promptB';

const IMPERIAL_UNITS = ['BRT', 'WTI', 'LST', 'EPC', 'EPC C4'];
const CRUDE_EXCHANGE = 'CME';
const NO_COMMISSION_BROKERS = ['Trayport'];
const COMBO_MONTHS = ['Q1', 'Q2', 'Q3', 'Q4', 'H1', 'H2', 'Cal'];

export const DEFAULT_OPT_FIELD_STR: FieldType<string> = {
    value: '',
    error: '',
    required: false,
    summary: null,
};

export const DEFAULT_REQ_FIELD_STR: FieldType<string> = {
    value: '',
    error: '',
    required: true,
    summary: null,
};

export const DEFAULT_FIELD_BOOL: FieldType<boolean> = {
    value: false,
    error: '',
    required: false,
    summary: null,
};

export const DEFAULT_PRICE_A_FIELD: FieldType<string> = {
    value: '',
    error: '',
    required: false,
    summary: <span>MOC</span>,
};

export const isCrude = (crudeProducts: Array<string>, productAValue: string): boolean => {
    return crudeProducts.includes(productAValue);
};

export const isProductSpread = (productA: string, productB: string): boolean => {
    return Boolean(productA) && Boolean(productB);
};

const isUSArbSpread = (options: TradeOptions, fields: AllTradesType): boolean => {
    if (!isProductSpread(fields.productA.value, fields.productB.value)) return false;
    return (
        options.mtb_products.includes(fields.productA.value) && !options.mtb_products.includes(fields.productB.value)
    );
};

const isMTB = (options: TradeOptions, fields: AllTradesType): boolean => {
    let mtbProducts = options.mtb_products;
    return mtbProducts.includes(fields.productA.value) || mtbProducts.includes(fields.productB.value);
};

export const isComboTimeSpread = (monthAValue: string, monthBValue: string): boolean => {
    let isTimeSpread = Boolean(monthAValue) && Boolean(monthBValue);
    return isTimeSpread && (COMBO_MONTHS.includes(monthAValue) || COMBO_MONTHS.includes(monthBValue));
};

export const derivedVolume = (options: TradeOptions, fields: AllTradesType): string => {
    if (!fields.productA.value) return '';
    if (isUSArbSpread(options, fields)) return 'MT';
    return isMTB(options, fields) || isCrude(options.crude_products, fields.productA.value) ? 'BBL' : 'MT';
};

export function volumeRangeError(volumeValue: string): string {
    if (volumeValue === '') return '';
    let volumeErrorText: string = '';
    let parsedVolume = parseInt(volumeValue);
    if (isNaN(parsedVolume)) {
        volumeErrorText = 'Needs to be a number';
    } else if (parsedVolume === 0) {
        volumeErrorText = "Can't be 0";
    } else if (parsedVolume < 500 && parsedVolume > 0) {
        volumeErrorText = "Can't be 0..500";
    } else if (parsedVolume > 500000) {
        volumeErrorText = "Can't be 500k+";
    } else if (parsedVolume > -500 && parsedVolume < 0) {
        volumeErrorText = "Can't be -500..0";
    } else if (parsedVolume < -500000) {
        volumeErrorText = "Can't be -500k+";
    }
    return volumeErrorText;
}

export function commissionError(commissionValue: string): string {
    if (commissionValue === '') return '';
    let commissionErrorText: string = '';
    const parsedCommission = parseFloat(commissionValue);
    if (isNaN(parsedCommission)) {
        commissionErrorText = 'Must be a number or left blank';
    }
    return commissionErrorText;
}

export const getBalmoDateSummary = (balmoDateValue: string): ReactNode => {
    let balmoDay = moment(balmoDateValue).format('D');
    return <span> BALMO {balmoDay}</span>;
};

export const executingForSummary = (authUserNickName: string, executingForNickName: string): ReactNode => {
    return authUserNickName === executingForNickName
        ? `${authUserNickName}`
        : `${authUserNickName} for ${executingForNickName}`;
};

export function handleAllTradesSimpleFieldChange<T>(
    state: AllTradesType,
    setState: (newState: AllTradesType) => void,
    key: string,
    newValue: FieldType<T>
) {
    if (!Object.keys(state).includes(key)) {
        throw Error(`Compile/runtime error: ${key} is not a valid state key`);
    }
    setState({
        ...state,
        [key]: newValue,
    });
}

export function handleExchangeProductChange(
    props: ExchangeTradesPropsType,
    newProductA: FieldType<string>,
    newProductB: FieldType<string>
): void {
    let broker = props.state.broker;
    let diff = props.state.diff;
    let exchange = props.state.exchange;
    if (isCrude(props.options.crude_products, newProductA.value)) {
        // It's ok to override productB even though we might be updating it as we are not going to
        // be in a situation where this case should be true
        newProductB = { value: '', error: '', required: false, summary: null };
        broker = { value: '', error: '', required: false, summary: null };
        diff = { value: false, error: '', required: false, summary: null };
        exchange = { value: CRUDE_EXCHANGE, error: '', required: true, summary: null };
    } else {
        newProductB = { ...newProductB };
        broker = { ...broker, required: true };
        diff = { ...diff };
    }
    if (isCrude(props.options.crude_products, newProductB.value)) {
        // Product B cannot be Crude
        newProductB = { value: '', error: '', required: false, summary: null };
    }
    if (!isProductSpread(newProductA.value, newProductB.value)) {
        diff = { value: false, error: '', required: false, summary: null };
    }

    // Changing product A might require change in requirement of isConvertedLegVolume
    let convertedLegVolume = getNewConvertedLegVolumeState(
        { ...props.state.convertedLegVolume },
        newProductA,
        newProductB,
        props.state.diff
    );

    props.setState({
        ...props.state,
        productA: newProductA,
        productB: newProductB,
        diff: diff,
        broker: broker,
        exchange,
        convertedLegVolume: convertedLegVolume,
    });
}

export function handleNonExchangeProductChange(
    props: AllTradesPropsType,
    newProductA: FieldType<string>,
    newProductB: FieldType<string>
): void {
    if (isCrude(props.options.crude_products, newProductA.value)) {
        // It's ok to override productB even though we might be updating it as we are not going to
        // be in a situation where this case should be true
        newProductB = { value: '', error: '', required: false, summary: null };
    } else {
        newProductB = { ...newProductB };
    }
    if (isCrude(props.options.crude_products, newProductB.value)) {
        // Product B cannot be Crude
        newProductB = { value: '', error: '', required: false, summary: null };
    }

    props.setState({
        ...props.state,
        productA: newProductA,
        productB: newProductB,
    });
}

export function handleExchangeBrokerFieldChange(
    state: ExchangeTradesType,
    setState: (newState: ExchangeTradesType) => void,
    newBroker: FieldType<string>
): void {
    let commissionOverride = state.commissionOverride;
    if (NO_COMMISSION_BROKERS.includes(newBroker.value)) {
        commissionOverride = { value: '0', error: '', required: true, summary: null };
    }
    setState({
        ...state,
        broker: newBroker,
        commissionOverride,
    });
}

export function handleSimpleFieldChange<T>(
    state: RegularTradesType,
    setState: (newState: RegularTradesType) => void,
    key: string,
    newValue: FieldType<T>
) {
    if (!Object.keys(state).includes(key)) {
        throw Error(`Compile/runtime error: ${key} is not a valid state key`);
    }
    setState({
        ...state,
        [key]: newValue,
    });
}

export function handleExchangeSimpleFieldChange<T>(
    state: ExchangeTradesType,
    setState: (newState: ExchangeTradesType) => void,
    key: string,
    newValue: FieldType<T>
) {
    if (!Object.keys(state).includes(key)) {
        throw Error(`Compile/runtime error: ${key} is not a valid state key`);
    }
    setState({
        ...state,
        [key]: newValue,
    });
}

export const getMonthYearBFields = (
    props: AllTradesPropsType,
    monthAField: FieldType<string>,
    yearAField: FieldType<string>,
    monthBValue: string,
    threeLetterNewMonthB: string,
    yearBValue: string
): Array<FieldType<string>> => {
    let monthB: FieldType<string> = {
        value: monthBValue,
        error: '',
        summary: null,
        required: props.state.monthB.required,
    };
    let yearB: FieldType<string> = {
        value: yearBValue,
        error: '',
        summary: null,
        required: props.state.yearB.required,
    };
    let errorText = validatePromptB(props, monthAField, yearAField, monthBValue, threeLetterNewMonthB, yearBValue);
    if (!errorText && monthB.value !== '') {
        monthB.summary = <span>/{monthB.value}</span>;
    } else {
        monthB.error = errorText;
        yearB.error = errorText;
    }
    return [monthB, yearB];
};

export function getDataInputError<T>(key: string, field: FieldType<T>): string {
    let capitalizedKey: string = key.charAt(0).toUpperCase() + key.slice(1);
    if (typeof field.value === 'string' && field.required && field.value === '') {
        return `${capitalizedKey}: is Required`;
    }

    if (field.error !== '') {
        return `${capitalizedKey}: ${field.error}`;
    }
    return '';
}

export function getNewConvertedLegVolumeState(
    currConvertedLegVolume: FieldType<string>,
    productA: FieldType<string>,
    productB: FieldType<string>,
    diff: FieldType<boolean>
): FieldType<string> {
    let convertedLegVolume = { ...currConvertedLegVolume };
    convertedLegVolume.required = shouldHaveConvertedLegVolumes(productA, productB, diff);
    if (!convertedLegVolume.required) {
        convertedLegVolume = { ...convertedLegVolume, value: '' };
    }
    return convertedLegVolume;
}

export function shouldHaveConvertedLegVolumes(
    productA: FieldType<string>,
    productB: FieldType<string>,
    diff: FieldType<boolean>
): boolean {
    let isProductAImperial = IMPERIAL_UNITS.indexOf(productA.value) > -1;
    let isProductBImperial = IMPERIAL_UNITS.indexOf(productB.value) > -1;
    let isImperialSpread = isProductAImperial && !isProductBImperial;
    return isProductSpread(productA.value, productB.value) && isImperialSpread && !diff.value;
}
