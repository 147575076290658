import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import {
    P_ARA,
    P_ARA_C4,
    P_BLPG,
    P_BLPG3,
    P_BRT,
    P_CP,
    P_CP_C4,
    P_EPC,
    P_EPC_C4,
    P_FEI,
    P_LST,
    P_NWE_NAPHTHA,
    P_WTI,
    productMap,
    ProductMeta,
} from '../../common/products';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { BackOfficeMiscDataResp } from '../fetchTypes';
import { Tooltip } from '@material-ui/core';
import chroma from 'chroma-js';
import { makeStyles } from '@material-ui/core/styles';
import { SwissColors } from '../../common/stylesConsts';
import SimpleTable from '../../tables/SimpleTable';
import { kFormatterWithSign } from '../common/common';
import { exposuresNumFormatter } from '../../common/exposures';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const useStyles = makeStyles(() => ({
    bodyProductCell: {
        padding: '0 !important',
        border: '0 !important',
        height: '10px !important', // Needed so that the 100% height will work
    },
}));

const pivotColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'term_month_date',
        title: 'Term',
        bodyTemplate: (val: any) => {
            if (val === '') return val;
            return moment(val).format('MMM YY');
        },
    },
];

const productColumns = [P_FEI, P_LST, P_EPC, P_CP, P_CP_C4, P_ARA, P_ARA_C4, P_NWE_NAPHTHA, P_EPC_C4, P_BLPG, P_BLPG3, P_WTI, P_BRT];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productName) => {
    let productMeta: ProductMeta = productMap.get(productName)!;
    return {
        name: `${productName}|gross_pnl_dtd`,
        title: productMeta.title,
        textColor: (_) => 'black',
        headerBackgroundColor: productMeta.backgroundColor,
        headerTextColor: productMeta.textColor,
        customDisplay: (row: SimpleTableRow, rawVal: any) => {
            if (rawVal === 0 || rawVal === '0' || Math.floor(Math.abs(rawVal)) === 0 || rawVal === undefined) return <span>{''}</span>;
            let rawFloat = row[`${productName}|gross_pnl_dtd`] as number;
            let displayVal = kFormatterWithSign(rawFloat);
            let todayGross = kFormatterWithSign(row[`${productName}|gross_pnl`] as number);
            let previousDayGross = '';
            if (row[`${productName}|gross_pnl_m1`]) {
                previousDayGross = kFormatterWithSign(row[`${productName}|gross_pnl_m1`] as number);
            }
            let customStyles: any = {
                width: '100%',
                height: '100%',
                display: 'block',
            };
            let minMax = rawFloat < 0 ? (row['_min_gross_pnl_dtd'] as number) : (row['_max_gross_pnl_dtd'] as number);
            let scale = rawFloat < 0 ? chroma.scale(['white', SwissColors.Red]) : chroma.scale(['white', SwissColors.Green]);
            let percent = (rawFloat as number) / minMax;
            customStyles['backgroundColor'] = scale(percent).hex();

            let todayExposure = exposuresNumFormatter(row[`${productName}|exposure_display`] as number | undefined);
            let previousDayExposure = exposuresNumFormatter(row[`${productName}|exposure_display_m1`] as number | undefined);

            return (
                <Tooltip
                    placement="top"
                    title={
                        <React.Fragment>
                            <p>
                                Today's Gross: <strong>{todayGross}</strong>
                            </p>
                            <p>
                                Previous Day Gross: <strong>{previousDayGross}</strong>
                            </p>
                            <p>
                                Today's Exposure: <strong>{todayExposure}</strong>
                            </p>
                            <p>
                                Previous Day Exposure: <strong>{previousDayExposure}</strong>
                            </p>
                        </React.Fragment>
                    }>
                    <span style={customStyles}>{displayVal}</span>
                </Tooltip>
            );
        },
    };
});

const allColumns = pivotColumns.concat(productsColumnConfig);

const config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.PnLDtD,
    title: 'DtD Gross',
    size: TableSize.Small,
    columns: allColumns,
    grandTotal: false,
    aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
        return aggFuncName === AggFuncName.Count ? val : kFormatterWithSign(val);
    },
    pivotDepth: 0,
    stickyHeader: true,
};

const DtDPnL = (props: Props) => {
    const classes = useStyles();
    const backOfficeMiscData = props.pnlAnalytics.back_office_misc;

    if (backOfficeMiscData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.BackOfficeMisc} data={backOfficeMiscData} minHeight={'552px'} />
        );
    }
    config.columns.forEach((el, _) => {
        el.bodyCellsClassName = classes.bodyProductCell;
    });

    let respData = backOfficeMiscData.data! as BackOfficeMiscDataResp;

    return <SimpleTable data={respData.dtd_changes} config={config} />;
};

export default fetchConnector(DtDPnL);
