import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import LookAheadSelectCompose, { LookAheadState } from '../common/LookAheadSelectCompose';
import PaperExpByMonth from './PaperExpByMonth';
import { isAuthScreenShotBot } from '../../common/auth';
import PerBookExp from '../common/perBookExposures/PerBookExp';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const PaperLookAhead = (props: Props) => {
    let isScreenShotBot = isAuthScreenShotBot(props.authUser);
    return (
        <LookAheadSelectCompose>
            {(lookAheadState: LookAheadState) => (
                <React.Fragment>
                    <PaperExpByMonth lookAheadState={lookAheadState} />
                    {!isScreenShotBot && <PerBookExp lookAheadState={lookAheadState} />}
                </React.Fragment>
            )}
        </LookAheadSelectCompose>
    );
};

export default fetchConnector(PaperLookAhead);
