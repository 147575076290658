import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import { siteErrorOpenAction } from './actions/siteAlerts';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { NotFoundPage } from './common/error';
import SecondaryAppBar, { SecondaryDrawerBar } from './containers/SecondaryAppBar';
import { Box } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ListIcon from '@material-ui/icons/List';
import HistoryIcon from '@material-ui/icons/History';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TasksList from './TasksList';
import TasksHistory from './TasksHistory';

const drawerWidth = 180;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            // backgroundColor: theme.palette.background.default,
            // padding: theme.spacing(3),
        },
    })
);

const mapState = (_: RootState) => ({});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const TasksHome = (_: Props) => {
    const classes = useStyles();
    let history = useHistory();
    let location = useLocation();

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left">
                <div className={'MuiToolbar-dense'} />
                <SecondaryDrawerBar title={'Tasks'} />
                <List>
                    <ListItem
                        button
                        selected={location.pathname === '/tasks/history'}
                        onClick={() => {
                            history.push('/tasks/history');
                        }}>
                        <ListItemIcon>
                            <HistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={'History'} />
                    </ListItem>
                    <ListItem
                        button
                        selected={location.pathname === '/tasks/list'}
                        onClick={() => {
                            history.push('/tasks/list');
                        }}>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary={'List'} />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={'MuiToolbar-dense'} />
                <Switch>
                    <Route path="/tasks/history">
                        <SecondaryAppBar title={'Tasks History'} backButtonUrl={null} />
                        <Box className={'MuiToolbar-gutters'} my={2}>
                            <TasksHistory />
                        </Box>
                    </Route>
                    <Route path="/tasks/list">
                        <SecondaryAppBar title={'All Tasks'} backButtonUrl={null} />
                        <Box className={'MuiToolbar-gutters'} my={2}>
                            <TasksList />
                        </Box>
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
            </main>
        </div>
    );
};

export default connector(TasksHome);
