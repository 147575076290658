import React, { ReactElement } from 'react';

export const currencyToLetter = (currency: string): string => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            throw Error(`Unsupported currency ${currency}`);
    }
};

const institutionNameToShortName = (institutionName: string): string => {
    switch (institutionName) {
        case 'National Bank of Greece':
            return 'NBG';
        case 'Eurobank Ergasias':
            return 'Eurobank';
        default:
            throw Error(`Unsupported bank ${institutionName}`);
    }
};

export const bankImgAndName = (bankIdValue: string, idName: string, institutions: Array<any>): ReactElement => {
    let institution = institutions.find((institution) => institution[idName] === bankIdValue);
    if (!institution) throw Error(`Did not find Bank Institution ${bankIdValue} for ${idName}`);
    let src = `data:image/png;base64, ${institution['logo']}`;
    return (
        <div>
            <img src={src} style={{ width: '25px', height: 'auto' }} alt="Red dot" />
            <span style={{ verticalAlign: 'super', marginLeft: '5px' }}>
                {institutionNameToShortName(institution['bank_name'])}
            </span>
        </div>
    );
};
