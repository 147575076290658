import { CancelRequest, ErrorContentType } from '../common/fetcher';

export const PNL_ANALYTICS_FETCH_LOADING = 'PNL_ANALYTICS_FETCH_LOADING';
export const PNL_ANALYTICS_FETCH_COMPLETED = 'PNL_ANALYTICS_FETCH_COMPLETED';
export const PNL_ANALYTICS_FETCH_FAILED = 'PNL_ANALYTICS_FETCH_FAILED';
export const PNL_ANALYTICS_CLEAR_ALL_DATA = 'PNL_ANALYTICS_CLEAR_ALL_DATA';
export const PNL_ANALYTICS_CLEAR_ACC_DATA = 'PNL_ANALYTICS_CLEAR_ACC_DATA';

export enum PNLAnalyticsEndpoint {
    Meta = 'meta',
    AllTradesRunDate = 'all_trades_run_date',
    ClearerRunDate = 'clearer_run_date',
    HistoricalPNLPerBookMetrics = 'historical_pnl_per_book_metrics',
    OpenPnlLastYear = 'open_pnl_last_year',
    Exposures = 'exposures',
    BackOfficeMisc = 'back_office_misc',
    // Accounting stuff
    AccountingMeta = 'accounting_meta',
    AccountingDanaosVessels = 'accounting_danaos_vessels',
    // Back Office Accounting
    BAccountingMeta = 'back_accounting_meta',
    BAccountingSCGPhysical = 'back_accounting_scg_physical',
    BACashPlanning = 'back_accounting_cash_planning',
}

export enum PNLFetchStatus {
    INIT = 'INIT',
    LOADING = 'LOADING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

// ================ LOADING ================

export interface FetchLoadingPayloadType {
    endpoint: PNLAnalyticsEndpoint;
    cancelRequest: CancelRequest;
}

export interface FetchLoadingActionType {
    type: typeof PNL_ANALYTICS_FETCH_LOADING;
    payload: FetchLoadingPayloadType;
}

export const fetchLoadingAction = (payload: FetchLoadingPayloadType): FetchLoadingActionType => ({
    type: PNL_ANALYTICS_FETCH_LOADING,
    payload: payload,
});

// ================ COMPLETED ================

export interface FetchCompletedPayloadType {
    endpoint: PNLAnalyticsEndpoint;
    data: any;
}

export interface FetchCompletedActionType {
    type: typeof PNL_ANALYTICS_FETCH_COMPLETED;
    payload: FetchCompletedPayloadType;
}

export const fetchCompletedAction = (payload: FetchCompletedPayloadType): FetchCompletedActionType => ({
    type: PNL_ANALYTICS_FETCH_COMPLETED,
    payload: payload,
});

// ================ FAILED ================

export interface FetchFailedPayloadType {
    endpoint: PNLAnalyticsEndpoint;
    err: ErrorContentType;
}

export interface FetchFailedActionType {
    type: typeof PNL_ANALYTICS_FETCH_FAILED;
    payload: FetchFailedPayloadType;
}

export const fetchFailedAction = (payload: FetchFailedPayloadType): FetchFailedActionType => ({
    type: PNL_ANALYTICS_FETCH_FAILED,
    payload: payload,
});

// ================ CLEAR ================

export interface ClearAllDataActionType {
    type: typeof PNL_ANALYTICS_CLEAR_ALL_DATA;
}

export const clearAllDataAction = (): ClearAllDataActionType => ({
    type: PNL_ANALYTICS_CLEAR_ALL_DATA,
});

export interface ClearAccDataActionType {
    type: typeof PNL_ANALYTICS_CLEAR_ACC_DATA;
}

export const clearAccDataAction = (): ClearAccDataActionType => ({
    type: PNL_ANALYTICS_CLEAR_ACC_DATA,
});

export type PNLAnalyticsActionTypes =
    | FetchLoadingActionType
    | FetchCompletedActionType
    | FetchFailedActionType
    | ClearAllDataActionType
    | ClearAccDataActionType;
