import React from 'react';
import { AllTradesPropsType } from '../Types';
import AutoCompleteField from '../base/AutoCompleteField';
import { handleNonExchangeProductChange, isCrude } from '../common';

interface ProductBFieldProps extends AllTradesPropsType {}

export function InternalProductBField(props: ProductBFieldProps) {
    const { state, options } = props;

    let productBDisabled = props.disabled || isCrude(props.options.crude_products, state.productB.value);

    return (
        <AutoCompleteField
            options={options.products}
            inputValue={state.productB.value}
            handleChange={(newVal: string) => {
                handleNonExchangeProductChange(props, state.productA, {
                    value: newVal,
                    error: '',
                    required: state.productA.required,
                    summary: <span>{newVal}</span>
                });
            }}
            errorText={state.productB.error}
            required={state.productB.required}
            label={'Product B'}
            width={130}
            disabled={productBDisabled}
            marginLeft={'16px'}
        />
    );
}
