import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonSpinner from '../../containers/ButtonSpinner';

interface SubmitButtonsProps {
    dataInputError: string;
    disableSubmitButton: boolean;
    isSubmittingAddClose: boolean;
    isSubmittingAddNext: boolean;
    submitTrade: (isSubmittingAddClose: boolean) => void;
}

export function SubmitButtons(props: SubmitButtonsProps) {
    const { dataInputError, disableSubmitButton, isSubmittingAddClose, isSubmittingAddNext } = props;

    return (
        <Tooltip title={dataInputError} placement={'top'}>
            <span>
                <ButtonSpinner
                    onClick={() => {
                        props.submitTrade(true);
                    }}
                    disabled={disableSubmitButton}
                    loading={isSubmittingAddClose}>
                    Add & Exit
                </ButtonSpinner>
                <ButtonSpinner
                    marginLeft={'16px'}
                    onClick={() => {
                        props.submitTrade(false);
                    }}
                    disabled={disableSubmitButton}
                    loading={isSubmittingAddNext}>
                    Add & Add More
                </ButtonSpinner>
            </span>
        </Tooltip>
    );
}
