import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './reducers';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { DateType } from '@date-io/type';
import ClearIcon from '@material-ui/icons/Clear';
import { EDFFilterTypes, VerifiedState } from './common/edfCommon';
import { getDate } from './common/common';

// const useStyles = makeStyles((_: Theme) =>
//     createStyles({
//         formControl: {
//             minWidth: 120,
//             marginRight: '20px',
//             verticalAlign: 'bottom',
//             marginBottom: '8px',
//         },
//     })
// );

// Default fetch data parameters
export const INIT_SEL_VERIFIED_STATE = VerifiedState.UNVERIFIED;
export const INIT_SEL_DATE = null;
export const INIT_SEARCH_TRADE_IDS = '';

const mapState = (_: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const defaultFilters = {
    verifiedState: INIT_SEL_VERIFIED_STATE,
    selectedDate: INIT_SEL_DATE,
    tradeIdsSubmit: INIT_SEARCH_TRADE_IDS,
} as EDFFilterTypes;

type Props = PropsFromRedux & {
    modificationsDisabled: () => boolean;
    filters: EDFFilterTypes;
    setFilters: (f: EDFFilterTypes) => void;
};

const EdfFilters = (props: Props) => {
    // const classes = useStyles({});
    const { setFilters, filters, modificationsDisabled } = props;
    const { selectedDate } = filters;

    // The input being typed. The default is empty of whatever the URL params where
    const [tradeIdsInput, setTradeIdsInput] = useState(filters.tradeIdsSubmit);

    return (
        <div style={{ marginBottom: '10px' }}>
            {/*<FormControl className={classes.formControl} disabled={modificationsDisabled()}>*/}
            {/*    <InputLabel id="demo-simple-select-helper-label">Verified State</InputLabel>*/}
            {/*    <Select*/}
            {/*        labelId="demo-simple-select-helper-label"*/}
            {/*        id="demo-simple-select-helper"*/}
            {/*        value={verifiedState}*/}
            {/*        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {*/}
            {/*            setFilters({ ...filters, verifiedState: event.target.value as VerifiedState });*/}
            {/*        }}>*/}
            {/*        <MenuItem value={VerifiedState.VERIFIED}>Verified</MenuItem>*/}
            {/*        <MenuItem value={VerifiedState.UNVERIFIED}>Unverified</MenuItem>*/}
            {/*        <MenuItem value={VerifiedState.ALL}>All</MenuItem>*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                disabled={selectedDate === null}
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        selectedDate: null,
                                    });
                                }}>
                                <ClearIcon />
                            </IconButton>
                        ),
                    }}
                    InputAdornmentProps={{
                        position: 'start',
                    }}
                    style={{ marginTop: 0 }}
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="Filter by Run Date"
                    value={selectedDate}
                    maxDate={getDate(-1)}
                    disableFuture={true}
                    autoOk={true}
                    allowKeyboardControl={false}
                    onAccept={(date: DateType | null) => {
                        console.log(date);
                        if (date === null) return;
                        let momentDate = moment(date).startOf('day');
                        // Clear the trade ids search box because we most likely don't want it
                        setTradeIdsInput('');
                        setFilters({
                            ...filters,
                            selectedDate: momentDate,
                            verifiedState: VerifiedState.ALL,
                            tradeIdsSubmit: '',
                        });
                    }}
                    onChange={(_: Date | null, __?: string | null) => {}}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disabled={modificationsDisabled()}
                />
            </MuiPickersUtilsProvider>
            <InputBase
                style={{ verticalAlign: 'bottom', marginBottom: '8px', marginLeft: '16px' }}
                placeholder="Search trade IDs"
                onChange={(e) => {
                    setTradeIdsInput(e.target.value);
                }}
                value={tradeIdsInput}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        setFilters({
                            ...filters,
                            selectedDate: null,
                            verifiedState: VerifiedState.ALL,
                            tradeIdsSubmit: tradeIdsInput,
                        });
                    }
                }}
                disabled={modificationsDisabled()}
            />
            <IconButton
                type="submit"
                aria-label="search"
                style={{ verticalAlign: 'bottom' }}
                disabled={props.modificationsDisabled()}
                onClick={() => {
                    props.setFilters({
                        ...filters,
                        selectedDate: null,
                        verifiedState: VerifiedState.ALL,
                        tradeIdsSubmit: tradeIdsInput,
                    });
                }}>
                <SearchIcon />
            </IconButton>
        </div>
    );
};

export default connector(EdfFilters);
