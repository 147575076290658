import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { accFetchConnector, FetchAccAnalyticsPropsFromRedux } from '../accDataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../pnlAnalytics/PaperPlaceHolder';
import { AccountingDanaosVessels } from '../../pnlAnalytics/fetchTypes';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { SimpleTableColumnConfig, TableSize } from '../../tables/types';
import { AggFuncName } from '../../common/cellRangeAgg';
import { exposuresNumFormatter } from '../../common/exposures';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerStateType } from '../../reducers';
import { SetFiltersPayloadType } from '../../actions/accounting';
import { dollarVal2D, kFormatterWithParenthesis, redOnlyHundred } from '../../containers/pivotHelper';
import { SwissColors } from '../../common/stylesConsts';

type Props = FetchAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const BankBalancesComp = (props: Props) => {
    const danaosVesselsData = props.pnlAnalytics.accounting_danaos_vessels;

    const filters = useSelector<RootReducerStateType, SetFiltersPayloadType>((state) => state.accounting, shallowEqual);

    if (danaosVesselsData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AccountingDanaosVessels}
                data={danaosVesselsData}
                minHeight={'680px'}
            />
        );
    }

    // Construct the config columns
    let respData = danaosVesselsData.data! as AccountingDanaosVessels;

    let data = respData.cash_bank_balances;

    let columnsConfig: Array<SimpleTableColumnConfig> = [
        {
            name: 'company_code',
            title: 'Company',
            bodyTemplate2: (val, row) => {
                return row['company_fixed_name'] ? `[${val}] ${row['company_fixed_name']}` : val;
            },
        },
        {
            name: 'ledger_card_code',
            title: 'Ledger Card',
            bodyTemplate2: (val, row) => {
                return val ? `[${val}] ${row['ledger_card_name']}` : val;
            },
        },
        {
            name: 'amount_starting',
            title: 'Starting Balance',
            bodyTemplate: (val) => {
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
        },
        {
            name: 'amount_ending',
            title: 'Ending Balance',
            bodyTemplate: (val) => {
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
        },
        {
            name: 'change',
            title: 'Change',
            bodyTemplate: (val) => {
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: redOnlyHundred,
        },
        {
            name: 'bank_ic_payments_diff',
            title: 'Bank VS Payments/IC Diff',
            bodyTemplate: (val) => {
                val = val !== null && val <= 0.0001 ? 0 : val;
                return filters.showFractions ? dollarVal2D(val) : kFormatterWithParenthesis(val);
            },
            textColor: (val) => {
                if (Math.abs(val) <= 0.01) return SwissColors.Green;
                return SwissColors.Red;
            },
        },
    ];

    //
    // let columns: Array<string> = respData.cost_sorted_vessel_names.concat(['Total']);
    // let compColumnsConfig: Array<SimpleTableColumnConfig> = columns.map((columnName) => {
    //     return {
    //         name: `${columnName}|amount_report`,
    //         title: (companyFixedNamesMap.get(columnName)?.title as string) || columnName,
    //         customDisplay: (row: SimpleTableRow, rawVal: any) => {
    //             return CostCellComp(
    //                 filters,
    //                 columnName,
    //                 row,
    //                 rawVal,
    //                 data,
    //                 '12px',
    //                 props.reportValFormatter,
    //                 props.moveValFormatter,
    //                 props.zeroThreshold
    //             );
    //         },
    //     };
    // });

    // compColumnsConfig[compColumnsConfig.length - 1].headerCellClassName = classes.totalColumn;
    // compColumnsConfig[compColumnsConfig.length - 1].bodyCellsClassName = classes.totalColumn;

    const config: ExpandableTableConfig = {
        uniqueTableName: UniqueTableName.CashBankBalances,
        title: 'Bank Balances',
        size: TableSize.Medium,
        columns: columnsConfig, // staticColumns.concat(compColumnsConfig),
        grandTotal: true,
        // grandTotalRowName: GRAND_TOTAL_ROW,
        aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
            return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
        },
        pivotDepth: 1,
        defaultExpandable: 0,
        stickyHeader: true,
    };

    return <ExpandableTable data={data} config={config} />;
};
export default accFetchConnector(BankBalancesComp);
