import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { RootState } from '../reducers';
import { siteErrorCloseAction, siteErrorOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import PrimaryDrawer from './PrimaryDrawer';
import { getRealUser } from '../common/auth';
import { config } from '../config';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        appBar: {
            // position: 'relative', // For z-index setting to work position needs to be relative not static
            // We need to not only exceed drawer but modal as well
            // https://material-ui.com/customization/z-index/
            zIndex: theme.zIndex.modal + 1,
        },
        userMenu: {
            // backgroundColor: 'red',
            // zIndex: 2500,
            // position: 'relative',
        },
    })
);

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
    siteErrorCloseAction: siteErrorCloseAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    signOut: () => void;
};

function MainTopAppBar(props: Props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Drawer
    const [primaryDrawerState, setPrimaryDrawerState] = React.useState<boolean>(false);

    let realUser = getRealUser(props.authUser);

    let perEnvConfigs = {
        // Default production
        appBarColor: '#113f7f',
        text: '',
    };
    // @ts-ignore  For some reason it thinks that SWISS_TECH_ENV needs to be development, production or test
    if (config.SWISS_TECH_ENV === 'staging')
        perEnvConfigs = {
            appBarColor: '#cccc04',
            text: 'Staging',
        };
    if (config.SWISS_TECH_ENV === 'development')
        perEnvConfigs = {
            appBarColor: '#5c9efa',
            text: 'Development',
        };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} style={{ backgroundColor: perEnvConfigs.appBarColor }}>
                <Toolbar variant={'dense'}>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setPrimaryDrawerState(!primaryDrawerState);
                        }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        SwissChemGas {perEnvConfigs.text}
                    </Typography>
                    <Typography variant="h6" onClick={handleMenu} style={{ cursor: 'pointer' }}>
                        {realUser.given_name}
                    </Typography>
                    <Menu
                        PaperProps={{
                            style: {
                                // We can't figure how to display menu above the app bar so we just move it more down
                                marginTop: '50px',
                            },
                        }}
                        className={classes.userMenu}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        <MenuItem
                            style={{ zIndex: 2501 }}
                            onClick={() => {
                                setAnchorEl(null);
                                props.signOut();
                            }}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <PrimaryDrawer primaryDrawerState={primaryDrawerState} setPrimaryDrawerState={setPrimaryDrawerState} />
        </div>
    );
}

export default connector(MainTopAppBar);
