import React  from 'react';
import {AllTradesPropsType} from '../Types';
import {MonthAField, YearAField} from "./promptA";
import {BalmoField} from "./BalmoDate";
import {MonthBField, YearBField} from "./promptB";

interface PromptFieldsProps extends AllTradesPropsType {}

export function PromptFields(props: PromptFieldsProps) {
    return (
        <div>
            <MonthAField {...props} />
            <YearAField {...props} />
            <BalmoField {...props} />
            <MonthBField {...props} />
            <YearBField {...props} />
        </div>
    );
}