import React from 'react';
import { ExchangeTradesPropsType } from '../Types';
import VolumeField from '../base/VolumeField';
import { volumeRangeError } from '../common';
import Tooltip from '@material-ui/core/Tooltip';

interface ConvertedSpreadVolumeProps extends ExchangeTradesPropsType {}

export function ConvertedLegVolume(props: ConvertedSpreadVolumeProps) {
    const { state, setState } = props;

    let diffTooltip: string = !state.convertedLegVolume.required
        ? 'Only enabled for Converted units spreads when not diff (e.g. LST/FEI)'
        : 'Enter the converted units (e.g. for 1kt FEI likely 12,000 BBL of LST)';
    return (
        <Tooltip title={diffTooltip} placement={'top'}>
            <span>
                <VolumeField
                    autofocus={false}
                    marginLeft={'16px'}
                    label={'BBL Volume'}
                    required={state.convertedLegVolume.required}
                    errorText={state.convertedLegVolume.error}
                    disabled={!state.convertedLegVolume.required}
                    value={state.convertedLegVolume.value}
                    handleChange={newValue => {
                        let errorText = '';
                        if (newValue !== '') {
                            errorText = volumeRangeError(newValue);
                            if (!errorText) {
                                // We only want to store the absolute value so ignore the sign
                                newValue = Math.abs(parseInt(newValue)).toString();
                            }
                        }
                        setState({
                            ...state,
                            convertedLegVolume: {
                                ...state.convertedLegVolume,
                                value: newValue,
                                error: errorText
                            }
                        });
                    }}
                />
            </span>
        </Tooltip>
    );
}
