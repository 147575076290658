import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getFetcher } from './fetcher';

export const MIN_WIDTH_PNL_PIVOT_GRAPH = '880px';

const MIN_PNL_DISPLAY_WIDTH_PIXELS = 1500;
const MIN_PNL_FULL_EL_WIDTH_PIXELS = MIN_PNL_DISPLAY_WIDTH_PIXELS - 100;
export const MIN_PNL_DISPLAY_WIDTH = `${MIN_PNL_DISPLAY_WIDTH_PIXELS}px`;

export const MIN_12_12_EL_WIDTH = `${MIN_PNL_FULL_EL_WIDTH_PIXELS}px`;
export const MIN_11_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(11 / 12))}px`;
export const MIN_10_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(10 / 12))}px`;
export const MIN_9_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(9 / 12))}px`;
export const MIN_8_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(8 / 12))}px`;
export const MIN_7_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(7 / 12))}px`;
export const MIN_6_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(6 / 12))}px`;
export const MIN_5_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(5 / 12))}px`;
export const MIN_4_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(4 / 12))}px`;
export const MIN_3_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(3 / 12))}px`;
export const MIN_2_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(2 / 12))}px`;
export const MIN_1_12_EL_WIDTH = `${Math.floor(MIN_PNL_FULL_EL_WIDTH_PIXELS * Math.floor(1 / 12))}px`;

export const STANDARD_GRAPH_ELEMENT_HEIGHT = '474px';

export const queryString = (params: { [string: string]: string }) =>
    Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&');

type CenteredCircularProgressProps = {
    msg?: string;
};

export const CenteredCircularProgress = (props: CenteredCircularProgressProps) => {
    return (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <div style={{ textAlign: 'center' }}>
                <CircularProgress />
            </div>
            <div style={{ marginTop: '25px' }}>{props.msg && <span>{props.msg}</span>}</div>
        </div>
    );
};

export const getDate = (fromToday: number) => {
    let yesterdaysDate = new Date();
    yesterdaysDate.setDate(yesterdaysDate.getDate() - fromToday);
    yesterdaysDate.setHours(0, 0, 0, 0);
    return yesterdaysDate;
};

export function useInitDataFetching<D>(url: string, defaultData: D, params?: any) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<D>(defaultData);
    const [initErr, setInitErr] = useState('');

    // We need to use this to avoid infinite loop
    const { current: myParams } = useRef(params);

    useEffect(() => {
        getFetcher<D>({
            url: url,
            params: myParams,
            onStart: () => {
                setLoading(true);
            },
            onSuccess: res => {
                setData(res);
            },
            onFail: err => {
                setInitErr(err.parsedMsg);
            },
            onFinal: () => {
                setLoading(false);
            }
        });
    }, [url, myParams]);

    return {
        initErr,
        loading,
        data
    };
}

export function useDataPollingFetching<D>(url: string, defaultData: D, interval: number) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<D>(defaultData);
    const [initErr, setInitErr] = useState('');

    const getDataPolling = useCallback(() => {
        getFetcher<D>({
            url: url,
            onStart: () => {},
            onSuccess: res => {
                setData(res);
                setInitErr('');
            },
            onFail: err => {
                setInitErr(err.parsedMsg);
            },
            onFinal: () => {
                setLoading(false);
            }
        });
    }, [url]);

    const triggerForceRefresh = () => {
        getDataPolling();
    };

    useEffect(() => {
        const pollingFunc = setInterval(() => {
            getDataPolling();
        }, interval);
        return () => clearInterval(pollingFunc);
    }, [getDataPolling, url, interval]);

    useEffect(() => {
        getDataPolling();
    }, [getDataPolling]);

    return {
        initErr,
        loading,
        data,
        triggerForceRefresh
    };
}

export function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}
