import React, { useEffect, useState } from 'react';
import { SimpleTableConfig, SimpleTableData, SimpleTableRow } from './types';
import { swissAssert } from '../common/swissAssert';
import SimpleTable from './SimpleTable';

export type ExpandableTableConfig = SimpleTableConfig & {
    defaultExpandable: number;
    grandTotalRowName?: string;
};

type ExpandedRows = Set<string>;

type ExpandableTableProps = {
    data: SimpleTableData;
    config: ExpandableTableConfig;
};

const ExpandableTable = (props: ExpandableTableProps) => {
    swissAssert(props.config.pivotDepth > 0, 'An Expandable table must have at least 1 dpeth');

    let totalRowName = props.config.grandTotalRowName ? props.config.grandTotalRowName : 'Total';

    const resetExpandableHelper = (depth?: number): ExpandedRows => {
        let resultDepth = depth !== undefined ? depth : props.config.defaultExpandable;
        let tmpExpandedRows = new Set<string>();
        let firstColumnName = props.config.columns[0].name;
        props.data.forEach((row) => {
            if (resultDepth > row['_depth'] && row[firstColumnName] !== totalRowName) tmpExpandedRows.add(row['_key']! as string);
        });
        return tmpExpandedRows;
    };

    const resetExpandable = (depth?: number) => {
        setExpandedRows(resetExpandableHelper(depth));
    };

    const [expandedRows, setExpandedRows] = useState<ExpandedRows>(resetExpandableHelper());
    const [displayRows, setDisplayRows] = useState<Array<any>>();

    const toggleExpandedRow = (row: SimpleTableRow) => {
        let rowKey = row['_key'] as string;
        let tmpExpandedRows = new Set(expandedRows);
        tmpExpandedRows.has(rowKey) ? tmpExpandedRows.delete(rowKey) : tmpExpandedRows.add(rowKey);
        setExpandedRows(tmpExpandedRows);
    };

    useEffect(() => {
        // console.log('recalculating rows');
        // console.log(expandedRows);
        // TODO: Why any? Typescript isn't letting me put SimpleTableRow
        let displayRowsTmp: Array<any> = [];
        let firstColumnName = props.config.columns[0].name;
        // The depth will track which depth we are on so to decide whether to skip a row or not
        let blockingExpansionDepth: undefined | number = undefined;
        props.data.forEach((row) => {
            if (blockingExpansionDepth !== undefined && row['_depth'] > blockingExpansionDepth) return;
            let rowKey = row['_key'] as string;
            let isExpandable = row['_depth'] !== props.config.pivotDepth && row[firstColumnName] !== totalRowName;
            let isExpanded = isExpandable && expandedRows.has(rowKey);
            blockingExpansionDepth = isExpanded ? undefined : (row['_depth'] as number);
            displayRowsTmp.push({ ...row, _isExpandable: isExpandable, _isExpanded: isExpanded });
        });
        setDisplayRows(displayRowsTmp);
    }, [props, expandedRows, totalRowName]);

    if (!displayRows) return null;

    return (
        <SimpleTable
            data={displayRows}
            allData={props.data}
            config={{ ...props.config }}
            toggleExpandedRow={toggleExpandedRow}
            changeExpansion={resetExpandable}
        />
    );
};

export default ExpandableTable;
