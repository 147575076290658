import React from 'react';
import { ExchangeTradesPropsType } from '../Types';
import { TextField } from '@material-ui/core';

interface ClearerTradeIDsProps extends ExchangeTradesPropsType {}

export function ClearerTradeIDsField(props: ClearerTradeIDsProps) {
    const { state, setState } = props;

    return (
        <TextField
            error={Boolean(state.clearerTradeIDs.error)}
            helperText={state.clearerTradeIDs.error}
            disabled={props.disabled}
            placeholder="Clearer ID(s)"
            value={state.clearerTradeIDs.value}
            style={{ verticalAlign: 'bottom', minHeight: '4rem', marginLeft: '16px' }}
            // style={{ width: 240 }}
            onChange={(event) => {
                let newValue: string = event.target.value;
                let clearerIDErrorText = '';
                // We disabled multiline edits in clearer ID, but just in case to avoid crashing the app
                if (newValue.includes('\n') || newValue.includes('\t')) {
                    clearerIDErrorText = 'Invalid characters \\. Dont add new lines or tabs';
                }
                setState({
                    ...state,
                    clearerTradeIDs: {
                        ...state.clearerTradeIDs,
                        value: newValue,
                        error: clearerIDErrorText,
                    },
                });
            }}
        />
    );
}
