import React from 'react';
import AutoCompleteField from '../base/AutoCompleteField';
import {AllTradesPropsType} from '../Types';
import { derivedVolume } from '../common';

interface VolumeMetricFieldProps extends AllTradesPropsType {}

export function VolumeMetricField(props: VolumeMetricFieldProps) {
    const { options } = props;

    return (
        <AutoCompleteField
            options={options.volume_metrics}
            inputValue={derivedVolume(props.options, props.state)}
            handleChange={_ => {}}
            required={true}
            label={'Metric'}
            width={80}
            marginLeft={'16px'}
            disabled={true}
            tooltipText={'Calculated from products selections'}
        />
    );
}
