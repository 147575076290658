import { UniqueTableName } from '../common/uniqueTableName';

export const SET_ACTIVE_TABLE = 'SET_ACTIVE_TABLE';

export interface SetActiveTablePayloadType {
    uniqueTableName: null | UniqueTableName;
}

export interface SetActiveTableActionType {
    type: typeof SET_ACTIVE_TABLE;
    payload: SetActiveTablePayloadType;
}

export const setActiveTableAction = (payload: SetActiveTablePayloadType): SetActiveTableActionType => ({
    type: SET_ACTIVE_TABLE,
    payload: payload
});

export type FooterAggActionTypes = SetActiveTableActionType;
