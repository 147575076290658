import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Business } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Add, AttachMoney, Check } from '@material-ui/icons';
import { RootState } from '../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { canAccessAccounting, canAccessBackAccounting } from '../accounting/common';

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        width: 250,
    },
    // toolbar: theme.mixins.toolbar
}));

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PrimaryDrawerProps = PropsFromRedux & {
    primaryDrawerState: boolean;
    setPrimaryDrawerState: (newVal: boolean) => void;
};

function PrimaryDrawer(props: PrimaryDrawerProps) {
    const classes = useStyles();
    let history = useHistory();

    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            // onClick={toggleDrawer(side, false)}
            // onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <MenuItem
                    button
                    onClick={() => {
                        history.push('/');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <AttachMoney />
                    </ListItemIcon>
                    <ListItemText primary={'PNL'} />
                </MenuItem>
                {canAccessAccounting(props.authUser) && (
                    <MenuItem
                        button
                        onClick={() => {
                            history.push('/accounting');
                            props.setPrimaryDrawerState(!props.primaryDrawerState);
                        }}>
                        <ListItemIcon>
                            <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Accounting'} />
                    </MenuItem>
                )}
                {canAccessBackAccounting(props.authUser) && (
                    <MenuItem
                        button
                        onClick={() => {
                            history.push('/back-accounting');
                            props.setPrimaryDrawerState(!props.primaryDrawerState);
                        }}>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary={'Back Office Accounting'} />
                    </MenuItem>
                )}
                <MenuItem
                    button
                    onClick={() => {
                        history.push('/edf');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    <ListItemText primary={'EDF Trades'} />
                </MenuItem>
                <MenuItem
                    button
                    onClick={() => {
                        history.push('/blotter');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText primary={'Blotter'} />
                </MenuItem>
                <MenuItem
                    button
                    onClick={() => {
                        history.push('/tasks/history');
                        props.setPrimaryDrawerState(!props.primaryDrawerState);
                    }}>
                    <ListItemIcon>
                        <EventAvailableIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Tasks'} />
                </MenuItem>
            </List>
        </div>
    );

    return (
        <Drawer
            open={props.primaryDrawerState}
            onClose={() => {
                props.setPrimaryDrawerState(!props.primaryDrawerState);
            }}>
            {/* Add the dense toolbar height */}
            <div className={'MuiToolbar-dense'} />
            {sideList()}
        </Drawer>
    );
}

export default connector(PrimaryDrawer);
