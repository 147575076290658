import React, { useCallback, useMemo } from 'react';
import { siteErrorOpenAction } from '../actions/siteAlerts';
import { connect, ConnectedProps } from 'react-redux';
import { RootState, swissUseSelector } from '../reducers';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { newSimpleFetcher } from '../common/fetcher';
import moment from 'moment-timezone';
import { useZusStore } from '../reducers/appStore';
import { AuthUserState } from '../reducers/authUser';
import { useMainSnackbar } from '../common/SwissSnackBar';
import { useConfirm } from 'material-ui-confirm';
import { ClearerBlotterTrade } from './types';
import { TradeRow } from './TradeRow';
import { BooksStrategiesType } from '../newTrade/Types';

const mapState = (state: RootState) => ({
    authUser: state.authUser,
});

const mapDispatch = {
    siteErrorOpenAction: siteErrorOpenAction,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ClearerBlotterProps = PropsFromRedux & {
    brokers: Array<string>;
    books: Array<BooksStrategiesType>;
};

const ClearerBlotter = ({ books, brokers }: ClearerBlotterProps) => {
    const { clearerBlotterTrades } = useZusStore((state) => ({
        clearerBlotterTrades: state.clearerBlotterTrades!,
    }));
    const {
        enableBackdropSpinner,
        disableBackdropSpinner,
        updateClearerBlotterTradeBroker,
        updateClearerBlotterTradeBook,
        updateClearerBlotterTradeStrategy,
        updateClearerBlotterTradeDeal,
        updateClearerBlotterTradeSubmitted,
        updateClearerBlotterTradeCommission,
    } = useZusStore((state) => ({
        enableBackdropSpinner: state.enableBackdropSpinner,
        disableBackdropSpinner: state.disableBackdropSpinner,
        updateClearerBlotterTradeBroker: state.updateClearerBlotterTradeBroker,
        updateClearerBlotterTradeBook: state.updateClearerBlotterTradeBook,
        updateClearerBlotterTradeStrategy: state.updateClearerBlotterTradeStrategy,
        updateClearerBlotterTradeDeal: state.updateClearerBlotterTradeDeal,
        updateClearerBlotterTradeSubmitted: state.updateClearerBlotterTradeSubmitted,
        updateClearerBlotterTradeCommission: state.updateClearerBlotterTradeCommission,
    }));

    const confirm = useConfirm();
    const authUser = swissUseSelector<AuthUserState>((state) => state.authUser);
    const mainSnackbar = useMainSnackbar();

    const blueBorderTrade = useMemo(() => {
        let highestDate = clearerBlotterTrades[0].trade_date;
        let secondHighestDateTrade = null;
        for (const trade of clearerBlotterTrades) {
            if (new Date(trade.trade_date) < new Date(highestDate)) {
                secondHighestDateTrade = trade;
                break;
            }
        }
        return secondHighestDateTrade;
    }, [clearerBlotterTrades]);

    const handleUndo = useCallback(
        (index: number, swissOrderNum: string) => {
            confirm({ description: 'This does not undo it from the Blotter Google sheet, just here visually' })
                .then(() => {
                    newSimpleFetcher<any>({
                        method: 'DELETE',
                        authUserState: authUser,
                        appStore: {
                            enableBackdropSpinner,
                            disableBackdropSpinner,
                        },
                        mainSnackbar: mainSnackbar,
                        withBackdropSpinner: true,
                        withErrorSnackbar: true,
                        url: '/blotter',
                        params: {
                            swiss_order_num: swissOrderNum,
                        },
                        onSimpleSuccess: (resp) => {
                            updateClearerBlotterTradeSubmitted(index, 0);
                        },
                    });
                })
                .catch(() => {
                    // Do nothing
                });
        },
        [authUser, mainSnackbar, enableBackdropSpinner, disableBackdropSpinner, confirm, updateClearerBlotterTradeSubmitted]
    );

    const handleSubmit = useCallback(
        (index: number, blotterTrade: ClearerBlotterTrade) => {
            newSimpleFetcher<any>({
                method: 'POST',
                authUserState: authUser,
                appStore: {
                    enableBackdropSpinner,
                    disableBackdropSpinner,
                },
                mainSnackbar: mainSnackbar,
                withBackdropSpinner: true,
                withErrorSnackbar: true,
                url: '/tasks/all/sheets/blotter/enter_clearer_regular_trade',
                data: {
                    swiss_order_num: blotterTrade.swiss_order_num,
                    trade_date: moment(blotterTrade.trade_date).format('YYYY-MM-DD'),
                    volume: blotterTrade.volume,
                    month_a: blotterTrade.month_a,
                    year_a: blotterTrade.year_a,
                    balmo_date: blotterTrade.balmo_date !== null ? moment(blotterTrade.balmo_date).format('YYYY-MM-DD') : null,
                    month_b: blotterTrade.month_b !== '' ? blotterTrade.month_b : null,
                    year_b: blotterTrade.year_b,
                    product_a: blotterTrade.product_a,
                    price_a: blotterTrade.price_a,
                    product_b: blotterTrade.product_b !== '' ? blotterTrade.product_b : null,
                    diff: blotterTrade.is_diff,
                    mini: blotterTrade.is_mini,
                    converted_leg_volume: blotterTrade.converted_leg_volume,
                    book: blotterTrade.book,
                    strategy: blotterTrade.strategy,
                    deal: blotterTrade.deal,
                    exchange: blotterTrade.exchange,
                    broker: blotterTrade.broker !== '' ? blotterTrade.broker : null,
                    commission_override: blotterTrade.commission_override,
                    executing_for: blotterTrade.trader_email,
                    notes: null,
                    clearer_trade_ids: blotterTrade.clearer_trade_ids,
                },
                onSimpleSuccess: (resp) => {
                    updateClearerBlotterTradeSubmitted(index, 1);
                    mainSnackbar.showSuccessSnackbar(`Ordered trade ${blotterTrade.swiss_order_num} entered successfully in the Blotter`);
                },
            });
        },
        [authUser, mainSnackbar, enableBackdropSpinner, disableBackdropSpinner, updateClearerBlotterTradeSubmitted]
    );

    return (
        <Box className={'MuiToolbar-gutters'} my={2}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Action</TableCell>
                            <TableCell>Broker</TableCell>
                            <TableCell>Book</TableCell>
                            <TableCell>Strategy</TableCell>
                            <TableCell>Deal</TableCell>
                            <TableCell>Commission</TableCell>
                            <TableCell>Trade Date</TableCell>
                            <TableCell>Exchange</TableCell>
                            <TableCell>Volume</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Tenor</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell style={{ width: '80px' }}>Balmo Date</TableCell>
                            <TableCell>Converted Leg Vol</TableCell>
                            <TableCell>Clearer Trade IDs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clearerBlotterTrades?.map((blotterTrade, index) => (
                            <TradeRow
                                key={blotterTrade.swiss_order_num}
                                index={index}
                                blotterTrade={blotterTrade}
                                books={books}
                                brokers={brokers}
                                blueBorder={blueBorderTrade?.swiss_order_num === blotterTrade.swiss_order_num}
                                handleUndo={handleUndo}
                                handleSubmit={handleSubmit}
                                updateClearerBlotterTradeBroker={updateClearerBlotterTradeBroker}
                                updateClearerBlotterTradeBook={updateClearerBlotterTradeBook}
                                updateClearerBlotterTradeStrategy={updateClearerBlotterTradeStrategy}
                                updateClearerBlotterTradeDeal={updateClearerBlotterTradeDeal}
                                updateClearerBlotterTradeCommission={updateClearerBlotterTradeCommission}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default connector(ClearerBlotter);
