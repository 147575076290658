import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { AllTradesRunDateDataResp } from '../fetchTypes';
import { SimpleTableColumnConfig, SimpleTableConfig, SimpleTableRow } from '../../tables/types';
import SimpleTable from '../../tables/SimpleTable';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import {
    greenOnlyReversedHundred,
    kFormatter,
    optNumberFormat,
    redGreenHundred,
    redGreenReversedHundred,
    redOnlyHundred,
} from '../../containers/pivotHelper';
import { kFormatterWithSign } from './common';
import { TrendDirection2Size } from '../../common/TrendDirection2';
import { UniqueTableName } from '../../common/uniqueTableName';

type Props = FetchPNLAnalyticsPropsFromRedux & {
    allTradesRunDateDataTableKey: string;
    config: SimpleTableConfig;
    classes: ClassNameMap;
    defaultTableHeight: string;
};

export const rawNumFormat = (raw_val: number | null | undefined) => {
    return optNumberFormat(raw_val, 0, false, false, false);
};

export const isInvalidInactiveStrategy = (row: SimpleTableRow) => row['is_active'] === 0 && row['strategy'] !== 'Inactive Strategies';

export const commonPNLSummaryTableColumns: Array<SimpleTableColumnConfig> = [
    {
        name: 'gross_pnl',
        title: 'Gross',
        bodyTemplate: kFormatter,
        hoverElement: (row, val) => {
            let raw_val = row['gross_pnl'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        textColor: redOnlyHundred,
    },
    {
        name: 'total_cost',
        title: 'Cost',
        bodyTemplate: kFormatterWithSign,
        hoverElement: (row, val) => {
            let raw_val = row['total_cost'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        textColor: greenOnlyReversedHundred,
    },
    {
        name: 'net_pnl',
        title: 'Net',
        bodyTemplate: kFormatter,
        hoverElement: (row, val) => {
            let raw_val = row['net_pnl'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        textColor: redOnlyHundred,
    },
    {
        name: 'delta_net_pnl',
        title: 'DtD Net',
        bodyTemplate: kFormatter,
        hoverElement: (row, val) => {
            let raw_val = row['delta_net_pnl'] as number;
            let tooltipText = `${rawNumFormat(raw_val)}`;
            if (isInvalidInactiveStrategy(row)) {
                tooltipText = `This inactive strategy moved by ${tooltipText} This should likely not happen`;
            }
            return <div>{tooltipText}</div>;
        },
        textColor: redGreenHundred,
        trendDirection: { downIsBad: true, size: TrendDirection2Size.Small, zeroThreshold: 100 },
    },
    {
        name: 'var_95',
        title: 'VaR',
        bodyTemplate: kFormatter,
        hoverElement: (row, val) => {
            let raw_val = row['var_95'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
    },
    {
        name: 'delta_var_95',
        title: 'DtD VaR',
        bodyTemplate: kFormatter,
        hoverElement: (row, val) => {
            let raw_val = row['delta_var_95'] as number;
            return <div>{rawNumFormat(raw_val)}</div>;
        },
        textColor: redGreenReversedHundred,
        trendDirection: { downIsBad: false, size: TrendDirection2Size.Small, zeroThreshold: 100 },
    },
];

const CommonPNLSummaryTable = (props: Props) => {
    const allTradesRunDateData = props.pnlAnalytics.all_trades_run_date;

    if (allTradesRunDateData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.AllTradesRunDate}
                data={allTradesRunDateData}
                minHeight={props.defaultTableHeight}
            />
        );
    }

    let config = props.config;
    let respData = allTradesRunDateData.data! as AllTradesRunDateDataResp;

    config.columns[config.columns.length - 2].headerCellClassName = props.classes.headerVaRCell;
    config.columns[config.columns.length - 2].bodyCellsClassName = props.classes.bodyVaRCell;
    config.columns[config.columns.length - 1].headerCellClassName = props.classes.headerDtDVaRCell;
    config.columns[config.columns.length - 1].bodyCellsClassName = props.classes.bodyDtDVaRCell;
    return (
        <React.Fragment>
            {/* Used for restpack.io shutter to know when the website has loaded */}
            {props.config.uniqueTableName === UniqueTableName.Overview && (
                <span className={'overview_table_loaded_class'} id={'unique_overview_table_loaded_id'} />
            )}
            <SimpleTable data={respData[props.allTradesRunDateDataTableKey as keyof AllTradesRunDateDataResp]} config={config} />
        </React.Fragment>
    );
};

export default fetchConnector(CommonPNLSummaryTable);
